import React from "react";
import axios from "axios";
import { Card, CardBody, Col, Row } from "reactstrap";
import { REQUEST_URL } from "../config/urls";
import NavBar from "../components/NavBar";
import {TabelaVoucherAlphaE} from "../components/TabelaVoucherAlphaE";

axios.defaults.headers.common = {'Authorization': sessionStorage.getItem("token")};

class MeusVouchers extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      usuarioLogado: {},
      vendedores: [],
      renderTable: false
    }
  }

  async componentWillMount () {
    axios.defaults.headers.common = {'Authorization': sessionStorage.getItem("token")};
    axios.get(`${REQUEST_URL}/vendedores`).then((response) => {
      let usuario = JSON.parse(sessionStorage.getItem("usuarioLogado"))[0];
      this.setState({usuarioLogado: usuario, vendedores: response.data, renderTable: true});
    }).catch((error) => {
      console.log(error);
    });
  }

  render () {
    return (
      <div>
        <NavBar className="no-print" nome={this.state.usuarioLogado.Nome} funcao={this.state.usuarioLogado.Funcao} />
        <Row style={{ margin: "0px 0px 0px 0px", }}>
          <Col style={{ marginTop: 20 }} id="container-orc1">
            <Card style={{ border: "none", borderRadius: 0, margin: 10, boxShadow: "0 2px 5px 0 rgba(0,0,0,.26)" }}>
              <CardBody>
                <Row>
                  {/* {
                    this.state.renderTable && this.state.usuarioLogado.idUsuario ?
                      <Col sm="12"><TabelaVoucherAlphaE vendedor={this.state.usuarioLogado && this.state.usuarioLogado.Funcao === "ORCAMENTISTA" ? 0 : this.state.usuarioLogado.idUsuario} ehOrcamentista={this.state.usuarioLogado && this.state.usuarioLogado.Funcao === "ORCAMENTISTA"} vendedores={this.state.vendedores}/></Col>
                    : null
                  } */}
                  <div style={{display: "flex", justifyContent: "center", width: "100%", alignItems: "center", fontWeight: 600}}> <i className="fa fa-exclamation-circle" style={{marginRight: 5}}></i> Este recurso foi desativado.</div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (MeusVouchers);