import React from 'react';

import axios from 'axios';
import { toast } from 'react-toastify';
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  CustomInput,
  Button,
} from 'reactstrap';

import { REQUEST_URL } from '../config/urls';

export default class ModalAdicionarItem extends React.Component {
  state = {
    servico: {},
    projetos: [],
    acabamentos: [],
    itens: [],
    listaDescricao: [],
    listaPrecos: [],
    listaPrecosIndigo: [],
    acabamentosSelecionados: [],
    inputQuantidade: 0,
    orcamento: {},
    carregando: false,
    precoDaArteT: 0,
    precoDoFreteT: 0,
    precoDaArteU: 0,
    precoDoFreteU: 0,
    ehMiolo29: false,
    margemAdicional: 0,
  };

  async handleGetValoresAdicionais() {
    const { data } = await axios.get(`${REQUEST_URL}/valor-arte`);
    this.setState({ margemAdicional: data.adicional_margem });
  }

  componentDidMount() {
    this.handleGetValoresAdicionais();
  }

  selecionarServico(servico) {
    this.handleGetValoresAdicionais();
    this.setState({
      carregando: true,
      servico: servico,
      projetos: [],
      itens: [],
      listaDescricao: [],
      listaPrecos: [],
      listaPrecosIndigo: [],
      inputQuantidade: 0,
      acabamentosSelecionados: [],
    });
    axios
      .post(`${REQUEST_URL}/Produto/listarProjetos`, { servico: servico })
      .then((response) => {
        this.setState({ carregando: false, projetos: response.data });
        document.getElementById(
          'body-add-item'
        ).scrollTop = document.getElementById('elem-1').offsetTop;
        axios
          .post(`${REQUEST_URL}/Produto/lista_acabamentos`, {
            servico: servico,
          })
          .then((response) => {
            this.setState({ acabamentos: response.data });
          });
      })
      .catch((erro) => {
        this.props.criarLog({
          erro: erro,
          msg: `Não foi possível carregar os projetos | Serviço: ${JSON.stringify(
            servico
          )}`,
        });
        this.setState({ carregando: false });
        toast.error('Não foi possivel carregar os projetos');
      });
  }

  selecionarProjeto(projeto) {
    this.setState({
      carregando: true,
      itens: [],
      listaDescricao: [],
      listaPrecos: [],
      listaPrecosIndigo: [],
      inputQuantidade: 0,
      acabamentosSelecionados: [],
    });
    axios
      .post(`${REQUEST_URL}/Produto/selecionarDescricao`, { projeto: projeto })
      .then((response) => {
        this.setState({ carregando: false, itens: response.data });
        document.getElementById(
          'body-add-item'
        ).scrollTop = document.getElementById('elem-2').offsetTop;
      })
      .catch((erro) => {
        this.props.criarLog({
          erro: erro,
          msg: `Não foi possível carregar os itens | Projeto: ${JSON.stringify(
            projeto
          )}`,
        });
        this.setState({ carregando: false });
        toast.error('Não foi possivel carregar os itens');
      });
  }

  selecionarItemFinal(item) {
    this.setState({
      carregando: true,
      listaDescricao: [],
      listaPrecos: [],
      listaPrecosIndigo: [],
      inputQuantidade: 0,
      acabamentosSelecionados: [],
    });
    axios
      .post(`${REQUEST_URL}/Produto/listaDescricao`, { item: item })
      .then((response) => {
        this.setState({ listaDescricao: response.data, carregando: false });
        document.getElementById(
          'body-add-item'
        ).scrollTop = document.getElementById('elem-3').offsetTop;
      })
      .catch((erro) => {
        this.props.criarLog({
          erro: erro,
          msg: `Não foi possível carregar as descrições | Item: ${JSON.stringify(
            item
          )}`,
        });
        this.setState({ carregando: false });
        toast.error('Não foi possivel carregar as descrições');
      });
  }

  selecionarDescricao(descricao) {
    if (
      descricao.DescProoduto === '29X29' &&
      descricao.DescrOrcamento.includes('Miolo')
    )
      this.setState({ ehMiolo29: true });
    this.setState({
      carregando: true,
      listaPrecos: [],
      listaPrecosIndigo: [],
      inputQuantidade: 0,
      acabamentosSelecionados: [],
    });
    axios
      .post(REQUEST_URL + '/Produto/lista_precos', { item: descricao })
      .then((response) => {
        this.setState({ listaPrecos: response.data, carregando: false });
        document.getElementById(
          'body-add-item'
        ).scrollTop = document.getElementById('elem-4').offsetTop;
      })
      .catch((erro) => {
        this.props.criarLog({
          erro: erro,
          msg: `Não foi possível carregar os preços | Descrição: ${JSON.stringify(
            descricao
          )}`,
        });
        this.setState({ carregando: false });
        toast.error('Não foi possivel carregar os preços');
      });

    if (this.state.itens[0].IDProject === 846) {
      axios
        .post(`${REQUEST_URL}/Produto/selecionarMiolos`, { miolos: descricao })
        .then((response) => {
          let orcamentoNaIndigo = response.data.filter(
              (orc) => orc.Description === descricao.Description
            )[0],
            descricao_indigo = {
              IdQuote: orcamentoNaIndigo.IdQuote,
              Description: orcamentoNaIndigo.Description,
            };

          axios
            .post(`${REQUEST_URL}/Produto/lista_precos`, {
              item: descricao_indigo,
            })
            .then((response) => {
              this.setState({
                listaPrecosIndigo: response.data,
                carregando: false,
              });
            })
            .catch((erro) => {
              this.props.criarLog({
                erro: erro,
                msg: `Não foi possível carregar os preços | Item: ${JSON.stringify(
                  descricao_indigo
                )}`,
              });
              this.setState({ carregando: false });
              toast.error('Não foi possivel carregar os preços');
            });
        })
        .catch((erro) => {
          this.props.criarLog({
            erro: erro,
            msg: 'Não foi possível buscar os miolos na indigo',
          });
          this.setState({ carregando: false });
          toast.error('Não foi possivel buscar os miolos na indigo');
        });
    }
  }

  pushAcabamento(acabamento) {
    let { acabamentosSelecionados } = this.state;

    if (acabamentosSelecionados.includes(acabamento)) {
      acabamentosSelecionados = acabamentosSelecionados.filter(
        (a) => a !== acabamento
      );
    } else {
      acabamentosSelecionados.push(acabamento);
    }

    this.setState({ acabamentosSelecionados });
  }

  somarMargens(orc = null) {
    let orcamento = orc === null ? this.props.orcamento : orc,
      somaMargens =
        orcamento.margens.cv +
        orcamento.margens.ce +
        orcamento.margens.ca +
        orcamento.margens.autVen +
        orcamento.margens.autGer +
        orcamento.margens.ci +
        orcamento.margens.mu +
        orcamento.margens.isv;

    if (somaMargens > 100 || somaMargens < 0) {
      somaMargens = 99;
    }

    orcamento.somaMargens = 1 - somaMargens / 100;
    return orcamento.somaMargens;
  }

  addItemOrcamento = async () => {
    let as = this.state.acabamentosSelecionados,
      quantidade = Number(this.state.inputQuantidade),
      precoTotalAcabamentos = 0,
      precoUnitarioAcabamentos = 0;

    for (let i = 0; i < as.length; i++) {
      await axios
        .post(`${REQUEST_URL}/Produto/lista_precos_acabamentos`, {
          cod: as[i].IdOpcaoProduto,
        })
        .then((response) => {
          let qtdAcab = as[i].Description.includes('Unico') ? 1 : quantidade,
            precoTotal = this.calcularValorItem(qtdAcab, response.data, 0);

          as[i].listaPrecos = response.data;
          as[i].precoTotal = Number(precoTotal);
          as[i].qtd = qtdAcab;
          as[i].precoUnitario = Number(precoTotal / qtdAcab);
          as[i].qtdBrinde = 0;
          as[i].precoTotalBrinde = 0;
          precoTotalAcabamentos += as[i].precoTotal;
          precoUnitarioAcabamentos += Number(as[i].precoUnitario);
        });
    }

    let orcamento = Object.assign({}, this.props.orcamento),
      item = this.state.listaPrecos[0],
      itemIndigo = this.state.listaPrecosIndigo[0],
      precoTotal = this.calcularValorItem(
        quantidade,
        this.state.listaPrecos,
        0
      ),
      precoTotalIndigo = this.calcularValorItem(
        quantidade,
        this.state.listaPrecosIndigo,
        0
      );

    if (
      precoTotalIndigo < precoTotal &&
      this.state.listaPrecosIndigo.length > 0
    ) {
      precoTotal = precoTotalIndigo;
    }

    let itemNovo = {
      IdQuote: item.IdQuote,
      IdQuoteIndigo: itemIndigo ? itemIndigo.IdQuote : 0,
      nome: item.DescrOrcamento,
      detalhes: item.DescProoduto.toUpperCase(),
      codItem: item.Description,
      qtd: quantidade,
      qtdBrinde: 0,
      precoTotalBrinde: 0,
      precoUnitario: Number(precoTotal / quantidade),
      precoTotal: Number(precoTotal),
      listaPrecos: this.state.listaPrecos,
      listaPrecosIndigo: this.state.listaPrecosIndigo,
      acabamentos: as,
      precoTotalAcabamentos: Number(precoTotalAcabamentos),
      precoUnitarioAcabamentos: Number(precoUnitarioAcabamentos),
      precoTotalDoItem: 0,
      precoUnitarioDoItem: 0,
      servico: this.state.servico,
      visivelProposta: true,
      ItemFuturo: this.props.itemFuturo,
    };

    if (itemNovo.servico === 'MIOLO') {
      orcamento.quantidadeMiolo += itemNovo.qtd;
      let precoDaArte =
        this.calcularValorItem(itemNovo.qtd, this.props.listaPrecosArte) +
        this.state.margemAdicional;
      orcamento.precoDaArteT = precoDaArte;
      orcamento.precoDaArteU = precoDaArte / itemNovo.qtd;
      let precoDoFrete = this.calcularValorItem(
        itemNovo.qtd,
        this.props.listaPrecosFrete
      );
      orcamento.precoDoFreteT = precoDoFrete;
      orcamento.precoDoFreteU = precoDoFrete / itemNovo.qtd;

      this.setState(
        {
          precoDaArteT: precoDaArte,
          precoDaArteU: precoDaArte / itemNovo.qtd,
          precoDoFreteT: precoDoFrete,
          precoDoFreteU: precoDoFrete / itemNovo.qtd,
        },
        () => {
          itemNovo.precoTotalDoItem = this.calcularPrecoTotalDoItem(itemNovo);
          itemNovo.precoTotalDoItem =
            itemNovo.precoTotalDoItem / this.somarMargens();
          itemNovo.precoUnitarioDoItem =
            itemNovo.precoTotalDoItem / itemNovo.qtd;
          orcamento.itens.push(itemNovo);
          orcamento.precoTotalComMargens =
            this.calcularPrecoTotal(true, itemNovo.servico) /
            this.somarMargens();
          this.props.close({ ...orcamento, ehMiolo29: this.state.ehMiolo29 });
        }
      );
    } else {
      itemNovo.precoTotalDoItem = this.calcularPrecoTotalDoItem(itemNovo);
      itemNovo.precoTotalDoItem =
        itemNovo.precoTotalDoItem / this.somarMargens();
      itemNovo.precoUnitarioDoItem = itemNovo.precoTotalDoItem / itemNovo.qtd;
      orcamento.itens.push(itemNovo);
      orcamento.precoTotalComMargens =
        this.calcularPrecoTotal(true, itemNovo.servico) / this.somarMargens();
      this.props.close(orcamento);
    }
  };

  calcularPrecoTotalDoItem(item) {
    let {
        possuiArte,
        possuiFrete,
        orcamento,
        possuiPagamentoIndividual,
      } = this.props,
      { precoDaArteU, precoDoFreteU } = this.state,
      precoTotalDoItem = 0;

    if (item.servico === 'MIOLO') {
      let precoDaArte = possuiArte
          ? precoDaArteU * (item.qtd + item.qtdBrinde)
          : 0,
        precoDoFrete = possuiFrete
          ? precoDoFreteU * (item.qtd + item.qtdBrinde)
          : 0;

      precoTotalDoItem =
        item.precoTotal +
        item.precoTotalAcabamentos +
        item.precoTotalBrinde +
        precoDaArte +
        precoDoFrete +
        (possuiPagamentoIndividual
          ? orcamento.valorBoleto + orcamento.valorCartao
          : 0);
    } else {
      precoTotalDoItem =
        item.precoTotal + item.precoTotalAcabamentos + item.precoTotalBrinde;
    }

    precoTotalDoItem += this.distribuirValorBrindePeloItem(precoTotalDoItem);
    return precoTotalDoItem;
  }

  calcularPrecoTotal(precoTotal = false, servico = '') {
    let {
        orcamento,
        possuiPagamentoIndividual,
        possuiArte,
        possuiFrete,
      } = this.props,
      { precoDaArteT, precoDoFreteT } = this.state,
      soma = 0,
      precoTotalAcabamentos = 0,
      precoTotalBrinde = 0,
      precoDaArte = possuiArte ? precoDaArteT : 0,
      precoDoFrete = possuiFrete ? precoDoFreteT : 0;

    if (precoTotal && servico !== 'MIOLO') {
      precoDaArte = orcamento.precoDaArteT;
      precoDoFrete = orcamento.precoDoFreteT;
    }

    orcamento.itens.map((i) => {
      if (i.ItemFuturo === 0) {
        soma = soma + i.precoTotal;
        precoTotalAcabamentos += Number(i.precoTotalAcabamentos);
        precoTotalBrinde += i.precoTotalBrinde;
      }
    });

    return (
      soma +
      precoTotalAcabamentos +
      precoTotalBrinde +
      precoDaArte +
      precoDoFrete +
      (possuiPagamentoIndividual
        ? orcamento.valorBoleto + orcamento.valorCartao
        : 0)
    );
  }

  distribuirValorBrindePeloItem(precoDoItem) {
    let totalBrinde = this.props.orcamento.valorTotalBrindes,
      totalOrcamento = this.calcularPrecoTotal() - totalBrinde;

    if (totalOrcamento > 0) {
      return (precoDoItem / totalOrcamento) * totalBrinde;
    }

    return 0;
  }

  calcularValorItem(qtd, listaPrecos, precoUnitario) {
    if (listaPrecos && listaPrecos.length > 0) {
      let listaAbaixo = listaPrecos.filter((item) => item.QtdTotal <= qtd),
        item1 = listaAbaixo[listaAbaixo.length - 1],
        item2 = listaPrecos.filter((item) => item.QtdTotal >= qtd)[0];

      if (!item1) {
        return item2.PrecoUnitario * qtd;
      } else if (!item2) {
        return item1.PrecoUnitario * qtd;
      } else if (item1 === item2) {
        return item1.PrecoTotal;
      }

      let preco1 = item2.PrecoUnitario,
        preco2 = item1.PrecoUnitario,
        quant1 = item2.QtdTotal,
        quant2 = item1.QtdTotal,
        QtdInformada = qtd;

      return (
        (preco1 /
          (preco1 / preco2 +
            ((1 - preco1 / preco2) / (quant1 - quant2)) *
              (QtdInformada - quant2))) *
        QtdInformada
      );
    }

    return qtd * precoUnitario;
  }

  render() {
    return (
      <div>
        <ModalHeader toggle={() => this.props.close(this.props.orcamento)}>
          Inserir Item
        </ModalHeader>
        <ModalBody style={{ height: 300, overflow: 'auto' }} id='body-add-item'>
          <div className='row'>
            <div className='col-md-12'>
              <form>
                <FormGroup>
                  <div style={{ padding: 10 }}>
                    <h4>1 - Selecione o Serviço</h4>
                    <CustomInput
                      type='radio'
                      id='capadura'
                      name='customRadio'
                      label='CAPA DURA'
                      value='CAPA DURA'
                      onClick={() => this.selecionarServico('CAPA DURA')}
                    />
                    <CustomInput
                      type='radio'
                      id='miolo'
                      name='customRadio'
                      label='MIOLO'
                      value='MIOLO'
                      onClick={() => this.selecionarServico('MIOLO')}
                    />
                    <CustomInput
                      type='radio'
                      id='simples'
                      name='customRadio'
                      label='SIMPLES'
                      value='SIMPLES'
                      onClick={() => this.selecionarServico('SIMPLES')}
                    />
                    <CustomInput
                      type='radio'
                      id='encarte'
                      name='customRadio'
                      label='ENCARTE'
                      value='ENCARTE'
                      onClick={() => this.selecionarServico('ENCARTE')}
                    />
                    <CustomInput
                      type='radio'
                      id='embalagem'
                      name='customRadio'
                      label='EMBALAGEM'
                      value='EMBALAGEM'
                      onClick={() => this.selecionarServico('EMBALAGEM')}
                    />
                    <CustomInput
                      type='radio'
                      id='outros'
                      name='customRadio'
                      label='OUTROS'
                      value='OUTROS'
                      onClick={() => this.selecionarServico('OUTROS')}
                    />
                  </div>
                </FormGroup>
              </form>
            </div>
            <div
              className='col-md-12'
              hidden={!this.state.projetos.length > 0}
              id='elem-1'
            >
              <form>
                <FormGroup>
                  <div style={{ padding: 10 }}>
                    <h4>2 - Selecionar Projeto</h4>
                    {this.state.projetos.map((proj, i) => (
                      <CustomInput
                        key={i}
                        type='radio'
                        id={proj.IDProject}
                        name='customRadio'
                        label={proj.DescrProjeto}
                        value={proj.IDProject}
                        onClick={() => this.selecionarProjeto(proj)}
                      />
                    ))}
                  </div>
                </FormGroup>
              </form>
            </div>
            <div
              className='col-md-12'
              hidden={!this.state.itens.length > 0}
              id='elem-2'
            >
              <form>
                <FormGroup>
                  <div style={{ padding: 10 }}>
                    <h4>3 - Selecione um dos ítens</h4>
                    {this.state.itens.map((itemFinal, i) => (
                      <CustomInput
                        key={i}
                        type='radio'
                        id={itemFinal.IdQuote}
                        name='customRadio'
                        label={itemFinal.DescrOrcamento}
                        value={itemFinal.IdQuote}
                        onClick={() => this.selecionarItemFinal(itemFinal)}
                      />
                    ))}
                  </div>
                </FormGroup>
              </form>
            </div>
            <div
              className='col-md-12'
              hidden={!this.state.listaDescricao.length > 0}
              id='elem-3'
            >
              <form>
                <FormGroup>
                  <div style={{ padding: 10 }}>
                    <h4>4 - Selecione o tamanho</h4>
                    {this.state.listaDescricao
                      .sort((a, b) => {
                        return (
                          a.DescProoduto.substring(0, 2) *
                            a.DescProoduto.substring(3, 5) -
                          b.DescProoduto.substring(0, 2) *
                            b.DescProoduto.substring(3, 5)
                        );
                      })
                      .map((descricao, i) => (
                        <CustomInput
                          key={i}
                          type='radio'
                          id={i}
                          name='customRadio'
                          label={descricao.DescProoduto}
                          value={descricao.IdQuote}
                          onClick={() => this.selecionarDescricao(descricao)}
                        />
                      ))}
                  </div>
                </FormGroup>
              </form>
            </div>
            <div
              className='col-md-12'
              hidden={!this.state.listaPrecos.length > 0}
              id='elem-4'
            >
              <h4>5 - Selecione a quantidade</h4>
              <div
                className='btn-group mr-2 table-responsive'
                role='group'
                aria-label='First group'
              >
                {[
                  30,
                  50,
                  100,
                  150,
                  200,
                  250,
                  300,
                  350,
                  400,
                  500,
                  600,
                  700,
                  800,
                  900,
                  1000,
                ].map((qtd) => (
                  <button
                    key={qtd}
                    onClick={() => this.setState({ inputQuantidade: qtd })}
                    type='button'
                    className='btn btn-secondary'
                  >
                    {qtd}
                  </button>
                ))}
              </div>
              <input
                type='number'
                style={{ marginTop: 10 }}
                className='form-control'
                id='qtd'
                value={this.state.inputQuantidade}
                onChange={(e) =>
                  this.setState({ inputQuantidade: e.target.value })
                }
              />
            </div>
            <div className='col-md-12' hidden={!this.state.inputQuantidade}>
              <br />
              <h4>6 - Acabamentos e Acréscimos</h4>
              <div className='table-responsive'>
                <table className='table table-condensed'>
                  <thead>
                    <tr>
                      <th>Selecionado</th>
                      <th>Acabamento/Acréscimo</th>
                    </tr>
                  </thead>
                  <tbody style={{ overflow: 'scroll', height: 200 }}>
                    {this.state.acabamentos.map((acabamento, i) => (
                      <tr
                        key={i}
                        style={
                          this.state.acabamentosSelecionados.includes(
                            acabamento
                          )
                            ? { backgroundColor: '#d5dcff', cursor: 'pointer' }
                            : { cursor: 'pointer' }
                        }
                        onClick={() => this.pushAcabamento(acabamento)}
                      >
                        <td style={{ textAlign: 'center' }}>
                          {!this.state.acabamentosSelecionados.includes(
                            acabamento
                          ) ? (
                            <i
                              className='far fa-circle fa-lg'
                              style={{ color: 'gray' }}
                            ></i>
                          ) : (
                            <i
                              className='far fa-check-circle fa-lg'
                              style={{ color: '#4c6ef5' }}
                            ></i>
                          )}
                        </td>
                        <td>{acabamento.DescProoduto}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='col-md-12' hidden={!this.state.carregando}>
              <div className='alert alert-primary ng-hide'>
                <h4 style={{ textAlign: 'center' }}>
                  Carregando... <i className='fa fa-spinner fa-pulse fa-fw'></i>{' '}
                </h4>
              </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={!this.state.inputQuantidade}
            color='success'
            onClick={() => this.addItemOrcamento()}
          >
            <i className='fas fa-check-circle'></i> Inserir{' '}
          </Button>
          <Button
            color='danger'
            onClick={() => this.props.close(this.props.orcamento)}
          >
            <i className='fas fa-times-circle'></i> Cancelar
          </Button>
        </ModalFooter>
      </div>
    );
  }
}
