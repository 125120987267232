/*
  TELA QUE SERIA UM HISTORICO DE VENDAS DO REPRESENTANTE NA VDD SE TORNOU UM KANBAN
*/
import React from 'react';
import axios from 'axios';
import {Redirect} from 'react-router-dom';
import {REQUEST_URL} from '../config/urls.js';
import { ToastContainer, toast } from 'react-toastify';
import {Modal,ModalHeader,ModalBody,Input,  Button,Col,Row,  Card
} from 'reactstrap';
import  NavBar from '../components/NavBar';
import Board from 'react-trello';
import Select from 'react-select';

class Historico extends React.Component {
  constructor(props){
    super(props);
    document.title = "Mapa";
    this.state = {
      usuarioLogado:{},
      data:{lanes:[]},
      dtFim:"",
      dtIni:"",
      post:{},
      clienteSelecionado:{},
      cursoSelecionado:{},
      selectCursosOptions:[],
      orcamentos:[]
    };
  }
  componentWillMount () {
    axios.defaults.headers.common = {'Authorization': sessionStorage.getItem("token")};
  }
  componentDidMount(){
    if(sessionStorage.getItem('usuarioLogado')){
      this.setState({usuarioLogado:JSON.parse(sessionStorage.getItem('usuarioLogado'))[0]},()=>{
        //console.log(this.state.usuarioLogado);
      });
    } 

    let post = {
      "cidades":this.props.location.state.cidades,
      "cidadesObj":this.props.location.state.cidadesObj,
      "instituicoes":this.props.location.state.instituicoes,
      "cursos":this.props.location.state.cursos,
      "estado":this.props.location.state.estado,
      "vendedor":this.props.location.state.vendedor,
      "vendedorNome":this.props.location.state.vendedorNome,
      "somenteCidadesVinculadas":this.props.location.state.somenteCidadesVinculadas,
      "somenteCursosFavoritados":this.props.location.state.somenteCursosFavoritados
    }
    this.setState({post});
    this.setDatasParaMes();
    this.getClientes(post);
    this.getCursos();
    
  }
  getCursos(){
    axios.get(REQUEST_URL+"/cursos").then((response2)=>{
      let optionsParaSelect = [];
      response2.data.forEach((curso)=>{
        let op = {
          value: curso.idcurso,
          label: curso.nomecurso
        }
        optionsParaSelect.push(op);
      })

      this.setState({selectCursosOptions:optionsParaSelect});
    });
  }
  formatReal(n){
    return Number(n.toFixed(2)).toLocaleString('pt-BR');
  }
  getClientes(post){
    this.setState({carregando:true});
    post.dtIni = this.state.dtIni;
    post.dtFim = this.state.dtFim;
    //console.log(post.dtIni,post.dtFim);
    axios.post(`${REQUEST_URL}/clientes_kanban`,post).then((response)=>{
      //console.log(response.data);
      let clientes = response.data;

      let prospectos = [],valorProspecto=0;
      let negocios = [],valorNegocios=0;
      let fechados = [],valorFechados=0;
      let perdidos = [],valorPerdidos=0;
      //console.log("antes for");
      clientes.map((cliente)=>{
        let card = {
          id:cliente.idCliente+"",
          title:cliente.curso.substring(0,18),
          description:cliente.Descricao+ ", "+cliente.Cidade+", "+cliente.Formatura+","+cliente.NomeVendedor+" , R$:"+ (cliente.MaxTotalOrc ? this.formatReal(cliente.MaxTotalOrc):0),
          label:new Date(cliente.DataInclusao).toLocaleDateString(),
          metadata: cliente
        }
        if(cliente.Status === "Prospecto"){
          prospectos.push(card);
          valorProspecto+=cliente.MaxTotalOrc;
        }else if(cliente.Status === "Negociacao"){
          negocios.push(card);
          valorNegocios+=cliente.MaxTotalOrc;
        }else if(cliente.Status === "Fechado" ){
          fechados.push(card);
          valorFechados+=cliente.MaxTotalOrc;
        }else if(cliente.Status === "Perdido"){//perdido
          perdidos.push(card);
          valorPerdidos+=cliente.MaxTotalOrc;
        }
      });
      //console.log("depois for");
      let data = {
        lanes: [
          {
            id: 'lane_p',
            label:  "",
            title: 'Prospectos',
            cards: [{id: 'Card1', title: 'Write Blog', description: 'Can AI make memes', label: '30 mins'},]
          },
          {
            id: 'lane_n',
            label: "",
            title: 'Negocios',
            cards: []
          },
          {
            id: 'lane_f',
            label: "",
            title: 'Fechados',
            cards: []
          },
          {
            id: 'lane_pd',
            label: "",
            title: 'Perdidos',
            cards: []
          }
        ]
      }
      //console.log(data);
      data.lanes[0].cards = prospectos;
      data.lanes[1].cards = negocios;
      data.lanes[2].cards = fechados;
      data.lanes[3].cards = perdidos;
      //console.log(prospectos,negocios,fechados);
      this.setState({data,carregando:false});
    }).catch((erro)=>{
      toast.error("desculpe um erro inesperado aconteceu :(");
    });
  }
  
  handleDragEnd(id,origem,destino){
    this.setState({carregando:true});
    let status = "";
    if(destino ==="lane_f"){
      status = "Fechado";
    }else if(destino ==="lane_p"){
      status = "Prospecto";
    }else if(destino ==="lane_n"){
      status = "Negociacao";
    }else if(destino ==="lane_pd"){
      status = "Perdido";
    }
    axios.put(`${REQUEST_URL}/cliente/status/`+id,{status:status}).then((response)=>{
      this.setState({carregando:false});
    }).catch((erro)=>{
      toast.error("Algo deu errado, não foi possivel atualizar o Status");
    });
  }
  handleDragStart(...vars){
    console.log(vars);
  }
  setDatasParaMes(){
    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    var primeiroDia = new Date(y, m, 1);
    var ultimoDia = new Date(y, m + 1, 0);
    console.log(primeiroDia.toISOString().substring(0,10));
    let primeiroString = primeiroDia.toISOString().substring(0,10);
    let ultimoString = ultimoDia.toISOString().substring(0,10);
    this.setState({dtIni:primeiroString,dtFim:ultimoString});
  }
  setDatasParaHj(){
    var date = new Date()
    let primeiroString = date.toISOString().substring(0,10);
    this.setState({dtIni:primeiroString,dtFim:primeiroString});
  }
  setDatasParaSemana(){
    var curr = new Date;
    var primeiroDia = new Date(curr.setDate(curr.getDate() - curr.getDay()));
    var ultimoDia = new Date(curr.setDate(curr.getDate() - curr.getDay()+6));
    let primeiroString = primeiroDia.toISOString().substring(0,10);
    let ultimoString = ultimoDia.toISOString().substring(0,10);
    this.setState({dtIni:primeiroString,dtFim:ultimoString});
  }
  atualizar(){
    let post = this.state.post;
    if(post.ano && post.semestre){
      post.formatura = post.ano+"/"+post.semestre;
    }else{
      post.formatura = "";
    }

    if(this.state.cursoSelecionado && this.state.cursoSelecionado.value){
      //console.log("temcurso");
      post.cursos = [];
      post.cursos.push(this.state.cursoSelecionado.value);
    }else{
      //console.log("não tem");
      post.cursos = [];
    }
    this.getClientes(post);
  }
  onChangeValue(e){
    let value = e.target.value;
    let name = e.target.name;
    let post = this.state.post;
    post[name] = value;
    this.setState({post});
  }
  
  handleChangeSelectCursos(selected){
    //if(selected)
    //console.log(selected);
    this.setState({cursoSelecionado:selected});
  }
  buscarOrcamentos(idCliente){
    axios.post(`${REQUEST_URL}/orcamentos`, {clienteId:idCliente}).then((response)=>{
      this.setState({orcamentos:response.data});
    }).catch((response)=>{
      toast.error("Algo deu errado, não foi possivel buscar orcamentos");
    });
  }
  reabrirOrcamento(idOrc){
    this.props.history.push({
     pathname:"/orcamento",
     state:{
         cliente:this.state.clienteSelecionado,
         idOrc:idOrc
      }
    });
  }
  render(){
    if(!sessionStorage.getItem('usuarioLogado')){
      return <Redirect to="/login"/>
    }
    return(
        <div>
            <ToastContainer
              position="bottom-left"
              autoClose={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange={false}
              draggable={false}
            />
            <NavBar></NavBar>
            
            <Card style={{marginTop:10}}>
               <div style={{width:1250,margin:"auto",padding:10}}>
                <Col md="12" id="filtros">
                  <Row style={{backgroundColor:"#e3e3e3",borderRadius:"3px",padding:20}}>
                    <Col md="2"> Cadastro:</Col>
                    <Col md="4">
                      <Row>
                        <Col md="4"><Button color="info" block onClick={this.setDatasParaHj.bind(this)}>HOJE</Button></Col>
                        <Col md="4"><Button color="info" block onClick={this.setDatasParaSemana.bind(this)}>SEMANA</Button></Col>
                        <Col md="4"><Button color="info" block onClick={this.setDatasParaMes.bind(this)}>MÊS</Button></Col>
                      </Row>
                      
                    </Col>
                    <Col style={{display:"flex"}} md="4">
                      <Input style={{borderTopRightRadius:0,borderBottomRightRadius:0,width:"40%"}} type="date" value={this.state.dtIni} onChange={(e)=>{this.setState({dtIni:e.target.value})}}/>
                      <Input style={{borderRadius:0,width:"40%"}}  type="date" value={this.state.dtFim} onChange={(e)=>{this.setState({dtFim:e.target.value})}}/>
                      <Button style={{borderTopLeftRadius:0,borderBottomLeftRadius:0}} color="danger" onClick={()=>this.setState({dtIni:"",dtFim:""})}><i className="fas fa-times"></i></Button>
                    </Col>
                    <Col md="2">
                    {
                      this.state.carregando ? 
                        <Button color="warning" block onClick={this.atualizar.bind(this)}>Buscando... </Button>
                      :
                        <Button color="success" block onClick={this.atualizar.bind(this)}>Buscar</Button>
                    }                     
                    </Col>
                    {/*}
                    <Col md="2">
                      Periodo:
                    </Col>
                    <Col style={{display:"flex"}} md="4">
                      <Input placeholder="Ano" name="ano" value={this.state.post.ano} onChange={this.onChangeValue.bind(this)}/>
                      <Input placeholder="Semestre" name="semestre" value={this.state.post.semestre} onChange={this.onChangeValue.bind(this)}/>
                    </Col>
                    {*/}
                    {/*<Col md="6"></Col>*/}
                    <Col md="2">
                      Curso:
                    </Col>
                    <Col md="4">
                      <Select
                      value={this.state.cursoSelecionado}
                      onChange={this.handleChangeSelectCursos.bind(this)}
                      options={this.state.selectCursosOptions} 
                      />
                    </Col>
                  </Row>
                  <Row style={{marginTop:10}}>
                    <Board 
                      laneDraggable={false}	 
                      draggable={true} 
                      data={this.state.data}
                      style={{background:"#fff",margin:"auto",width:"100%"}}
                      handleDragEnd={this.handleDragEnd.bind(this)} 
                      handleDragStart={this.handleDragStart.bind(this)}  
                      onCardClick={(id,meta,...vars)=>{this.setState({showModalCliente:true,clienteSelecionado:meta});this.buscarOrcamentos(meta.idCliente)}}       
                    />
                  </Row>
                </Col>
               </div>
            </Card>
            <Modal isOpen={this.state.showModalCliente}      id="modal-cliente">
              <ModalHeader toggle={()=>this.setState({showModalCliente:false})}>Detalhes</ModalHeader>
              <ModalBody>
                <div className="form-group row">
                  <label className="col-4 col-form-label">Id Cliente:</label>
                  <div className="col-8">
                    <label>{this.state.clienteSelecionado.idCliente}</label>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-4 col-form-label">Descricao:</label>
                  <div className="col-8">
                    <label>{this.state.clienteSelecionado.Descricao}</label>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-4 col-form-label">Curso:</label>
                  <div className="col-8">
                    <label>{this.state.clienteSelecionado.curso}</label>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-4 col-form-label">Cidade:</label>
                  <div className="col-8">
                    <label>{this.state.clienteSelecionado.Cidade}</label>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-4 col-form-label">Instituição:</label>
                  <div className="col-8">
                    <label>{this.state.clienteSelecionado.Instituicao}</label>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-4 col-form-label">idNoBitrix:</label>
                  <div className="col-8">
                    <label>{this.state.clienteSelecionado.idNegocioBitrix}</label>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-4 col-form-label">Vendedor:</label>
                  <div className="col-8">
                    <label>{this.state.clienteSelecionado.NomeVendedor}</label>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-4 col-form-label">Agencia:</label>
                  <div className="col-8">
                    <label>{this.state.clienteSelecionado.NomeAgencia}</label>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-4 col-form-label">Maior Orcamento:</label>
                  <div className="col-8">
                    <label>R$: {this.state.clienteSelecionado.MaxTotalOrc}</label>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-4 col-form-label">Formatura:</label>
                  <div className="col-8">
                    <label>R$: {this.state.clienteSelecionado.Formatura}</label>
                  </div>
                </div>
                <h5>Orçamentos: </h5>
                <table className="table table-sm">
                  <tbody style={{maxHeight:30}}>  
                    {
                      this.state.orcamentos.map((orc)=>(
                        <tr>
                          <td>{orc.idOrc}</td>
                          <td>{orc.Status}</td>
                          <td>{orc.CustTotal.toFixed(2)}</td>
                          <td><Button onClick={()=>{this.reabrirOrcamento(orc.idOrc)}} size="sm">abrir</Button></td>
                        </tr>
                      ))
                    }     
                  </tbody>
                </table>
              </ModalBody>
            </Modal>
        </div>
    )
  }
}


export default Historico;
