import React from 'react';

import axios from 'axios';
import extenso from 'extenso';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Draggable from 'react-draggable';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { ToastContainer, toast } from 'react-toastify';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from 'reactstrap';
import SimpleCrypto from 'simple-crypto-js';

import { getPrecoArtes } from '../actions/PrecoArte.js';
import { getPrecoFrete } from '../actions/PrecoFrete.js';
import { getPropostaImage } from '../actions/dataUrlImages';
import FloatingMenuOrcamento from '../components/FabButton/FloatingMenuOrcamento';
import ModalAdicionarItem from '../components/ModalAdicionarItem';
import NavBar from '../components/NavBar';
import NavegationBlocker from '../components/NavegationBlocker';
import { REQUEST_URL } from '../config/urls.js';
import TelegramLog from '../services/telegram';

class Orcamento2 extends React.Component {
  constructor(props) {
    super(props);
    document.title = 'Orcamento';
    this.state = {
      nomeorc: '',
      reabrindo: false,
      usuarioLogado: '',
      cliente: {},
      orcamento: {
        ehMiolo29: false,
        itens: [],
        somaMargens: 0.2,
        quantidadeMiolo: 0,
        possuiVoucher: null,

        possuiArte: false,
        precoDaArteU: 0,
        precoDaArteT: 0,

        possuiFrete: false,
        precoDoFreteU: 0,
        precoDoFreteT: 0,
        //salva o preco dos item que são totalmente brinde
        valorTotalBrindes: 0,
        margens: {
          cv: 0,
          ce: 0,
          ca: 0,
          autVen: 0,
          autGer: 0,
          ci: 0,
          mu: 0,
          isv: 0,
        },
        nome: '',
        observacao: '',
        status: 'Aguardando',
        vendedor: '',
        duplicado: false,
        idDuplicado: null,

        qtdFormandoBoleto: 0,
        qtdFormandoCartao: 0,
        numeroParcelas: 0,
        valorCartao: 0,
        valorBoleto: 0,
        idOrcOff: 0,
        precoTotalComMargens: 0,
        valorArte: 0,
        valorFrete: 0,
      },
      statusOrcamento: '',
      servico: {},
      acabamentos: [],
      itens: [],
      listaPrecos: [],
      listaPrecosIndigo: [],
      acabamentosSelecionados: [],
      //modal
      carregando: false,
      recalcular: false,
      atualizar: false,
      showBotoesAcao: false,
      showModalAddItem: false,
      showModalFinalizar: false,
      showModalDeleteItem: false,
      showModalMudarQtd: false,
      showModalVoucher: false,
      showModalMudarAcabamento: false,
      showModalAddAcabamento: false,
      showModalNomeArquivo: false,
      showFormMargens: false,
      showModalAddItemManual: false,
      showCardOpcoes: false,
      showModalConfirmarBrinde: false,
      showModalBoleto: false,
      showTotalOrcamento: false,
      showModalAbrirOrcamento: false,
      showModalRecalcular: false,
      inputMudarQtd: 0,
      itemTabelaSelecionado: null,
      itemAcabamentoSelecionado: null,
      itemManual: {
        item: '',
        detalhes: '',
        quantidade: '',
        precoUnitario: '',
      },
      listaPrecosArte: [],
      listaPrecosFrete: getPrecoFrete(),
      //opcoes
      possuiVoucher: null,
      possuiFrete: true,
      possuiArte: this.habilitarArte(),
      propostaSemValor: false,
      possuiConviteOnline: false,
      possuiPagamentoIndividual: false,

      inputMudarBrinde: 0,
      inputMudarAcabamento: 0,
      inconsistencias: false,

      idCampanha: 0,
      nomeCampanha: null,
      inicioCampanha: null,
      fimCampanha: null,
      campanhaAtiva: false,

      precoOrcamento: '',
      alterarPrecoOrcamento: false,

      //selecionar clientes
      listaClientes: [],
      inputClienteId: '',
      inputClienteNome: '',
      clienteSelecionado: {},
      finalizarOnSave: false,
      reopenOrcamento: false,

      colocarPonto: false,
      bkQtdFormandoBoleto: 0,
      bkNumeroParcelas: 0,

      precoDeBriga: 'maximo',
      margemAdicional: 0,
    };
  }

  async componentWillMount() {
    this.handleGetValoresAdicionais();
    axios.defaults.headers.common = {
      Authorization: sessionStorage.getItem('token'),
    };
    await axios.get(REQUEST_URL + '/campanha/ativa').then((response) => {
      let data = response.data[0];
      if (data !== undefined) {
        this.setState({
          idCampanha: data.id,
          inicioCampanha: data.inicio,
          nomeCampanha: data.nome,
          fimCampanha: data.fim,
          campanhaAtiva: data.ativa === 'true' ? true : false,
        });
      }
    });
  }

  async componentDidMount() {
    if (sessionStorage.getItem('usuarioLogado')) {
      this.setState({
        usuarioLogado: JSON.parse(sessionStorage.getItem('usuarioLogado'))[0],
      });
    }
    await this.setState({ cliente: this.props.location.state.cliente }, () => {
      let orcamento = this.state.orcamento;
      orcamento.NomeAgencia = this.state.cliente.NomeAgencia;
      orcamento.NomeVendedor = this.state.cliente.NomeVendedor;
      this.setState({ orcamento });
    });
    let idOrc = this.props.location.state.idOrc;
    if (idOrc) {
      this.setState({ reabrindo: true });
      this.reabrirOrcamento(idOrc);
    } else {
      let precoBriga = 'maximo';
      //buscar margens padrõelse
      axios
        .get(REQUEST_URL + '/representantes/' + this.state.cliente.Vendedor)
        .then((rep) => {
          axios
            .get(REQUEST_URL + '/margens')
            .then((response) => {
              let mp = response.data[0];
              let orcamento = this.state.orcamento;
              orcamento.margens = {
                cv: this.state.usuarioLogado.cvFixo,
                ce: mp.CE,
                ca: mp.CA,
                autVen: Number(this.state.usuarioLogado.margemCusto),
                autGer: mp.AutoGerente,
                ci: mp.CI,
                mu: mp.MU,
                isv: mp.ISV,
              };
              if (this.state.cliente.caPadrao) {
                orcamento.margens.ca = this.state.cliente.caPadrao * 100;
              }
              if (
                rep.data[0].liberarMargens === 0 &&
                rep.data[0].Funcao === 'VENDEDOR'
              ) {
                orcamento.margens.autGer = 10;
                precoBriga = 'minimo';
              }
              this.setState({ orcamento, precoDeBriga: precoBriga });
            })
            .catch((erro) => {
              this.criarLog(
                'ERROR',
                erro,
                'Não foi possível carregar as margens padrão'
              );
              toast.error('Não foi possivel carregar as margens padrão');
            });
        })
        .catch((erro) => {
          this.criarLog(
            'ERROR',
            erro,
            'Não foi possível pegar as informações do vendedor. Por favor, faça o login novamente!'
          );
          toast.error(
            'Não foi possível pegar as informações do vendedor. Por favor, faça o login novamente!'
          );
        });
      // Verifica se existe ua promoção ativa
    }
    let listaPrecosArte = await getPrecoArtes();
    let listaPrecosFrete = await getPrecoFrete();
    this.setState({ listaPrecosArte, listaPrecosFrete }, () => {
      let margem = this.state.orcamento.margens.autGer;
    });
  }

  criarLog(tipo, erro, mensagem) {
    new TelegramLog().novo(tipo, [
      JSON.stringify(erro),
      mensagem,
      `Orçamento nº: ${this.state.orcamento.idOrc}`,
      `usuarioLogado: ${
        JSON.parse(sessionStorage.getItem('usuarioLogado'))[0].Login
      }`,
    ]);
  }

  async handleGetValoresAdicionais() {
    const { data } = await axios.get(`${REQUEST_URL}/valor-arte`);
    this.setState({ margemAdicional: data.adicional_margem });
  }

  habilitarArte() {
    return JSON.parse(sessionStorage.getItem('usuarioLogado'))[0]
      .contadeparceiro != 1
      ? true
      : false;
  }
  /*
  retorna a tela anterior
  */
  voltar() {
    this.props.history.push({
      pathname: '/select_client',
    });
  }
  /*
    Verifica se possui idOrc se possuir é porque ja foi salvo uma vez assim é chamado a funcao para editar se nao tiver chama a de inserir
  */
  salvarOrcamento() {
    //orcamentoInfos retorna 0 se tiver alguma inconsistencias
    this.setState({ carregando: true });
    if (!this.orcamentoInfos() && this.state.orcamento.itens.length > 0) {
      // Se não houver id na variável codCliente, ele vai pesquisar na idCliente, se não houver ele vai pegar do orçamento.
      let codCliente =
        this.state.cliente.codCliente === null ||
        this.state.cliente.codCliente === undefined
          ? this.state.cliente.idCliente !== null &&
            this.state.cliente.idCliente !== undefined
            ? this.state.cliente.idCliente
            : this.state.orcamento.cliente
          : this.state.cliente.codCliente;
      if (!this.state.orcamento.idOrc) {
        if (this.state.finalizarOnSave) {
          this.mudarStatusCliente(codCliente, 'Fechado');
        } else if (this.state.cliente.Status === 'Prospecto') {
          this.mudarStatusCliente(codCliente, 'Negociacao');
        }
        this.inserirNovoOrcamento();
      } else {
        if (this.state.finalizarOnSave) {
          this.mudarStatusCliente(codCliente, 'Fechado');
          this.setState({ status: 'Fechado' });
        }
        this.atualizarOrcamento();
      }
    } else {
      toast.error(
        'O orçamento não pode possuir inconsistências ou estar vazio'
      );
      this.setState({
        showModalSalvar: false,
        showModalFinalizar: false,
        carregando: false,
      });
    }
  }

  mudarStatusCliente(idCliente, status) {
    axios
      .put(REQUEST_URL + '/cliente/status/' + idCliente, { status: status })
      .then((response) => {
        toast.success(`Status do cliente definido para '${status}'`);
      })
      .catch((erro) => {
        this.criarLog(
          'ERROR',
          erro,
          'Não foi possível atualizar o status do cliente'
        );
        toast.error('Não foi possível atualizar o status do cliente');
      });
  }

  recalcularPrecoByVoucher() {
    let orcamento = this.state.orcamento;
    // Diminuir preço do orçamento se tiver voucher
    if (this.state.possuiVoucher === true) {
      let somaMargens =
        orcamento.margens.cv +
        orcamento.margens.ce +
        orcamento.margens.ca +
        orcamento.margens.autVen +
        orcamento.margens.autGer +
        orcamento.margens.ci +
        orcamento.margens.mu +
        orcamento.margens.isv;
      let valorOrcamentoComVoucher =
        orcamento.precoTotalComMargens - orcamento.precoTotalComMargens * 0.05;
      let valorMargemComVoucher =
        100 - 100 * (this.calcularPrecoTotal() / valorOrcamentoComVoucher);
      orcamento.margens.mu =
        orcamento.margens.mu - (somaMargens - valorMargemComVoucher);
    } else {
      orcamento.margens.mu = 20;
    }
  }

  inserirNovoOrcamento() {
    let orcamento = this.state.orcamento;
    orcamento.precoTotal = this.calcularPrecoTotal();
    orcamento.precoTotalComMargens =
      this.calcularPrecoTotal() / this.somarMargens();
    orcamento.cliente =
      this.state.cliente.idCliente !== undefined &&
      typeof this.state.cliente.idCliente === 'number'
        ? this.state.cliente.idCliente
        : this.state.cliente.codCliente;
    orcamento.vendedor =
      this.state.cliente.Vendedor !== undefined &&
      typeof this.state.cliente.Vendedor === 'number'
        ? this.state.cliente.Vendedor
        : this.state.cliente.codVendedor;
    orcamento.agencia = this.state.cliente.codAgencia;
    orcamento.somaMargens = this.somarMargens();
    orcamento.possuiFrete = this.state.possuiFrete;
    orcamento.possuiArte = this.state.possuiArte;
    orcamento.possuiVoucher = this.state.possuiVoucher;
    orcamento.possuiConviteOnline = this.state.possuiConviteOnline ? 1 : 0;
    orcamento.voucher =
      orcamento.possuiVoucher === true &&
      new Date(this.state.fimCampanha).getTime() > new Date().getTime()
        ? this.state.idCampanha
        : 0;
    orcamento.status = this.state.finalizarOnSave ? 'Fechado' : 'Negociacao';
    let v = this.verificaCapaEMiolo();
    if (v === 'passou') {
      axios
        .post(REQUEST_URL + '/orcamento', { orcamento: orcamento })
        .then((response) => {
          let orcamento = this.state.orcamento;
          orcamento.idOrc = response.data.rows.insertId;
          orcamento.itens = response.data.orc;
          toast.success('Orçamento Salvo!');
          this.setState({
            orcamento,
            showModalSalvar: false,
            showModalFinalizar: false,
            showBotoesAcao: true,
            carregando: false,
          });
        })
        .catch((erro) => {
          this.criarLog('ERROR', erro, 'Não foi possível salvar o orçamento');
          toast.error('Não foi possível salvar o orçamento');
          this.setState({ carregando: false });
        });
    } else {
      toast.error(v);
      this.setState({
        showModalSalvar: false,
        showModalFinalizar: false,
        carregando: false,
      });
    }
  }

  async atualizarOrcamento() {
    let orcamento = this.state.orcamento;
    orcamento.precoTotalComMargens =
      this.calcularPrecoTotal() / this.somarMargens();
    orcamento.cliente =
      this.state.cliente.idCliente !== undefined &&
      typeof this.state.cliente.idCliente === 'number'
        ? this.state.cliente.idCliente
        : this.state.cliente.codCliente;
    orcamento.vendedor =
      this.state.cliente.Vendedor !== undefined &&
      typeof this.state.cliente.Vendedor === 'number'
        ? this.state.cliente.Vendedor
        : this.state.cliente.codVendedor;
    orcamento.agencia = this.state.cliente.codAgencia;
    orcamento.possuiFrete = this.state.possuiFrete;
    orcamento.possuiArte = this.state.possuiArte;
    orcamento.possuiConviteOnline = this.state.possuiConviteOnline ? 1 : 0;
    orcamento.possuiVoucher = this.state.possuiVoucher;
    orcamento.voucher =
      orcamento.possuiVoucher === true &&
      new Date(this.state.fimCampanha).getTime() > new Date().getTime()
        ? this.state.idCampanha
        : 0;
    orcamento.qtdFormandoBoleto = parseInt(orcamento.qtdFormandoBoleto);
    orcamento.qtdFormandoCartao = parseInt(orcamento.qtdFormandoCartao);
    orcamento.numeroParcelas = parseInt(orcamento.numeroParcelas);
    orcamento.somaMargens = this.somarMargens();
    orcamento.precoTotal = this.calcularPrecoTotal();
    orcamento.status = this.state.finalizarOnSave ? 'Fechado' : 'Negociacao';
    if (this.state.possuiPagamentoIndividual === false) {
      orcamento.valorBoleto = 0;
      orcamento.qtdFormandoBoleto = 0;
      orcamento.numeroParcelas = 0;
    }
    let v = this.verificaCapaEMiolo();
    if (v === 'passou') {
      await axios
        .put(`${REQUEST_URL}/orcamento/${this.state.orcamento.idOrc}`, {
          orcamento: orcamento,
        })
        .then(async (response) => {
          toast.success('Orcamento Salvo!');
          if (!this.state.finalizarOnSave) {
            let precoTotal = this.calcularPrecoTotal() / this.somarMargens();
            if (
              this.state.oldCustoTotal &&
              this.state.oldCustoTotal !== precoTotal
            ) {
              axios
                .delete(
                  `${REQUEST_URL}/orcamento/${this.state.orcamento.idOrc}/deletar-kits`
                )
                .then(() => {
                  toast.info('Orçamento atualizado. Kits deletados!');
                })
                .catch((erro) => {
                  this.criarLog(
                    'ERROR',
                    erro,
                    'Não foi possível deletar os kits e combos cadastrados!'
                  );
                  toast.error(
                    'Não foi possível deletar os kits e combos cadastrados!'
                  );
                });
            }
          }
          this.setState({
            showModalSalvar: false,
            showModalFinalizar: false,
            carregando: false,
            showBotoesAcao: true,
          });
        })
        .catch((erro) => {
          this.criarLog('ERROR', erro, 'Não foi possível salvar o orçamento');
          toast.error('Não foi possível salvar o orçamento');
          this.setState({ carregando: false });
        });
    } else {
      toast.error(v);
      this.setState({
        showModalSalvar: false,
        showModalFinalizar: false,
        carregando: false,
      });
    }
  }
  //carregar informanões quando orcamento for informado
  reabrirOrcamento = (idOrc) => {
    axios.get(REQUEST_URL + '/orcamento/' + idOrc).then(async (response) => {
      let orcamentoSalvo = response.data;
      let possuiFrete = this.state.possuiFrete;
      let possuiArte = this.state.possuiArte;
      let orcamento = this.state.orcamento;
      orcamentoSalvo.itens.sort((a, b) => {
        if (a.IndexPlano > b.IndexPlano) {
          return 1;
        }
        if (a.IndexPlano < b.IndexPlano) {
          return -1;
        }
      });
      for (let i = 0; i < orcamentoSalvo.itens.length; i++) {
        let item = orcamentoSalvo.itens[i];
        await this.reabrirItemOrcamento(
          item,
          orcamentoSalvo.precoArte,
          orcamentoSalvo.Frete
        );
      }
      orcamento.idOrc = orcamentoSalvo.idOrc;
      orcamento.nome = orcamentoSalvo.idOrc;
      orcamento.precoTotalComMargens = orcamentoSalvo.CustTotal;
      orcamento.possuiArte = orcamentoSalvo.ArteAlpha === 'true' ? true : false;
      orcamento.possuiFrete =
        orcamentoSalvo.FreteAlpha === 'true' ? true : false;
      orcamento.possuiVoucher =
        orcamentoSalvo.possuiVoucher === 'true'
          ? true
          : orcamentoSalvo.possuiVoucher === 'false'
          ? false
          : null;
      orcamento.observacao = orcamentoSalvo.Obs;
      orcamento.status = orcamentoSalvo.Status;
      orcamento.vendedor = orcamentoSalvo.codVendedor;
      orcamento.numeroParcelas = orcamentoSalvo.NParcelasBoleto;
      orcamento.qtdFormandoBoleto = orcamentoSalvo.QtFormBoleto;
      orcamento.qtdFormandoCartao = orcamentoSalvo.QtFormCartao;
      orcamento.valorBoleto = orcamentoSalvo.ValorBoleto;
      if (orcamento.qtdFormandoBoleto > 0 && orcamento.numeroParcelas > 0) {
        this.setState({
          possuiPagamentoIndividual: true,
          bkQtdFormandoBoleto: orcamentoSalvo.QtFormBoleto,
          bkNumeroParcelas: orcamentoSalvo.NParcelasBoleto,
        });
      }
      await axios
        .get(`${REQUEST_URL}/orcamento/${orcamento.idOrc}/status-orcamento`)
        .then((data) => {
          if (data.data.status !== 'Negociacao') {
            this.setState({ showBotoesAcao: true });
          }
          this.setState({ statusOrcamento: data.data.status });
        })
        .catch((erro) => {
          this.criarLog(
            'ERROR',
            erro,
            'Não foi possível obter o código do status. Atualize a página e tente novamente!'
          );
          toast.error(
            'Não foi possível obter o código do status. Atualize a página e tente novamente!'
          );
        });
      this.setState({
        possuiFrete: orcamento.possuiFrete,
        possuiArte: orcamento.possuiArte,
        possuiVoucher: orcamento.possuiVoucher,
        oldCustoTotal: orcamentoSalvo.CustTotal,
        possuiConviteOnline:
          orcamentoSalvo.possuiConviteOnline === 1 ? true : false,
      });
      // Data alterada para setembro porque o sistema estava com a tabela de margens desatualizadas
      if (new Date().getTime() >= new Date('2024-04-20').getTime()) {
        if (
          new Date(
            orcamentoSalvo.DataInclusao.split('T')[0].replace(/-/g, '/')
          ).getTime() < new Date('2024-04-20').getTime() &&
          (orcamentoSalvo.DataAlteracao === null ||
            new Date(
              orcamentoSalvo.DataAlteracao.split('T')[0].replace(/-/g, '/')
            ).getTime() < new Date('2024-04-20').getTime())
        ) {
          this.setState({ showModalRecalcular: true });
        }
      }
      this.reabrirMargens(orcamentoSalvo);
      this.setState({ reabrindo: false });
      this.atualizarValoresItem(false);
      this.atualizarItensAoReabrirOrcamento();
    });
  };

  async recalcularOrcamento() {
    this.setState({
      recalcular: true,
      orcamento: {
        ...this.state.orcamento,
        margens: { ...this.state.orcamento.margens, mu: 30 },
      },
    });
    let itens = this.state.orcamento.itens;
    for (let i = 0; i < itens.length; i++) {
      let itemSelecionado = itens[i];
      if (itemSelecionado.IdQuote !== 0) {
        itemSelecionado.listaPrecos = [];
        itemSelecionado.listaPrecosIndigo = [];
        await this.setState(
          { itemTabelaSelecionado: itemSelecionado },
          async () => {
            await this.mudarQtdItemTabela(itemSelecionado.qtd);
          }
        );
      }
    }
    this.setState({ recalcular: false, showModalRecalcular: false });
  }

  alterarStatusOrcamento() {
    if (this.state.reopenOrcamento) {
      // Se não houver id na variável codCliente, ele vai pesquisar na idCliente, se não houver ele vai pegar do orçamento.
      let codCliente =
        this.state.cliente.codCliente === null ||
        this.state.cliente.codCliente === undefined
          ? this.state.cliente.idCliente !== null &&
            this.state.cliente.idCliente !== undefined
            ? this.state.cliente.idCliente
            : this.state.orcamento.cliente
          : this.state.cliente.codCliente;
      axios
        .put(REQUEST_URL + '/orcamento/status/' + this.state.orcamento.idOrc, {
          status: 'Negociacao',
        })
        .then((response) => {
          toast.success('Orcamento reaberto!');
          this.setState({ showModalAbrirOrcamento: false, carregando: false });
          this.mudarStatusCliente(codCliente, 'Negociacao');
          let orcamento = this.state.orcamento;
          orcamento.status = 'Negociacao';
          this.setState({ reopenOrcamento: false, orcamento });
        })
        .catch((erro) => {
          this.criarLog('ERROR', erro, 'Não foi possível reabrir o orçamento');
          toast.error('Não foi possível reabrir o orçamento');
          this.setState({ carregando: false });
        });
    } else {
      this.setState({ showModalAbrirOrcamento: false, carregando: false });
    }
  }

  reabrirAcabamento = async (acabamentoSalvo) => {
    let quantidade = acabamentoSalvo.Qtdd;
    acabamentoSalvo.precoTotalBrinde = acabamentoSalvo.PrcBrinde
      ? acabamentoSalvo.PrcBrinde
      : 0;
    acabamentoSalvo.qtdBrinde = acabamentoSalvo.QtdBrinde
      ? acabamentoSalvo.QtdBrinde
      : 0;
    let precoTotal = acabamentoSalvo.PrcUN_Origem * acabamentoSalvo.Qtdd;
    acabamentoSalvo.precoTotal = Number(precoTotal);
    acabamentoSalvo.DescProoduto = acabamentoSalvo.Descricao;
    acabamentoSalvo.qtd = quantidade;
    acabamentoSalvo.Description = acabamentoSalvo.Descricao;
    acabamentoSalvo.precoUnitario = acabamentoSalvo.PrcUN_Origem;
    acabamentoSalvo.IdQuote = acabamentoSalvo.codOrcMetrics;
    acabamentoSalvo.IdOpcaoProduto = acabamentoSalvo.codProdutoMetrics;
    acabamentoSalvo.listaPrecos = [];
    return acabamentoSalvo;
  };

  getListaPrecos(itemParaBusca) {
    return axios.post(REQUEST_URL + '/Produto/lista_precos', {
      item: itemParaBusca,
    });
  }

  getListaPrecosIndigo(itemParaBusca) {
    return axios.post(REQUEST_URL + '/Produto/lista_precos', {
      item: itemParaBusca,
    });
  }

  getListaAcabamentos(itemParaBusca) {
    return axios.post(REQUEST_URL + '/Produto/lista_precos_acabamentos', {
      cod: itemParaBusca,
    });
  }

  reabrirItemOrcamento = async (itemSalvo, valorarte, valorfrete) => {
    let acabamentos = [];
    let acabamentosTotal = 0;
    let acabamentosUnitario = 0;
    for (let i = 0; itemSalvo.acabamentos.length > i; i++) {
      let a = await this.reabrirAcabamento(itemSalvo.acabamentos[i]);
      acabamentosTotal += Number(a.precoTotal);
      acabamentosUnitario += Number(a.precoUnitario);
      acabamentos.push(a);
    }
    let orcamento = this.state.orcamento;
    let quantidade = itemSalvo.QtdPlano;
    let precoTotal = itemSalvo.PrcUN_Origem * itemSalvo.QtdPlano;
    let itemNovo = {
      idPlano: itemSalvo.idPlano,
      IdQuote: itemSalvo ? itemSalvo.NumOrcMetrics : 0,
      IdQuoteIndigo: itemSalvo.OrIndigo ? itemSalvo.OrIndigo : 0,
      nome: itemSalvo.Descricao ? itemSalvo.Descricao : '',
      detalhes: itemSalvo.Detalhe ? itemSalvo.Detalhe : '',
      codItem: itemSalvo.codItem ? itemSalvo.codItem : '',
      qtd: quantidade,
      IndexPlano: itemSalvo.IndexPlano,
      qtdBrinde: itemSalvo.QtdBrinde ? itemSalvo.QtdBrinde : 0,
      precoTotalBrinde: itemSalvo.PrcBrinde ? itemSalvo.PrcBrinde : 0,
      precoUnitario: Number(precoTotal / quantidade),
      precoTotal: precoTotal > 0 ? Number(precoTotal - itemSalvo.PrcBrinde) : 0,
      acabamentos: acabamentos,
      precoTotalAcabamentos: acabamentosTotal,
      precoUnitarioAcabamentos: acabamentosUnitario,
      servico: itemSalvo.TpServico,
      listaPrecos: [],
      listaPrecosIndigo: [],
      precoTotalDoItem: 0,
      precoUnitarioDoItem: 0,
      ItemFuturo: itemSalvo.ItemFuturo,
      visivelProposta: true,
    };
    if (valorarte !== 0 && itemNovo.servico === 'MIOLO') {
      orcamento.quantidadeMiolo += itemNovo.qtd + itemNovo.qtdBrinde;
      orcamento.precoDaArteU = valorarte / orcamento.quantidadeMiolo;
      orcamento.precoDoFreteU = valorfrete / orcamento.quantidadeMiolo;
      orcamento.valorFrete = valorfrete;
      orcamento.valorArte = valorarte;
      orcamento.precoDoFreteT = valorfrete;
      orcamento.precoDaArteT = valorarte;
    }
    // se a data for inferior a 11/04 (att do banco - criação de um campo responsável por guardar o valor da arte para renderizar posteriormente)
    if (valorarte === 0 && itemNovo.servico === 'MIOLO') {
      orcamento.quantidadeMiolo += itemNovo.qtd + itemNovo.qtdBrinde;
      let precoDaArte =
        this.calcularValorItem(
          orcamento.quantidadeMiolo,
          this.state.listaPrecosArte
        ) + this.state.margemAdicional;
      orcamento.precoDaArteT = precoDaArte;
      orcamento.precoDaArteU = precoDaArte / orcamento.quantidadeMiolo;
      let precoDoFrete = this.calcularValorItem(
        orcamento.quantidadeMiolo,
        this.state.listaPrecosFrete
      );
      orcamento.precoDoFreteT = precoDoFrete;
      orcamento.precoDoFreteU = precoDoFrete / orcamento.quantidadeMiolo;
      orcamento.valorArte = precoDaArte;
      orcamento.valorFrete = precoDoFrete;
      orcamento.precoDaArteT = precoDaArte;
      orcamento.precoDoFreteT = precoDoFrete;
    }
    //se for brinde total soma o valor no campo valorTotalBrindes;
    if (itemNovo.qtd === 0 && itemNovo.qtdBrinde > 0) {
      orcamento.valorTotalBrindes += itemNovo.precoTotalBrinde;
    }
    itemNovo.precoTotalDoItem = this.buscarValoresItem(itemNovo);
    itemNovo.precoTotalDoItem = itemNovo.precoTotalDoItem / this.somarMargens();
    itemNovo.precoUnitarioDoItem = itemNovo.precoTotalDoItem / itemNovo.qtd;
    orcamento.itens.push(itemNovo);
    this.setState({ orcamento });
  };

  verificaCapaEMiolo() {
    let qtdMiolo = 0,
      qtdCapa = 0,
      qtdEmbl = true,
      embalagem = [],
      itens = this.state.orcamento.itens;
    itens.forEach((item) => {
      if (item.servico.indexOf('MIOLO') !== -1) {
        qtdMiolo++;
      } else if (item.servico.indexOf('CAPA DURA') !== -1) {
        qtdCapa++;
      } else if (
        item.servico.indexOf('EMBALAGEM') !== -1 &&
        item.nome.indexOf('Embalagem - Envelopes Padrão') === -1
      ) {
        let achouEmbalagem = false;
        embalagem.forEach((emb) => {
          if (emb.nome === item.nome) {
            emb.qtd = item.qtd;
            achouEmbalagem = true;
          }
        });
        if (!achouEmbalagem) {
          embalagem.push({ nome: item.nome, qtd: item.qtd });
        }
      }
    });
    embalagem.forEach((emb) => {
      if (
        emb.qtd > -1 &&
        emb.qtd < 30 &&
        this.state.usuarioLogado.Funcao !== 'ORCAMENTISTA'
      ) {
        qtdEmbl = `Não é possível orçar menos de 30 ${emb.nome}`;
      }
    });
    if (qtdMiolo > 1 && this.state.usuarioLogado.Funcao !== 'ORCAMENTISTA') {
      return 'O orçamento não pode conter mais de um item do tipo miolo!';
    }
    if (qtdMiolo === 0 && this.state.usuarioLogado.Funcao !== 'ORCAMENTISTA') {
      return 'O orçamento deve conter ao menos 30 miolos';
    }
    if (qtdCapa > 1 && this.state.usuarioLogado.Funcao !== 'ORCAMENTISTA') {
      return 'O orçamento não pode conter mais de um item do tipo capa!';
    }
    if (
      qtdEmbl !== true &&
      this.state.usuarioLogado.Funcao !== 'ORCAMENTISTA'
    ) {
      return qtdEmbl;
    }
    return 'passou';
  }

  reabrirMargens(acabamentoSalvo) {
    let margens = {
      cv: acabamentoSalvo.comissao * 100,
      ce: acabamentoSalvo.CE * 100,
      ca: acabamentoSalvo.CA * 100,
      autVen: acabamentoSalvo.AutoVendedor * 100,
      autGer: acabamentoSalvo.Autonomia * 100,
      ci: acabamentoSalvo.CI * 100,
      mu: acabamentoSalvo.MU * 100,
      isv: acabamentoSalvo.ISV * 100,
    };
    let orcamento = this.state.orcamento;
    orcamento.margens = margens;
    let precoDeBriga = this.state.precoDeBriga;
    if (margens.autGer === 10) precoDeBriga = 'minimo';
    else if (margens.autGer === 5) {
      precoDeBriga = 'medio';
    } else if (margens.autGer === 0) {
      precoDeBriga = 'maximo';
    }
    this.setState({ orcamento, precoDeBriga });
  }

  pushAcabamento(acabamento) {
    let acabamentosSelecionados = this.state.acabamentosSelecionados;
    if (acabamentosSelecionados.includes(acabamento)) {
      acabamentosSelecionados = acabamentosSelecionados.filter(
        (a) => a !== acabamento
      );
    } else {
      acabamentosSelecionados.push(acabamento);
    }
    this.setState({ acabamentosSelecionados });
  }
  //### metodos para calcular o preco ###
  /*
    Busca Um valor acima e um abaixa na lista de precos,
    Faz um relação linear com a quantidade informada,
    Caso a lista de valores esteja vazia é porque o item foi informado manualmente,
    assim o terceiro parametro é usado retornando a multiplicação da quantidade pelo preço unitario informado
  */
  calcularValorItem2(qtd, listaPrecos, precoUnitario) {
    if (listaPrecos.length > 0) {
      let listaAbaixo = listaPrecos.filter((item) => item.QtdTotal <= qtd);
      let item1 = listaAbaixo[listaAbaixo.length - 1];
      let item2 = listaPrecos.filter((item) => item.QtdTotal >= qtd)[0];

      if (!item1) return item2.PrecoUnitario * qtd;
      if (!item2) return item1.PrecoUnitario * qtd;
      if (item1 === item2) return item1.PrecoTotal;

      let a = this.a(item1, item2);
      let b = this.b(a, Number(item1.QtdTotal), Number(item1.PrecoTotal));
      return a * qtd + b;
    } else {
      return qtd * precoUnitario;
    }
  }

  a(ponto1, ponto2) {
    return (
      (ponto2.PrecoTotal - ponto1.PrecoTotal) /
      (ponto2.QtdTotal - ponto1.QtdTotal)
    );
  }

  b(a, x0, y0) {
    return -(a * x0) + y0;
  }

  calcularValorItem(qtd, listaPrecos, precoUnitario) {
    if (listaPrecos && listaPrecos.length > 0) {
      let listaAbaixo = listaPrecos.filter((item) => item.QtdTotal <= qtd),
        item1 = listaAbaixo[listaAbaixo.length - 1],
        item2 = listaPrecos.filter((item) => item.QtdTotal >= qtd)[0];

      if (!item1) {
        return item2.PrecoUnitario * qtd;
      } else if (!item2) {
        return item1.PrecoUnitario * qtd;
      } else if (item1 === item2) {
        return item1.PrecoTotal;
      }

      let preco1 = item2.PrecoUnitario,
        preco2 = item1.PrecoUnitario,
        quant1 = item2.QtdTotal,
        quant2 = item1.QtdTotal,
        QtdInformada = qtd;
      //console.log("############### Precos para o calculo: #################");
      //console.log("PMaior/((PMaior/PMenor)+((1-(PMaior/PMenor))/(QtdMaior-QtdMenor))*(QtdInformada-QtdMenor))");
      //console.log(PMaior + "/((" + PMaior + "/" + PMenor +")+(("+ 1 +"-("+PMaior+"/"+PMenor+"))/("+QtdMaior+"-"+QtdMenor+"))*("+QtdInformada+"-"+QtdMenor+"))");
      return (
        (preco1 /
          (preco1 / preco2 +
            ((1 - preco1 / preco2) / (quant1 - quant2)) *
              (QtdInformada - quant2))) *
        QtdInformada
      );
    } else {
      return qtd * precoUnitario;
    }
  }
  //=PMaior/((PMaior/PMenor)+((1-(PMaior/PMenor))/(QtdMaior-QtdMenor))*(QtdInformada-QtdMenor))
  //#####################################

  /*
  Apenas Remove o item da lista de itens no objeto orcamento no state do componente,
  Isso fara o recalculo do orcamento automaticamente.
  */
  retirarItemTabela() {
    let orcamento = this.state.orcamento,
      itens = orcamento.itens;
    //SE FOR MIOLO TEM QUE RETIRAR O CUSTO DA ARTE E DO FRETE DO ORCAMENTO
    if (this.state.itemTabelaSelecionado.servico === 'MIOLO') {
      orcamento.quantidadeMiolo -=
        this.state.itemTabelaSelecionado.qtd +
        this.state.itemTabelaSelecionado.qtdBrinde;
      let precoDaArte =
        this.calcularValorItem(
          orcamento.quantidadeMiolo,
          this.state.listaPrecosArte
        ) + this.state.margemAdicional;
      orcamento.precoDaArteT = precoDaArte;
      orcamento.precoDaArteU =
        precoDaArte && orcamento.quantidadeMiolo
          ? precoDaArte / orcamento.quantidadeMiolo
          : 0;
      let precoDoFrete = this.calcularValorItem(
        orcamento.quantidadeMiolo,
        this.state.listaPrecosFrete
      );
      orcamento.precoDoFreteT = precoDoFrete;
      orcamento.precoDoFreteU =
        precoDoFrete && orcamento.quantidadeMiolo
          ? precoDoFrete / orcamento.quantidadeMiolo
          : 0;
      orcamento.ehMiolo29 = false;
      orcamento.margens.ci = 3;
    }
    itens = itens.filter((i) => i !== this.state.itemTabelaSelecionado);
    orcamento.itens = itens;
    orcamento.precoTotalComMargens =
      this.calcularPrecoTotal() / this.somarMargens();
    this.setState(
      { orcamento, showModalDeleteItem: false, showBotoesAcao: false },
      () => {
        this.setState({ itemTabelaSelecionado: null });
        this.somarValorDosBrindesTotais();
        this.atualizarValoresItem();
      }
    );
  }

  passarTudoParaBrinde() {
    let item = this.state.itemTabelaSelecionado;

    if (item.qtd === 0) {
      toast.error('Não é possível converter 0 itens em brinde');
      this.setState({ showModalConfirmarBrinde: false });
    } else {
      item.qtdBrinde = item.qtd;
      item.qtd = 0;
      item.precoTotalBrinde = item.precoTotal;
      item.precoTotal = 0;
      item.precoUnitario = 0;
      let orcamento = this.state.orcamento;

      for (let i = 0; i < orcamento.itens.length; i++) {
        if (
          orcamento.itens[i].IdQuote === item.IdQuote &&
          orcamento.itens[i].nome === item.nome
        ) {
          orcamento.itens[i] = item;
          break;
        }
      }
      orcamento.precoTotalComMargens =
        this.calcularPrecoTotal() / this.somarMargens();

      this.setState(
        { orcamento, showModalConfirmarBrinde: false, showBotoesAcao: false },
        () => {
          this.somarValorDosBrindesTotais();
          this.atualizarValoresItem();
        }
      );
    }
  }
  /*
  Quando Muda a quantidade do item quando ja está inserido na tabela
  */
  async mudarQtdItemTabela(outraQtd = 0) {
    this.setState({ carregando: true });

    let qtd = outraQtd;

    if (qtd === 0) {
      qtd = Number(this.state.inputMudarQtd);
    }

    let item = this.state.itemTabelaSelecionado,
      qtdAntiga = item.qtd,
      novaQtd = qtd + item.qtdBrinde,
      itemParaBusca = { Description: item.codItem, IdQuote: item.IdQuote },
      itemParaBuscaIndigo = {
        Description: item.codItem,
        IdQuote: item.IdQuoteIndigo,
      };

    if (item.listaPrecos.length === 0) {
      let response = await this.getListaPrecos(itemParaBusca);
      item.listaPrecos = response.data;
    }
    if (item.listaPrecosIndigo.length === 0) {
      let response = await this.getListaPrecos(itemParaBuscaIndigo);
      item.listaPrecosIndigo = response.data;
    }

    if (qtd > 0 || item.qtdBrinde > 0) {
      let reculculado = this.calcularValorItem(
          novaQtd,
          item.listaPrecos,
          item.precoUnitario
        ),
        reculculadoIndigo = this.calcularValorItem(
          novaQtd,
          item.listaPrecosIndigo,
          item.precoUnitario
        );

      if (
        reculculadoIndigo < reculculado &&
        item.listaPrecosIndigo.length > 0
      ) {
        reculculado = reculculadoIndigo;
      }

      item.precoTotal = Number(reculculado) - item.precoTotalBrinde;

      if (item.listaPrecos.length > 0) {
        item.precoUnitario = Number(reculculado / qtd);
      }

      item.qtd = qtd;

      let somaAcab = 0,
        qtdAcabamentoBusca = 0,
        qtdAcabamentoSemBrinde = 0,
        brindeAcabamento = 0,
        qtdItem = 0;

      for (let i = 0; i < item.acabamentos.length; i++) {
        if (item.acabamentos[i].listaPrecos.length === 0) {
          let response = await this.getListaAcabamentos(
            item.acabamentos[i].codProdutoMetrics
          );
          item.acabamentos[i].listaPrecos = response.data;
        }

        if (
          item.acabamentos[i].listaPrecos.length > 0 &&
          !item.acabamentos[i].listaPrecos[0].Description.includes('Unico')
        ) {
          brindeAcabamento = item.qtdBrinde;
          qtdAcabamentoBusca = qtd + item.qtdBrinde;
          qtdAcabamentoSemBrinde = qtd;
          qtdItem = qtd;
        } else {
          brindeAcabamento = 0;
          qtdAcabamentoBusca = item.acabamentos[i].qtd;
          qtdAcabamentoSemBrinde = 0;
          qtdItem = item.acabamentos[i].qtd;
        }

        let precoTotal = item.acabamentos[i].precoTotal,
          precoTotalComQtdBrinde = this.calcularValorItem(
            qtdAcabamentoBusca,
            item.acabamentos[i].listaPrecos,
            item.acabamentos[i].precoUnitario
          ),
          precoAcabDoBrinde = precoTotalComQtdBrinde - precoTotal;

        item.acabamentos[i].qtd = qtdItem;
        item.acabamentos[i].precoTotal = precoTotalComQtdBrinde; // precoTotal - item.acabamentos[i].precoTotalBrinde;
        item.acabamentos[i].precoUnitario =
          item.acabamentos[i].precoTotal / item.acabamentos[i].qtd; //item.acabamentos[i].qtd;
        item.acabamentos[i].qtdBrinde = brindeAcabamento;
        item.acabamentos[i].precoTotalBrinde = precoAcabDoBrinde;
        somaAcab += item.acabamentos[i].precoTotal;
      }
      item.precoTotalAcabamentos = somaAcab;
      item.precoUnitarioAcabamentos = somaAcab / qtd;
      //fim atualiza valores acabamento

      let orcamento = this.state.orcamento;

      for (let i = 0; i < orcamento.itens.length; i++) {
        if (
          orcamento.itens[i].IdQuote === item.IdQuote &&
          orcamento.itens[i].qtd == item.qtd
        ) {
          orcamento.itens[i] = item;
        }
      }
      if (item.servico === 'MIOLO') {
        orcamento.quantidadeMiolo = 0;
        let qtdOrcamento = item.qtd + item.qtdBrinde;
        orcamento.itens.forEach((item) => {
          if (item.servico === 'MIOLO') {
            orcamento.quantidadeMiolo += item.qtd + item.qtdBrinde;
          }
        });
        // let qtdMudada = novaQtd - qtdAntiga;
        // orcamento.quantidadeMiolo = novaQtd;
        let precoDaArte =
          this.calcularValorItem(qtdOrcamento, this.state.listaPrecosArte) +
          this.state.margemAdicional;
        orcamento.precoDaArteT = precoDaArte;
        orcamento.precoDaArteU = precoDaArte / qtdOrcamento;
        let precoDoFrete = this.calcularValorItem(
          qtdOrcamento,
          this.state.listaPrecosFrete
        );
        orcamento.precoDoFreteT = precoDoFrete;
        orcamento.precoDoFreteU = precoDoFrete / qtdOrcamento;
        orcamento.valorArte = precoDaArte;
        orcamento.valorFrete = precoDoFrete;
      }
      item.precoTotalDoItem = this.calcularPrecoTotalDoItem(item);
      item.precoTotalDoItem = item.precoTotalDoItem / this.somarMargens();
      item.precoUnitarioDoItem = item.precoTotalDoItem / item.qtd;
      orcamento.precoTotalComMargens =
        this.calcularPrecoTotal() / this.somarMargens();
      this.setState(
        {
          orcamento,
          showModalMudarQtd: false,
          carregando: false,
          showModalMudarBrinde: false,
          inputMudarQtd: 0,
          showBotoesAcao: false,
        },
        () => {
          this.somarValorDosBrindesTotais();
        }
      );
    }
  }

  async mudarBrindeItemTabela() {
    this.setState({ carregando: true });

    let { itemTabelaSelecionado, orcamento, inputMudarBrinde } = this.state;

    itemTabelaSelecionado.qtdBrinde =
      inputMudarBrinde === 0
        ? itemTabelaSelecionado.qtdBrinde
        : Number(this.state.inputMudarBrinde);

    for (let i = 0; i < orcamento.itens.length; i++) {
      if (
        orcamento.itens[i].IdQuote === itemTabelaSelecionado.IdQuote &&
        orcamento.itens[i].qtd == itemTabelaSelecionado.qtd
      ) {
        orcamento.itens[i] = itemTabelaSelecionado;
        break;
      }
    }
    this.setState({ orcamento, inputMudarBrinde: 0 }, () => {
      this.mudarQtdItemTabela(itemTabelaSelecionado.qtd);
    });
  }

  mudarQtdAcabamento = async () => {
    let qtd = Number(this.state.inputMudarAcabamento),
      itemAcabamentoSelecionado = this.state.itemAcabamentoSelecionado,
      itemTabelaSelecionado = this.state.itemTabelaSelecionado;
    if (qtd < 1) {
      toast.error('A quantidade mínima de acabamentos é 1');
    } else {
      itemTabelaSelecionado.acabamentos.forEach((acabamento) => {
        if (acabamento.DescProoduto === 'Alpha-Play') {
          let precoUnitario =
            acabamento.PrcUN_Origem !== undefined
              ? acabamento.PrcUN_Origem
              : acabamento.precoUnitario;
          acabamento.Qtdd = qtd;
          acabamento.qtd = qtd;
          acabamento.precoTotal = precoUnitario * qtd;
        }
      });

      let orcamento = this.state.orcamento;
      for (let i = 0; i < orcamento.itens.length; i++) {
        if (
          orcamento.itens[i].IdQuote === itemTabelaSelecionado.IdQuote &&
          orcamento.itens[i].qtd == itemTabelaSelecionado.qtd
        ) {
          orcamento.itens[i] = itemTabelaSelecionado;
          break;
        }
      }
      this.setState(
        {
          orcamento,
          showModalMudarAcabamento: false,
          carregando: false,
          inputMudarAcabamento: 0,
          showBotoesAcao: false,
        },
        () => {
          this.somarValorDosBrindesTotais();
        }
      );
      this.mudarBrindeItemTabela();
    }
  };

  somarValorDosBrindesTotais() {
    let brindesTotais = this.state.orcamento.itens.filter((i) => {
      return i.qtd === 0 && i.qtdBrinde > 0;
    });
    //soma o valor total dos brindes totais
    let valorTotalBrindes = 0;
    for (let i = 0; i < brindesTotais.length; i++) {
      let totalItem =
        brindesTotais[i].precoTotalBrinde +
        brindesTotais[i].precoTotalAcabamentos +
        brindesTotais[i].precoTotal;
      valorTotalBrindes += totalItem;
    }
    let orcamento = this.state.orcamento;
    orcamento.valorTotalBrindes = valorTotalBrindes;

    this.setState({ orcamento });
  }
  /*Soma todos os itens e seus acabamentos é chamada no render() toda vez que o state muda*/
  calcularPrecoTotal(recalcular = true) {
    let {
        orcamento,
        possuiPagamentoIndividual,
        possuiArte,
        possuiFrete,
      } = this.state,
      soma = 0,
      precoTotalAcabamentos = 0,
      precoTotalBrinde = 0,
      precoDaArte = 0,
      precoDoFrete = 0;

    orcamento.itens.map((i) => {
      if (i.ItemFuturo === 0) {
        soma = soma + i.precoTotal;
        precoTotalAcabamentos += Number(i.precoTotalAcabamentos);
        precoTotalBrinde += i.precoTotalBrinde;
      }
    });

    if (recalcular) {
      precoDaArte = possuiArte ? orcamento.precoDaArteT : 0;
      precoDoFrete = possuiFrete ? orcamento.precoDoFreteT : 0;
    } else {
      precoDaArte = possuiArte ? orcamento.valorArte : 0;
      precoDoFrete = possuiArte ? orcamento.valorFrete : 0;
    }

    return (
      soma +
      precoTotalAcabamentos +
      precoTotalBrinde +
      precoDaArte +
      precoDoFrete +
      (possuiPagamentoIndividual
        ? orcamento.valorBoleto + orcamento.valorCartao
        : 0)
    );
  }
  /* remove o acabamento da lista de acabamentos no item, e atualiza o valor do preco de acabamento no item */
  retirarAcabamentoItem(e) {
    let acabamento = this.state.acabamentoParaExcluir,
      itemTabelaSelecionado = this.state.itemTabelaSelecionado,
      acabamentos = itemTabelaSelecionado.acabamentos.filter((a) => {
        return a !== acabamento;
      }),
      orcamento = this.state.orcamento;
    itemTabelaSelecionado.acabamentos = acabamentos;
    itemTabelaSelecionado.precoTotalAcabamentos -= Number(
      acabamento.precoTotal +
        (acabamento.PrcBrinde > 0 ? acabamento.PrcBrinde : 0)
    );
    itemTabelaSelecionado.precoUnitarioAcabamentos -= Number(
      acabamento.precoUnitario
    );
    itemTabelaSelecionado.precoTotalDoItem = this.calcularPrecoTotalDoItem(
      itemTabelaSelecionado
    );
    itemTabelaSelecionado.precoTotalDoItem =
      itemTabelaSelecionado.precoTotalDoItem / this.somarMargens();
    itemTabelaSelecionado.precoUnitarioDoItem =
      itemTabelaSelecionado.precoTotalDoItem / itemTabelaSelecionado.qtd;
    orcamento.precoTotalComMargens =
      this.calcularPrecoTotal() / this.somarMargens();
    this.setState({
      itemTabelaSelecionado,
      orcamento,
      showModalExcluirAcab: false,
      acabamentoParaExcluir: null,
      showBotoesAcao: false,
    });
  }
  /*
   Percorre os acabamentosSelecionados,
   state onde fica temporariamente os acabemnto selecionados na lista e calcula o preço de cada um,
   concatena o os acabamentos selecionados e calculados no item e soma seu valor
   */
  adicionarAcabamentoAoItem = async () => {
    this.setState({ carregando: true });
    let as = this.state.acabamentosSelecionados;
    let itemTabela = this.state.itemTabelaSelecionado;
    let quantidade = itemTabela.qtd;
    let novaQtdBrinde = itemTabela.qtdBrinde;
    let precoTotalAcabamentos = 0;
    let precoUnitarioAcabamentos = 0;
    let orcamento = this.state.orcamento;
    for (let i = 0; i < as.length; i++) {
      await axios
        .post(REQUEST_URL + '/Produto/lista_precos_acabamentos', {
          cod: as[i].IdOpcaoProduto,
        })
        .then((response) => {
          as[i].listaPrecos = response.data;
          let qtdAcab = quantidade;
          if (as[i].Description.includes('Unico')) {
            qtdAcab = 1;
            novaQtdBrinde = 0;
          }
          let precoTotal = this.calcularValorItem(
            qtdAcab + novaQtdBrinde,
            response.data,
            as[i].precoUnitario
          );
          as[i].precoTotal = Number(precoTotal);
          precoTotalAcabamentos += as[i].precoTotal;
          as[i].qtd = qtdAcab;
          as[i].precoUnitario = Number(precoTotal / qtdAcab);
          as[i].qtdBrinde = novaQtdBrinde;
          as[i].precoTotalBrinde = 0;
          precoUnitarioAcabamentos += Number(as[i].precoUnitario);
        });
    }
    let itens = this.state.orcamento.itens;
    itens = itens.filter((i) => i !== this.state.itemTabelaSelecionado);
    let itemTabelaSelecionado = this.state.itemTabelaSelecionado;
    itemTabelaSelecionado.acabamentos = itemTabelaSelecionado.acabamentos.concat(
      as
    );
    itemTabelaSelecionado.precoTotalAcabamentos += precoTotalAcabamentos;
    itemTabelaSelecionado.precoUnitarioAcabamentos += precoUnitarioAcabamentos;

    itemTabelaSelecionado.precoTotalDoItem = this.calcularPrecoTotalDoItem(
      itemTabelaSelecionado
    );
    itemTabelaSelecionado.precoTotalDoItem =
      itemTabelaSelecionado.precoTotalDoItem / this.somarMargens();
    itemTabelaSelecionado.precoUnitarioDoItem =
      itemTabelaSelecionado.precoTotalDoItem / itemTabelaSelecionado.qtd;

    orcamento.precoTotalComMargens =
      this.calcularPrecoTotal() / this.somarMargens();
    this.setState({
      itemTabelaSelecionado,
      showModalAddAcabamento: false,
      carregando: false,
      acabamentosSelecionados: [],
      orcamento,
    });
    this.setState({ orcamento });
  };
  /*
  Ao clicar em add nos acabemntos chama essa funcao buscando apenas os acabamentos de deternimado servico e preenchendo o modal
  */
  buscarApenasAcabamentos() {
    axios
      .post(`${REQUEST_URL}/Produto/lista_acabamentos`, {
        servico: this.state.itemTabelaSelecionado.servico,
      })
      .then((response) => {
        this.setState({
          acabamentos: response.data,
          showModalAddAcabamento: true,
          acabamentosSelecionados: [],
        });
      });
  }
  /*
  retorna valor pronto para calcular a porcentagem inversa
  */
  somarMargens(orc = null) {
    let orcamento = orc === null ? this.state.orcamento : orc,
      somaMargens =
        orcamento.margens.cv +
        orcamento.margens.ce +
        orcamento.margens.ca +
        orcamento.margens.autVen +
        orcamento.margens.autGer +
        orcamento.margens.ci +
        orcamento.margens.mu +
        orcamento.margens.isv;

    if (somaMargens > 100 || somaMargens < 0) {
      somaMargens = 99;
    }

    orcamento.somaMargens = 1 - somaMargens / 100;
    return orcamento.somaMargens;
  }

  // #####  handles inputs ####
  changeInputMargem(e) {
    let input = e.target.name;
    let orcamento = this.state.orcamento;
    let value = e.target.value;
    // Trava o campo para não aceitar mais do que 5 caracteres (contando a vírgula)
    // if (value.length <= 5) {
    if (this.state.colocarPonto) {
      this.setState({ colocarPonto: false });
    } else {
      if (value === '') {
        value = 0;
        e.target.value = 0;
      }
      if (
        orcamento.margens[input] === 0 &&
        value.length === 2 &&
        (value > 0 &&
          value[value.length - 1] !== '.' &&
          value[value.length - 1] !== ',')
      ) {
        value = e.target.value.substring(1, e.target.value.length);
        e.target.value = e.target.value.substring(1, e.target.value.length);
      }
      orcamento.margens[input] = parseFloat(value);
      orcamento.precoTotalComMargens =
        this.calcularPrecoTotal() / this.somarMargens();
      this.atualizarValoresItem();
      this.setState({ orcamento, showBotoesAcao: false });
    }
    // }
  }

  changeInputOrcamento(e) {
    let input = e.target.name;
    let orcamento = this.state.orcamento;
    orcamento[input] = e.target.value;
    orcamento.precoTotalComMargens =
      this.calcularPrecoTotal() / this.somarMargens();
    if (
      this.state.usuarioLogado.Funcao === 'ORCAMENTISTA' &&
      input === 'observacao'
    ) {
      this.setState({ orcamento });
    } else {
      this.setState({ orcamento, showBotoesAcao: false });
    }
  }

  changeInputItemManual(e) {
    let input = e.target.name;
    let itemManual = this.state.itemManual;
    itemManual[input] = e.target.value;
    // orcamento.precoTotalComMargens = this.calcularPrecoTotal()/this.somarMargens();
    this.setState({ itemManual, showBotoesAcao: false });
  }

  onChangeInputsPagamento(e) {
    let value = e.target.value;
    let name = e.target.name;
    let orcamento = Object.assign({}, this.state.orcamento);
    orcamento[name] = value;
    orcamento.precoTotalComMargens =
      this.calcularPrecoTotal() / this.somarMargens();
    this.setState({ orcamento, showBotoesAcao: false });
  }

  addItemManual() {
    let jaExiste = false,
      { itemManual, orcamento } = this.state;

    itemManual.precoUnitario = itemManual.precoUnitario.replace(',', '.');

    let itemNovo = {
      IdQuote: 0,
      IdQuoteIndigo: 0,
      nome: itemManual.item,
      detalhes: itemManual.detalhes,
      codItem: 0,
      qtd: itemManual.quantidade,
      precoUnitario: Number(itemManual.precoUnitario),
      precoTotal: Number(itemManual.precoUnitario) * itemManual.quantidade,
      listaPrecos: [],
      acabamentos: [],
      precoTotalAcabamentos: 0,
      precoUnitarioAcabamentos: 0,
      qtdBrinde: 0,
      precoTotalBrinde: 0,
      servico: '',
      visivelProposta: true,
      ItemFuturo: 0,
    };

    for (let i = 0; i < orcamento.itens.length; i++) {
      if (
        itemNovo.nome.toUpperCase() === orcamento.itens[i].nome.toUpperCase()
      ) {
        jaExiste = true;
      }
    }
    if (jaExiste) {
      toast.error(
        'Não é possível cadastrar mais de um item manual com o mesmo nome'
      );
      this.setState({
        showModalAddItemManual: false,
        itemManual: {
          item: '',
          detalhes: '',
          quantidade: '',
          precoUnitario: '',
        },
      });
    } else {
      orcamento.itens.push(itemNovo);
      this.atualizarValoresItem();
      orcamento.precoTotalComMargens =
        this.calcularPrecoTotal() / this.somarMargens();
      this.setState({ orcamento }, () => {
        this.setState({
          showModalAddItemManual: false,
          itemManual: {
            item: '',
            detalhes: '',
            quantidade: '',
            precoUnitario: '',
          },
          showBotoesAcao: false,
        });
      });
    }
  }

  orcamentoInfos() {
    let qtdMiolo = 0,
      qtdMioloBrinde = 0,
      tamMiolo = '';
    let qtdCapa = 0,
      qtdCapaBrinde = 0,
      tamCapa = '';
    let qtdSimples = 0,
      qtdSimplesBrinde = 0,
      tamSimples = '';
    //Embalagens
    //Luvas
    let qtdLuva = 0,
      qtdLuvaBrinde = 0,
      qtdLuvaSimples = 0,
      qtdLuvaSimplesBrinde = 0,
      qtdLuvaLuxo = 0,
      qtdLuvaLuxoBrinde = 0,
      qtdLuvaSemi = 0,
      qtdLuvaSemiBrinde = 0,
      tamLuvaLuxo = '',
      tamLuvaSemi = '',
      tamLuvaSimples = '';
    //Envelope
    let qtdEnvelope = 0,
      qtdEnvelopeBrinde = 0,
      qtdEnvelopeSimples = 0,
      qtdEnvelopeSimplesBrinde = 0,
      qtdEnvelopeLuxo = 0,
      qtdEnvelopeLuxoBrinde = 0,
      qtdEnvelopeSemi = 0,
      qtdEnvelopeSemiBrinde = 0,
      tamEnvelopeLuxo = '',
      tamEnvelopeSemi = '',
      tamEnvelopeSimples = '';
    //Caixas
    let qtdCaixa = 0,
      qtdCaixaBrinde = 0,
      qtdCaixaSimples = 0,
      qtdCaixaSimplesBrinde = 0,
      qtdCaixaLuxo = 0,
      qtdCaixaLuxoBrinde = 0,
      qtdCaixaSemi = 0,
      qtdCaixaSemiBrinde = 0,
      tamCaixaLuxo = '',
      tamCaixaSemi = '',
      tamCaixaSimples = '';

    for (let i = 0; this.state.orcamento.itens.length > i; i++) {
      let item = this.state.orcamento.itens[i];
      if (item.ItemFuturo === 0) {
        if (item.nome.indexOf('Capa') === 0) {
          qtdCapa += item.qtd;
          qtdCapaBrinde += item.qtdBrinde;
          tamCapa = item.detalhes;
        } else if (item.nome.indexOf('Miolo') === 0) {
          qtdMiolo += item.qtd;
          tamMiolo = item.detalhes;
          qtdMioloBrinde += item.qtdBrinde;
        } else if (item.nome.indexOf('Simples') === 0) {
          qtdSimples += item.qtd;
          tamSimples = item.detalhes;
          qtdSimplesBrinde += item.qtdBrinde;
        } else if (item.nome.includes('Luva')) {
          if (item.nome.includes('Simples')) {
            qtdLuvaSimples += item.qtd;
            qtdLuvaSimplesBrinde += item.qtdBrinde;
            tamLuvaSimples = item.detalhes;
          } else if (item.nome.includes('Semi Luxo')) {
            qtdLuvaSemi += item.qtd;
            qtdLuvaSemiBrinde += item.qtdBrinde;
            tamLuvaSemi = item.detalhes;
          } else if (item.nome.includes('Luxo')) {
            qtdLuvaLuxo += item.qtd;
            qtdLuvaLuxoBrinde += item.qtdBrinde;
            tamLuvaLuxo = item.detalhes;
          }
          qtdLuva += item.qtd;
          qtdLuvaBrinde += item.qtdBrinde;
        } else if (item.nome.includes('Envelope')) {
          if (item.nome.includes('Simples')) {
            qtdEnvelopeSimples += item.qtd;
            qtdEnvelopeSimplesBrinde += item.qtdBrinde;
            tamEnvelopeSimples = item.detalhes;
          } else if (item.nome.includes('Semi Luxo')) {
            qtdEnvelopeSemi += item.qtd;
            qtdEnvelopeSemiBrinde += item.qtdBrinde;
            tamEnvelopeLuxo = item.detalhes;
          } else if (item.nome.includes('Luxo')) {
            qtdEnvelopeLuxo += item.qtd;
            qtdEnvelopeLuxoBrinde += item.qtdBrinde;
            tamEnvelopeLuxo = item.detalhes;
          }
          qtdEnvelope += item.qtd;
          qtdEnvelopeBrinde += item.qtdBrinde;
        } else if (item.nome.includes('Caixa')) {
          qtdCaixaLuxo += item.qtd;
          qtdCaixaLuxoBrinde += item.qtdBrinde;

          qtdCaixa += item.qtd;
          qtdCaixaBrinde += item.qtdBrinde;
          tamCaixaLuxo = item.detalhes;
        }
      }
    }
    let qtdConviteLuxo = Number(qtdCapa && qtdMiolo ? qtdCapa : 0);
    let qtdConviteSemi = Number(qtdMiolo ? qtdMiolo - qtdCapa : 0);

    let qtdConviteLuxoBrinde = Number(
      qtdCapaBrinde && qtdMioloBrinde ? qtdCapaBrinde : 0
    );
    let qtdConviteSemiBrinde = Number(
      qtdMioloBrinde ? qtdMioloBrinde - qtdCapaBrinde : 0
    );

    //Alertas
    let alertas = [];
    let inconsistencias = false;

    let qtdEmbalagensLuxo =
      qtdCaixaLuxo +
      qtdLuvaLuxo +
      qtdEnvelopeLuxo +
      (qtdCaixaLuxoBrinde + qtdLuvaLuxoBrinde + qtdEnvelopeLuxoBrinde);
    let qtdEmbalagensSemi =
      qtdLuvaSemi +
      qtdEnvelopeSemi +
      qtdCaixaSemi +
      (qtdLuvaSemiBrinde + qtdEnvelopeSemiBrinde + qtdCaixaSemiBrinde);
    let qtdEmbalagensSimples =
      qtdCaixaSimples +
      qtdLuvaSimples +
      qtdEnvelopeSimples +
      (qtdCaixaSimplesBrinde + qtdLuvaSimplesBrinde + qtdEnvelopeSimplesBrinde);
    let qtdEmbalagensLuxoBrinde =
      qtdCaixaLuxoBrinde + qtdLuvaLuxoBrinde + qtdEnvelopeLuxoBrinde;
    let qtdEmbalagensSemiBrinde =
      qtdLuvaSemiBrinde + qtdEnvelopeSemiBrinde + qtdCaixaSemiBrinde;
    let qtdEmbalagensSimplesBrinde =
      qtdCaixaSimplesBrinde + qtdLuvaSimplesBrinde + qtdEnvelopeSimplesBrinde;

    alertas.push(
      'Luxo: ' +
        (qtdConviteLuxo + qtdConviteLuxoBrinde) +
        ', Semi Luxo: ' +
        (qtdConviteSemi + qtdConviteSemiBrinde) +
        ', Simples: ' +
        (qtdSimples + qtdSimplesBrinde)
    );
    alertas.push(
      'Emb Luxo: ' +
        qtdEmbalagensLuxo +
        ', Emb Semi Luxo: ' +
        qtdEmbalagensSemi +
        ', Emb Simples: ' +
        qtdEmbalagensSimples
    );
    if (qtdCapa + qtdCapaBrinde > qtdMiolo + qtdMioloBrinde) {
      alertas.push(
        'Não é possivel ter mais capas do que miolo ou orçamento sem miolo'
      );
    }
    if (
      this.state.usuarioLogado.Funcao !== 'ORCAMENTISTA' &&
      ((qtdMiolo + qtdMioloBrinde < 30 && qtdMiolo + qtdMioloBrinde !== 0) ||
        (qtdSimples + qtdSimplesBrinde < 30 &&
          qtdSimples + qtdSimplesBrinde !== 0))
    ) {
      alertas.push('A quantidade minima de convites é 30');
    }

    let qtdTotalSemi = qtdConviteSemi + qtdConviteSemiBrinde;
    let qtdTotalSemiBrinde = qtdConviteSemiBrinde;

    if (qtdEmbalagensSemi !== qtdTotalSemi) {
      alertas.push(
        'A quantidade de embalagens semiluxo não confere com a de convites semiluxo'
      );
    }

    if (qtdEmbalagensLuxo != qtdConviteLuxo + qtdConviteLuxoBrinde) {
      alertas.push(
        'A quantidade de embalagens luxo não confere com a de convites luxo'
      );
    }
    if (qtdEmbalagensSimples > qtdSimples + qtdSimplesBrinde) {
      alertas.push(
        'A quantidade de embalagens simples não pode ser maior que de convite simples'
      );
    }
    // Brindes
    if (qtdEmbalagensSemiBrinde !== qtdTotalSemiBrinde) {
      alertas.push(
        'A quantidade de embalagens semiluxo de brinde não confere com a de convites semiluxo'
      );
    }
    if (qtdEmbalagensLuxoBrinde !== qtdConviteLuxoBrinde) {
      alertas.push(
        'A quantidade de embalagens luxo de brinde não confere com a de convites luxo'
      );
    }
    // Weslley pediu pra comentar essa regra 02/08/2019
    // if(qtdEmbalagensSimplesBrinde !== qtdSimplesBrinde ){
    //   alertas.push("A quantidade de embalagens simples de brinde não pode ser maior que de convite simples");
    // }
    //tamanho capa e miolo
    if (
      tamMiolo &&
      tamCapa &&
      tamMiolo.toUpperCase().replace(/\s/g, '') !==
        tamCapa.toUpperCase().replace(/\s/g, '')
    ) {
      alertas.push('O tamanho da capa deve ser o mesmo do miolo');
    }
    //tamanhos embalagens
    //luva
    if (
      tamLuvaSimples &&
      tamSimples &&
      tamLuvaSimples.toUpperCase().replace(/\s/g, '') !==
        tamSimples.toUpperCase().replace(/\s/g, '')
    ) {
      alertas.push(
        'O tamanho da embalagem luva simples deve ser o mesmo do convite simples'
      );
    }
    if (
      tamLuvaLuxo &&
      tamLuvaLuxo.toUpperCase().replace(/\s/g, '') !==
        tamMiolo.toUpperCase().replace(/\s/g, '')
    ) {
      alertas.push(
        'O tamanho da embalagem luva luxo deve ser o mesmo do miolo'
      );
    }
    if (
      tamLuvaSemi &&
      tamLuvaSemi.toUpperCase().replace(/\s/g, '') !==
        tamMiolo.toUpperCase().replace(/\s/g, '')
    ) {
      alertas.push(
        'O tamanho da embalagem luva semiluxo deve ser o mesmo do miolo'
      );
    }
    //Envelope
    if (
      tamEnvelopeSimples &&
      tamEnvelopeSimples.toUpperCase().replace(/\s/g, '') !==
        tamSimples.toUpperCase().replace(/\s/g, '')
    ) {
      alertas.push(
        'O tamanho da embalagem envelope simples deve ser o mesmo do convite simples'
      );
    }
    if (
      tamEnvelopeLuxo &&
      tamEnvelopeLuxo.toUpperCase().replace(/\s/g, '') !==
        tamMiolo.toUpperCase().replace(/\s/g, '')
    ) {
      alertas.push(
        'O tamanho da embalagem envelope luxo deve ser o mesmo do miolo'
      );
    }
    if (
      tamEnvelopeSemi &&
      tamEnvelopeSemi.toUpperCase().replace(/\s/g, '') !==
        tamMiolo.toUpperCase().replace(/\s/g, '')
    ) {
      alertas.push(
        'O tamanho da embalagem envelope semiluxo deve ser o mesmo do miolo'
      );
    }
    //CAIXA
    if (
      tamCaixaLuxo &&
      tamCaixaLuxo.toUpperCase().replace(/\s/g, '') !==
        tamMiolo.toUpperCase().replace(/\s/g, '')
    ) {
      alertas.push('O tamanho da embalagem caixa deve ser o mesmo do miolo');
    }

    if (alertas.length > 2) {
      return (
        <div style={{ marginTop: 10 }}>
          {alertas.map((alerta, i) => {
            if (i <= 1) {
              return (
                <div
                  key={i}
                  style={{
                    textAlign: 'center',
                    padding: '0px 5px',
                    marginBottom: 5,
                  }}
                  className='alert alert-primary'
                >
                  {alerta}
                </div>
              );
            }
            return (
              <div
                key={i}
                style={{
                  textAlign: 'center',
                  padding: '0px 5px',
                  marginBottom: 5,
                }}
                className='alert alert-danger'
              >
                {alerta}
              </div>
            );
          })}
        </div>
      );
    }
    return false;
  }

  salvarMargens() {
    axios
      .post(REQUEST_URL + '/margens', this.state.orcamento.margens)
      .then((response) => {
        toast.success('Margens padrão atualizadas');
      })
      .catch((erro) => {
        this.criarLog(
          'ERROR',
          erro,
          'Não foi possível atualizar as margens padrão'
        );
        toast.error('Não foi possível atualizar as margens padrão');
      });
    this.setState({ showModalSalvarMargens: false });
  }

  focus(inputRef) {
    // Explicitly focus the text input using the raw DOM API
    // Note: we're accessing "current" to get the DOM node
    this[inputRef].current.focus();
  }

  formatReal(n) {
    if (n !== undefined && n !== null) {
      return Number(n)
        .toFixed(2)
        .toLocaleString('pt-BR');
    }
  }

  ocultarDaProposta() {
    let itemTabelaSelecionado = this.state.itemTabelaSelecionado;
    if (itemTabelaSelecionado.visivelProposta) {
      itemTabelaSelecionado.visivelProposta = false;
    } else {
      itemTabelaSelecionado.visivelProposta = true;
    }
    let orcamento = this.state.orcamento;
    for (let i = 0; i < orcamento.itens.length; i++) {
      if (
        orcamento.itens[i].IdQuote === itemTabelaSelecionado.IdQuote &&
        orcamento.itens[i].qtd == itemTabelaSelecionado.qtd &&
        orcamento.itens[i].nome == itemTabelaSelecionado.nome
      ) {
        orcamento.itens[i] = itemTabelaSelecionado;
        break;
      }
    }
    this.setState({ orcamento });
  }

  moverItem(item, direcao) {
    let orcamento = this.state.orcamento;
    let aux;
    for (let i = 0; i < orcamento.itens.length; i++) {
      if (orcamento.itens[i].IdQuote === item.IdQuote) {
        if (direcao === 'cima') {
          if (i !== 0) {
            aux = orcamento.itens[i - 1];
            orcamento.itens[i - 1] = orcamento.itens[i];
            orcamento.itens[i] = aux;
          }
        } else {
          if (i !== orcamento.itens.length - 1) {
            aux = orcamento.itens[i + 1];
            orcamento.itens[i + 1] = orcamento.itens[i];
            orcamento.itens[i] = aux;
          }
        }
        break;
      }
    }
    this.setState({ orcamento });
  }

  /*Esse metodo remove o id do orçamento para quando salvar criar outro e não modificar o primeiro*/
  duplicarOrcamento() {
    if (this.state.orcamento.idOrc) {
      let orcamento = this.state.orcamento;
      orcamento.idDuplicado = orcamento.idOrc;
      orcamento.duplicado = true;
      orcamento.status = 'Aguardando';
      orcamento.idOrc = null;
      orcamento.cliente =
        this.state.cliente.idCliente !== undefined &&
        typeof this.state.cliente.idCliente === 'number'
          ? this.state.cliente.idCliente
          : this.state.cliente.codCliente;
      orcamento.vendedor =
        this.state.cliente.Vendedor !== undefined &&
        typeof this.state.cliente.Vendedor === 'number'
          ? this.state.cliente.Vendedor
          : this.state.cliente.codVendedor;
      axios
        .get(REQUEST_URL + '/margens')
        .then((response) => {
          // PEGA AS MARGENS PADRÕES DO BANCO
          // let mp = response.data[0];
          // let orcamento = this.state.orcamento;
          // orcamento.margens = {cv:mp.CV,ce:mp.CE,ca:mp.CA,autVen:mp.AutoVendedor,autGer:mp.AutoGerente,ci:mp.CI,mu:mp.MU,isv:mp.ISV};
          // if(this.state.cliente.caPadrao){
          //   orcamento.margens.ca = this.state.cliente.caPadrao *100;
          // }
          let mp = response.data[0];
          let orcamento = this.state.orcamento;
          let cliente = this.state.cliente;
          orcamento.margens = {
            cv: this.state.usuarioLogado.cvFixo,
            ce: mp.CE,
            ca: mp.CA,
            autVen: Number(this.state.usuarioLogado.margemCusto),
            autGer: mp.AutoGerente,
            ci: mp.CI,
            mu: mp.MU,
            isv: mp.ISV,
          };
          if (this.state.cliente.caPadrao) {
            orcamento.margens.ca = this.state.cliente.caPadrao * 100;
          }
          this.setState({ orcamento });
          toast.info('Orcamento duplicado, margens padrões restabelecidas');
          this.setState({ orcamento, showModalDuplicarOrc: false });
          this.buscarClientes();
        })
        .catch((erro) => {
          this.criarLog(
            'ERROR',
            erro,
            'Não foi possível carregar as margens padrão'
          );
          toast.error('Não foi possivel carregar as margens padrão');
        });
    } else {
      this.setState({ showModalDuplicarOrc: false });
      toast.error(
        'Este orçamento ainda não foi salvo, não é possivel duplica-lo'
      );
    }
  }

  buscarClientes() {
    this.setState({ showModalSelectCliente: true });
    axios
      .post(REQUEST_URL + '/clientes_vendas', {
        vendedor: this.state.usuarioLogado.idUsuario,
        nivel: this.state.usuarioLogado.Nivel,
      })
      .then((response) => {
        this.setState({ listaClientes: response.data });
      });
  }

  procurarCliente() {
    axios
      .post(REQUEST_URL + '/clientes_vendas', {
        vendedor: this.state.usuarioLogado.idUsuario,
        nivel: this.state.usuarioLogado.Nivel,
        id: this.state.inputClienteId,
        nome: this.state.inputClienteNome,
      })
      .then((response) => {
        this.setState({ listaClientes: response.data });
      });
  }

  trocarCliente() {
    if (this.state.clienteSelecionado.idCliente) {
      this.setState({ cliente: this.state.clienteSelecionado }, () => {
        this.setState({
          clienteSelecionado: {},
          showModalSelectCliente: false,
        });
      });
    } else {
      toast.error('Selecione um cliente');
    }
  }

  /*Controla o input da busca de clientes*/
  handleDescricaoClienteInput(event) {
    let inputClienteNome = event.target.value;
    this.setState({ inputClienteNome }, () => {
      this.procurarCliente();
    });
  }

  /*Controla o input da busca de clientes*/
  handleIdClienteInput(event) {
    let inputClienteId = event.target.value;
    this.setState({ inputClienteId }, () => {
      this.procurarCliente();
    });
  }

  gotoContrato() {
    let orcamento = this.state.orcamento;
    orcamento.precoTotal = this.calcularPrecoTotal() / this.somarMargens();
    orcamento.somaMargens = this.somarMargens();

    orcamento.itens.map((item) => {
      let precoTotalDoItem = this.calcularPrecoTotalDoItem(item, false);

      precoTotalDoItem = precoTotalDoItem / this.somarMargens();
      let precoUnitarioDoItem = precoTotalDoItem / item.qtd;

      item.precoFinal = precoTotalDoItem;
      item.precoFinalUni = precoUnitarioDoItem;
    });
    orcamento.propostaSemValor = this.state.propostaSemValor;

    this.props.history.push({
      pathname: '/contrato',
      state: {
        orcamento: orcamento,
        cliente: this.state.cliente,
        possuiArte: this.state.possuiArte,
        possuiFrete: this.state.possuiFrete,
      },
    });
  }

  buscarValoresItem(item) {
    let precoTotalDoItem = 0;
    if (item.servico === 'MIOLO') {
      precoTotalDoItem =
        item.precoTotal +
        item.precoTotalAcabamentos +
        item.precoTotalBrinde +
        (this.state.orcamento.possuiArte ? this.state.orcamento.valorArte : 0) +
        (this.state.orcamento.possuiFrete
          ? this.state.orcamento.valorFrete
          : 0) +
        (this.state.possuiPagamentoIndividual
          ? this.state.orcamento.valorBoleto + this.state.orcamento.valorCartao
          : 0);
    } else {
      precoTotalDoItem =
        item.precoTotal + item.precoTotalAcabamentos + item.precoTotalBrinde;
    }
    precoTotalDoItem += this.distribuirValorBrindePeloItem(precoTotalDoItem);
    return precoTotalDoItem;
  }

  atualizarItensAoReabrirOrcamento() {
    let orcamento = this.state.orcamento;
    for (let i = 0; i < orcamento.itens.length; i++) {
      if (orcamento.itens[i].ItemFuturo === 0) {
        orcamento.itens[i].precoTotalDoItem = this.buscarValoresItem(
          orcamento.itens[i]
        );
        orcamento.itens[i].precoTotalDoItem =
          orcamento.itens[i].precoTotalDoItem / this.somarMargens();
        orcamento.itens[i].precoUnitarioDoItem =
          orcamento.itens[i].precoTotalDoItem / orcamento.itens[i].qtd;
      }
    }
    this.setState({ orcamento });
  }

  atualizarValoresItem(recalcular = true) {
    let orcamento = this.state.orcamento;
    for (let i = 0; i < orcamento.itens.length; i++) {
      if (orcamento.itens[i].ItemFuturo === 0) {
        orcamento.itens[i].precoTotalDoItem = this.calcularPrecoTotalDoItem(
          orcamento.itens[i],
          recalcular
        );
        orcamento.itens[i].precoTotalDoItem =
          orcamento.itens[i].precoTotalDoItem / this.somarMargens();
        orcamento.itens[i].precoUnitarioDoItem =
          orcamento.itens[i].precoTotalDoItem / orcamento.itens[i].qtd;
        orcamento.precoTotalComMargens =
          this.calcularPrecoTotal() / this.somarMargens();
      }
    }
    this.setState({ orcamento });
  }

  calcularPrecoTotalDoItem(item, recalcular = true) {
    let precoTotalDoItem = 0;
    if (item.servico === 'MIOLO') {
      let precoDaArte = 0;
      let precoDoFrete = 0;
      if (recalcular) {
        precoDaArte = this.state.possuiArte
          ? this.state.orcamento.precoDaArteU * (item.qtd + item.qtdBrinde)
          : 0;
        precoDoFrete = this.state.possuiFrete
          ? this.state.orcamento.precoDoFreteU * (item.qtd + item.qtdBrinde)
          : 0;
      } else {
        precoDaArte = this.state.possuiArte
          ? this.state.orcamento.valorArte
          : 0;
        precoDoFrete = this.state.possuiFrete
          ? this.state.orcamento.valorFrete
          : 0;
      }
      precoTotalDoItem =
        item.precoTotal +
        item.precoTotalAcabamentos +
        item.precoTotalBrinde +
        precoDaArte +
        precoDoFrete +
        (this.state.possuiPagamentoIndividual
          ? this.state.orcamento.valorBoleto + this.state.orcamento.valorCartao
          : 0);
      // console.log("ac", item.precoTotalAcabamentos);
      // console.log("br", item.precoTotalBrinde);
      // console.log("dd", item.precoTotal);
      // console.log("dd", precoTotalDoItem);
    } else {
      precoTotalDoItem =
        item.precoTotal + item.precoTotalAcabamentos + item.precoTotalBrinde;
    }
    precoTotalDoItem += this.distribuirValorBrindePeloItem(
      precoTotalDoItem,
      recalcular
    );
    // precoTotalDoItem = precoTotalDoItem/this.somarMargens();
    return precoTotalDoItem;
  }

  gotoKits() {
    let orcamento = this.state.orcamento,
      orcamentoCalculado = {};

    orcamentoCalculado.precoTotal =
      this.calcularPrecoTotal() / this.somarMargens();
    orcamentoCalculado.somaMargens = this.somarMargens();
    orcamentoCalculado.id = orcamento.idOrc;
    orcamentoCalculado.itens = [];
    orcamentoCalculado.cliente = this.state.cliente;
    orcamentoCalculado.status = orcamento.status;
    orcamentoCalculado.duplicado = orcamento.duplicado;
    orcamentoCalculado.possuiConviteOnline = this.state.possuiConviteOnline;
    orcamentoCalculado.idDuplicado = orcamento.idDuplicado;
    orcamentoCalculado.pagamentoIndividual = this.state.possuiPagamentoIndividual;
    orcamentoCalculado.NomeVendedor = orcamento.NomeVendedor;
    orcamentoCalculado.propostaSemValor = this.state.propostaSemValor;
    orcamentoCalculado.margens = orcamento.margens;
    orcamentoCalculado.valorTotalBrindes = orcamento.valorTotalBrindes;
    orcamentoCalculado.itensCompletos = [];

    orcamento.itens.map((item) => {
      let precoTotalDoItem = 0,
        precoUnitarioDoItem = 0;

      if (item.qtd > 0) {
        precoTotalDoItem = this.buscarValoresItem(item) / this.somarMargens();
        precoUnitarioDoItem = precoTotalDoItem / item.qtd;
      }

      item.precoFinal = precoTotalDoItem;
      item.precoFinalUni = precoUnitarioDoItem;

      orcamentoCalculado.itens.push({
        id: item.idPlano,
        nome: item.nome,
        precoFinal: precoTotalDoItem,
        precoFinalUni: precoUnitarioDoItem,
        qtd: item.qtd,
        qtdBrinde: item.qtdBrinde,
        qtdTotal: item.qtd + item.qtdBrinde,
        acabamentos: item.acabamentos,
        visivelProposta: item.visivelProposta,
        detalhes: item.detalhes,
        all: item,
      });
    });

    this.props.history.push({
      pathname: '/kits-orcamento',
      state: {
        orcamento: orcamentoCalculado,
        cliente: this.state.cliente,
        possuiArte: this.state.possuiArte,
        precosArte: this.state.listaPrecosArte,
        possuiFrete: this.state.possuiFrete,
        precosFrete: this.state.listaPrecosFrete,
        usuarioLogado: this.state.usuarioLogado,
      },
    });
  }

  downloadProposta() {
    var doc = new jsPDF({ lineHeight: 1.8 });
    var imgPropostaData = getPropostaImage();
    doc.setFontSize(11);
    let lineHeight = 7;
    let marginLeft = 10;
    let marginRight = 20;
    let pageWidth = doc.internal.pageSize.getWidth();
    let pageHeight = doc.internal.pageSize.getHeight();
    let textOptions = {
      maxWidth: pageWidth - (marginLeft + marginRight),
      align: 'justify',
    };
    doc.setFont('times');
    doc.addImage(imgPropostaData, 'JPEG', 0, 0, pageWidth, pageHeight);
    let line = 10;

    line += lineHeight * 4;
    doc.setFontType('bold');
    doc.text(marginLeft, line, `Proposta nº ${this.state.orcamento.idOrc}`);
    doc.setFontType('normal');
    doc.text(
      marginLeft + 125,
      line,
      `${new Date().toLocaleString('pt-br', {
        weekday: 'long',
        month: 'long',
        year: 'numeric',
        day: 'numeric',
      })}`
    );
    doc.text(
      marginLeft,
      (line += lineHeight),
      `À comissão de formatura do curso: ${this.state.cliente.curso}`
    );
    doc.text(
      marginLeft,
      (line += lineHeight),
      `Instituição: ${this.state.cliente.Instituicao}`
    );
    line += lineHeight;
    doc.text(
      marginLeft,
      (line += lineHeight),
      `Conforme solicitação, apresentamos abaixo nossa estimativa de preços conforme especificações e condições gerais.`,
      textOptions
    );
    line += lineHeight;
    doc.text(
      marginLeft,
      (line += lineHeight),
      `Arte: Projeto designer alpha (company)`
    );
    doc.text(
      marginLeft + 5,
      (line += lineHeight),
      `* Resposta do Briefing no site da empresa, onde será obtido um conjunto de respostas que traduzem da melhor forma os anseios e intenções da turma.`,
      {
        maxWidth: pageWidth - (marginLeft + marginRight + 15),
        align: 'justify',
      }
    );
    doc.text(
      marginLeft + 5,
      (line += lineHeight * 2),
      `* Pesquisa de imagens que abrangem a tendência.`
    );
    doc.text(
      marginLeft + 5,
      (line += lineHeight),
      `* Criação, editoração e diagramação.`
    );
    doc.text(
      marginLeft + 5,
      (line += lineHeight),
      `* Duas provas online no site da Alpha convites, para revisão, alterações e validações pela comissão de formatura.`
    );

    line += lineHeight;
    let tablebody = [];
    this.state.orcamento.itens.map((item) => {
      let precoTotalDoItem = this.calcularPrecoTotalDoItem(item, false);
      let precoUnitarioDoItem = 0;
      precoTotalDoItem = precoTotalDoItem / this.somarMargens();
      precoUnitarioDoItem = precoTotalDoItem / item.qtd;
      if (item.qtd > 0 && item.visivelProposta) {
        if (!this.state.propostaSemValor) {
          tablebody.push([
            item.nome +
              ' ' +
              item.detalhes +
              ` ${
                item.acabamentos.length > 0
                  ? 'C/ ' +
                    item.acabamentos
                      .map((a) => {
                        return a.DescProoduto;
                      })
                      .join(', ')
                  : ' '
              }` +
              '\n',
            item.qtd,
            `R$:${this.formatReal(precoUnitarioDoItem)}`,
            `R$:${this.formatReal(precoTotalDoItem)}`,
          ]);
        } else {
          tablebody.push([
            item.nome +
              ' ' +
              item.detalhes +
              ` ${
                item.acabamentos.length > 0
                  ? 'C/ ' +
                    item.acabamentos
                      .map((a) => {
                        return a.DescProoduto;
                      })
                      .join(', ')
                  : ' '
              }` +
              '\n',
            item.qtd,
          ]);
        }
      }
    });
    let head;
    head = !this.state.propostaSemValor
      ? ['ITENS', 'QTD', 'UN', 'TOTAL']
      : ['ITENS', 'QTD'];
    doc.autoTable({
      head: [head],
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
      },
      body: tablebody,
      startY: line,
      styles: { overflow: 'linebreak' },
      columnStyles: {
        0: {
          cellPadding: { top: 2, right: 8, bottom: 8, left: 2 },
          cellWidth: 100,
        },
      },
      theme: 'grid',
    });
    line = doc.autoTable.previous.finalY + 10;
    if (pageHeight < line + lineHeight + 30) {
      doc.addPage();
      doc.addImage(imgPropostaData, 'JPEG', 0, 0, pageWidth, pageHeight);
      line = 25;
    }
    let tablebodybrinde = [];
    this.state.orcamento.itens.map((item) => {
      if (item.qtdBrinde > 0 && item.visivelProposta) {
        if (!this.state.propostaSemValor) {
          tablebodybrinde.push([
            item.nome +
              ' ' +
              item.detalhes +
              ` ${
                item.acabamentos.length > 0
                  ? 'C/ ' +
                    item.acabamentos
                      .map((a) => {
                        return a.DescProoduto;
                      })
                      .join(', ')
                  : ' '
              }` +
              '\n',
            item.qtdBrinde,
            `R$:00,00`,
            `R$:00,00`,
          ]);
        } else {
          tablebodybrinde.push([
            item.nome +
              ' ' +
              item.detalhes +
              ` ${
                item.acabamentos.length > 0
                  ? 'C/ ' +
                    item.acabamentos
                      .map((a) => {
                        return a.DescProoduto;
                      })
                      .join(', ')
                  : ' '
              }` +
              '\n',
            item.qtdBrinde,
          ]);
        }
      }
    });
    let headbrinde;
    if (!this.state.propostaSemValor) {
      headbrinde = ['BRINDES', 'QTD', 'UN', 'TOTAL'];
    } else {
      headbrinde = ['BRINDES', 'QTD'];
    }
    if (tablebodybrinde.length > 0) {
      doc.autoTable({
        head: [headbrinde],
        headStyles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
        },
        body: tablebodybrinde,
        startY: line,
        styles: { overflow: 'linebreak' },
        columnStyles: {
          0: {
            cellPadding: { top: 2, right: 8, bottom: 10, left: 2 },
            cellWidth: 100,
          },
        },
        theme: 'grid',
      });
      line = doc.autoTable.previous.finalY + 10;
    }

    if (pageHeight < line + lineHeight + 30) {
      doc.addPage();
      doc.addImage(imgPropostaData, 'JPEG', 0, 0, pageWidth, pageHeight);
      line = 25;
    }
    if (this.state.possuiConviteOnline) {
      doc.text(
        marginLeft,
        (line += lineHeight),
        'BRINDE EXTRA: Convite on-line'
      );
    }
    if (this.state.possuiFrete) {
      doc.text(
        marginLeft,
        (line += lineHeight + 15),
        'Frete terreste por conta do fornecedor'
      );
    }
    if (!this.state.propostaSemValor) {
      doc.text(
        marginLeft + 150,
        (line += lineHeight * 2),
        `TOTAL: R$:${this.formatReal(
          this.calcularPrecoTotal() / this.somarMargens()
        )}`
      );
      doc.text(
        `(${extenso(
          (this.calcularPrecoTotal() / this.somarMargens()).toLocaleString(
            'pt-BR'
          )
        )})`,
        marginLeft + 183.5,
        (line += lineHeight),
        {
          maxWidth: pageWidth - (marginLeft + marginRight + 15),
          align: 'right',
        }
      );
    }
    doc.text(
      marginLeft + 113,
      (line += lineHeight),
      `Condições e forma de pagamento a combinar`
    );
    doc.text(
      marginLeft + 141.5,
      (line += lineHeight),
      `Proposta válida por 30 dias`
    );
    line += lineHeight;
    doc.text(marginLeft, (line += lineHeight), `Observação:`);

    let observacao = [];
    let linha = '';

    observacao = this.state.orcamento.observacao.split('\n');
    observacao.forEach((linha) => {
      if (pageHeight < line + lineHeight + 10) {
        doc.addPage();
        doc.addImage(imgPropostaData, 'JPEG', 0, 0, pageWidth, pageHeight);
        line = 25;
      }
      doc.text(marginLeft, (line += lineHeight), linha, textOptions);
      if (linha.length > 80) line += lineHeight;
    });

    doc.text(
      marginLeft,
      pageHeight - 10,
      `Representante: ${this.state.orcamento.NomeVendedor} `
    );

    doc.save(`proposta-${this.state.orcamento.idOrc}.pdf`);
  }

  salvarOffLine() {
    var element = document.createElement('a'),
      textoPlano = JSON.stringify(this.state),
      simpleCrypto = new SimpleCrypto('f0151b748a1c50c3ec97bce7913a3d94'),
      encriptado = simpleCrypto.encrypt(textoPlano);
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(encriptado)
    );
    element.setAttribute('download', `${this.state.nomeorc}.orc`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  desativarShowTotalOrcamento() {
    this.setState({ showTotalOrcamento: !this.state.showTotalOrcamento });
  }

  uploadOrcamento(e) {
    let file = e.target.files[0];
    if (file && file.name.split('.')[1] === 'orc') {
      let fileReader = new FileReader(),
        self = this;
      fileReader.onload = () => {
        var text = fileReader.result,
          simpleCrypto = new SimpleCrypto('f0151b748a1c50c3ec97bce7913a3d94'),
          decipherText = simpleCrypto.decrypt(text);
        self.setState(JSON.parse(decipherText));
        self.setState({
          showModalNomeArquivo: false,
          carregando: false,
          usuarioLogado: JSON.parse(sessionStorage.getItem('usuarioLogado'))[0],
        });
        toast.info('Orcamento recuperado!');
        let orcamento = self.state.orcamento;
        orcamento.itens.forEach((orc) => {
          orc.ItemFuturo = 0;
        });
        self.setState({ orcamento });
      };
      fileReader.readAsText(file);
    } else {
      toast.error('Entre com um arquivo válido');
    }
  }

  distribuirValorBrindePeloItem(precoDoItem, recalcular = true) {
    let totalBrinde = this.state.orcamento.valorTotalBrindes;
    let totalOrcamento = this.calcularPrecoTotal(recalcular) - totalBrinde;
    if (totalOrcamento > 0) {
      return (precoDoItem / totalOrcamento) * totalBrinde;
    }
    return 0;
  }

  editarValorPagamentos() {
    let orcamento = this.state.orcamento,
      valorBoleto =
        orcamento.qtdFormandoBoleto * orcamento.numeroParcelas * 3.44,
      valorCartao = 0,
      bkNumeroParcelas = orcamento.numeroParcelas,
      bkQtdFormandoBoleto = orcamento.qtdFormandoBoleto;

    orcamento.valorBoleto = valorBoleto;
    orcamento.valorCartao = valorCartao;
    this.atualizarValoresItem();
    orcamento.precoTotalComMargens =
      this.calcularPrecoTotal() / this.somarMargens();
    this.setState({
      orcamento,
      showModalBoleto: false,
      bkNumeroParcelas,
      bkQtdFormandoBoleto,
      possuiPagamentoIndividual: valorBoleto === 0 ? false : true,
    });
  }

  async alterarValorMU() {
    let orcamento = this.state.orcamento,
      valorNovo = this.state.precoOrcamento,
      valorAntigoSemMargens = await this.calcularPrecoTotal(),
      porcentPrecoSugerido = 1 - valorAntigoSemMargens / valorNovo,
      valorMargemAntiga = Number(1 - this.somarMargens()),
      muAntigo = orcamento.margens.mu,
      muNovo = muAntigo - (valorMargemAntiga - porcentPrecoSugerido) * 100;
    if (muNovo < 5) {
      toast.error('Reajuste abaixo do permitido!');
      this.setState({ alterarPrecoOrcamento: false });
    } else {
      this.setState({
        alterarPrecoOrcamento: false,
        orcamento: {
          ...this.state.orcamento,
          precoTotalComMargens: valorNovo,
          margens: { ...this.state.orcamento.margens, mu: muNovo },
        },
      });
    }
  }

  cancelarEdicaoPagamento() {
    let orcamento = Object.assign({}, this.state.orcamento);
    orcamento.numeroParcelas = this.state.bkNumeroParcelas;
    orcamento.qtdFormandoBoleto = this.state.bkQtdFormandoBoleto;
    orcamento.precoTotalComMargens =
      this.calcularPrecoTotal() / this.somarMargens();
    this.setState({ showModalBoleto: false, orcamento });
    if (this.state.orcamento.valorBoleto === 0) {
      this.setState({ possuiPagamentoIndividual: false });
    }
  }

  setPrecoDeBriga(precoDeBriga) {
    let orcamento = this.state.orcamento;
    switch (precoDeBriga) {
      case 'minimo':
        orcamento.margens.autGer = 10;
        break;
      case 'medio':
        orcamento.margens.autGer = 5;
        break;
      case 'maximo':
        orcamento.margens.autGer = 0;
        break;
      default:
        break;
    }
    this.atualizarValoresItem();
    this.setState({ orcamento, precoDeBriga });
  }

  rotinaPosItemAdicionado(orc) {
    if (orc.ehMiolo29) orc.margens.ci = 10;

    this.setState({ orcamento: orc, showModalAddItem: false }, () => {
      this.atualizarValoresItem(true);
    });
  }

  _renderCliente() {
    return (
      <Card
        style={{
          marginTop: 30,
          boxShadow:
            '0.5rem 0.5rem 2rem 0 rgb(8 15 41 / 8%), 0 0 1px 0 rgb(8 15 41 / 8%)',
          borderRadius: '.375rem',
        }}
      >
        <CardBody>
          <div className='table-responsive'>
            <table className='table table-sm'>
              <thead>
                <tr>
                  <th>Cliente</th>
                  <th>Faculdade</th>
                  <th>Curso</th>
                  <th>Cidade</th>
                  <th>Parceiro</th>
                  <th>Formatura</th>
                  <th style={{ width: 10 }}></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ whiteSpace: 'nowrap' }}>
                    {this.state.cliente.Descricao}
                  </td>
                  <td style={{ whiteSpace: 'nowrap' }}>
                    {this.state.cliente.Instituicao}
                  </td>
                  <td style={{ whiteSpace: 'nowrap' }}>
                    {this.state.cliente.curso}
                  </td>
                  <td style={{ whiteSpace: 'nowrap' }}>
                    {this.state.cliente.Cidade}
                  </td>
                  <td style={{ whiteSpace: 'nowrap' }}>
                    {this.state.cliente.NomeAgencia}
                  </td>
                  <td style={{ whiteSpace: 'nowrap' }}>
                    {this.state.cliente.Formatura !== undefined
                      ? this.state.cliente.Formatura
                      : '---'}
                  </td>
                  <td>
                    <div style={{ height: 0 }}>
                      <button
                        onClick={this.buscarClientes.bind(this)}
                        className='btn'
                      >
                        Trocar
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    );
  }

  _renderOrcamentoStatus() {
    return (
      <Card
        style={{
          marginTop: 30,
          boxShadow:
            '0.5rem 0.5rem 2rem 0 rgb(8 15 41 / 8%), 0 0 1px 0 rgb(8 15 41 / 8%)',
          borderRadius: '.375rem',
        }}
      >
        <CardBody>
          <div className='table-responsive'>
            <table className='table table-sm'>
              <thead>
                <tr>
                  <th style={{ whiteSpace: 'nowrap' }}>Orcamento</th>
                  <th style={{ whiteSpace: 'nowrap' }}>Status</th>
                  <th style={{ whiteSpace: 'nowrap' }}>Vendedor</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {this.state.orcamento.idOrc
                      ? this.state.orcamento.idOrc
                      : 'Não salvo'}
                  </td>
                  <td>
                    {this.state.orcamento.status}{' '}
                    {this.state.orcamento.duplicado
                      ? ', Duplicado do ' + this.state.orcamento.idDuplicado
                      : ''}
                  </td>
                  <td>{this.state.orcamento.NomeVendedor}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    );
  }
  /*melhorar essa função qualquer dia*/
  _renderItensAgrupados() {
    let qtdMiolo = 0,
      qtdMioloBrinde = 0,
      tamMiolo = '',
      precoMiolo = 0;
    let qtdCapa = 0,
      qtdCapaBrinde = 0,
      tamCapa = '',
      precoCapa = 0;
    let qtdSimples = 0,
      qtdSimplesBrinde = 0,
      tamSimples = '',
      precoSimples = 0;
    //Embalagens
    //Luvas
    let qtdLuva = 0,
      qtdLuvaBrinde = 0,
      qtdLuvaSimples = 0,
      qtdLuvaSimplesBrinde = 0,
      qtdLuvaLuxo = 0,
      qtdLuvaLuxoBrinde = 0,
      qtdLuvaSemi = 0,
      qtdLuvaSemiBrinde = 0,
      tamLuvaLuxo = '',
      tamLuvaSemi = '',
      tamLuvaSimples = '';
    //Envelope
    let qtdEnvelope = 0,
      qtdEnvelopeBrinde = 0,
      qtdEnvelopeSimples = 0,
      qtdEnvelopeSimplesBrinde = 0,
      qtdEnvelopeLuxo = 0,
      qtdEnvelopeLuxoBrinde = 0,
      qtdEnvelopeSemi = 0,
      qtdEnvelopeSemiBrinde = 0,
      tamEnvelopeLuxo = '',
      tamEnvelopeSemi = '',
      tamEnvelopeSimples = '';
    //Caixas
    let qtdCaixa = 0,
      qtdCaixaBrinde = 0,
      qtdCaixaSimples = 0,
      qtdCaixaSimplesBrinde = 0,
      qtdCaixaLuxo = 0,
      qtdCaixaLuxoBrinde = 0,
      qtdCaixaSemi = 0,
      qtdCaixaSemiBrinde = 0,
      tamCaixaLuxo = '',
      tamCaixaSemi = '',
      tamCaixaSimples = '';
    let precoEmbSimples = 0;
    let precoEmbSemi = 0;

    let precoEmbLuxoLuva = 0;
    let precoEmbLuxoEnvelope = 0;
    let precoEmbLuxoCaixa = 0;

    let precoEmbSemiLuva = 0;
    let precoEmbSemiEnvelope = 0;

    let precoEmbSimplesLuva = 0;
    let precoEmbSimplesEnvelope = 0;

    //(item.precoTotal + item.precoTotalAcabamentos + item.precoTotalBrinde)
    for (let i = 0; this.state.orcamento.itens.length > i; i++) {
      let item = this.state.orcamento.itens[i];
      let precoTotalItem = this.calcularPrecoTotalDoItem(item, false);

      if (item.nome.indexOf('Capa') === 0) {
        qtdCapa += item.qtd;
        qtdCapaBrinde += item.qtdBrinde;
        precoCapa += precoTotalItem;
      } else if (item.nome.indexOf('Miolo') === 0) {
        qtdMiolo += item.qtd;
        qtdMioloBrinde += item.qtdBrinde;
        precoMiolo += precoTotalItem;
      } else if (item.nome.indexOf('Simples') === 0) {
        qtdSimples += item.qtd;
        qtdSimplesBrinde += item.qtdBrinde;
        precoSimples += precoTotalItem;
      } else if (item.nome.includes('Luva')) {
        if (item.nome.includes('Simples')) {
          qtdLuvaSimples += item.qtd;
          qtdLuvaSimplesBrinde += item.qtdBrinde;
          precoEmbSimplesLuva += precoTotalItem;
        } else if (item.nome.includes('Semi Luxo')) {
          qtdLuvaSemi += item.qtd;
          qtdLuvaSemiBrinde += item.qtdBrinde;
          precoEmbSemiLuva += precoTotalItem;
        } else if (item.nome.includes('Luxo')) {
          qtdLuvaLuxo += item.qtd;
          qtdLuvaLuxoBrinde += item.qtdBrinde;
          precoEmbLuxoLuva += precoTotalItem;
        }
        qtdLuva += item.qtd;
        qtdLuvaBrinde += item.qtdBrinde;
      } else if (item.nome.includes('Envelope')) {
        if (item.nome.includes('Simples')) {
          qtdEnvelopeSimples += item.qtd;
          qtdEnvelopeSimplesBrinde += item.qtdBrinde;
          precoEmbSimplesEnvelope += precoTotalItem;
        } else if (item.nome.includes('Semi Luxo')) {
          qtdEnvelopeSemi += item.qtd;
          qtdEnvelopeSemiBrinde += item.qtdBrinde;
          precoEmbSemiEnvelope += precoTotalItem;
        } else if (item.nome.includes('Luxo')) {
          qtdEnvelopeLuxo += item.qtd;
          qtdEnvelopeLuxoBrinde += item.qtdBrinde;
          precoEmbLuxoEnvelope += precoTotalItem;
        }
        qtdEnvelope += item.qtd;
        qtdEnvelopeBrinde += item.qtdBrinde;
      } else if (item.nome.includes('Caixa')) {
        qtdCaixaLuxo += item.qtd;
        qtdCaixaLuxoBrinde += item.qtdBrinde;

        qtdCaixa += item.qtd;
        qtdCaixaBrinde += item.qtdBrinde;
        precoEmbLuxoCaixa += precoTotalItem;
      }
    }
    let qtdConviteLuxo = Number(qtdCapa && qtdMiolo ? qtdCapa : 0);
    let qtdConviteSemi = Number(qtdMiolo ? qtdMiolo - qtdCapa : 0);

    let qtdConviteLuxoBrinde = Number(
      qtdCapaBrinde && qtdMioloBrinde ? qtdCapaBrinde : 0
    );
    let qtdConviteSemiBrinde = Number(
      qtdMioloBrinde ? qtdMioloBrinde - qtdCapaBrinde : 0
    );

    //Alertas
    let alertas = [];
    let inconsistencias = false;

    let qtdEmbalagensLuxo =
      qtdCaixaLuxo +
      qtdLuvaLuxo +
      qtdEnvelopeLuxo +
      (qtdCaixaLuxoBrinde + qtdLuvaLuxoBrinde + qtdEnvelopeLuxoBrinde);
    let qtdEmbalagensSemi =
      qtdLuvaSemi +
      qtdEnvelopeSemi +
      qtdCaixaSemi +
      (qtdLuvaSemiBrinde + qtdEnvelopeSemiBrinde + qtdCaixaSemiBrinde);
    let qtdEmbalagensSimples =
      qtdCaixaSimples +
      qtdLuvaSimples +
      qtdEnvelopeSimples +
      (qtdCaixaSimplesBrinde + qtdLuvaSimplesBrinde + qtdEnvelopeSimplesBrinde);

    let precoUniMiolo = precoMiolo / qtdMiolo;
    let precoUniCapa = precoCapa / qtdCapa;

    let precoLuxoLuva =
      (precoUniCapa * qtdLuvaLuxo +
        precoUniMiolo * qtdLuvaLuxo +
        precoEmbLuxoLuva) /
      this.somarMargens();
    let precoLuxoCaixa =
      (precoUniCapa * qtdCaixaLuxo +
        precoUniMiolo * qtdCaixaLuxo +
        precoEmbLuxoCaixa) /
      this.somarMargens();
    let precoLuxoEnvelope =
      (precoUniCapa * qtdEnvelopeLuxo +
        precoUniMiolo * qtdEnvelopeLuxo +
        precoEmbLuxoEnvelope) /
      this.somarMargens();

    let precoSemiLuva =
      ((precoMiolo / qtdMiolo) * qtdLuvaSemi + precoEmbSemiLuva) /
      this.somarMargens();
    let precoSemiEnvelope =
      ((precoMiolo / qtdMiolo) * qtdEnvelopeSemi + precoEmbSemiEnvelope) /
      this.somarMargens();

    let precoSimplesLuva =
      ((precoSimples / qtdSimples) * qtdLuvaSimples + precoEmbSimplesLuva) /
      this.somarMargens();
    let precoSimplesEnvelope =
      ((precoSimples / qtdSimples) * qtdEnvelopeSimples +
        precoEmbSimplesEnvelope) /
      this.somarMargens();
    let qtdSimplesSemEmbalagem =
      qtdSimples - (qtdEnvelopeSimples + qtdLuvaSimples);
    let precoSimplesSemEmb =
      ((precoSimples / qtdSimples) * qtdSimplesSemEmbalagem) /
      this.somarMargens();

    precoSimples = (precoSimples + precoEmbSimples) / this.somarMargens();
    return (
      <Card style={{ borderRadius: 0 }}>
        <CardBody>
          <table className='table table-sm'>
            <thead>
              <tr>
                <th>Convite</th>
                <th>Qtd</th>
                <th>Unitario</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr hidden={!qtdCaixaLuxo}>
                <td>Luxo com Caixa</td>
                <td>{qtdCaixaLuxo}</td>
                <td>
                  {qtdCaixaLuxo
                    ? this.formatReal(precoLuxoCaixa / qtdCaixaLuxo)
                    : 0}
                </td>
                <td>{qtdCaixaLuxo ? this.formatReal(precoLuxoCaixa) : 0}</td>
              </tr>
              <tr hidden={!qtdLuvaLuxo}>
                <td>Luxo com Luva</td>
                <td>{qtdLuvaLuxo}</td>
                <td>
                  {qtdLuvaLuxo
                    ? this.formatReal(precoLuxoLuva / qtdLuvaLuxo)
                    : 0}
                </td>
                <td>{qtdLuvaLuxo ? this.formatReal(precoLuxoLuva) : 0}</td>
              </tr>
              <tr hidden={!qtdEnvelopeLuxo}>
                <td>Luxo com Envelope</td>
                <td>{qtdEnvelopeLuxo}</td>
                <td>
                  {qtdEnvelopeLuxo
                    ? this.formatReal(precoLuxoEnvelope / qtdEnvelopeLuxo)
                    : 0}
                </td>
                <td>
                  {qtdEnvelopeLuxo ? this.formatReal(precoLuxoEnvelope) : 0}
                </td>
              </tr>

              <tr hidden={!qtdLuvaSemi}>
                <td>Semi Luxo com Luva</td>
                <td>{qtdLuvaSemi}</td>
                <td>
                  {qtdLuvaSemi
                    ? this.formatReal(precoSemiLuva / qtdLuvaSemi)
                    : 0}
                </td>
                <td>{qtdLuvaSemi ? this.formatReal(precoSemiLuva) : 0}</td>
              </tr>
              <tr hidden={!qtdEnvelopeSemi}>
                <td>Semi Luxo Envelope</td>
                <td>{qtdEnvelopeSemi}</td>
                <td>
                  {qtdEnvelopeSemi
                    ? this.formatReal(precoSemiEnvelope / qtdEnvelopeSemi)
                    : 0}
                </td>
                <td>
                  {qtdEnvelopeSemi ? this.formatReal(precoSemiEnvelope) : 0}
                </td>
              </tr>
              <tr hidden={!qtdSimplesSemEmbalagem}>
                <td>Simples sem Embalagem</td>
                <td>{qtdSimplesSemEmbalagem}</td>
                <td>
                  {qtdSimplesSemEmbalagem
                    ? this.formatReal(
                        precoSimplesSemEmb / qtdSimplesSemEmbalagem
                      )
                    : 0}
                </td>
                <td>
                  {qtdSimplesSemEmbalagem
                    ? this.formatReal(precoSimplesSemEmb)
                    : 0}
                </td>
              </tr>
              <tr hidden={!qtdEnvelopeSimples}>
                <td>Simples com Envelope</td>
                <td>{qtdEnvelopeSimples}</td>
                <td>
                  {qtdEnvelopeSimples
                    ? this.formatReal(precoSimplesEnvelope / qtdEnvelopeSimples)
                    : 0}
                </td>
                <td>
                  {qtdEnvelopeSimples
                    ? this.formatReal(precoSimplesEnvelope)
                    : 0}
                </td>
              </tr>
              <tr hidden={!qtdLuvaSimples}>
                <td>Simples com Luva</td>
                <td>{qtdLuvaSimples}</td>
                <td>
                  {qtdLuvaSimples
                    ? this.formatReal(precoSimplesLuva / qtdLuvaSimples)
                    : 0}
                </td>
                <td>
                  {qtdLuvaSimples ? this.formatReal(precoSimplesLuva) : 0}
                </td>
              </tr>
            </tbody>
          </table>
        </CardBody>
      </Card>
    );
  }

  _renderTableItens() {
    return (
      <Card
        style={{
          boxShadow:
            '0.5rem 0.5rem 2rem 0 rgb(8 15 41 / 8%), 0 0 1px 0 rgb(8 15 41 / 8%)',
          borderRadius: '.375rem',
        }}
      >
        <CardHeader
          style={{
            borderBottom: 'none',
            backgroundColor: 'white',
            borderRadius: '.375rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          ITENS
          <div style={{ display: 'flex' }}>
            <button
              style={{
                border: '1px solid #007bff',
                color: '#007bff',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '.750rem',
              }}
              className='btn btn-default'
              onClick={() => {
                this.setState({ showModalAddItemManual: true });
              }}
            >
              <i className='fa fa-plus' />
              <div className='hidden-text' style={{ marginLeft: 5 }}>
                Item manual
              </div>
            </button>
            <button
              style={{
                marginLeft: 15,
                display: 'flex',
                alignItems: 'center',
                borderRadius: '.750rem',
              }}
              className='btn btn-success'
              onClick={() => this.setState({ showModalAddItem: true })}
            >
              <i className='fa fa-plus' />
              <div className='hidden-text' style={{ marginLeft: 5 }}>
                Item
              </div>
            </button>
          </div>
        </CardHeader>
        <CardBody style={{ marginTop: 0, paddingTop: 0 }}>
          <div className='table-responsive'>
            <Table hover size='sm'>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Item</th>
                  <th>Detalhes</th>
                  <th>Acab</th>
                  <th>Qtd</th>
                  <th style={{ minWidth: 92 }}>Qtd brinde</th>
                  {this.state.showTotalOrcamento ? <th>P/ unitário</th> : null}
                  {this.state.showTotalOrcamento ? <th>Total</th> : null}
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody style={{ height: '100px', overflowY: 'scroll' }}>
                {this.state.orcamento.itens.length > 0
                  ? this.state.orcamento.itens.map((item, i) => {
                      if (item.ItemFuturo === 0) {
                        return (
                          <tr
                            key={i}
                            style={
                              this.state.itemTabelaSelecionado === item
                                ? { backgroundColor: '#73dbff73' }
                                : { cursor: 'pointer' }
                            }
                            onClick={() =>
                              this.setState({ itemTabelaSelecionado: item })
                            }
                          >
                            <td style={{ width: 10 }}>
                              <div
                                style={{
                                  width: 10,
                                  height: 20,
                                  position: 'initial',
                                }}
                              >
                                <i
                                  style={{
                                    position: 'relative',
                                    left: 0,
                                    top: -8,
                                  }}
                                  onClick={() => {
                                    this.moverItem(item, 'cima');
                                  }}
                                  className='fas fa-caret-up'
                                ></i>
                                <i
                                  style={{
                                    position: 'relative',
                                    left: 0,
                                    top: -15,
                                  }}
                                  onClick={() => {
                                    this.moverItem(item, 'baixa');
                                  }}
                                  className='fas fa-caret-down'
                                ></i>
                              </div>
                            </td>
                            <td style={{ whiteSpace: 'nowrap' }}>
                              {item.nome}
                            </td>
                            <td style={{ whiteSpace: 'nowrap' }}>
                              {item.detalhes}
                            </td>
                            <td style={{ whiteSpace: 'nowrap' }}>
                              {item.acabamentos ? item.acabamentos.length : 0}
                            </td>
                            <td style={{ whiteSpace: 'nowrap' }}>
                              {item.qtd}{' '}
                              {item.qtd > 0 ? (
                                <i
                                  onClick={() => {
                                    this.setState(
                                      {
                                        showModalMudarQtd: true,
                                        itemTabelaSelecionado: item,
                                        inputMudarQtd: item.qtd,
                                      },
                                      () => {
                                        document
                                          .getElementById('inputQtdItemTabela')
                                          .focus();
                                      }
                                    );
                                  }}
                                  className='fas fa-pencil-alt fa-sm'
                                ></i>
                              ) : null}
                            </td>
                            <td style={{ whiteSpace: 'nowrap' }}>
                              {item.qtdBrinde}{' '}
                              {item.qtd > 0 ? (
                                <i
                                  onClick={() => {
                                    this.setState(
                                      {
                                        showModalMudarBrinde: true,
                                        itemTabelaSelecionado: item,
                                        inputMudarBrinde: item.qtdBrinde,
                                      },
                                      () => {
                                        document
                                          .getElementById('inputQtdBrinde')
                                          .focus();
                                      }
                                    );
                                  }}
                                  className='fas fa-pencil-alt fa-sm'
                                ></i>
                              ) : null}
                            </td>
                            {this.state.showTotalOrcamento ? (
                              <td style={{ whiteSpace: 'nowrap' }}>
                                R$:{' '}
                                {item.qtd > 0
                                  ? this.formatReal(item.precoUnitarioDoItem)
                                  : 0}
                              </td>
                            ) : null}
                            {this.state.showTotalOrcamento ? (
                              <td style={{ whiteSpace: 'nowrap' }}>
                                R$:{' '}
                                {item.qtd > 0
                                  ? this.formatReal(item.precoTotalDoItem)
                                  : 0}
                              </td>
                            ) : null}
                            <td style={{ whiteSpace: 'nowrap' }}>
                              <i
                                title='ocultar da proposta'
                                className={`far ${
                                  item.visivelProposta
                                    ? 'fa-eye'
                                    : 'fa-eye-slash'
                                }`}
                                onClick={() => {
                                  this.setState(
                                    { itemTabelaSelecionado: item },
                                    () => {
                                      this.ocultarDaProposta();
                                    }
                                  );
                                }}
                              ></i>{' '}
                              <i
                                title='excluir item'
                                className='fa fa-trash'
                                style={{ color: '#dc3545' }}
                                onClick={() => {
                                  this.setState({
                                    showModalDeleteItem: true,
                                    itemTabelaSelecionado: item,
                                  });
                                }}
                              ></i>{' '}
                              <i
                                onClick={() => {
                                  this.setState({
                                    showModalConfirmarBrinde: true,
                                    itemTabelaSelecionado: item,
                                  });
                                }}
                                className='fa fa-gift'
                              ></i>
                            </td>
                          </tr>
                        );
                      }
                    })
                  : null}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    );
  }

  _renderAcabamentos() {
    return (
      <Card
        style={{
          marginTop: 30,
          boxShadow:
            '0.5rem 0.5rem 2rem 0 rgb(8 15 41 / 8%), 0 0 1px 0 rgb(8 15 41 / 8%)',
          borderRadius: '.375rem',
        }}
        hidden={!this.state.itemTabelaSelecionado}
      >
        <CardHeader
          style={{
            borderBottom: 'none',
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: '.375rem',
          }}
        >
          ACABAMENTOS/ACRÉSCIMOS
          <i
            title='adicionar novo acabamento'
            onClick={this.buscarApenasAcabamentos.bind(this)}
            className='float-right fa fa-plus'
            style={{ cursor: 'pointer' }}
          ></i>
        </CardHeader>
        <CardBody>
          {this.state.itemTabelaSelecionado &&
          this.state.itemTabelaSelecionado.acabamentos.length === 0 ? (
            <div>O item não possui nenhum acabamento</div>
          ) : (
            <div className='table-responsive'>
              <table className='table table-striped table-sm'>
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>V/ Unitário</th>
                    <th hidden={this.state.usuarioLogado.Funcao === 'VENDEDOR'}>
                      V/ Total
                    </th>
                    <th>Qtd</th>
                    {/* <th>Qtd Brinde</th> */}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.itemTabelaSelecionado &&
                  this.state.itemTabelaSelecionado.acabamentos
                    ? this.state.itemTabelaSelecionado.acabamentos.map(
                        (a, i) => (
                          <tr key={i} style={{ cursor: 'initial' }}>
                            <td>{a.DescProoduto}</td>
                            <td>
                              R$:{' '}
                              {this.formatReal(
                                a.precoTotal / a.qtd / this.somarMargens()
                              )}
                            </td>
                            <td
                              hidden={
                                this.state.usuarioLogado.Funcao === 'VENDEDOR'
                              }
                            >
                              R$:{' '}
                              {this.formatReal(
                                a.precoTotal / this.somarMargens()
                              )}
                            </td>
                            <td>
                              {a.qtd}
                              {a.DescProoduto === 'Alpha-Play' ? (
                                <i
                                  className='fas fa-pencil-alt fa-sm'
                                  onClick={() => {
                                    this.setState(
                                      {
                                        showModalMudarAcabamento: true,
                                        itemAcabamentoSelecionado: a,
                                        inputMudarAcabamento: a.qtd,
                                      },
                                      () => {
                                        document
                                          .getElementById('inputQtdAcabamento')
                                          .focus();
                                      }
                                    );
                                  }}
                                ></i>
                              ) : null}
                            </td>
                            {/* <td hidden={this.state.usuarioLogado.Funcao === "VENDEDOR"}>{a.qtdBrinde} <i  onClick={()=>{alert("Em breve")}} className="fas fa-pencil-alt fa-sm"></i></td> */}
                            <td>
                              <i
                                style={{
                                  color: 'rgb(220, 53, 69)',
                                  cursor: 'pointer',
                                }}
                                className='fa fa-trash'
                                onClick={() =>
                                  this.setState({
                                    showModalExcluirAcab: true,
                                    acabamentoParaExcluir: a,
                                  })
                                }
                              ></i>
                            </td>
                          </tr>
                        )
                      )
                    : null}
                </tbody>
              </table>
            </div>
          )}
        </CardBody>
      </Card>
    );
  }

  _renderObservacao() {
    return (
      <Card
        style={{
          marginTop: 30,
          boxShadow:
            '0.5rem 0.5rem 2rem 0 rgb(8 15 41 / 8%), 0 0 1px 0 rgb(8 15 41 / 8%)',
          borderRadius: '.375rem',
        }}
      >
        <CardHeader
          style={{
            paddingBottom: 0,
            borderBottom: 'none',
            backgroundColor: 'white',
            borderRadius: '.375rem',
          }}
        >
          OBSERVAÇÕES
        </CardHeader>
        <CardBody>
          <textarea
            className=''
            size='lg'
            style={{ textAlign: 'left', width: '100%', height: 120 }}
            name='observacao'
            value={this.state.orcamento.observacao}
            onChange={this.changeInputOrcamento.bind(this)}
            onKeyDown={(e) => {
              if (e.keyCode == 9 || e.which == 9) {
                e.preventDefault();
                let orcamento = this.state.orcamento;
                orcamento.observacao = orcamento.observacao += '\t';
                this.setState({ orcamento });
              }
            }}
          ></textarea>
        </CardBody>
      </Card>
    );
  }

  _renderOpcoes() {
    return (
      <Card
        style={{
          marginTop: 30,
          boxShadow:
            '0.5rem 0.5rem 2rem 0 rgb(8 15 41 / 8%), 0 0 1px 0 rgb(8 15 41 / 8%)',
          borderRadius: '.375rem',
        }}
      >
        <CardHeader
          style={{
            borderBottom: 'none',
            backgroundColor: 'white',
            borderRadius: '.375rem',
          }}
        >
          OPÇÕES
          <i
            onClick={() =>
              this.setState({ showCardOpcoes: !this.state.showCardOpcoes })
            }
            style={{ float: 'right', marginTop: 5, cursor: 'pointer' }}
            className={`fas fa-angle-double-${
              this.state.showCardOpcoes ? 'up' : 'down'
            } fa-lg`}
          ></i>
        </CardHeader>
        <Collapse isOpen={this.state.showCardOpcoes}>
          <CardBody style={{ paddingTop: 0 }}>
            <div
              hidden={this.state.usuarioLogado.Funcao === 'VENDEDOR'}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.setState(
                  {
                    possuiFrete: !this.state.possuiFrete,
                    showBotoesAcao: false,
                  },
                  () => {
                    this.atualizarValoresItem();
                  }
                );
              }}
            >
              {this.state.possuiFrete ? (
                <i
                  className='fas fa-check'
                  style={{ color: '#28a745', marginRight: 5 }}
                ></i>
              ) : (
                <i
                  className='fas fa-times'
                  style={{ color: '#dc3545', marginRight: 5 }}
                ></i>
              )}
              Frete terrestre Alpha
            </div>
            <div
              hidden={this.state.usuarioLogado.Funcao === 'VENDEDOR'}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.setState(
                  { possuiArte: !this.state.possuiArte, showBotoesAcao: false },
                  () => {
                    this.atualizarValoresItem();
                  }
                );
              }}
            >
              {this.state.possuiArte ? (
                <i
                  className='fas fa-check'
                  style={{ color: '#28a745', marginRight: 5 }}
                ></i>
              ) : (
                <i
                  className='fas fa-times'
                  style={{ color: '#dc3545', marginRight: 5 }}
                ></i>
              )}
              Preco da Arte
            </div>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.setState(
                  {
                    possuiPagamentoIndividual: !this.state
                      .possuiPagamentoIndividual,
                    showModalBoleto: !this.state.possuiPagamentoIndividual,
                    showBotoesAcao: false,
                  },
                  () => {
                    this.atualizarValoresItem();
                  }
                );
              }}
            >
              {this.state.possuiPagamentoIndividual ? (
                <i
                  className='fas fa-check'
                  style={{ color: '#28a745', marginRight: 5 }}
                ></i>
              ) : (
                <i
                  className='fas fa-times'
                  style={{ color: '#dc3545', marginRight: 5 }}
                ></i>
              )}
              Pagamento Individual
            </div>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.setState({
                  propostaSemValor: !this.state.propostaSemValor,
                  showBotoesAcao: false,
                });
              }}
            >
              {this.state.propostaSemValor ? (
                <i
                  className='fas fa-check'
                  style={{ color: '#28a745', marginRight: 5 }}
                ></i>
              ) : (
                <i
                  className='fas fa-times'
                  style={{ color: '#dc3545', marginRight: 5 }}
                ></i>
              )}
              Proposta Sem Valor
            </div>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.setState({
                  possuiConviteOnline: !this.state.possuiConviteOnline,
                  showBotoesAcao: false,
                });
              }}
            >
              {this.state.possuiConviteOnline ? (
                <i
                  className='fas fa-check'
                  style={{ color: '#28a745', marginRight: 5 }}
                ></i>
              ) : (
                <i
                  className='fas fa-times'
                  style={{ color: '#dc3545', marginRight: 5 }}
                ></i>
              )}
              Convite Online
            </div>
          </CardBody>
        </Collapse>
      </Card>
    );
  }

  _renderMargens() {
    return (
      <div style={{ marginTop: 25 }}>
        MARGENS:
        <FormGroup row>
          <Label for='cv' sm={4}>
            CV
          </Label>
          <Col sm={8}>
            <InputGroup
              size='sm'
              hidden={!this.state.usuarioLogado.liberarMargens}
            >
              <Input
                disabled={!this.state.usuarioLogado.liberarMargens}
                type='number'
                max='9999'
                onKeyDown={(e) => {
                  if (e.keyCode === 190 || e.keyCode === 194) {
                    this.setState({ colocarPonto: true });
                  }
                }}
                value={this.state.orcamento.margens.cv}
                name='cv'
                onChange={this.changeInputMargem.bind(this)}
              />
              <InputGroupAddon addonType='append'>%</InputGroupAddon>
            </InputGroup>
            <label hidden={this.state.usuarioLogado.liberarMargens}>
              R${' '}
              {this.formatReal(
                ((this.calcularPrecoTotal() / this.somarMargens()) *
                  this.state.orcamento.margens.cv) /
                  100
              )}
            </label>
          </Col>
        </FormGroup>
        <FormGroup row hidden={this.state.usuarioLogado.Funcao === 'VENDEDOR'}>
          <Label for='ce' sm={4}>
            CE
          </Label>
          <Col sm={8}>
            <InputGroup size='sm'>
              <Input
                type='number'
                max='9999'
                onKeyDown={(e) => {
                  if (e.keyCode === 190 || e.keyCode === 194) {
                    this.setState({ colocarPonto: true });
                  }
                }}
                value={this.state.orcamento.margens.ce}
                name='ce'
                onChange={this.changeInputMargem.bind(this)}
              />
              <InputGroupAddon addonType='append'>%</InputGroupAddon>
            </InputGroup>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='ce' sm={4}>
            CA
          </Label>
          <Col sm={8}>
            <InputGroup size='sm'>
              <Input
                type='number'
                max='9999'
                onKeyDown={(e) => {
                  if (e.keyCode === 190 || e.keyCode === 194) {
                    this.setState({ colocarPonto: true });
                  }
                }}
                value={this.state.orcamento.margens.ca}
                name='ca'
                onChange={this.changeInputMargem.bind(this)}
              />
              <InputGroupAddon addonType='append'>%</InputGroupAddon>
            </InputGroup>
          </Col>
        </FormGroup>
        <FormGroup row hidden={this.state.usuarioLogado.Funcao === 'VENDEDOR'}>
          <Label for='ce' sm={4}>
            Aut. Ven
          </Label>
          <Col sm={8}>
            <InputGroup size='sm'>
              <Input
                type='number'
                max='9999'
                onKeyDown={(e) => {
                  if (e.keyCode === 190 || e.keyCode === 194) {
                    this.setState({ colocarPonto: true });
                  }
                }}
                value={this.state.orcamento.margens.autVen}
                name='autVen'
                onChange={this.changeInputMargem.bind(this)}
              />
              <InputGroupAddon addonType='append'>%</InputGroupAddon>
            </InputGroup>
          </Col>
        </FormGroup>
        <FormGroup row hidden={this.state.usuarioLogado.Funcao === 'VENDEDOR'}>
          <Label for='ce' sm={4}>
            Aut. Ger
          </Label>
          <Col sm={8}>
            <InputGroup size='sm'>
              <Input
                type='number'
                max='9999'
                onKeyDown={(e) => {
                  if (e.keyCode === 190 || e.keyCode === 194) {
                    this.setState({ colocarPonto: true });
                  }
                }}
                value={this.state.orcamento.margens.autGer}
                name='autGer'
                onChange={this.changeInputMargem.bind(this)}
              />
              <InputGroupAddon addonType='append'>%</InputGroupAddon>
            </InputGroup>
          </Col>
        </FormGroup>
        <FormGroup row hidden={this.state.usuarioLogado.Funcao === 'VENDEDOR'}>
          <Label for='ce' sm={4}>
            CI
          </Label>
          <Col sm={8}>
            <InputGroup size='sm'>
              <Input
                type='number'
                max='9999'
                onKeyDown={(e) => {
                  if (e.keyCode === 190 || e.keyCode === 194) {
                    this.setState({ colocarPonto: true });
                  }
                }}
                value={this.state.orcamento.margens.ci}
                name='ci'
                onChange={this.changeInputMargem.bind(this)}
              />
              <InputGroupAddon addonType='append'>%</InputGroupAddon>
            </InputGroup>
          </Col>
        </FormGroup>
        <FormGroup row hidden={this.state.usuarioLogado.Funcao === 'VENDEDOR'}>
          <Label for='ce' sm={4}>
            MU
          </Label>
          <Col sm={8}>
            <InputGroup size='sm'>
              <Input
                type='number'
                max='9999'
                onKeyDown={(e) => {
                  if (e.keyCode === 190 || e.keyCode === 194) {
                    this.setState({ colocarPonto: true });
                  }
                }}
                value={this.state.orcamento.margens.mu}
                name='mu'
                onChange={this.changeInputMargem.bind(this)}
              />
              <InputGroupAddon addonType='append'>%</InputGroupAddon>
            </InputGroup>
          </Col>
        </FormGroup>
        <FormGroup row hidden={this.state.usuarioLogado.Funcao === 'VENDEDOR'}>
          <Label for='ce' sm={4}>
            ISV
          </Label>
          <Col sm={8}>
            <InputGroup size='sm'>
              <Input
                type='number'
                max='9999'
                onKeyDown={(e) => {
                  if (e.keyCode === 190 || e.keyCode === 194) {
                    this.setState({ colocarPonto: true });
                  }
                }}
                value={this.state.orcamento.margens.isv}
                name='isv'
                onChange={this.changeInputMargem.bind(this)}
              />
              <InputGroupAddon addonType='append'>%</InputGroupAddon>
            </InputGroup>
          </Col>
        </FormGroup>
        {/* <FormGroup row hidden={this.state.usuarioLogado.Nivel !== "SENIOR"}>
          <Button color="info" onClick={()=>{this.setState({showModalSalvarMargens:true})}}>Salvar Como Padrão</Button>
        </FormGroup> */}
      </div>
    );
  }

  _renderVoucher() {
    return (
      <Card
        className='alert-warning'
        style={{
          marginTop: 30,
          boxShadow:
            '0.5rem 0.5rem 2rem 0 rgb(8 15 41 / 8%), 0 0 1px 0 rgb(8 15 41 / 8%)',
          borderRadius: '.375rem',
        }}
      >
        <CardHeader style={{ borderBottom: 'none', borderRadius: '.375rem' }}>
          CAMPANHA EM ANDAMENTO:
          <i
            onClick={() =>
              this.setState({ showModalVoucher: !this.state.showModalVoucher })
            }
            style={{ float: 'right', marginTop: 5, cursor: 'pointer' }}
            className={`fas fa-angle-double-${
              this.state.showModalVoucher ? 'up' : 'down'
            } fa-lg`}
          ></i>
        </CardHeader>
        <Collapse isOpen={this.state.showModalVoucher}>
          <CardBody style={{ padding: 15, position: 'relative' }}>
            <FormGroup row style={{ margin: 2 }}>
              <p>
                Campanha:{' '}
                <strong
                  style={{
                    textTransform: 'uppercase',
                    textDecoration: 'underline',
                  }}
                >
                  {this.state.nomeCampanha}
                </strong>
              </p>
              <p>
                Campanha ativa até:{' '}
                <strong>
                  {new Date(this.state.fimCampanha).toLocaleDateString('pt-BR')}
                  , às{' '}
                  {new Date(this.state.fimCampanha).toLocaleTimeString('pt-BR')}
                </strong>
              </p>
              <p>
                Desconto de: <strong>5%</strong>
              </p>
            </FormGroup>
            <img src='imgs/presente.png' id='imgPresente' />
            <hr />
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.setState(
                  { possuiVoucher: !this.state.possuiVoucher },
                  () => {
                    this.recalcularPrecoByVoucher();
                    this.atualizarValoresItem();
                  }
                );
              }}
            >
              <small>Clique para ativar/desativar o desconto</small>
              <br />
              {this.state.possuiVoucher ? (
                <i
                  className='fas fa-check'
                  style={{ color: '#28a745', marginRight: 5 }}
                ></i>
              ) : (
                <i
                  className='fas fa-times'
                  style={{ color: '#dc3545', marginRight: 5 }}
                ></i>
              )}
              Desconto{' '}
              <strong>{this.state.possuiVoucher ? 'ativo' : 'inativo'}</strong>{' '}
              neste orçamento!
            </div>
          </CardBody>
        </Collapse>
      </Card>
    );
  }

  _renderTotalOrcamento() {
    return (
      <Card
        style={{
          marginTop: 30,
          boxShadow:
            '0.5rem 0.5rem 2rem 0 rgb(8 15 41 / 8%), 0 0 1px 0 rgb(8 15 41 / 8%)',
          borderRadius: '.375rem',
          marginTop: 30,
        }}
      >
        <CardHeader
          style={{
            borderBottom: 'none',
            backgroundColor: 'white',
            borderRadius: '.375rem',
          }}
        >
          TOTAL
          <i
            onClick={() =>
              this.setState({
                showTotalOrcamento: !this.state.showTotalOrcamento,
                showFormMargens: !this.state.showTotalOrcamento,
              })
            }
            style={{ float: 'right', marginTop: 5, cursor: 'pointer' }}
            className={`fas fa-angle-double-${
              this.state.showTotalOrcamento ? 'up' : 'down'
            } fa-lg`}
          ></i>
        </CardHeader>
        <CardBody style={{ padding: 0 }}>
          <Collapse
            isOpen={this.state.showTotalOrcamento}
            style={{ padding: 15 }}
          >
            {this.state.usuarioLogado.Funcao === 'ORCAMENTISTA' ? (
              !this.state.alterarPrecoOrcamento ? (
                <InputGroup
                  size='lg'
                  hidden={!this.state.usuarioLogado.liberarMargens}
                >
                  <InputGroupAddon addonType='prepend'>
                    <Button
                      color='secondary'
                      onClick={() => {
                        this.setState({
                          alterarPrecoOrcamento: true,
                          precoOrcamento: this.state.orcamento
                            .precoTotalComMargens,
                        });
                      }}
                    >
                      <i className='fas fa-pen-square'></i>
                    </Button>
                  </InputGroupAddon>
                  <Input
                    type='text'
                    value={this.formatReal(
                      this.state.orcamento.precoTotalComMargens
                    )}
                    onChange={(e) => {
                      this.setState({ precoOrcamento: e.target.value });
                    }}
                  />
                </InputGroup>
              ) : (
                <InputGroup
                  size='lg'
                  hidden={!this.state.usuarioLogado.liberarMargens}
                >
                  <Input
                    type='text'
                    value={this.state.precoOrcamento}
                    onChange={(e) => {
                      this.setState({ precoOrcamento: e.target.value });
                    }}
                  />
                  <InputGroupAddon addonType='append'>
                    <Button
                      color='danger'
                      onClick={this.alterarValorMU.bind(this)}
                    >
                      <i className='fas fa-redo-alt'></i>
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              )
            ) : (
              <strong style={{ fontSize: 35 }}>
                {' '}
                R$: {this.formatReal(
                  this.state.orcamento.precoTotalComMargens
                )}{' '}
              </strong>
            )}
            {!this.state.usuarioLogado.liberarMargens ? (
              <div>
                <label>PREÇO DE BRIGA:</label>
                <br />
                <ButtonGroup>
                  <Button
                    outline
                    onClick={() => this.setPrecoDeBriga('minimo')}
                    active={this.state.precoDeBriga === 'minimo'}
                  >
                    Máximo
                  </Button>
                  <Button
                    outline
                    onClick={() => this.setPrecoDeBriga('medio')}
                    active={this.state.precoDeBriga === 'medio'}
                  >
                    Médio
                  </Button>
                  <Button
                    outline
                    onClick={() => this.setPrecoDeBriga('maximo')}
                    active={this.state.precoDeBriga === 'maximo'}
                  >
                    Mínimo
                  </Button>
                </ButtonGroup>
              </div>
            ) : null}
            {this._renderMargens()}
          </Collapse>
        </CardBody>
      </Card>
    );
  }

  _renderAcoesOrcamento2() {
    return (
      <div>
        {this.state.orcamento.status === 'Negociacao' ||
        this.state.orcamento.status === 'Aguardando' ? (
          <button
            className='btn btn-info btn-block'
            style={{ marginTop: 30 }}
            disabled={this.state.inconsistencias}
            onClick={() => this.setState({ showModalSalvar: true })}
          >
            <i className='far fa-save'></i> Salvar
          </button>
        ) : (
          <div className='alert alert-success' style={{ marginTop: 30 }}>
            Orcamento fechado! Duplique ou crie um novo caso queira editar.
          </div>
        )}
        {this.state.orcamento.status === 'Fechado' &&
        this.state.usuarioLogado.Funcao === 'ORCAMENTISTA' ? (
          <button
            className='btn btn-danger btn-block'
            style={{ marginTop: 30 }}
            onClick={() => this.setState({ showModalAbrirOrcamento: true })}
          >
            <i className='fa fa-file-import'></i> Reabrir orçamento
          </button>
        ) : null}
        {this.state.orcamento.status !== 'Fechado' ? (
          <button
            hidden={this.state.usuarioLogado.Funcao === 'VENDEDOR'}
            className='btn btn-info btn-block float-right'
            style={{ marginTop: 30 }}
            onClick={this.gotoKits.bind(this)}
            disabled={!this.state.showBotoesAcao}
          >
            <i className='fas fa-boxes'></i> Kits
          </button>
        ) : (
          <button
            hidden={this.state.usuarioLogado.Funcao === 'VENDEDOR'}
            disabled
            className='btn btn-info btn-block float-right'
            style={{ marginTop: 30 }}
          >
            <i className='fas fa-boxes'></i> Kits
          </button>
        )}
        {this.state.orcamento.status !== 'Fechado' ? (
          <button
            className='btn btn-info btn-block float-right'
            style={{ marginTop: 30 }}
            onClick={() => this.downloadProposta(this)}
            disabled={!this.state.showBotoesAcao}
          >
            <i className='fas fa-file-pdf'></i> Visualizar proposta (PDF)
          </button>
        ) : (
          <button
            className='btn btn-info btn-block float-right'
            disabled
            style={{ marginTop: 30 }}
          >
            <i className='fas fa-file-pdf'></i> Visualizar proposta (PDF)
          </button>
        )}
        <button
          className='btn btn-info btn-block float-right'
          style={{ marginTop: 30 }}
          onClick={() => this.setState({ showModalDuplicarOrc: true })}
          disabled={!this.state.showBotoesAcao}
        >
          <i className='far fa-copy'></i> Duplicar
        </button>
        {
          // this.state.orcamento.status !== "Fechado" ?
          //   <button className="btn btn-info btn-block float-right" style={{marginTop: 30}} onClick={this.gotoContrato.bind(this)} disabled={!this.state.showBotoesAcao}>
          //     <i className="far fa-file-alt"></i> Contrato
          //   </button>
          // :
          //   <button className="btn btn-info btn-block float-right" disabled style={{marginTop: 30}}>
          //     <i className="far fa-file-alt"></i> Contrato
          //   </button>
        }
        {this.state.orcamento.status === 'Negociacao' ||
        this.state.orcamento.status === 'Aguardando' ? (
          <button
            className='btn btn-info btn-block float-right'
            disabled={this.state.inconsistencias}
            style={{ marginTop: 30 }}
            onClick={() => this.setState({ showModalNomeArquivo: true })}
          >
            <i className='fas fa-download'></i> Arquivo .orc
          </button>
        ) : null}
        {this.state.orcamento.status === 'Negociacao' ||
        this.state.orcamento.status === 'Aguardando' ? (
          <Input
            className='btn btn-info btn-block float-right'
            accept='.orc'
            onChange={this.uploadOrcamento.bind(this)}
            type='file'
            placeholder='upload orçamento'
            disabled={this.state.inconsistencias}
            style={{ marginTop: 30 }}
          />
        ) : null}
      </div>
    );
  }

  _renderDetalhesNosCalculos() {
    return (
      <div className='table-responsive' style={{ marginTop: 30 }}>
        <table className='table table-bordered'>
          <thead>
            <tr>
              <th>Qtd miolo</th>
              <th>Preço arte</th>
              <th>Preço frete</th>
              <th>Valor margens</th>
              <th>Valor brindes</th>
              <th>Preço boleto</th>
              <th>Preço cartão</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{this.state.orcamento.quantidadeMiolo}</td>
              <td>
                {this.state.orcamento.precoDaArteT === null
                  ? 0
                  : this.state.orcamento.precoDaArteT.toFixed(2)}
              </td>
              <td>
                {this.state.orcamento.precoDoFreteT === null
                  ? 0
                  : this.state.orcamento.precoDoFreteT.toFixed(2)}
              </td>
              <td>{this.somarMargens()}</td>
              <td>
                {this.state.orcamento.valorTotalBrindes === null
                  ? 0
                  : this.state.orcamento.valorTotalBrindes.toFixed(2)}
              </td>
              <td>{this.state.orcamento.valorBoleto}</td>
              <td>{this.state.orcamento.valorCartao}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  _renderAlertaOrcamentoFechado() {
    return (
      <div
        className='alert alert-success'
        style={{
          marginTop: 30,
          marginBottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          boxShadow:
            '0.5rem 0.5rem 2rem 0 rgb(8 15 41 / 8%), 0 0 1px 0 rgb(8 15 41 / 8%)',
          borderRadius: '.375rem',
        }}
      >
        <div>
          <i className='fas fa-lock' /> Orçamento fechado
        </div>
        {this.state.usuarioLogado.Funcao === 'ORCAMENTISTA' ? (
          <button
            className='btn btn-danger'
            onClick={() => this.setState({ showModalAbrirOrcamento: true })}
          >
            <i className='fas fa-undo-alt'></i> Reabrir
          </button>
        ) : null}
      </div>
    );
  }

  render() {
    return (
      <div
        style={{
          backgroundColor: '#f5f4f9',
          paddingBottom: 30,
          position: 'relative',
          minHeight: '100vh',
        }}
      >
        {this.state.reabrindo ? (
          <div
            style={{
              position: 'absolute',
              zIndex: 9999,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0,0,0,.4)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img src='svgs/loading.svg' style={{ height: 80 }} />
          </div>
        ) : null}
        <NavegationBlocker navigationBlocked={!this.state.orcamento.idOrc} />
        <ToastContainer
          position='bottom-left'
          autoClose={5000}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange={false}
          draggable={false}
        />
        <NavBar
          className='no-print'
          nome={this.state.usuarioLogado.Nome}
          funcao={this.state.usuarioLogado.Funcao}
        />
        <div className='container-fluid'>
          {this.state.orcamento.status === 'Fechado'
            ? this._renderAlertaOrcamentoFechado()
            : null}
          <Row>
            <Col sm='6' xs='12' md='8' lg='8'>
              {this._renderCliente()}
            </Col>
            <Col sm='6' xs='12' md='4' lg='4'>
              {this._renderOrcamentoStatus()}
            </Col>
          </Row>
          <Row style={{ marginTop: 30 }}>
            <Col sm='12'>{this._renderTableItens()}</Col>
          </Row>
          <Row>
            <Col sm='12'>{this.orcamentoInfos()}</Col>
          </Row>
          <Row>
            <Col sm='12' md='4' style={{ paddingTop: 0 }}>
              {/* {this._renderItensAgrupados()} */}
              {this._renderAcabamentos()}
            </Col>
            <Col sm='12' md='3' style={{ paddingTop: 0 }}>
              {this._renderOpcoes()}
              {this._renderTotalOrcamento()}
              {new Date(this.state.fimCampanha).getTime() >
                new Date().getTime() || this.state.possuiVoucher
                ? this._renderVoucher()
                : null}
            </Col>
            <Col sm='12' md='5' style={{ paddingTop: 0 }}>
              {this._renderObservacao()}
            </Col>
          </Row>
          <FloatingMenuOrcamento
            salvarOrcamento={() => this.setState({ showModalSalvar: true })}
            abrirKits={this.gotoKits.bind(this)}
            gerarProposta={() => this.downloadProposta(this)}
            duplicarOrcamento={() =>
              this.setState({ showModalDuplicarOrc: true })
            }
            baixarOrcamento={() =>
              this.setState({ showModalNomeArquivo: true })
            }
            ehOrcamentista={this.state.usuarioLogado.Funcao === 'ORCAMENTISTA'}
            statusOrcamento={this.state.orcamento.status}
            botoesAcao={this.state.showBotoesAcao}
          />
          {this.state.usuarioLogado.Funcao !== 'VENDEDOR' ? (
            <Row>
              <Col sm='12' md='8'>
                {this._renderDetalhesNosCalculos()}
              </Col>
            </Row>
          ) : null}
        </div>
        <Draggable handle='.modal-header'>
          <Modal
            className='no-print'
            isOpen={this.state.showModalAddItem}
            size='lg'
            id='inserir_item'
          >
            <ModalAdicionarItem
              itemFuturo={0}
              orcamento={this.state.orcamento}
              criarLog={(data) => this.criarLog('ERROR', data.erro, data.msg)}
              close={(orcamento) => this.rotinaPosItemAdicionado(orcamento)}
              possuiArte={this.state.possuiArte}
              possuiFrete={this.state.possuiFrete}
              possuiPagamentoIndividual={this.state.possuiPagamentoIndividual}
              listaPrecosArte={this.state.listaPrecosArte}
              listaPrecosFrete={this.state.listaPrecosFrete}
            />
          </Modal>
        </Draggable>
        <Modal
          className='no-print'
          isOpen={this.state.showModalDeleteItem}
          size='sm'
          id='confirmar_delete'
        >
          <ModalHeader
            toggle={() => this.setState({ showModalDeleteItem: false })}
          >
            Confirme sua ação
          </ModalHeader>
          <ModalBody>Você tem certeza que quer excluir este item?</ModalBody>
          <ModalFooter>
            <Button color='success' onClick={this.retirarItemTabela.bind(this)}>
              <i className='fas fa-check-circle'></i> Confirmar
            </Button>
            <Button
              color='danger'
              onClick={() => this.setState({ showModalDeleteItem: false })}
            >
              <i className='fas fa-times-circle'></i> Cancelar
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          className='no-print'
          isOpen={this.state.showModalRecalcular}
          size='md'
        >
          <ModalHeader>Orçamento desatualizado</ModalHeader>
          <ModalBody>
            Foi identificado que este orçamento está com os valores
            desatualizados, e por essa razão ele precisa ser recalculado.
          </ModalBody>
          <ModalFooter>
            {this.state.usuarioLogado.Funcao === 'ORCAMENTISTA' ? (
              <Button
                color='link'
                onClick={() => {
                  this.setState({ showModalRecalcular: false });
                }}
              >
                Ignorar
              </Button>
            ) : null}
            <Button
              color='danger'
              onClick={this.recalcularOrcamento.bind(this)}
            >
              {this.state.recalcular ? (
                <span>
                  <i className='fa fa-spinner fa-pulse fa-fw'></i>Recalculando
                </span>
              ) : (
                <span>
                  <i className='fas fa-exclamation-circle'></i> Recalcular
                </span>
              )}
            </Button>
            <Button
              color='secondary'
              onClick={() => {
                this.props.history.push({ pathname: '/mapa' });
              }}
            >
              <i className='fas fa-times-circle'></i> Cancelar
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          className='no-print'
          isOpen={this.state.showModalMudarQtd}
          size='sm'
          id='mudar_qtd'
        >
          <ModalHeader
            toggle={() => this.setState({ showModalMudarQtd: false })}
          >
            Alteração de quantidade
          </ModalHeader>
          <ModalBody>
            <Label for='inputQtdItemTabela'>Nova quantidade</Label>
            <Input
              className='form-control'
              id='inputQtdItemTabela'
              onFocus={(event) => {
                event.target.select();
              }}
              value={this.state.inputMudarQtd}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  this.mudarQtdItemTabela(0);
                }
              }}
              onChange={(e) => this.setState({ inputMudarQtd: e.target.value })}
            />
          </ModalBody>
          <ModalFooter>
            {this.state.carregando ? (
              <i className='fas fa-spinner fa-spin'></i>
            ) : null}
            <Button
              disabled={this.state.carregando}
              color='success'
              onClick={this.mudarQtdItemTabela.bind(this, 0)}
            >
              <i className='fas fa-check-circle'></i> Confirmar
            </Button>
            <Button
              disabled={this.state.carregando}
              color='danger'
              onClick={() => this.setState({ showModalMudarQtd: false })}
            >
              {' '}
              <i className='fas fa-times-circle'></i> Cancelar
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          className='no-print'
          isOpen={this.state.showModalMudarAcabamento}
          size='sm'
          id='mudar_qtdAcabamento'
        >
          <ModalHeader
            toggle={() => this.setState({ showModalMudarAcabamento: false })}
          >
            Alteração de quantidade
          </ModalHeader>
          <ModalBody>
            <Label for='inputQtdAcabamento'>Nova quantidade</Label>
            <Input
              className='form-control'
              id='inputQtdAcabamento'
              onFocus={(event) => {
                event.target.select();
              }}
              value={this.state.inputMudarAcabamento}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  this.mudarQtdAcabamento();
                }
              }}
              onChange={(e) =>
                this.setState({ inputMudarAcabamento: e.target.value })
              }
            />
          </ModalBody>
          <ModalFooter>
            {this.state.carregando ? (
              <i className='fas fa-spinner fa-spin'></i>
            ) : null}
            <Button
              disabled={this.state.carregando}
              color='success'
              onClick={this.mudarQtdAcabamento.bind(this)}
            >
              <i className='fas fa-check-circle'></i> Confirmar
            </Button>
            <Button
              disabled={this.state.carregando}
              color='danger'
              onClick={() => this.setState({ showModalMudarAcabamento: false })}
            >
              {' '}
              <i className='fas fa-times-circle'></i> Cancelar
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          className='no-print'
          isOpen={this.state.showModalConfirmarBrinde}
          size='sm'
          id='mudar_qtd'
        >
          <ModalHeader
            toggle={() => this.setState({ showModalConfirmarBrinde: false })}
          >
            Confirme sua ação
          </ModalHeader>
          <ModalBody>
            Você tem certeza que quer de converter todos esses itens para
            brinde?
          </ModalBody>
          <ModalFooter>
            <Button
              type='button'
              color='success'
              onClick={this.passarTudoParaBrinde.bind(this)}
            >
              <i className='fas fa-check-circle'></i> Confirmar
            </Button>
            <Button
              color='danger'
              onClick={() => this.setState({ showModalConfirmarBrinde: false })}
            >
              {' '}
              <i className='fas fa-times-circle'></i> Cancelar
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          className='no-print'
          isOpen={this.state.showModalMudarBrinde}
          size='sm'
          id='mudar_brinde'
        >
          <ModalHeader
            toggle={() => this.setState({ showModalMudarBrinde: false })}
          >
            Alteração de quantidade
          </ModalHeader>
          <ModalBody>
            <Label for='inputQtdBrinde'>Nova quantidade</Label>
            <Input
              value={this.state.inputMudarBrinde}
              onFocus={(event) => {
                event.target.select();
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  this.mudarBrindeItemTabela();
                }
              }}
              id='inputQtdBrinde'
              onChange={(e) =>
                this.setState({ inputMudarBrinde: e.target.value })
              }
            />
          </ModalBody>
          <ModalFooter>
            {this.state.carregando ? (
              <i className='fas fa-spinner fa-spin'></i>
            ) : null}
            <Button
              disabled={this.state.carregando}
              color='success'
              onClick={this.mudarBrindeItemTabela.bind(this)}
            >
              <i className='fas fa-check-circle'></i> Confirmar
            </Button>
            <Button
              disabled={this.state.carregando}
              color='danger'
              onClick={() => this.setState({ showModalMudarBrinde: false })}
            >
              <i className='fas fa-times-circle'></i> Cancelar
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          className='no-print'
          isOpen={this.state.showModalAddAcabamento}
          size='lg'
          id='adicionar_acrecimo'
        >
          <ModalHeader
            toggle={() => this.setState({ showModalAddAcabamento: false })}
          >
            Adicionar acabamento
          </ModalHeader>
          <ModalBody>
            <div className='table-responsive'>
              <table className='table table-condensed'>
                <thead>
                  <tr>
                    <th>Selecionado</th>
                    <th>Acabamento/Acréscimo</th>
                  </tr>
                </thead>
                <tbody style={{ overflow: 'scroll', height: 200 }}>
                  {this.state.acabamentos.map((acabamento, i) => (
                    <tr
                      key={i}
                      style={
                        this.state.acabamentosSelecionados.includes(acabamento)
                          ? { backgroundColor: '#d5dcff', cursor: 'pointer' }
                          : { cursor: 'pointer' }
                      }
                      onClick={() => {
                        this.pushAcabamento(acabamento);
                      }}
                    >
                      <td style={{ textAlign: 'center' }}>
                        {!this.state.acabamentosSelecionados.includes(
                          acabamento
                        ) ? (
                          <i
                            className='far fa-circle fa-lg'
                            style={{ color: 'gray' }}
                          ></i>
                        ) : (
                          <i
                            className='far fa-check-circle fa-lg'
                            style={{ color: '#4c6ef5' }}
                          ></i>
                        )}
                      </td>
                      <td>{acabamento.DescProoduto}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </ModalBody>
          <ModalFooter>
            {this.state.carregando ? (
              <i className='fas fa-spinner fa-spin'></i>
            ) : null}
            <Button
              disabled={this.state.carregando}
              color='success'
              onClick={this.adicionarAcabamentoAoItem.bind(this)}
            >
              <i className='fas fa-check-circle'></i> Confirmar
            </Button>
            <Button
              disabled={this.state.carregando}
              color='danger'
              onClick={() => this.setState({ showModalAddAcabamento: false })}
            >
              <i className='fas fa-times-circle'></i> Cancelar
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          className='no-print'
          isOpen={this.state.showModalAddItemManual}
          size='sm'
          id='inserir_item_manual'
        >
          <ModalHeader
            toggle={() => this.setState({ showModalAddItemManual: false })}
          >
            Inserir Item Manualmente
          </ModalHeader>
          <ModalBody>
            <form name='frmEditQtdItem' className='ng-pristine ng-valid'>
              <div className='row'>
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                  <br />
                  <label>Item</label>
                  <input
                    type='text'
                    name='item'
                    value={this.state.itemManual.item}
                    onChange={this.changeInputItemManual.bind(this)}
                    className='form-control'
                  />

                  <label>Detalhes</label>
                  <input
                    type='text'
                    name='detalhes'
                    value={this.state.itemManual.detalhes}
                    onChange={this.changeInputItemManual.bind(this)}
                    className='form-control'
                  />

                  <label>Quantidade</label>
                  <input
                    type='number'
                    name='quantidade'
                    value={this.state.itemManual.quantidade}
                    onChange={this.changeInputItemManual.bind(this)}
                    className='form-control'
                  />

                  <label>Preço Unitário</label>
                  <input
                    type='text'
                    name='precoUnitario'
                    value={this.state.itemManual.precoUnitario}
                    onChange={this.changeInputItemManual.bind(this)}
                    className='form-control'
                  />
                </div>
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button color='success' onClick={this.addItemManual.bind(this)}>
              <i className='fas fa-check-circle'></i> Inserir
            </Button>
            <Button
              color='danger'
              onClick={() => this.setState({ showModalAddItemManual: false })}
            >
              <i className='fas fa-times-circle'></i> Cancelar
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          className='no-print'
          isOpen={this.state.showModalSalvar}
          size='sm'
          id='modal-salvar'
        >
          <ModalHeader toggle={() => this.setState({ showModalSalvar: false })}>
            Tem certeza que deseja salvar?
          </ModalHeader>
          <ModalFooter>
            {this.state.carregando ? (
              <i className='fas fa-spinner fa-spin'></i>
            ) : null}
            <Button
              disabled={this.state.carregando}
              color='success'
              onClick={
                this.state.usuarioLogado.Funcao === 'ORCAMENTISTA'
                  ? () => {
                      this.setState({ showModalFinalizar: true });
                    }
                  : this.salvarOrcamento.bind(this)
              }
            >
              <i className='fas fa-save'></i> Sim
            </Button>
            <Button
              disabled={this.state.carregando}
              color='danger'
              onClick={() => this.setState({ showModalSalvar: false })}
            >
              <i className='fas fa-times-circle'></i> Cancelar
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          className='no-print'
          isOpen={this.state.showModalFinalizar}
          size='sm'
          id='modal-finalizar'
        >
          <ModalHeader
            toggle={() => this.setState({ showModalFinalizar: false })}
          >
            Deseja tambem fechar esse cliente?
          </ModalHeader>
          <ModalFooter>
            {this.state.carregando ? (
              <i className='fas fa-spinner fa-spin'></i>
            ) : null}
            <Button
              disabled={this.state.carregando}
              color='success'
              onClick={() =>
                this.setState({ finalizarOnSave: true }, () => {
                  this.salvarOrcamento();
                })
              }
            >
              <i className='fas fa-save'></i> Sim
            </Button>
            <Button
              disabled={this.state.carregando}
              color='danger'
              onClick={() =>
                this.setState({ finalizarOnSave: false }, () => {
                  this.salvarOrcamento();
                })
              }
            >
              <i className='fas fa-times-circle'></i> Não
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          className='no-print'
          isOpen={this.state.showModalAbrirOrcamento}
          size='sm'
          id='modal-abrirOrcamento'
        >
          <ModalHeader
            toggle={() => this.setState({ showModalAbrirOrcamento: false })}
          >
            Você tem certeza que quer reabrir este orçamento?
          </ModalHeader>
          <ModalFooter>
            {this.state.carregando ? (
              <i className='fas fa-spinner fa-spin'></i>
            ) : null}
            <Button
              disabled={this.state.carregando}
              color='success'
              onClick={() =>
                this.setState({ reopenOrcamento: true }, () => {
                  this.alterarStatusOrcamento();
                })
              }
            >
              <i className='fas fa-check-circle'></i> Sim
            </Button>
            <Button
              disabled={this.state.carregando}
              color='danger'
              onClick={() =>
                this.setState({ reopenOrcamento: false }, () => {
                  this.alterarStatusOrcamento();
                })
              }
            >
              <i className='fas fa-times-circle'></i> Não
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          className='no-print'
          isOpen={this.state.showModalSalvarMargens}
          size='sm'
          id='modal-salvar'
        >
          <ModalHeader
            toggle={() => this.setState({ showModalSalvarMargens: false })}
          >
            Tem certeza que deseja salvar essas margens?
          </ModalHeader>
          <ModalFooter>
            <Button color='success' onClick={this.salvarMargens.bind(this)}>
              <i className='fas fa-check-circle'></i> Sim
            </Button>
            <Button
              color='danger'
              onClick={() => this.setState({ showModalSalvarMargens: false })}
            >
              <i className='fas fa-times-circle'></i> Cancelar
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          className='no-print'
          isOpen={this.state.showModalDuplicarOrc}
          size='sm'
          id='modal-duplicar'
        >
          <ModalHeader
            toggle={() => this.setState({ showModalDuplicarOrc: false })}
          >
            Tem certeza?
          </ModalHeader>
          <ModalFooter>
            <Button color='success' onClick={this.duplicarOrcamento.bind(this)}>
              <i className='fas fa-copy'></i> Sim
            </Button>
            <Button
              color='danger'
              onClick={() => this.setState({ showModalDuplicarOrc: false })}
            >
              <i className='fas fa-times-circle'></i> Cancelar
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          className='no-print'
          isOpen={this.state.showModalNomeArquivo}
          size='sm'
          id='modal-nomearquivo'
        >
          <ModalHeader
            toggle={() => this.setState({ showModalNomeArquivo: false })}
          >
            Salvar uma cópia do orçamento
          </ModalHeader>
          <ModalBody>
            <Label>Nome do arquivo:</Label>
            <Input
              value={this.state.nomeorc}
              onChange={(e) => {
                this.setState({ nomeorc: e.target.value });
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={!this.state.nomeorc}
              color='primary'
              onClick={this.salvarOffLine.bind(this)}
            >
              <i className='fas fa-download'></i> Download
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          className='no-print'
          isOpen={this.state.showModalExcluirAcab}
          size='sm'
          id='modal-excluir-acab'
        >
          <ModalHeader
            toggle={() => this.setState({ showModalExcluirAcab: false })}
          >
            Confirme sua ação
          </ModalHeader>
          <ModalBody>
            Você tem certeza que deseja excluir este acabamento?
          </ModalBody>
          <ModalFooter>
            <Button
              color='success'
              onClick={this.retirarAcabamentoItem.bind(this)}
            >
              <i className='fas fa-check-circle'></i> Confirmar
            </Button>
            <Button
              color='danger'
              onClick={() =>
                this.setState({
                  showModalExcluirAcab: false,
                  acabamentoParaExcluir: null,
                })
              }
            >
              <i className='fas fa-times-circle'></i> Cancelar
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          className='no-print'
          isOpen={this.state.showModalBoleto}
          id='modal-individual'
        >
          <ModalHeader toggle={() => this.setState({ showModalBoleto: false })}>
            Adição de forma de pagamento
          </ModalHeader>
          <ModalBody>
            Você selecionou a opção de pagamento por boleto. Preencha a
            quantidade de formandos e a de parcelas que os formandos poderão
            parcelar.
            <Row form style={{ marginTop: 10 }}>
              <Col md={6}>
                <FormGroup>
                  <Label for='qtd-formandos'>Formandos</Label>
                  <Input
                    type='number'
                    name='qtdFormandoBoleto'
                    id='qtd-formandos'
                    value={this.state.orcamento.qtdFormandoBoleto}
                    onChange={this.onChangeInputsPagamento.bind(this)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for='num-parcelas'>Parcelas</Label>
                  <Input
                    type='number'
                    name='numeroParcelas'
                    id='num-parcelas'
                    value={this.state.orcamento.numeroParcelas}
                    onChange={this.onChangeInputsPagamento.bind(this)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color='success'
              onClick={this.editarValorPagamentos.bind(this)}
            >
              {' '}
              <i className='fas fa-check-circle' /> Confirmar
            </Button>
            <Button
              color='danger'
              onClick={this.cancelarEdicaoPagamento.bind(this)}
            >
              {' '}
              <i className='fas fa-times-circle' /> Cancelar
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          className='no-print'
          isOpen={this.state.showModalSelectCliente}
          size='lg'
          id='modal-cliente'
        >
          <ModalHeader
            toggle={() => this.setState({ showModalSelectCliente: false })}
          >
            Deseja trocar o cliente?
          </ModalHeader>
          <ModalBody>
            <Table>
              <tbody>
                <tr style={{ width: '100%' }}>
                  <td style={{ width: '5%' }}>
                    ID:
                    <br />{' '}
                    <input
                      style={{ width: '100%' }}
                      onChange={this.handleIdClienteInput.bind(this)}
                      value={this.state.inputClienteId}
                    />
                  </td>
                  <td style={{ width: '25%' }}>
                    Descrição:
                    <br />{' '}
                    <input
                      style={{ width: '100%' }}
                      onChange={this.handleDescricaoClienteInput.bind(this)}
                      value={this.state.inputClienteNome}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
            <div
              className='table-responsive'
              style={{ maxHeight: 300, overflow: 'auto' }}
            >
              <Table className='table-bordered table-sm'>
                <tbody>
                  <tr style={{ width: '100%' }}>
                    <th
                      style={{
                        width: '5%',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {' '}
                      IdCliente{' '}
                    </th>
                    <th
                      style={{
                        width: '25%',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {' '}
                      Descrição{' '}
                    </th>
                    <th
                      style={{
                        width: '25%',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {' '}
                      Status{' '}
                    </th>
                    <th
                      style={{
                        width: '20%',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {' '}
                      Cidade{' '}
                    </th>
                    <th
                      style={{
                        width: '25%',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {' '}
                      Curso{' '}
                    </th>
                    <th
                      style={{
                        width: '25%',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {' '}
                      Instituição{' '}
                    </th>
                  </tr>
                  {this.state.listaClientes.map((c, i) => (
                    <tr
                      key={i}
                      style={
                        this.state.clienteSelecionado === c
                          ? {
                              cursor: 'pointer',
                              background: '#bbed21',
                              width: '100%',
                            }
                          : { cursor: 'pointer', width: '100%' }
                      }
                      onClick={
                        this.state.clienteSelecionado === c
                          ? () => this.setState({ clienteSelecionado: {} })
                          : () => this.setState({ clienteSelecionado: c })
                      }
                    >
                      <td
                        style={{
                          width: '5%',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {' '}
                        {c.idCliente} &nbsp;
                      </td>
                      <td
                        style={{
                          width: '25%',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {' '}
                        {c.Descricao} &nbsp;
                      </td>
                      <td
                        style={{
                          width: '25%',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {' '}
                        {c.Status} &nbsp;
                      </td>
                      <td
                        style={{
                          width: '20%',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {c.Cidade} &nbsp;
                      </td>
                      <td
                        style={{
                          width: '25%',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {c.curso} &nbsp;
                      </td>
                      <td
                        style={{
                          width: '25%',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {c.SiglaInst} &nbsp;
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color='success' onClick={this.trocarCliente.bind(this)}>
              <i className='fas fa-check-circle'></i> Confirmar
            </Button>
            <Button
              color='danger'
              onClick={() => this.setState({ showModalSelectCliente: false })}
            >
              <i className='fas fa-times-circle'></i> Não
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default Orcamento2;
function toPosition(altura) {
  let currentYOffset = document.getElementById('body-add-item').pageYOffset;
  let initYOffset = currentYOffset;

  var intervalId = setInterval(function() {
    currentYOffset += initYOffset * 0.05;
    document.getElementById('body-add-item').scrollTop = currentYOffset;
    if (document.getElementById('body-add-item').pageYOffset == altura) {
      clearInterval(intervalId);
    }
  }, 20);
}
function primeiraLetraMaiuscula(frase) {
  frase = frase.toLowerCase();
  frase = replaceAt(frase, 0, frase.charAt(0).toUpperCase());
  for (let i = 1; i < frase.length; i++) {
    if (frase.charAt(i - 1) === ' ') {
      frase = replaceAt(frase, i, frase.charAt(i).toUpperCase());
    }
  }
  return frase;
}
function replaceAt(string, index, replace) {
  return string.substring(0, index) + replace + string.substring(index + 1);
}
