import {toast} from 'react-toastify';
const INITIAL_STATE = {
  autenticado: null,
  especifico: {},
  erro: null,
  carregando:false
};

export default (state = INITIAL_STATE, action) => {
  switch(action.type){
    case 'REQUISITAR':
      return {...state, carregando: true};
    case 'AUTENTICOU':
      if(!action.usuario){
      toast.error('Usuario ou senha não encontrado!');
        return {...state, carregando: false, erro: "usuario não encontrado"};
      }else{
        return {...state, carregando: false, autenticado: action.usuario, erro: null};
      }
    case 'ERRO_USUARIO':
      toast.error('Usuario ou senha não encontrado!');
      return {...state, carregando: true, erro: action.erro};
    case 'ERRO_AUTENTICACAO':
      if(action.message === "Network Error"){
        toast.error('Serviço indisponivel, tente novamente mais tarde');
      } else {
        toast.error('Usuario ou senha não encontrado!');
      }
      return {...state, carregando: false, erro: action};
    case 'AUTENTICOU_COOKIE':
      return {...state, carregando: false, autenticado: action.usuario, erro: null};
    case 'LOGOUT':
      return {...state, autenticado: null, token: "", erro: null, carregando: false};
    default:
      return state;
  }
};
