import React from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
const NavigationBlocker = (props) => {
  if (props.navigationBlocked) {
    window.onbeforeunload = () => true
  } else {
    window.onbeforeunload = null
  }
  return (
    <Prompt
      when={props.navigationBlocked}
      message="Você tem certeza que quer sair do sistema? Você ainda possui ações não finalizadas!"
    />
  )
}
NavigationBlocker.propTypes = {
  navigationBlocked: PropTypes.bool.isRequired,
}
export default NavigationBlocker