/*
  ESSA PAGINA ESTA CABULOSA 
*/
import React from 'react';
import {Redirect} from 'react-router-dom';
import axios from 'axios';
import {REQUEST_URL} from '../config/urls.js';
import { ToastContainer, toast } from 'react-toastify';
import {Modal,ModalHeader,ModalFooter,ModalBody,Container,  Button,  Alert,  Badge,  Col,Row,  Card,  Table,Label,Input
} from 'reactstrap';
import  NavBar from '../components/NavBar';
import 'react-sliding-pane/dist/react-sliding-pane.css';

const motivosPerdido = [
  'Stand-By',
  'Contrato cancelado',
  'Sem Perfil',
  'Dados Incompletos',
  'Vinculado a empresa de eventos',
  'Contato Inviável',
  'Prazo de Produção',
  'Preço',
  'Qualidade do produto',
  'Falta de atendimento presencial',
  'Falta de contato do cliente',
  'Qualidade da empresa',
  'Turma não fará convites',
  'Restrição financeira',
  'Empresa fechou'
]

class Faculdades extends React.Component {
  constructor(props){
    super(props);
    document.title = "Mapa";
    this.state = {
      region:"",
      code:"",
      cidades_intituicao_curso:{},
      anos:[],
      mostrarFaculdade:{},
      mostrarCidade:{},
      loading:false,
      usuarioLogado:{},
      isPaneOpenLeft: false,
      buscandoCliente:false,
      clienteSelecionado:[],
      idsSelecionado:{},
      esconderCurso:false,
      keysSelecionadas:{},
      abrirOpcoes:false,
      busca:{},
      qtdInstsBusca:0,
      qtdCidadesBusca:0,
      qtdCursosBusca:0,
      mostrarTodasCidades:false,
      vendedores:[]
    };
  }
  componentWillMount() {
    axios.defaults.headers.common = {'Authorization': sessionStorage.getItem("token")};
  }
  fecharTurma(){
    let cliente = {
      nome:"Fechamento administrativo",
      sobrenome:"",
      codAgencia:0,
      vendedor:this.state.usuarioLogado.idUsuario,
      formatura:this.state.idsSelecionado.semestre,
      entrega:"null",
      contatos:[],
      status:"Fechado"
    }

    axios.post(REQUEST_URL+"/cadastrar_cliente",cliente).then((response)=>{
      toast.success('Turma fechada! estará visivel na proxima busca.');
      this.setState({isPaneOpenLeft:false});
    }).catch((error)=>{
      console.log(error);
      toast.error('Nao foi possivel fechar a turma!');
    });

  }
  marcarCancelada(){
    let cliente = {
      nome:"Fechamento administrativo",
      sobrenome:"",

      codEstado:this.state.idsSelecionado.siglaestado,
      codCidade:this.state.idsSelecionado.cod_cidade,
      codInst:this.state.idsSelecionado.cod_inst,
      codCurso:this.state.idsSelecionado.cod_curso,

      cidade:"null",
      instituicao:"null",
      curso:"null",

      codAgencia:0,
      vendedor:this.state.usuarioLogado.idUsuario,

      formatura:this.state.idsSelecionado.semestre,
      entrega:"null",
      contatos:[],
      status:"Cancelado"
    }

      axios.post(REQUEST_URL+"/cadastrar_cliente",cliente).then((response)=>{
        toast.success('Marcado como cancelado! estará visivel na proxima busca.');
        this.setState({isPaneOpenLeft:false});
      }).catch((error)=>{
        console.log(error);
        toast.error('Algo deu errado, Nao foi possivel fechar a turma!');
      });

  }
  async componentDidMount(){
    if(sessionStorage.getItem('usuarioLogado')){
      this.setState({usuarioLogado:JSON.parse(sessionStorage.getItem('usuarioLogado'))[0]},()=>{
        console.log(this.state.usuarioLogado);
      });
    }
    //console.log(this.props.location.state.cidades);
    let self = this;
    //console.log(this.props.location.state.vendedor);
    let post = {
      "cidades":this.props.location.state.cidades,
      "cidadesObj":this.props.location.state.cidadesObj,
      "instituicoes":this.props.location.state.instituicoes,
      "cursos":this.props.location.state.cursos,
      "estado":this.props.location.state.estado,
      "vendedor":this.props.location.state.vendedor,
      "vendedorNome":this.props.location.state.vendedorNome,
      "somenteCidadesVinculadas":this.props.location.state.somenteCidadesVinculadas,
      "somenteCursosFavoritados":this.props.location.state.somenteCursosFavoritados,
      "somenteCidadesSemVinculo":this.props.location.state.somenteCidadesSemVinculo
    }
    
    this.setState({busca:post});
    this.setState({loading:true});

    await axios.get(REQUEST_URL+"/vendedores").then((response)=>{     
      this.setState({vendedores: response.data})
    }).catch((erro)=>{    
      toast.error("Não foi possivel buscar os nomes dos representantes");
    });

    axios.post(REQUEST_URL+"/visitas",post).then((response)=>{
      let cidades_intituicao_curso = response.data;
      let mostrarFaculdade = {};
      let mostrarCidade = {};
      let qtdCidadesBusca = 0;
      let qtdInstsBusca = 0;
      let qtdCursosBusca = 0;
      let qtdCursosBuscaFavoritos = 0;
      Object.keys(cidades_intituicao_curso).forEach((cidade)=>{
        qtdCidadesBusca++;
        mostrarCidade[cidade] = false;
        Object.keys(cidades_intituicao_curso[cidade]).forEach((inst)=>{
          qtdInstsBusca++;
          mostrarFaculdade[inst] = false;
          Object.keys(cidades_intituicao_curso[cidade][inst]).forEach((curso)=>{
            if(curso !== '_quantidade'){
              qtdCursosBusca++;
            }
            if(curso !== '_quantidade' && cidades_intituicao_curso[cidade][inst][curso][0].favoritado)qtdCursosBuscaFavoritos++;
            if(curso !== '_quantidade' && cidades_intituicao_curso[cidade][inst][curso][0].Vendedor){
              let vendedor = this.getVendedor(cidades_intituicao_curso[cidade][inst][curso][0].Vendedor);
              cidades_intituicao_curso[cidade][inst][curso][0].NomeVendedor = vendedor ?vendedor.Login:'';
            }
          });
          return 1;
        });
        return 1;
      });
      self.setState({mostrarFaculdade, mostrarCidade,qtdInstsBusca,qtdCidadesBusca,qtdCursosBusca,qtdCursosBuscaFavoritos});
      self.setState({cidades_intituicao_curso,loading:false});
    }).catch((erro)=>{
      self.setState({loading:false});
      toast.error("Servidor não respondeu a tempo");
    });



    let data = new Date();
    let anos = [];
    for(let i=-1;i<5;i++){
      anos.push(data.getFullYear()+i+'/1');
      anos.push(data.getFullYear()+i+'/2');
    }
    this.setState({anos});
  }
  getVendedor(id){
    let vendedor = this.state.vendedores.filter((v)=>{return v.idUsuario === id})[0];
    return vendedor;
  }
  setarTodasCidades(){
    let mostrarCidade = this.state.mostrarCidade;
    let montrarFaculdade = this.state.mostrarFaculdade;
    let value = !this.state.mostrarTodasCidades;
    this.setState({carregando:true})
    Object.keys(mostrarCidade).forEach((c)=>{
      mostrarCidade[c] = value;
    })
    Object.keys(montrarFaculdade).forEach((c)=>{
      montrarFaculdade[c] = value;
    })
    this.setState({mostrarCidade,montrarFaculdade,mostrarTodasCidades:value,carregando:false});
  }
  recarregar(){
    //console.log(this.props.location.state.cidades);
    let self = this;
    //console.log(this.props.location.state.vendedor);
    let post = {
      "cidades":this.props.location.state.cidades,
      "instituicoes":this.props.location.state.instituicoes,
      "cursos":this.props.location.state.cursos,
      "estado":this.props.location.state.estado,
      "vendedor":this.props.location.state.vendedor,
      "somenteCidadesVinculadas":this.props.location.state.somenteCidadesVinculadas,
      "somenteCursosFavoritados":this.props.location.state.somenteCursosFavoritados
    }
    this.setState({loading:true});
    axios.post(REQUEST_URL+"/visitas",post).then((response)=>{
      console.log(!!Object.keys(this.state.cidades_intituicao_curso)[0]);
      let mostrarFaculdade = {};
      let mostrarCidade = {};
      Object.keys(this.state.cidades_intituicao_curso).forEach((key)=>{
        mostrarCidade[key] = true;
        Object.keys(this.state.cidades_intituicao_curso[key]).forEach((key2)=>{
          mostrarFaculdade[key2] = 'false';
          return 1;
        });
        return 1;
      });
      self.setState({mostrarFaculdade,mostrarCidade});
      self.setState({cidades_intituicao_curso:response.data,loading:false});
    });
  }
  toggleFaculdade(key2){
    let mostrarFaculdade = this.state.mostrarFaculdade;
    mostrarFaculdade[key2] = !mostrarFaculdade[key2];
    this.setState({mostrarFaculdade});
    console.log(mostrarFaculdade);
  }
  toggleCidade(key){
    let mostrarCidade = this.state.mostrarCidade;
    mostrarCidade[key] = !mostrarCidade[key];
    this.setState({mostrarCidade});
    console.log(mostrarCidade);
  }
  getQtdVisitados(key,key2,semestre){
    //console.log(key, key2,semestre);
    let count = 0;
    let inst = this.state.cidades_intituicao_curso[key][key2];
    let cursos = {}
    Object.keys(inst).forEach((key3)=>{
      for(let i =0 ;i<inst[key3].length;i++){
        if(inst[key3][i].Formatura === semestre && !cursos[inst[key3][i].nomecurso]){     
            cursos[inst[key3][i].nomecurso] = 1           
            count+=1;         
        }
      }
      return 1;
    });
    return count;
  }
  getCliente(v,a,b,c,d){
    this.setState({isPaneOpenLeft:true,buscandoCliente:true});
    console.log(v);
    console.log(a,b,c);
    if(v !== {} && !a && !b && !c){
      let post ={
        cod_curso:v.idcurso,
        cod_inst:v.cod_ies,
        cod_cidade:v.idcidade,
        formatura:v.Formatura
      }
      axios.post(REQUEST_URL+"/cliente_mapa",post).then((response)=>{
        this.setState({clienteSelecionado:response.data,buscandoCliente:false});
      }).catch((error)=>{
        this.setState({buscandoCliente:false});
      })
    }else{
      this.setState({idsSelecionado:{},clienteSelecionado:[],abrirOpcoes:false});
      axios.post(REQUEST_URL+"/getIds",{cidade:a,inst:b,curso:c}).then((response)=>{
        //console.log(response.data[0]);
        let ids = response.data[0];
        ids.semestre = d;
        this.setState({idsSelecionado:ids});
        this.setState({buscandoCliente:false});
      });
    }
  }
  esconderCursoConfirmar(){
    let post ={
      cod_cidade:this.state.idsSelecionado.cod_cidade,
      cod_curso:this.state.idsSelecionado.cod_curso,
      cod_inst:this.state.idsSelecionado.cod_inst,
      id_curso_inst:this.state.idsSelecionado.id_curso_inst
    }
    axios.post(REQUEST_URL+"/desativarCurso",post).then((response)=>{
      console.log(response.data);
      toast.success('Curso escondido! ele não aparecerá na proxima busca');
      this.setState({idsSelecionado:{},clienteSelecionado:[]});
    });
  }
  favoritarCursoConfirmar(){
    let post ={
      cod_cidade:this.state.idsSelecionado.cod_cidade,
      cod_curso:this.state.idsSelecionado.cod_curso,
      cod_inst:this.state.idsSelecionado.cod_inst,
      id_curso_inst:this.state.idsSelecionado.id_curso_inst
    }
    axios.post(REQUEST_URL+"/favoritarCurso",post).then((response)=>{
      console.log(response.data);
      toast.success('Curso favoritado! será destacado na proxima busca');
      this.setState({idsSelecionado:{},clienteSelecionado:[]});
    });
  }
  desfavoritarCursoConfirmar(){
    let post ={
      cod_cidade:this.state.idsSelecionado.cod_cidade,
      cod_curso:this.state.idsSelecionado.cod_curso,
      cod_inst:this.state.idsSelecionado.cod_inst,
      id_curso_inst:this.state.idsSelecionado.id_curso_inst
    }
    axios.post(REQUEST_URL+"/desfavoritarCurso",post).then((response)=>{
      console.log(response.data);
      toast.success('Curso desfavoritado! será removido o destaque na proxima busca');
      this.setState({idsSelecionado:{},clienteSelecionado:[]});
    });
  }
  setStatusCurso(id,status){
    let payload = {
      status:status
    }
    if(status === 'Perdido') payload.motivo = this.state.motivoPerda;

    axios.put(`${REQUEST_URL}/cliente/status/`+id,payload).then((response)=>{
      this.setState({carregando:false});
      toast.success("OK");
    }).catch((erro)=>{
      toast.error("Algo deu errado, não foi possivel atualizar o Status");
    });
  }
  setCursoAnual(anual){
    let post ={
      cod_cidade:this.state.idsSelecionado.cod_cidade,
      cod_curso:this.state.idsSelecionado.cod_curso,
      cod_inst:this.state.idsSelecionado.cod_inst,
      id_curso_inst:this.state.idsSelecionado.id_curso_inst,
      anual:anual
    }
    console.log(post);
    
    axios.post(REQUEST_URL+"/setCursoAnual",post).then((response)=>{
      console.log(response.data);
      toast.success('Periodo do curso alterado, será atualizado na proxima busca');
      this.setState({idsSelecionado:{},clienteSelecionado:[]});
    });
  }
  abrirOpcoesCurso(a,b,c){
    this.setState({isPaneOpenLeft:true,abrirOpcoes:true,idsSelecionado:{},clienteSelecionado:[]});
    axios.post(REQUEST_URL+"/getIds",{cidade:a,inst:b,curso:c}).then((response)=>{
      console.log(response.data[0]);
      let ids = response.data[0];
      this.setState({idsSelecionado:ids});
      this.setState({keysSelecionadas:{cidade:a,inst:b,curso:c}});
      this.setState({buscandoCliente:false});
    });
  }
  excluirCliente(id){
    console.log(id);
    axios.delete(REQUEST_URL+"/cliente/"+id).then((response)=>{
      console.log(response.data);
      toast.success("Fechamento cancelado, não será mais exibido a partir da próxima busca");
    }).catch((err)=>{
      toast.success("Não foi possível cancelar, tente novamente");
    }).finally(()=>{
      this.setState({showModalDeleteCliente:false})
    })
  }
  visaoPeriodoMacro(semestre){
    let qtdCursos = 0;
    let qtdProspecto = 0;
    let qtdNegociacao = 0;
    let qtdFechado = 0;
    console.log(semestre);
    console.log(this.state.cidades_intituicao_curso);
    Object.keys(this.state.cidades_intituicao_curso).forEach((cidade)=>{
      Object.keys(this.state.cidades_intituicao_curso[cidade]).forEach((instituicoes)=>{
        Object.keys(this.state.cidades_intituicao_curso[cidade][instituicoes]).forEach((cursos)=>{
          //console.log(this.state.cidades_intituicao_curso[cidade][instituicoes][cursos]);
          
          if(this.state.cidades_intituicao_curso[cidade][instituicoes][cursos].length){
            //console.log(this.state.cidades_intituicao_curso[cidade][instituicoes][cursos][0].anual);
            //console.log(semestre.substring(5,6));
            if(this.state.cidades_intituicao_curso[cidade][instituicoes][cursos][0].anual && semestre.substring(5,6) === "1"){
              //console.log("curso anual e primeiro semestre");
            }else{
              qtdCursos++;
            }
            this.state.cidades_intituicao_curso[cidade][instituicoes][cursos].forEach((curso)=>{
              if(curso.Formatura === semestre){
                //qtdNoSemestre++;
                if(curso.Status === "Prospecto")qtdProspecto++;
                else if(curso.Status === "Negociacao")qtdNegociacao++;
                else if(curso.Status === "Fechado")qtdFechado++;
              }

            });
          }
        });
      });
    });

    console.log("Cursos: "+qtdCursos);

    console.log("Prospectado: "+(qtdProspecto+qtdNegociacao+qtdFechado)+" - "+(((qtdProspecto+qtdNegociacao+qtdFechado)*100)/qtdCursos).toFixed(2)+"%");
    console.log("Negociação: "+(qtdNegociacao+qtdProspecto)+" - "+(((qtdNegociacao+qtdProspecto)*100)/qtdCursos).toFixed(2)+"%");
    console.log("Fechado: "+qtdFechado+" - "+ ( (qtdFechado*100)/qtdCursos).toFixed(2)+"%");
    this.setState({qtdCursos,qtdProspectado:(qtdProspecto+qtdNegociacao+qtdFechado),qtdNegociacao:(qtdNegociacao+qtdFechado),qtdFechado,periodoSelcionado:semestre});
    this.setState({showModalPeriodoMacro:true});
  }
  gotoCadastro(){
    console.log(this.state.idsSelecionado);
  
    this.props.history.push({
      pathname:"/cadastro",
      state:{
        ids:this.state.idsSelecionado
      }
     });
  
  }
  gotoOrcamento(){
    this.props.history.push({
      pathname:"/orcamento",
      state:{
          cliente:this.state.clienteSelecionado[0],
       }
     });
  }
  render(){
    if(!sessionStorage.getItem('usuarioLogado')){
      return <Redirect to="/login"/>
    }
    return(
      <div style={{height:1000}} ref={ref => this.el = ref}>
        <ToastContainer
          position="bottom-left"
          autoClose={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange={false}
          draggable={false}
        />
        <NavBar nome={this.state.usuarioLogado.Nome} funcao={this.state.usuarioLogado.Funcao}/>

        <Container style={{marginTop:"20px"}} fluid id="container-faculdades">
          <Card style={{borderRadius:0,border:0,padding:20,marginBottom:20}} id="infos">
            <Row style={{textAlign:"center"}}>
              <Col sm="6" xs="6">
                <Card body outline color="secondary" style={{borderRadius:5,padding:0}}>
                  <h5 style={{backgroundColor:"#6c757d",color:"white",margin:0}}>Cidades</h5>
                  {this.state.qtdCidadesBusca}
                </Card>
              </Col>
              <Col sm="6" xs="6">
                <Card body outline color="secondary" style={{borderRadius:5,padding:0}}>
                  <h5 style={{backgroundColor:"#6c757d",color:"white",margin:0}}>Faculdades</h5>
                  {this.state.qtdInstsBusca}
                </Card>
              </Col>
              <Col sm="6" xs="6">
                <Card body outline color="secondary" style={{borderRadius:5,padding:0}}>
                  <h5 style={{backgroundColor:"#6c757d",color:"white",margin:0}}>Cursos</h5>
                  {this.state.qtdCursosBusca}
                </Card>
              </Col>
              <Col sm="6" xs="6">
                <Card body outline color="secondary" style={{borderRadius:5,padding:0}}>
                  <h5 style={{backgroundColor:"#6c757d",color:"white",margin:0}}>Favoritos</h5>
                  {this.state.qtdCursosBuscaFavoritos}
                </Card>
              </Col>
            </Row>
            
            <div id="legenda" style={{textAlign:"center"}}>
              <h5>Legenda: </h5>
              <a style={{backgroundColor:"#17a2b8",padding:"4px",color:"white",margin:2}}> Prospecto </a>
              <a style={{backgroundColor:"#ffc107",padding:"4px",color:"white",margin:2}}> Negociacao </a>
              <a style={{backgroundColor:"#28a745",padding:"4px",color:"white",margin:2}}> Fechado </a>
              <a style={{backgroundColor:"#dc3545",padding:"4px",color:"white",margin:2}}> Cancelado </a> 
              <a style={{backgroundColor:"black",padding:"4px",color:"white",margin:2}}> Perdido </a>
              <a style={{backgroundColor:"#85138df7",padding:"4px",color:"white",margin:2}}>Cadastro Duplicado</a>
            </div>
          </Card>

          <Card style={{borderRadius:0,border:0,padding:20}} id="cursos">
            <div id="table-container" className="table-responsive fixedTable">
              <Table borderless id="maintable">
                <thead>
                  <tr>
                    <th width="30%">
                      {
                        !this.state.mostrarTodasCidades ? 
                          <div title="mostrar todas as cidades" style={{cursor: "pointer"}} onClick={()=>this.setarTodasCidades()}><i className="fa fa-plus"></i> Abrir cidades / inst. / cursos</div>
                        : <div title="esconder todas as cidades" style={{cursor: "pointer"}} onClick={()=>this.setarTodasCidades()}><i className="fa fa-minus"></i> Fechar cidades / inst. / cursos</div>
                      }
                    </th>
                    {
                      this.state.anos.map((semestre,i) => (
                        <th key={i} title="ver macro do periodo" style={{cursor: "pointer"}} onClick={this.visaoPeriodoMacro.bind(this,semestre)}>{semestre}</th>
                      ))
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    Object.keys(this.state.cidades_intituicao_curso)[0] ?
                      Object.keys(this.state.cidades_intituicao_curso).map((key, i) => {
                        return [
                          <tr key={key}>
                            <th style={{padding:"4px 40px 2px 0px"}}>
                              <Button style={{border:"none",borderRadius:"0px",backgroundColor:"#033649"}} onClick={()=>this.toggleCidade(key)} block sm="true">
                                {
                                  this.state.mostrarCidade[key] ?
                                    <i className="fas fa-angle-up float-right fa-lg" style={{color:"#fff"}}></i>
                                  : <i className="fas fa-angle-down float-right fa-lg" style={{color:"#fff"}}></i>
                                }
                                {key}
                                <Badge color="light" style={{float:"right",marginRight:5,width:35}}>{Object.keys(this.state.cidades_intituicao_curso[key]).length}</Badge>
                              </Button>
                            </th>
                          </tr>,
                          Object.keys(this.state.cidades_intituicao_curso[key]).map((key2) => {
                            return [
                              <tr style={this.state.mostrarCidade[key] ? {} : {display:'none'}} key={key2}>
                                <th style={{padding:"2px 0px 2px 30px"}}>
                                  <Button style={{border:"none",borderRadius:"0px",backgroundColor:"#036564"}} onClick={()=>this.toggleFaculdade(key2)} block sm="true">
                                    {key2 ? key2 : "-----"}
                                    {
                                      this.state.mostrarFaculdade[key2] ?
                                        <i className="fas fa-angle-up float-right fa-lg" style={{color:"#fff"}}></i>
                                      : <i className="fas fa-angle-down float-right fa-lg" style={{color:"#fff"}}></i>
                                    }
                                    <Badge color="info" style={{float:"right",marginRight:5,width:35}}>{Object.keys(this.state.cidades_intituicao_curso[key][key2]).length-1}</Badge>
                                  </Button>
                                </th>
                                {
                                  this.state.anos.map((semestre,i)=>{
                                    let visitados = this.getQtdVisitados(key,key2,semestre);
                                    return (
                                      <td key={i} aligh="center" style={{padding:"2px 0px 2px 30px"}}>
                                        {
                                          visitados>0 ?
                                          <Button color={visitados>0 ?visitados === this.state.cidades_intituicao_curso[key][key2]['_quantidade']? "success" : "warning" : "default"} style={{color:"#fff",padding:"0px 5px 0px 5px"}}>
                                            {visitados} / {this.state.cidades_intituicao_curso[key][key2]['_quantidade']}
                                          </Button>
                                          :
                                          <span> {visitados} / {this.state.cidades_intituicao_curso[key][key2]['_quantidade']}</span>
                                        }
                                      </td>
                                    )
                                  })
                                }
                              </tr>,
                              Object.keys(this.state.cidades_intituicao_curso[key][key2]).map((key3) => {
                                if (key3 !== '_quantidade') {
                                  return (
                                    <tr style={this.state.mostrarFaculdade[key2] && this.state.mostrarCidade[key] ? {} : {display:'none'}} key={key3}>
                                      <td style={{padding:"2px 0px 2px 60px"}}>
                                        <div onDoubleClick={()=>this.abrirOpcoesCurso(key,key2,key3)}  style={{paddingLeft:10,backgroundColor:"#CDB380",color:"031634"}}>
                                          {key3}
                                          {
                                            this.state.cidades_intituicao_curso[key][key2][key3][0].favoritado ?
                                              <i style={{float:"right",marginTop:4,marginRight:4,color:"gold",textShadow: "2px 2px 5px black"}} className="fa fa-star"></i>
                                            : null
                                          }
                                          {
                                            this.state.cidades_intituicao_curso[key][key2][key3][0].anual ?
                                              <i style={{float:"right",marginTop:4,marginRight:4,color:"black"}} className="fa fa-font"></i>
                                            : null
                                          }
                                        </div>
                                      </td>
                                      {
                                        this.state.anos.map((semestre,i) => {
                                          let visitado= false;
                                          let visita = {};
                                          let qtd = 0;
                                          let curso = this.state.cidades_intituicao_curso[key][key2][key3];
                                          for (let i = 0; i < curso.length; i++) {
                                            if (curso[i].Formatura === semestre) {
                                              visitado = true;
                                              visita = curso[i];
                                              qtd++;
                                            }
                                          }
                                          let cor = "";
                                          if (!visitado) {
                                            cor = "#E8DDCB";
                                          } else if (qtd > 1) {
                                            cor = "#85138df7";
                                          } else {
                                            switch (visita.Status) {
                                              case "Prospecto":
                                                cor = "#17a2b8"
                                                break;
                                              case "Negociacao":
                                                cor = "#ffc107"
                                                break;
                                              case "Fechado":
                                                cor = "#28a745"
                                                break;
                                              case "Perdido":
                                                cor = "black"
                                                break;
                                              case "Cancelado":
                                                cor = "#dc3545"
                                                break;
                                              default:
                                            }
                                          }
                                          return (
                                            <td key={i} align="center" style={{padding:"2px 0px 2px 0px"}}>
                                              {
                                                visitado ?
                                                  <div onClick={ () =>{ this.getCliente(visita) }} style={{backgroundColor:cor,color:"#fff"}}>
                                                    {visita.NomeVendedor}&nbsp; 
                                                    <i className="fas fa-check" style={{color:"#fff",cursor:"pointer"}}></i>
                                                  </div>
                                                : <div onClick={ () =>{ this.getCliente(visita,key,key2,key3,semestre) }}  style={{backgroundColor:cor,color:"#fff"}}>
                                                  {visita.NomeVendedor}&nbsp; 
                                                  <i className="fas fa-times" style={{color:"#dc3545",cursor:"pointer"}}></i>
                                                </div>
                                              }
                                            </td>
                                          )
                                        })
                                      }
                                    </tr>
                                  )
                                }
                                return null;
                              })
                            ]
                          })
                        ]
                      })
                    : <tr>
                      {
                        !this.state.loading ?
                          <td style ={{padding:"10px 0px 0px 0px"}} colSpan="11">
                            <Alert color="success">
                              Nada foi encontrado, confira os filtros e tente novamente<br/>
                              <a href="/mapa"><i className="fas fa-arrow-left  fa-sm"></i> voltar</a>
                            </Alert>
                          </td>
                        : <td colSpan={11}>aguarde...</td>
                      }
                    </tr>
                  }
                </tbody>
              </Table>
              <div id="bottom_anchor"></div>
            </div>
          </Card>
        </Container>
        <Modal isOpen={ this.state.isPaneOpenLeft } size="lg">
            <ModalHeader toggle={()=>this.setState({isPaneOpenLeft:false})}></ModalHeader>
            <ModalBody>
            <div>
              {
                this.state.buscandoCliente ?
                  <p>Buscando detalhes...</p>
                :
                this.state.clienteSelecionado.length>0 ?
                  this.state.clienteSelecionado.map((c)=>(
                  <div style={{margin:5,padding:5,border:"1px solid gray"}}>
                    <div style={{borderBottom:"1px dotted gray"}}><Label>Id:</Label>               <div style={{float:"right"}}>{c.idCliente}</div> </div>
                    <div style={{borderBottom:"1px dotted gray"}}><Label>Descrição:</Label>        <div style={{float:"right"}}>{c.Descricao}</div> </div>
                    <div style={{borderBottom:"1px dotted gray"}}><Label>Cidade:</Label>           <div style={{float:"right"}}>{c.Cidade}</div></div>
                    <div style={{borderBottom:"1px dotted gray"}}><Label>Estado: </Label>          <div style={{float:"right"}}>{c.SiglaEstado}</div></div>
                    <div style={{borderBottom:"1px dotted gray"}}><Label>Endereço: </Label>        <div style={{float:"right"}}>{c.Endereco}</div></div>
                    <div style={{borderBottom:"1px dotted gray"}}><Label>Curso:  </Label>          <div style={{float:"right"}}>{c.curso}</div></div>
                    <div style={{borderBottom:"1px dotted gray"}}><Label>Data de inclusão:</Label> <div style={{float:"right"}}>{new Date(c.DataInclusao).toLocaleDateString()}</div></div>
                    <div style={{borderBottom:"1px dotted gray"}}><Label>Formatura: </Label>       <div style={{float:"right"}}>{c.Formatura}</div></div>
                    <div style={{borderBottom:"1px dotted gray"}}><Label>Agencia:</Label>          <div style={{float:"right"}}>{c.codAgencia}</div></div>
                    <div style={{borderBottom:"1px dotted gray"}}><Label>Vendedor: </Label>        
                      <div style={{float:"right"}}>
                      {
                        this.getVendedor(c.Vendedor) &&
                        this.getVendedor(c.Vendedor).Nome                      
                      }
                      </div>
                      </div>
                      <div style={{borderBottom:"1px dotted gray"}}><Label>Status:         <div style={{float:"right"}}>{c.Status}</div></Label></div>
                    {
                      c.Status === 'Prospecto' &&
                      <Button onClick={()=>this.gotoOrcamento()} color="success">Novo Orçamento</Button> 
                    }                  
                    {
                      c.Descricao === "Fechamento administrativo  " && this.state.usuarioLogado.Funcao !== 'VENDEDOR' &&
                      <Button onClick={()=>this.setState({showModalDeleteCliente:true})} color="danger">Cancelar Fechamento</Button>                     
                    }
                    {
                      this.state.usuarioLogado.Funcao !== 'VENDEDOR' &&
                      <div>
                        <br/>
                        Alterar status?
                        <br/>
                        <Button onClick={()=>this.setStatusCurso(c.idCliente,'Prospecto')} outline={!(c.Status === 'Prospecto')} color="info">Prospecto</Button>
                        <Button onClick={()=>this.setStatusCurso(c.idCliente,'Negociacao')} outline={!(c.Status === 'Negociacao')} color="warning">Negócio</Button>
                        <Button onClick={()=>this.setStatusCurso(c.idCliente,'Fechado')} outline={!(c.Status === 'Fechado')} color="success">Fechado</Button>
                        <Button onClick={()=>this.setStatusCurso(c.idCliente,'Cancelado')} outline={!(c.Status === 'Cancelado')} color="danger">Cancelado</Button>
                        <Button onClick={()=>this.setState({showModalMotivoPerda:true})} outline={!(c.Status === 'Perdido')} color="secondary">Perdido</Button>
                      </div>
                    }
                  </div>
                  ))
                  
                :
                this.state.abrirOpcoes ?
                <div style={{textAlign:"center"}}>
                  <h4>O que deseseja fazer com esse curso?</h4>
                  <p>Curso de {this.state.keysSelecionadas.curso} da Instituição {this.state.keysSelecionadas.inst} da cidade de {this.state.keysSelecionadas.cidade}</p>
                  {/*<Button outline  onClick={()=>this.esconderCursoConfirmar()} block style={{}} color="danger"> Esconder Curso  </Button>*/}
                  <Button outline  onClick={()=>this.desfavoritarCursoConfirmar()} block color="warning"> Desfavoritar Curso  </Button>
                  <Button outline  onClick={()=>this.favoritarCursoConfirmar()} block color="success"> Favoritar Curso  </Button>
                  <Button outline  onClick={()=>this.setCursoAnual(1)} block color="primary"> Informar Anual  </Button>
                  <Button outline  onClick={()=>this.setCursoAnual(0)} block color="info"> Informar Semestral  </Button>
                </div>
                :
                <div>
                  <p>Nenhum cadastro para essa turma</p>
                  {this.state.usuarioLogado.Funcao !== 'VENDEDOR' && <Button outline onClick={()=>this.fecharTurma()} block color="success" >Marcar como Fechada</Button>}
                  {this.state.usuarioLogado.Funcao !== 'VENDEDOR' &&<Button outline onClick={()=>this.marcarCancelada()}block color="danger" >Marcar como Cancelada</Button>}   
                  <Button outline onClick={()=>this.gotoCadastro()} block color="primary"> 
                    Abrir Cadastro 
                    {this.state.buscandoCliente ?<i className="fa fa-spinner fa-spin  fa-fw"></i>:""}
                  </Button>
                </div>
              }

            </div>
            </ModalBody>
        </Modal>
        <Modal isOpen={this.state.showModalDeleteCliente} size="sm" id="confirmar_delete">
          <ModalHeader toggle={()=>this.setState({showModalDeleteCliente:false})}>Tem Certeza?</ModalHeader>
          <ModalFooter>
            <Button color="secondary" onClick={()=>this.setState({showModalDeleteCliente:false})}>Cancelar</Button>
          <Button color="danger" onClick={()=>this.excluirCliente(this.state.clienteSelecionado[0].idCliente)}>Sim</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.showModalMotivoPerda} size="sm" id="motivo">
          <ModalHeader toggle={()=>this.setState({showModalMotivoPerda:false})}>Qual o motivo de ter perdido esse cliente?</ModalHeader>
          <ModalBody>      
            {
              motivosPerdido.map((motivo, i)=>(
                <div key={i}><input type="radio" name="motivos-radio" onClick={()=>{this.setState({motivoPerda:motivo})}}/>{motivo} </div>
              ))
            }      
            <Input value={this.state.motivoPerda} onChange={(e)=>this.setState({motivoPerda:e.target.value})} type="textarea" name="text-motivo" id="text-motivo" style={{height:50}} />    
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={()=>this.setState({showModalMotivoPerda:false})}>Cancelar</Button>
            <Button color="danger" onClick={()=>this.setStatusCurso(this.state.clienteSelecionado[0].idCliente,'Perdido')}>Enviar</Button>
          </ModalFooter>
        </Modal>
        <Modal modalTransition={{ timeout: 50 }} backdropTransition={{ timeout: 50 }} isOpen={this.state.showModalPeriodoMacro} id="periodo-macro">
          <ModalHeader toggle={()=>this.setState({showModalPeriodoMacro:false})}>Macro Periodo {this.state.periodoSelcionado}</ModalHeader>
          <ModalBody style={{color:"#fff",height:370,textAlign:"center",backgroundImage:"url(./imgs/funil.png)",backgroundSize:"100%",backgroundRepeat:"no repeat"}}>
            <h4 style={{marginTop:0}}>Cursos<br/>{this.state.qtdCursos}</h4>
            <h4 style={{marginTop:35}}>Prospectado<br/>{this.state.qtdProspectado} - {(this.state.qtdProspectado*100/this.state.qtdCursos).toFixed(2)}%</h4>
            <h4 style={{marginTop:40}}>Negociação<br/>{this.state.qtdNegociacao} - {(this.state.qtdNegociacao*100/this.state.qtdCursos).toFixed(2)}%</h4>
            <h4 style={{marginTop:40}}>Fechado<br/>{this.state.qtdFechado} - {(this.state.qtdFechado*100/this.state.qtdCursos).toFixed(2)}%</h4>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}


export default Faculdades;
