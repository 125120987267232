import axios from 'axios';
import {REQUEST_URL} from '../config/urls';

const autenticou = (resposta) => {
  return {
    type: "AUTENTICOU",
    usuario: resposta.usuario
  }
}

const erroAutenticacao = (message) => {
  return {
    type: "ERRO_AUTENTICACAO",
    message
  }
}
const autenticouCookie = (usuario) => {
  return {
    type: "AUTENTICOU_COOKIE",
    usuario
  }
}
const requisitar = () => {
  return {
    type: "REQUISITAR"
  }
}


export const login = (credenciais) => {
  return dispatch => {
    dispatch(requisitar());
    return axios.post(`${REQUEST_URL}/loginMapa`,credenciais).then((response)=>{
      sessionStorage.setItem('usuarioLogado', JSON.stringify(response.data.user));
      sessionStorage.setItem('token', response.data.token);
      dispatch(autenticou({"usuario": response.data.user[0], "token": response.data.token}));
    }).catch((erro)=>{
      dispatch(erroAutenticacao(erro));
    });
  }
}
export const setUsuario = (usuario) => {
  return dispatch => {
    dispatch(autenticouCookie(usuario));
  }
}
export const logout = () => {
  axios.defaults.headers.common['Authorization'] = "";
  sessionStorage.removeItem('usuarioLogado');
  sessionStorage.removeItem('token');
  return {
    type: "LOGOUT"
  }
}
