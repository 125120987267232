import React, { useState, useEffect } from 'react';
import { REQUEST_URL } from "../config/urls";
import { Collapse, Label, Input, FormGroup,  Button } from 'reactstrap';
import { FaPlus, FaMinus, FaTrash } from 'react-icons/fa';
import InputMask from 'react-input-mask';
import axios from 'axios';

import TelegramLog from '../services/telegram';

const CollapseContato = (props) => {
  const [collapse, setCollapse] = useState(false);
  const [contato, setContato] = useState({ Nome: "", email: "", CPF: "", RG: "", CNPJ: "", Celular: "", Fixo: "", idContato: "", IdCliente: "", ContatoPrinc: false });
  const [alterandoContato, setAlterandoContato] = useState(false);
  const [id, setId] = useState(null);

  const toggle = () => setCollapse(!collapse);

  useEffect (() => {
    let contato = props.contato;
    contato.ContatoPrinc = contato.ContatoPrinc === "SIM" ? true : contato.ContatoPrinc === "NAO" ? false : contato.ContatoPrinc; 
    setContato(contato);
  }, [props]);

  useEffect (() => {
    if (alterandoContato) {
      if (contato.idContato === "") {
        axios.post(`${REQUEST_URL}/cliente/contato`, contato).then(() => {
          setAlterandoContato(false);
          toggle();
          props.atualizarContatos();
        }).catch((erro) => {
          new TelegramLog().novo("ERRO", [JSON.stringify(erro), "Erro ao inserir um contato", `usuarioLogado: ${JSON.parse(sessionStorage.getItem("usuarioLogado"))[0].Login}`]);
        });
      } else {
        axios.put(`${REQUEST_URL}/cliente/contato/${contato.idContato}`, contato).then(() => {
          setAlterandoContato(false);
          toggle();
          props.atualizarContatos();
        }).catch((erro) => {
          new TelegramLog().novo("ERRO", [JSON.stringify(erro), "Erro ao atualizar as informações do contato", `usuarioLogado: ${JSON.parse(sessionStorage.getItem("usuarioLogado"))[0].Login}`]);
        });
      }
      setAlterandoContato(false);
    }
  }, [alterandoContato]);

  useEffect (() => {
    if (id) {
      axios.delete(`${REQUEST_URL}/cliente/contato/${id}`).then((response) => {
        toggle();
        setId(null);
        props.atualizarContatos();
      }).catch((erro) => {
        new TelegramLog().novo("ERRO", [JSON.stringify(erro), "Erro ao excluir o contato", `usuarioLogado: ${JSON.parse(sessionStorage.getItem("usuarioLogado"))[0].Login}`]);
      });
    }
  }, [id]);

  function handleChangeContato (event) {
    const { name, value, type } = event.target;
    setContato({...contato, [name]: (type === "checkbox" ? event.target.checked : value)});
  }

  function validarFormulario () {
    let helpblocks = document.querySelectorAll("input"),
      status = true;

    helpblocks.forEach((campo) => {
      let id = campo.getAttribute("id"); 

      if (id && id.includes(props.index) && (id.includes("nome") || id.includes("email") || id.includes("celular"))) {
        campo.setAttribute("required", "true");
        
        if (!campo.checkValidity()) {
          campo.closest(".form-group").innerHTML += "<span class='help-block'>"+ campo.validationMessage +"</span>";
          status = false;
        }
        
        document.getElementById(id).removeAttribute("required");
      }
    });

    return status;
  }

  function removerHelpBlock () {
    const helpblocks = document.querySelectorAll(".help-block");
    
    helpblocks.forEach((campo) => {
      campo.remove();
    });
  }

  function handleClickSalvar () {
    removerHelpBlock();

    let validou = validarFormulario();
    if (validou) {
      setAlterandoContato(true);
    }
  }

  return (
    <React.Fragment>
      <div style={{ border: "1px solid #ccc", borderRadius: 4, marginBottom: 15, paddingLeft: 10, paddingRight: 10 }}>
        <h6 style={{ margin: "5px 0px" }}>
          {
            contato.idContato ? 
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div onClick={toggle} style={{ display: "flex", alignItems: "center", width: "calc(100% - 16px)", cursor: "pointer" }}>
                  { collapse ? <FaMinus style={{ marginRight: 5 }}/> : <FaPlus style={{ marginRight: 5 }}/> }
                  { contato.Nome }
                </div>
                <FaTrash color="rgb(220, 53, 69)" style={{ cursor: "pointer" }} onClick={() => setId(contato.idContato)}/>
              </div> 
            : <div onClick={toggle} style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>{ collapse ? <FaMinus style={{ marginRight: 5 }}/> : <FaPlus style={{ marginRight: 5 }}/> } Adicionar novo</div>
          }
        </h6>
        <Collapse isOpen={collapse}>
          <br/>
          <FormGroup>
            <Label for={`nome${props.index}`}><span style={{ color: "#DC3545", fontWeight: 600 }}>*</span>Nome</Label>
            <Input type="text" id={`nome${props.index}`} name="Nome" value={contato.Nome} onChange={handleChangeContato}/>
          </FormGroup>
          <FormGroup>
            <Label for={`email${props.index}`}><span style={{ color: "#DC3545", fontWeight: 600 }}>*</span>E-mail</Label>
            <Input type="email" id={`email${props.index}`} name="email" value={contato.email} onChange={handleChangeContato}/>
          </FormGroup>
          <FormGroup>
            <Label for={`cpf${props.index}`}>CPF</Label>
            <InputMask id={`cpf${props.index}`} name="CPF" className="form-control" mask="999.999.999-99" value={contato.CPF} onChange={handleChangeContato}/>  
          </FormGroup>
          <FormGroup>
            <Label for={`rg${props.index}`}>RG</Label>
            <InputMask id={`rg${props.index}`} name="RG" className="form-control" mask="99.999.999-9" value={contato.RG} onChange={handleChangeContato}/>  
          </FormGroup>
          <FormGroup>
            <Label for={`cnpj${props.index}`}>CNPJ</Label>
            <InputMask id={`cnpj${props.index}`} name="CNPJ" className="form-control" mask="99.999.999/9999-99" value={contato.CNPJ} onChange={handleChangeContato}/>  
          </FormGroup>
          <FormGroup>
            <Label for={`celular${props.index}`}><span style={{ color: "#DC3545", fontWeight: 600 }}>*</span>Celular</Label>
            <InputMask id={`celular${props.index}`} name="Celular" className="form-control" mask="(99) 9 9999-9999" value={contato.Celular} onChange={handleChangeContato}/>  
          </FormGroup>
          <FormGroup>
            <Label for={`telefone${props.index}`}>Fixo</Label>
            <InputMask id={`telefone${props.index}`} name="Fixo" className="form-control" mask="(99) 9999-9999" value={contato.Fixo} onChange={handleChangeContato}/>  
          </FormGroup>
          {
            contato.idContato ?
              <FormGroup>
                <Label>
                  <input type="checkbox" checked={contato.ContatoPrinc} onChange={handleChangeContato} name="ContatoPrinc"/> 
                  {" "}Contrato principal
                </Label>
              </FormGroup>
            : null
          }
          <Button color="primary" onClick={handleClickSalvar} style={{ marginBottom: 10 }}>Salvar</Button>
        </Collapse>
      </div>
    </React.Fragment>
  );
}

export default CollapseContato;