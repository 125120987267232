import React, { useState, useEffect } from "react";
import { REQUEST_URL } from "../config/urls";
import { FaSpinner } from "react-icons/fa";
import { ModalHeader, ModalBody, ModalFooter, Button, Input, FormGroup, Col, Row, Label, Form } from 'reactstrap';
import axios from "axios";
import Select from 'react-select';
import 'react-select/dist/react-select.css';

import TelegramLog from '../services/telegram';

export default function ModalEditarClientes (props) {

  const [instituicaoSelecionada, setInstituicaoSelecionada] = useState({ value: "", label: "" });
  const estados = [
    { value: 'AC', label: 'Acre' },
    { value: 'AL', label: 'Alagoas' },
    { value: 'AP', label: 'Amapá' },
    { value: 'AM', label: 'Amazonas' },
    { value: 'BA', label: 'Bahia' },
    { value: 'CE', label: 'Ceará' },
    { value: 'DF', label: 'Distrito Federal' },
    { value: 'ES', label: 'Espírito Santo' },
    { value: 'GO', label: 'Goiás' },
    { value: 'MA', label: 'Maranhão' },
    { value: 'MT', label: 'Mato Grosso' },
    { value: 'MS', label: 'Mato Grosso do Sul' },
    { value: 'MG', label: 'Minas Gerais' },
    { value: 'PA', label: 'Pará' },
    { value: 'PB', label: 'Paraíba' },
    { value: 'PR', label: 'Paraná' },
    { value: 'PE', label: 'Pernambuco' },
    { value: 'PI', label: 'Piauí' },
    { value: 'RJ', label: 'Rio de Janeiro' },
    { value: 'RN', label: 'Rio Grande do Norte' },
    { value: 'RS', label: 'Rio Grande do Sul' },
    { value: 'RO', label: 'Rondônia' },
    { value: 'RR', label: 'Roraima' },
    { value: 'SC', label: 'Santa Catarina' },
    { value: 'SP', label: 'São Paulo' },
    { value: 'SE', label: 'Sergipe' },
    { value: 'TO', label: 'Tocantins' }
  ];
  const [estadoSelecionado, setEstadoSelecionado] = useState(null);
  const [cidadesVinculadas, setCidadesVinculadas] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [cidadeSelecionada, setCidadeSelecionada] = useState(null);
  const [vincularCidade, setVincularCidade] = useState(false);
  const [cursos, setCursos] = useState([]);
  const [cursoSelecionado, setCursoSelecionado] = useState(null);

  useEffect (() => {
    const { instituicao, cidades } = props;
    setInstituicaoSelecionada(instituicao);
    setCidadesVinculadas(cidades)
    axios.get(`${REQUEST_URL}/cursos`, {headers: {"Authorization": sessionStorage.getItem("token")}}).then((response) => {
      let aux = [];
      response.data.forEach((curso) => {
        aux.push({ value: curso.idcurso, label: curso.nomecurso });
      });
      setCursos(aux);
    }).catch((erro) => {
      new TelegramLog().novo("ERRO", [JSON.stringify(erro), "Erro ao consultar os cursos", `usuarioLogado: ${JSON.parse(sessionStorage.getItem("usuarioLogado"))[0].Login}`]);
    });
  }, []);

  useEffect (() => {
    if (estadoSelecionado) {
      axios.post(`${REQUEST_URL}/cidades`, {estado_cod: estadoSelecionado.value}, {headers: {"Authorization": sessionStorage.getItem("token")}}).then((response) => {
        let aux = [];
        response.data.map((cidade) => {
          let existe = false;
          
          cidadesVinculadas.forEach((cidadeVinculada) => {
            if (cidadeVinculada.idcidade === cidade.codigo) {
              existe = true;
            }
          });

          if (!existe) {
            aux.push({ value: cidade.codigo, label: cidade.cidade });           
          }
        });
        setCidades(aux);
      }).catch((err) => {
        console.log(err);
      });
    }
  }, [estadoSelecionado]);

  useEffect (() => {
    if (vincularCidade) {
      axios.post(`${REQUEST_URL}/instituicoes/novo-curso`, {cod_ies: instituicaoSelecionada.value, cod_curso: cursoSelecionado.value, nome_curso: cursoSelecionado.label, cod_cidade: cidadeSelecionada.value}).then((response) => {
        props.close({ tipo: "sucesso", mensagem: "A IES foi vinculada a uma nova cidade com sucesso!" });
      }).catch((erro) => {
        new TelegramLog().novo("ERRO", [JSON.stringify(erro), "Erro ao vincular um curso a universidade", `usuarioLogado: ${JSON.parse(sessionStorage.getItem("usuarioLogado"))[0].Login}`]);
      });
    }
  }, [vincularCidade]);

  function handleChangeEstado (data) {
    setEstadoSelecionado(data);
    setCidadeSelecionada({});
  }
  
  function handleChangeCidade (data) {
    setCidadeSelecionada(data);
  }

  function handleChangeCurso (data) {
    setCursoSelecionado(data);
  }

  function handleSubmitCidade (event) {
    event.preventDefault();
    setVincularCidade(true);
  }

  return (
    <React.Fragment>
      <ModalHeader toggle={props.close}>Vincular IES a uma nova cidade</ModalHeader>
      <Form onSubmit={handleSubmitCidade}>
        <ModalBody>
          <Row>
            <Col sm="12">
              <FormGroup>
                <Label for="estado">IES selecionada</Label>
                <Input type="text" value={instituicaoSelecionada.label} disabled/>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="4">
              <FormGroup>
                <Label for="estado"><span className="perguntaObrigatoria">*</span>Estado</Label>
                <Select 
                  name="estado" id="estado" required
                  value={estadoSelecionado} 
                  onChange={handleChangeEstado} 
                  options={estados} 
                  placeholder="Selecione"
                />
              </FormGroup>
            </Col>
            <Col sm="12" md="8">
              <FormGroup>
                <Label for="cidade"><span className="perguntaObrigatoria">*</span>Cidade</Label>
                <Select
                  name="cidade" id="cidade" required
                  noOptionsMessage="Nenhuma cidade encontrada"
                  value={cidadeSelecionada}
                  onChange={handleChangeCidade}
                  options={cidades}
                  placeholder="Selecione"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="8">
              <FormGroup>
                <Label for="curso"><span className="perguntaObrigatoria">*</span>Selecione um curso</Label>
                <Select
                  name="curso" id="curso" required
                  value={cursoSelecionado}
                  onChange={handleChangeCurso}
                  options={cursos}
                  placeholder="Selecione"
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="success" disabled={vincularCidade}>
            { 
              vincularCidade ?
                <span style={{ display: "flex", alignItems: "center" }}><FaSpinner style={{ marginRight: 5 }} className="rodarIcone"/> Carregando</span>
              : "Vincular"
            }
          </Button>
        </ModalFooter>
      </Form>
    </React.Fragment>
  );

}