import axios from 'axios';

const URL_API_WHATSAPP = "https://simplechat.com.br/api";
const TOKEN_WHATSAPP = "b2db6118bb3e6dee6a31c184f821b70d";

class ModelosMensagem {
	static contrato () {
		return "Olá!\nEste é o número do SAC da Alpha Convites. Qualquer problema, dúvida ou sugestão é só chamar a gente que faremos o possível para atendê-l@ da melhor maneira possível!\n\n*Obrigado por fechar com a gente!*";
	}
	static acessoSistemaComissao (usuario) {
		return `Seja bem-vindo(a) a *Alpha Convites*!\nAbaixo você encontrará o link e o seu login e senha para acessar o Portal da Comissão:\n\nLink: https://comissao.alphaeditora.com.br\nLogin: ${usuario.email}\nSenha: ${usuario.senha}\n\nVocê já pode dar uma olhadinha! Lá tem uma aba com as orientações fotográficas. Veja com atenção, porque elas são muito importantes!\nEm breve entraremos em contato e lhe explicaremos como tudo funcionará! Enquanto isso, se você tiver alguma dúvida é só chamar a gente:\nno nosso whatsapp: (43) 9 9954-0060\nou no nosso telefone: (43) 3542-8585`;
	} 
	static getMensagem (tipo, usuario = null) {
		let mensagem = "";
		switch (tipo) {
			case "contrato":
				mensagem = this.contrato();
				break;
			case "acessoSistemaComissao":
				mensagem = this.acessoSistemaComissao(usuario);
				break;
		}
		return mensagem;
	}
}


export default class WhatsappServices {
	sendMessageWithAFile (data) {
		return new Promise ((resolve,reject) => {
			let formdata = new FormData();
			formdata.append("file", data.link);
			formdata.append("message", data.mensagem);
			formdata.append("celular", data.celular);
			formdata.append("extensao", data.extensao);
			axios.post(`${URL_API_WHATSAPP}/send-image/${TOKEN_WHATSAPP}`, formdata).then((response)=>{
				resolve(response.data);
			}).catch((erro)=>{
				reject(erro);
			});
		});
	}

	sendMessage (data) {
		return new Promise ((resolve,reject) => {
			let mensagem = ModelosMensagem.getMensagem(data.tipo, data.usuario);
			axios.post(`${URL_API_WHATSAPP}/send/${TOKEN_WHATSAPP}`, {celular: data.celular, nome: data.nome, message: mensagem}).then((response)=>{
				resolve(response.data);
			}).catch((erro)=>{
				reject(erro);
			});
		});
	}
}