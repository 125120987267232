import React, { useState, useEffect } from "react";
import { REQUEST_URL } from "../config/urls";
import { FaSpinner } from "react-icons/fa";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, FormGroup, Col, Row, Label, Form } from 'reactstrap';
import axios from "axios";

import TelegramLog from '../services/telegram';

export default function ModalInstituicao (props) {
 
  const [instituicao, setInstituicao] = useState({ instituicao: "", sigla: "" });
  const [adicionarInstituicao, setAdicionarInstituicao] = useState(false);

  useEffect (() => {
    if (adicionarInstituicao) {
      axios.post(`${REQUEST_URL}/instituicoes/nova-instituicao`, instituicao).then((response) => {
        props.close({ tipo: "sucesso", mensagem: "IES cadastrada com sucesso!" });
      }).catch((erro) => {
        new TelegramLog().novo("ERRO", [JSON.stringify(erro), "Erro ao cadastrar a IES. Tente novamente!", `usuarioLogado: ${JSON.parse(sessionStorage.getItem("usuarioLogado"))[0].Login}`]);
      });
    }
  }, [adicionarInstituicao]);

  function handleChangeInstituicao (event) {
    const { value, name } = event.target;
    setInstituicao({ ...instituicao, [name]: value });
  }
  
  function handleSubmit (event) {
    event.preventDefault();
    setAdicionarInstituicao(true);
  }

  return (
    <React.Fragment>
      <ModalHeader toggle={props.close}>Adicionar nova IES</ModalHeader>
      <Form onSubmit={handleSubmit}>
        <ModalBody>
          <Row>
            <Col sm="12" md="8">
              <FormGroup>
                <Label for="instituicao"><span className="perguntaObrigatoria">*</span>Instituição</Label>
                <Input type="text" name="instituicao" id="instituicao" value={instituicao.instituicao} onChange={handleChangeInstituicao} required/>
              </FormGroup>
            </Col>
            <Col sm="12" md="4">
              <FormGroup>
                <Label for="sigla"><span className="perguntaObrigatoria">*</span>Sigla</Label>
                <Input type="text" name="sigla" id="sigla" value={instituicao.sigla} onChange={handleChangeInstituicao} required/>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="success" disabled={adicionarInstituicao}>
            { 
              adicionarInstituicao ?
                <span style={{ display: "flex", alignItems: "center" }}><FaSpinner style={{ marginRight: 5 }} className="rodarIcone"/> Carregando</span>
              : "Adicionar"
            }
          </Button>
        </ModalFooter>
      </Form>
    </React.Fragment>
  );

}