import React, { useEffect, useState } from "react";
import { Jumbotron, Row, Col, Card, CardBody, Input, Table } from "reactstrap";
import axios from "axios";
import { REQUEST_URL } from "../../config/urls";

import NavBar from '../../components/NavBar';
import ModalEditarClientes from "../../components/ModalEditarClientes";
import './main.css';
import { ToastContainer, toast } from "react-toastify";

function Editar (props) {
  const [usuarioLogado, setUsuarioLogado] = useState("");
  const [filtroCliente, setFiltroCliente] = useState({ id: "", descricao: "", status: "", cidade: "", curso: "", faculdade: "" });
  const [numContrato, setNumContrato] = useState(null);
  const [clientes, setClientes] = useState([]);
  const [clienteSelecionado, setClienteSelecionado] = useState(null);
  const [showModalEditarCliente, setShowModalEditarCliente] = useState(false);
  const [parceiros, setParceiros] = useState([]);
  const [vendedores, setVendedores] = useState([]);

  useEffect (() => {
    let usuario = JSON.parse(sessionStorage.getItem("usuarioLogado"))[0];

    if (usuario.Funcao === "VENDEDOR" || usuario.Nivel === "RESTRITO") {
      props.history.push({ pathname: "/mapa" });
		} else {
      setUsuarioLogado(usuario);
    }

    axios.get(`${REQUEST_URL}/agencias`).then((response) => {
      let aux = [];
      response.data.map((a) => {
        aux.push({ value: a.IdAgencia, label: a.NomeAgencia });
      });
      setParceiros(aux);
    }).catch(() => {
      toast.error("Falha ao obter os parceiros. Tente novamente!");
    });

    axios.get(`${REQUEST_URL}/vendedores`).then((response) => {
      let aux = [];
      response.data.map((v) => {
        aux.push({ value: v.idUsuario, label:v.Nome });
      });
      setVendedores(aux);
    }).catch(() => {
      toast.error("Falha ao obter os parceiros. Tente novamente!");
    });
  }, []);

  useEffect (() => {
    axios.post(`${REQUEST_URL}/clientes_vendas`, { vendedor: usuarioLogado.idUsuario, id: filtroCliente.id, nome: filtroCliente.descricao, cidade: filtroCliente.cidade, curso: filtroCliente.curso, faculdade: filtroCliente.faculdade, nivel: usuarioLogado.Nivel }, {headers: {"Authorization": sessionStorage.getItem("token")}}).then((response) => {
      let clientes = response.data.map((cliente) => { return {id: cliente.idCliente, descricao: cliente.Descricao, status: cliente.Status, cidade: cliente.Cidade, curso: cliente.curso, faculdade: cliente.SiglaInst, cliente: cliente}});
      setClientes(clientes);
    });
  }, [filtroCliente]);

  useEffect (() => {
    if (numContrato) {
      axios.get(`${REQUEST_URL}/clientes/findByContract/${numContrato}`, {headers: {"Authorization": sessionStorage.getItem("token")}}).then((response) => {
        let clientes = response.data.map((cliente) => { return {id: cliente.idCliente, descricao: cliente.Descricao, status: cliente.Status, cidade: cliente.Cidade, curso: cliente.curso, faculdade: cliente.SiglaInst, cliente: cliente}});
        setClientes(clientes);
      }).catch(() => {
        setClientes([]);
      });
    }
  }, [numContrato]);

  function handleChangeFiltroCliente (event) {
    const { name, value } = event.target;
    setFiltroCliente({...filtroCliente, [name]: value});
  }

  function handleChangeNumContrato (event) {
    const { value } = event.target;
    setNumContrato(value);
  }

  function handleClickCliente (c) {
    setClienteSelecionado(c);
    setShowModalEditarCliente(true);
  }

  function handleCloseShowModalEditarCliente (resposta) {
    setShowModalEditarCliente(false);
    if (resposta === "sucesso") {
      toast.success("Cliente alterado com sucesso!");
      setFiltroCliente({...filtroCliente});
    } else if (resposta === "erro") {
      toast.error("Erro ao alterar os dados do cliente. Tente novamente!");
      setFiltroCliente({...filtroCliente});
    }
  }

  return (
    <React.Fragment>
      <NavBar className="no-print" nome={usuarioLogado.Nome} funcao={usuarioLogado.Funcao} />
      <ToastContainer position="top-right" autoClose={5000} newestOnTop={false} closeOnClick rtl={false} pauseOnVisibilityChange={false} draggable={false}/>
      <Row style={{ margin: 0 }}>
        <Col style={{ marginTop: 20 }}>
          <Card style={{ border: "none", borderRadius: 0, margin: 10, boxShadow: "0 2px 5px 0 rgba(0,0,0,.26)" }}>
            <CardBody>
              <Row>
                <Col sm="12">
                  <Jumbotron style={{ minHeight: 600, padding: "2rem 1rem", marginBottom: 0 }} className="noselect">
                    <div id="header-orc1"><h3>SELECIONE O CLIENTE</h3></div>
                    <div className="table-responsive" style={{ border: "1px solid #6c757d", borderRadius: 3 }}>
                      Filtros:
                      <Row style={{ margin: 0, padding: 5 }}>
                        <Col lg="2" md="4" sm="6" xs="6">
                          <Input placeholder="ID" name="id" onChange={handleChangeFiltroCliente} value={filtroCliente.id} />
                        </Col>
                        <Col lg="2" md="4" sm="6" xs="6">
                          <Input placeholder="Descrição" name="descricao" onChange={handleChangeFiltroCliente} value={filtroCliente.descricao} />
                        </Col>
                        <Col lg="2" md="4" sm="6" xs="6">
                          <Input placeholder="Cidade" name="cidade" onChange={handleChangeFiltroCliente} value={filtroCliente.cidade} />
                        </Col>
                        <Col lg="2" md="4" sm="6" xs="6">
                          <Input placeholder="Curso" name="curso" onChange={handleChangeFiltroCliente} value={filtroCliente.curso} />
                        </Col>
                        <Col lg="2" md="4" sm="6" xs="6">
                          <Input placeholder="Faculdade" name="faculdade" onChange={handleChangeFiltroCliente} value={filtroCliente.faculdade} />
                        </Col>
                        <Col lg="2" md="4" sm="6" xs="6">
                          <Input placeholder="Número do contrato" name="numContrato" onChange={handleChangeNumContrato} value={numContrato} />
                        </Col>
                      </Row>
                    </div>
                    <div style={{ height: 450, overflowY: "auto", width: "100%" }} className="lista-orcamento">
                      <Table className="table-bordered table-sm">
                        <thead>
                          <tr style={{ width: "100%" }}>
                            <th style={{ width: "5%", whiteSpace: "nowrap", textOverflow: "ellipsis" }}> Código </th>
                            <th style={{ width: "25%", whiteSpace: "nowrap", textOverflow: "ellipsis" }}> Descrição </th>
                            <th style={{ width: "25%", whiteSpace: "nowrap", textOverflow: "ellipsis" }}> Status </th>
                            <th style={{ width: "20%", whiteSpace: "nowrap", textOverflow: "ellipsis" }}> Cidade </th>
                            <th style={{ width: "25%", whiteSpace: "nowrap", textOverflow: "ellipsis" }}> Curso </th>
                            <th style={{ width: "25%", whiteSpace: "nowrap", textOverflow: "ellipsis" }}> Faculdade </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            clientes.map((c, i) => (
                              <tr key={i} onClick={() => handleClickCliente(c)} style={clienteSelecionado === c ? {cursor: "pointer", backgroundColor: "rgba(115, 219, 255, .45)", width: "100%"} : {cursor: "pointer", width: "100%"}}>
                                <td style={{ width: "5%", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{c.id}</td>
                                <td style={{ width: "25%", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{c.descricao}</td>
                                <td style={{ width: "25%", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{c.status}</td>
                                <td style={{ width: "25%", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{c.cidade}</td>
                                <td style={{ width: "25%", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{c.curso}</td>
                                <td style={{ width: "25%", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{c.faculdade}</td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </Table>
                    </div>
                  </Jumbotron>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {
        showModalEditarCliente ?
          <ModalEditarClientes parceiros={parceiros} vendedores={vendedores} cliente={clienteSelecionado.cliente} close={(resposta) => handleCloseShowModalEditarCliente(resposta)}/>
        : null
      }
    </React.Fragment>
  )
};

export default Editar; 
