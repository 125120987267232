import ApolloClient from 'apollo-boost';
import {URL_ALPHAE_GRAPHQL} from '../config/urls';

export default new ApolloClient({
  uri: URL_ALPHAE_GRAPHQL,
  request: (operation) => {
    const token = sessionStorage.getItem('tokenAlphaE');
    operation.setContext({
      headers: {
        'X-Token': token
      }
    })
  }
});