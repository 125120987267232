import React from 'react';
import { Input, Row, Col, Card, CardHeader, CardBody, CardTitle, Table, Button } from 'reactstrap';
import NavBar from '../components/NavBar';
import axios from 'axios';
import {REQUEST_URL, WEBHOOK_B24} from '../config/urls.js';

class Clientes extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      usuarioLogado:{},
      deals:[],
      fields:"",
      stageList:""
    };
  }

  componentDidMount(){
    delete axios.defaults.headers.common["Authorization"];
    if(sessionStorage.getItem('usuarioLogado')){
      this.setState({usuarioLogado:JSON.parse(sessionStorage.getItem('usuarioLogado'))[0]},()=>{
        this.buscarDeals();
      });
    }
    axios.post(WEBHOOK_B24+'/crm.status.list').then((response)=>{
      this.setState({stageList:response.data.result});
    }).catch((e) => {console.log(e)});
    axios.post(WEBHOOK_B24+'/crm.deal.fields',{
          select: ["UF_*","*",],
    }).then((response)=>{    
      this.setState({fields:response.data.result});
    }).catch((e) => {console.log(e)});
  }
  buscarDeals(chave){
    delete axios.defaults.headers.common["Authorization"];
    let payload = {
      order: { "BEGINDATE": "DESC" },
      select: ["UF_*","*"]
    }
    if(this.state.usuarioLogado.Nivel === "RESTRITO" && this.state.usuarioLogado.Funcao === "VENDEDOR"){
      payload.filter = {"ASSIGNED_BY_ID":this.state.usuarioLogado.idBitrix}
    }
    if(chave)payload.filter = { "ID": chave };
    axios.post(WEBHOOK_B24+'/crm.deal.list',payload).then(async (response)=>{
      let deals = response.data.result;
      this.setState({deals});
    });
  }
  getFieldLabel(field,id){
    if(this.state.fields){
      let options = this.state.fields[field].items;
      let item =  options.filter((option)=>{return option.ID === id})[0];
      return item ? item.VALUE: id;
    }
    return "";
  }
  getStage(stageId){
    if(this.state.stageList){
      let item = this.state.stageList.filter((option)=>{return option.STATUS_ID === stageId})[0];
      return item ? item.NAME : stageId;
    }
    return "";
  }
  verificarClienteNaTabela(idbitrix){
    axios.get(REQUEST_URL+'/cliente/idbitrix/'+idbitrix, {headers: {"Authorization": sessionStorage.getItem("token")}}).then((response)=>{
      let deals = this.state.deals;
      for(let i=0;i<deals.length;i++){
        if(idbitrix === deals[i].ID){
          deals[i].clienteNaTabela = response.data;
          break;
        }
      }
      this.setState({deals});
    });
  }
  gotoCadastro(deal){
    this.props.history.push({
             pathname:"/cadastro",
             state:{
               negocio:deal,
               editar: true
             }
            });
  }
  render(){
    return (
    <div>
      <NavBar nome={this.state.usuarioLogado.Nome} funcao={this.state.usuarioLogado.Funcao}/>
      <Row style={{margin:"0px 0px 0px 0px",}}>
        <Col id="container-clientes" style={{marginTop:20}}>
          <Card style={{border:"none",borderRadius:0, boxShadow:"0 2px 5px 0 rgba(0,0,0,.26)"}}>
            <CardHeader>
              <CardTitle>
                <div>Clientes No Bitrix</div>
                <div>
                  <form style={{display:"flex",float:"right"}} onSubmit={(e)=>{e.preventDefault();this.buscarDeals(this.state.negocioBusca)}}>
                    <Input value={this.state.negocioBusca} onChange={(e)=>{this.setState({negocioBusca:e.target.value});}} placeholder="Negocio" style={{width:200,float:"right"}}></Input>
                    <Button  type="submit" style={{float:"right"}}>Buscar</Button>
                  </form>
                </div>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <div className="table-responsive">
                <Table className="table-condensad">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Negócio</th>
                    <th>Fase</th>
                    <th>Valor</th>
                    <th>Criado</th>
                    <th>Canal de Entrada</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.deals.map((deal)=>(
                      <tr key={deal.ID}>
                        <td>{deal.ID}</td>
                        <td>{deal.TITLE}</td>
                        <td>{this.getStage(deal.STAGE_ID)}</td>
                        <td>R$: {deal.OPPORTUNITY ?deal.OPPORTUNITY : '00.00'}</td>
                        <td>{new Date(deal.BEGINDATE).toLocaleString()}</td>
                        <td>{this.getFieldLabel('UF_CRM_5B4F6F99E2A9E',deal.UF_CRM_5B4F6F99E2A9E)}</td>
                        <td>
                          {
                            deal.clienteNaTabela ?
                              deal.clienteNaTabela.length>0 ?
                                <Button color="success">
                                  <i className="fas fa-check"></i>
                                </Button>
                              :
                              <Button color="danger">
                                <i className="fas fa-times"></i>
                              </Button>
                            :
                            <Button onClick={this.verificarClienteNaTabela.bind(this,deal.ID)} color="warning">
                              <i className="fas fa-question"></i>
                            </Button>
                          }
                          {' '}
                          <Button onClick={this.gotoCadastro.bind(this, deal)} color="primary">
                            <i className="fas fa-edit"></i>
                          </Button>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
    )
  }
}

export default Clientes;
