import React from 'react';

import axios from 'axios';
import extenso from 'extenso';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import InputMask from 'react-input-mask';
import { ToastContainer, toast } from 'react-toastify';
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Jumbotron,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from 'reactstrap';

import { getPropostaImage } from '../../actions/dataUrlImages';
import ModalAdicionarItem from '../../components/ModalAdicionarItem';
import NavBar from '../../components/NavBar';
import NavegationBlocker from '../../components/NavegationBlocker';
import {
  REQUEST_URL,
  URL_AGENCIA_API,
  URL_CONTRATO_API,
} from '../../config/urls';
import TelegramLog from '../../services/telegram';
import Contrato from '../Contrato';
import './styles.css';

export default class Kits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orcamento: this.props.location.state.orcamento,
      statusOrcamento: '',
      kits: [
        {
          id: 'K_' + new Date().getTime(),
          nome: 'kit 1',
          itens: [],
          qtd: 1,
          preco: 1,
          ids: null,
        },
      ],
      itensSelecionados: [],
      meusKits: [],
      combos: [],
      brindes: [],
      kitsDeletados: [],
      alerta: false,
      kitSelecionado: null,
      usuarioLogado: this.props.location.state.usuarioLogado,
      darFocoAEsteCampo: null,
      digitouUmValor: false,
      descricao: [],
      bloqueado: false,
      botaoPlus: true,
      botaoValidarKit: false,
      atualizarContatosOrcamento: false,
      contato: {
        idContato: '',
        Nome: '',
        email: '',
        Fixo: '',
        Celular: '',
        outroTelefone: '',
        ContatoPrinc: false,
      },
      mascaraOutroTelefone: 'celular',
      contrato: {
        numero: '',
        status: '',
        emailComissao: '',
        usuario: '',
        dono: '',
        tipoContrato: '',
        telefone: '',
        dataFormatura: '',
        dataEntrega: '',
        dataInicio: '',
        dataChegada: '',
        anoCorrente: '',
        // idClienteMetrics: "",
        numOrcamento: '',
        idClienteMapa: '',
        contratante: '',
        statusCronograma: '',
        pagamentoIndividual: '',
        sistemaPagto: false,
        dataMaximaPagto: null,
      },
      numContrato: '',
      descontoComissao: 0,
      tipoPessoa: '',
      validators: {
        temUmPrincipal: 'none',
        infoContatoPrincipal: 'none',
        datasPreenchidas: 'none',
      },
      nomeKitSelecionado: '',
      showModalAddItem: false,
      carregando: false,
      contatosOrcamento: [],
      showModalConfirmar: false,
      showBotoes: false,
      showBotaoSalvarOrcamento: false,
      showBotaoGerarAcesso: false,
      showModalSugerirPreco: false,
      showModalProposta: false,
      showModalContrato: false,
      showModalContratoFinal: false,
      showModalContato: false,
      showModalDeleteItem: false,
    };
  }

  async componentDidMount() {
    axios.defaults.headers.common = {
      Authorization: sessionStorage.getItem('token'),
    };
    let orcamento = this.state.orcamento;
    await this.asyncSetState({ loading: true });
    await axios
      .get(
        `${REQUEST_URL}/cliente/${
          orcamento.cliente.codCliente === undefined
            ? orcamento.cliente.idCliente
            : orcamento.cliente.codCliente
        }/all`
      )
      .then((response) => {
        orcamento.cliente.contatos = response.data;
        this.setState({ orcamento });
      });
    let combos = await this.montarCombos(
        this.state.orcamento.itens,
        'contratado'
      ),
      brindes = await this.montarCombos(this.state.orcamento.itens, 'brinde'),
      kits = await this.buscarKitsSalvos(this.state.orcamento.id);
    await axios
      .get(`${REQUEST_URL}/orcamento/${orcamento.id}/status-orcamento`)
      .then((data) => {
        let status = data.data ? data.data.status : this.state.orcamento.status;
        this.setState({ statusOrcamento: status });
      })
      .catch((erro) => {
        toast.error('Erro ao consultar o status do orçamento');
      });
    combos.forEach((combo, i) => {
      if (!this.checarSeOComboEhUmKit(kits, combo)) {
        if (combos.length === 0 || combos.length === 1) {
          // do nothing
        } else if (i === combos.length - 1) {
          let aux = combos[i];
          combos[i] = combos[i - 1];
          combos[i - 1] = aux;
        } else {
          let aux = combos[i];
          combos[i] = combos[i + 1];
          combos[i + 1] = aux;
        }
      } else {
        combos[i].precoPedidoExtra = kits.find((kit) => {
          return kit.nome === combos[i].nome;
        }).precoPedidoExtra;
      }
    });
    let usuarioLogado = await this.state.usuarioLogado;
    let alerta = kits.length > 0 ? false : true;
    this.setState(
      { combos, kits, alerta, usuarioLogado, loading: false, brindes },
      () => {
        if (kits.length > 0) {
          kits.forEach((kit, i) => {
            if (document.querySelector('#cardColor' + i) !== null) {
              this.colorirCardKitMontado('green', 'green', i);
            }
          });
        }
        this.setState({ showBotoes: true });
      }
    );
  }

  async componentDidUpdate() {
    if (this.state.atualizarContatosOrcamento) {
      await axios
        .get(REQUEST_URL + `/cliente/${this.getCodCliente()}/all`)
        .then((contatos) => {
          this.setState({
            atualizarContatosOrcamento: false,
            contatosOrcamento: contatos.data,
          });
        });
    }
  }

  colorirCardKitMontado(cor, corBorda, index) {
    let el = document.querySelector(`#cardColor${index}`);
    let carHeader = el.querySelector('.car-header');
    let carBody = el.querySelector('.car-body');
    let carFooter = el.querySelector('.car-Footer');

    el.style.backgroundColor = cor;
    el.style.border = `1px solid ${corBorda}`;

    if (carHeader) {
      carHeader.style.backgroundColor = cor;
    }

    if (carBody) {
      carBody.style.backgroundColor = cor;
    }

    if (carFooter) {
      carFooter.style.backgroundColor = cor;
    }
  }

  getCodCliente() {
    return this.state.orcamento.cliente.codCliente !== undefined
      ? this.state.orcamento.cliente.codCliente
      : this.state.orcamento.cliente.idCliente;
  }

  getCodVendedor() {
    return this.state.orcamento.cliente.codVendedor !== undefined
      ? this.state.orcamento.cliente.codVendedor
      : this.state.orcamento.cliente.Vendedor;
  }

  checarSeOComboEhUmKit(kits, combo) {
    let tem = false;
    if (combo.tipo !== 'brinde') {
      if (kits.length > 0) {
        kits.forEach((kit) => {
          if (kit.nome === combo.nome) {
            tem = true;
          }
        });
      }
    }
    return tem;
  }

  buscarKitsSalvos(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${REQUEST_URL}/orcamentos/kit?orcamento=${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((erro) => {
          reject(erro);
        });
    });
  }

  criarDescricao(descricao, item) {
    if (descricao.indexOf('sem acabamentos') === -1) {
      if (descricao.length !== 0) {
        descricao += ' / ';
      } else {
        if (item.nome !== undefined) {
          descricao += item.nome;
        }
      }
      if (item.acabamentos !== undefined) {
        if (item.acabamentos.length > 0) {
          descricao += ' com ';
          item.acabamentos.forEach((acabamento) => {
            descricao += `${acabamento.DescProoduto} + `;
          });
          descricao = descricao.substr(0, descricao.length - 3);
        } else {
          descricao += ' sem acabamentos';
        }
      }
    }
    return descricao;
  }

  aglomerarIds(descricao, item) {
    return descricao + item.id + ';';
  }

  montarCombos(itens, tipo) {
    let embalagens = [],
      convites = [],
      combos = [],
      dataItem = {
        Miolo: { ids: '', descricao: '', preco: 0, qtd: 0 },
        Capa: { ids: '', descricao: '', preco: 0, qtd: 0 },
        Simples: { ids: '', descricao: '', preco: 0, qtd: 0 },
      },
      dataCombo = {
        Simples: {
          ids: '',
          descricao: '',
          convite: 'Simples',
          nome: 'Simples',
          data: ['Luva', 'Envelope'],
        },
        Semi: {
          ids: '',
          descricao: '',
          convite: 'Semiluxo',
          nome: 'Semi Luxo',
          data: ['Luva', 'Envelope'],
        },
        Luxo: {
          ids: '',
          descricao: '',
          convite: 'Luxo',
          nome: 'Luxo',
          data: ['Luva', 'Envelope', 'Caixa'],
        },
      };

    itens.forEach((item) => {
      let auxQtd = tipo === 'brinde' ? item.qtdBrinde : item.qtd;

      if (auxQtd > 0) {
        let encontrouConvite = false;
        this.criarDescricao(item.acabamentos, item.nome);

        for (let key in dataItem) {
          let itemStore = dataItem[key];

          if (
            item.nome.includes(key) &&
            !(
              item.nome.includes('EMBALAGEM') || item.nome.includes('Embalagem')
            )
          ) {
            itemStore.qtd += auxQtd;
            itemStore.ids += `${item.id};`;
            itemStore.descricao = this.criarDescricao(
              itemStore.descricao,
              item
            );
            itemStore.preco += item.precoFinal;
            encontrouConvite = true;
          }
          dataItem[key] = itemStore;
        }

        if (!encontrouConvite) {
          let encontrouDataCombo = false;
          for (let key in dataCombo) {
            if (
              (item.nome.includes(key) ||
                (key === 'Luxo' && item.nome.includes('Caixa'))) &&
              !encontrouDataCombo
            ) {
              let itemStore = dataCombo[key],
                encontrouTipo = false;

              encontrouDataCombo = true;

              itemStore.data.forEach((itemStoreData) => {
                if (item.nome.includes(itemStoreData)) {
                  let regExp = new RegExp(
                    `${itemStoreData}|${itemStore.nome}/g`
                  );

                  embalagens.push({
                    tipo: itemStoreData,
                    convite: itemStore.convite,
                    detalhes: item.nome.replace(regExp, ''),
                    qtd: auxQtd,
                    preco: item.precoFinal,
                  });

                  itemStore.ids = this.aglomerarIds(itemStore.ids, item);
                  itemStore.descricao = this.criarDescricao(
                    itemStore.descricao,
                    item
                  );
                  encontrouTipo = true;
                }
              });

              if (!encontrouTipo) {
                let novoItem = item;

                novoItem.descricao = this.criarDescricao('', item);
                combos.push(novoItem);
              }

              encontrouConvite = true;
              dataCombo[key] = itemStore;
            }
          }
        }

        if (!encontrouConvite) {
          let novoItem = { ...item };

          novoItem.descricao = this.criarDescricao('', item);
          novoItem.qtd = auxQtd;

          combos.push(novoItem);
        }
      }
    });

    if (dataItem.Miolo.qtd > dataItem.Capa.qtd) {
      let qtdConviteSemi = dataItem.Miolo.qtd - dataItem.Capa.qtd,
        qtdConviteLuxo = dataItem.Capa.qtd;

      if (qtdConviteLuxo > 0) {
        let preco =
          (dataItem.Miolo.preco / dataItem.Miolo.qtd) * qtdConviteLuxo +
          (dataItem.Capa.preco / dataItem.Capa.qtd) * qtdConviteLuxo;

        convites.push({
          tipo: 'Luxo',
          preco: preco,
          qtd: qtdConviteLuxo,
          id: dataItem.Miolo.ids + dataItem.Capa.ids + dataCombo.Luxo.ids,
          descricao:
            dataItem.Capa.descricao +
            ' / ' +
            dataItem.Miolo.descricao +
            ' / ' +
            dataCombo.Luxo.descricao,
        });
      }
      if (qtdConviteSemi > 0) {
        let preco =
          (dataItem.Miolo.preco / dataItem.Miolo.qtd) * qtdConviteSemi;

        convites.push({
          tipo: 'Semiluxo',
          preco: preco,
          qtd: qtdConviteSemi,
          id: dataItem.Miolo.ids + dataCombo.Semi.ids,
          descricao:
            dataItem.Miolo.descricao + ' / ' + dataCombo.Semi.descricao,
        });
      }
    } else if (dataItem.Capa.qtd >= dataItem.Miolo.qtd) {
      let qtdConviteLuxo = dataItem.Capa.qtd;

      if (qtdConviteLuxo > 0) {
        let preco =
          (dataItem.Miolo.preco / dataItem.Miolo.qtd) * qtdConviteLuxo +
          (dataItem.Capa.preco / dataItem.Capa.qtd) * qtdConviteLuxo;

        convites.push({
          tipo: 'Luxo',
          preco: preco,
          qtd: qtdConviteLuxo,
          id: dataItem.Capa.ids + dataItem.Miolo.ids + dataCombo.Luxo.ids,
          descricao:
            dataItem.Capa.descricao +
            ' / ' +
            dataItem.Miolo.descricao +
            ' / ' +
            dataCombo.Luxo.descricao,
        });
      }
    }

    if (dataItem.Simples.qtd > 0) {
      convites.push({
        tipo: 'Simples',
        preco: dataItem.Simples.preco,
        qtd: dataItem.Simples.qtd,
        id: dataItem.Simples.ids + dataCombo.Simples.ids,
        descricao:
          dataItem.Simples.descricao + ' / ' + dataCombo.Simples.descricao,
      });
    }

    convites.forEach((convite) => {
      let embalagensDoConvite = embalagens.filter((e) => {
          return e.convite === convite.tipo;
        }),
        qtdEmbalagem = 0;

      embalagensDoConvite.forEach((e) => {
        let preco = (convite.preco / convite.qtd) * e.qtd + e.preco;

        qtdEmbalagem += e.qtd;
        combos.push({
          nome: `Convite ${convite.tipo} com ${e.tipo}`,
          precoFinal: preco,
          precoFinalUni: preco / e.qtd,
          qtd: e.qtd,
          descricao: convite.descricao,
          id: convite.id,
          descricao: convite.descricao,
        });
      });

      if (qtdEmbalagem < convite.qtd && convite.tipo === 'Simples') {
        let preco =
          (convite.preco / convite.qtd) * (convite.qtd - qtdEmbalagem);

        combos.push({
          nome: `Convite ${convite.tipo} sem Embalagem`,
          precoFinal: preco,
          precoFinalUni: preco / (convite.qtd - qtdEmbalagem),
          qtd: convite.qtd - qtdEmbalagem,
          id: convite.id,
          descricao: convite.descricao,
        });
      }
    });

    return combos;
  }

  selectItem(item) {
    let itensSelecionados = Object.assign([], this.state.itensSelecionados);
    if (!itensSelecionados.includes(item)) {
      itensSelecionados.push(item);
    } else {
      itensSelecionados = itensSelecionados.filter((i) => {
        return i !== item;
      });
    }
    this.setState({ itensSelecionados });
  }

  kitTemEsseItem(kit, item) {
    if (kit && item) {
      let existe = kit.itens.filter((i) => {
        return i.nome === item.nome;
      });
      return existe.length;
    } else {
      return false;
    }
  }

  addNewKit() {
    let kits = Object.assign([], this.state.kits);
    kits.push({
      nome: 'novo kit',
      id: 'K_' + new Date().getTime(),
      descricao: '',
      itens: [],
      qtd: 0,
      tipo: 'kit',
      preco: 0,
    });
    this.setState({
      kits,
      botaoPlus: false,
      botaoValidarKit: true,
      showBotoes: true,
    });
  }

  removeKit(pos) {
    let kits = Object.assign([], this.state.kits);
    let kitsDeletados = Object.assign([], this.state.kitsDeletados);
    for (let i = 0; i < kits.length; i++) {
      if (kits[i] !== undefined) {
        if (kits[i].tipo === 'avulso') {
          kitsDeletados.push(kits[i]);
          delete kits[i];
        }
      }
    }
    if (pos !== -1) {
      kitsDeletados.push(kits[pos]);
      delete kits[pos];
    }
    this.handleBotoesMontarKit('plus');
    this.setState({ kits, kitsDeletados, showBotoes: true });
  }

  inserirItemNoKit(idClasse) {
    let kits = Object.assign([], this.state.kits);
    let kitSelecionado = Object.assign({}, this.state.kitSelecionado);
    let itensSelecionados = Object.assign([], this.state.itensSelecionados);
    for (let i = 0; i < kits.length; i++) {
      if (kits[i]) {
        if (kitSelecionado.id === kits[i].id) {
          kits[i].ids = kits[i].itens.concat(
            itensSelecionados.map((i) => {
              return i.id;
            })
          );
          kits[i].descricao = kits[i].itens.concat(
            itensSelecionados.map((i) => {
              return i.descricao;
            })
          );
          kits[i].itens = kits[i].itens.concat(
            itensSelecionados.map((i, index) => {
              return {
                id: 'I_' + index + '_' + new Date().getTime(),
                nome: i.nome,
                qtd: 0,
                precoFinal: i.precoFinal,
                precoFinalUni: i.precoFinalUni,
                idPlano: i.id,
              };
            })
          );
          break;
        }
      }
    }
    this.setState({
      kits,
      kitSelecionado: null,
      itensSelecionados: [],
      showModalItens: false,
      showBotoes: true,
    });
  }

  changeKitNome(kit, idClasse, e) {
    this.handleControleDeFocoInputKits(false);
    let kits = Object.assign([], this.state.kits);
    for (let i = 0; i < kits.length; i++) {
      if (kit === kits[i]) {
        kits[i].nome = e.target.value;
        break;
      }
    }
    this.handleBotoesMontarKit('validarKit', idClasse);
    this.setState({ kits, showBotoes: true });
  }

  changeKitQtd(kit, idClasse, e) {
    this.handleControleDeFocoInputKits(false);
    let kits = Object.assign([], this.state.kits);
    for (let i = 0; i < kits.length; i++) {
      if (kit === kits[i]) {
        kits[i].qtd = e.target.value;
        break;
      }
    }
    this.handleBotoesMontarKit('validarKit', idClasse);
    this.setState({ kits, showBotoes: true });
  }

  changeKitPreco(kit, idClasse, e) {
    this.handleControleDeFocoInputKits(false);
    let kits = Object.assign([], this.state.kits);
    let ultimaLetra = e.target.value[e.target.value.length - 1];
    if (/^-?\d*\.?\d*$/.test(ultimaLetra) || !ultimaLetra) {
      for (let i = 0; i < kits.length; i++) {
        if (kit === kits[i]) {
          kits[i].preco = e.target.value;
          break;
        }
      }
      this.handleBotoesMontarKit('validarKit', idClasse);
      this.setState({ kits }, () => {
        let done = true,
          qtd = 0,
          combos = this.state.combos;
        combos.forEach((combo) => {
          if (combo.qtd - this.qtdNosKits(combo) > 0) {
            done = false;
          } else {
            qtd++;
          }
        });
        if (qtd === combos.length) {
          if (done) {
            if (kits[kits.length - 1].preco > 0) {
              let precoUltimoKit =
                kits[kits.length - 1].qtd * kits[kits.length - 1].preco;
              kits[kits.length - 1].preco =
                (this.state.orcamento.precoTotal -
                  (this.precoTotalKits() - precoUltimoKit)) /
                kits[kits.length - 1].qtd;
            } else {
              kits[kits.length - 1].preco =
                (this.state.orcamento.precoTotal - this.precoTotalKits()) /
                kits[kits.length - 1].qtd;
            }
          } else {
            kits[kits.length - 1].preco = 0;
          }
        }
        this.setState({ kits, showBotoes: true });
      });
    }
  }

  // Função que faz o foco permanecer no input que está sendo digitado o valor do item do kit
  handleControleDeFocoInputKits(valorDigitado) {
    this.setState({ digitouUmValor: valorDigitado });
  }

  changeKitItemQtd(kit, item, id, valorDigitado, idClasse, e) {
    let kits = Object.assign([], this.state.kits),
      combos = this.state.combos;
    if (id !== null) {
      this.setState({ darFocoAEsteCampo: id, showBotoes: true });
    }
    this.handleControleDeFocoInputKits(valorDigitado);
    for (let i = 0; i < kits.length; i++) {
      if (kit === kits[i]) {
        for (let k = 0; k < kits[i].itens.length; k++) {
          if (kits[i].itens[k] === item) {
            kits[i].itens[k].qtd = Number(e.target.value);
            kits[i].preco = 0;
            break;
          }
        }
      }
    }
    // Fazer com que os combos mudem de lugar, se necessário
    combos.forEach((combo, i) => {
      if (combo.qtd - this.qtdNosKits(combo) <= 0) {
        if (i > 0) {
          let j = i - 1;
          for (; j >= 0; j--) {
            if (combos[j].qtd - this.qtdNosKits(combos[j]) > 0) {
              let aux = combos[i];
              combos[i] = combos[j];
              combos[j] = aux;
            }
          }
        }
      }
    });
    this.handleBotoesMontarKit('validarKit', idClasse);
    this.setState({ kits }, () => {
      // Fazer com que a quantidade de produtos vendidos avulso seja zerada se não houver quantidade de produto disponível
      let kits = this.state.kits,
        combos = this.state.combos,
        kitsDeletados = Object.assign([], this.state.kitsDeletados);
      kits.forEach((kit, i) => {
        if (kit.preco === 0) {
          combos.forEach((combo) => {
            if (kit.nome === combo.nome) {
              kits[i].qtd = 0;
              kits[i].preco = 0;
              kitsDeletados.push(kits[i]);
              delete kits[i];
            }
          });
        } else {
          combos.forEach((combo) => {
            if (kit.nome === combo.nome) {
              kits[i].qtd = 0;
              kits[i].preco = 0;
            }
          });
        }
      });
      let possuiComboComQtd = false;
      combos.forEach((combo) => {
        if (combo.qtd - this.qtdNosKits(combo) > 0) {
          possuiComboComQtd = true;
        }
      });
      let passei = false;
      if (!possuiComboComQtd) {
        for (let i = kits.length - 1; i >= 0; i--) {
          if (kits[i] !== undefined && !passei && kits[i].tipo === 'kit') {
            passei = true;
            kits[i].preco =
              (this.state.orcamento.precoTotal -
                Number(this.precoTotalKits())) /
              Number(kits[i].qtd);
          }
        }
      }
      this.setState({ kits });
    });
  }

  qtdNosKits(item) {
    let qtdNosKits = 0,
      auxKits = [...this.state.kits];

    auxKits = auxKits.filter((kit) => kit !== undefined);

    auxKits.forEach((kit) => {
      if (kit.itens !== undefined) {
        kit.itens.forEach((i) => {
          if (i.nome === item.nome) {
            qtdNosKits += Number(i.qtd) * Number(kit.qtd);
          }
        });
      }
    });
    return qtdNosKits;
  }

  precoSugeridoKit(kit) {
    let precoTotalDosItens = 0;
    kit.itens.forEach((i) => {
      precoTotalDosItens += i.qtd * i.precoFinalUni;
    });
    return precoTotalDosItens;
  }

  precoTotalKits() {
    let kits = Object.assign([], this.state.kits);
    let precoKits = 0;
    kits.forEach((kit) => {
      precoKits += Number(kit.preco) * Number(kit.qtd);
    });
    return precoKits;
  }

  encontrarItemNoMeusKits(meusKits, item) {
    // Recebe um array de itens e um item, e retorna o item se ele existir dentro do array de itens
    let i = meusKits.filter((mkit) => {
      if (item.nome === mkit.nome) {
        return mkit;
      }
    });
    return i;
  }

  fazerASomaDasQuantidadesDeKits() {
    // Se o item for avulso significa que ele não foi criado como um kit (botão de +)
    let kits = Object.assign([], this.state.kits);
    let meusKits = [];
    // Pesquisa dentro dos itens da tabela
    kits.forEach((kit) => {
      // Se o item é avulso
      if (kit.tipo === 'avulso') {
        // Encontra o item no array de itens
        let i = this.encontrarItemNoMeusKits(meusKits, kit);
        // Se o item existir, então ele atualiza o preço e a quantidade
        if (i.length > 0) {
          i[0].preco += kit.preco;
          i[0].qtd += kit.qtd;
          // Senão ele adiciona um item novo
        } else {
          meusKits.push(kit);
        }
      } else {
        kit.itens.forEach((item) => {
          let i = this.encontrarItemNoMeusKits(meusKits, item);
          if (i.length > 0) {
            i[0].preco += kit.preco;
            i[0].qtd += kit.qtd * item.qtd;
          } else {
            let novoItem = {
              nome: item.nome,
              preco: kit.preco,
              qtd: kit.qtd * item.qtd,
              tipo: 'avulso',
            };
            meusKits.push(novoItem);
          }
        });
      }
    });
    return meusKits;
  }

  existemItensInvalidos() {
    let temItensInvalidos = false,
      meusKits = this.fazerASomaDasQuantidadesDeKits(),
      combos = this.state.combos,
      kits = this.state.kits;
    // Pesquisa dentro dos combos se todos os produtos foram selecionados
    combos.forEach((combo) => {
      let i = this.encontrarItemNoMeusKits(meusKits, combo);
      if (combo.id) {
        if (i.length === 0) {
          temItensInvalidos = true;
        } else if (
          combo.nome === i[0].nome &&
          (combo.qtd !== i[0].qtd || i[0].preco <= 0)
        ) {
          temItensInvalidos = true;
        }
      }
    });

    kits.forEach((kit) => {
      if (
        kit.tipo !== 'brinde' &&
        kit.tipo !== 'itemFuturo' &&
        kit.preco <= 0 &&
        kit.nome !== undefined
      ) {
        temItensInvalidos = true;
      }
    });

    return temItensInvalidos;
  }

  passarDescricaoParaOsKits() {
    let combos = this.state.combos;
    let kits = this.state.kits;
    kits.forEach((kit) => {
      combos.forEach((combo) => {
        if (combo.nome === kit.nome) {
          kit.descricao = combo.descricao;
          kit.ids = combo.id;
        }
      });
    });
    this.setState({ kits });
  }

  encontrarBrindeNosKits(brinde) {
    let kits = this.state.kits,
      achou = false;
    kits.forEach((kit) => {
      if (
        kit.tipo === 'brinde' &&
        kit.nome === brinde.nome &&
        kit.qtd === brinde.qtd
      ) {
        achou = true;
      }
    });
    return achou;
  }

  passarBrindesParaKits() {
    let brindes = this.state.brindes,
      kits = [...this.state.kits];
    brindes.forEach((brinde) => {
      if (!this.encontrarBrindeNosKits(brinde)) {
        kits.push({
          nome: brinde.nome,
          id: 'B_' + new Date().getTime(),
          ids: brinde.id,
          descricao: brinde.descricao,
          itens: [],
          qtd: brinde.qtd,
          tipo: 'brinde',
          preco: 0,
        });
      }
    });
    this.setState({ kits });
  }

  async handleSaveItems() {
    this.setState({ showBotaoSalvarOrcamento: true });
    // Se o preço total dos kits for igual ao preco do orçamento realizado e não existem itens inválidos ele salva
    if (!this.existemItensInvalidos()) {
      if (
        this.formatReal(this.precoTotalKits()) ===
        this.formatReal(this.state.orcamento.precoTotal)
      ) {
        await this.passarBrindesParaKits();
        await this.passarDescricaoParaOsKits();
        await this.salvarKits();
        await this.salvarItensNovos();
        if (this.state.statusOrcamento === 'Negociacao') {
          await axios
            .post(`${REQUEST_URL}/orcamento/status-orcamento`, {
              orcamento: this.state.orcamento.id,
              status: 'Kits Gerados',
            })
            .catch((err) => {
              toast.error(
                'Não foi possível atualizar o status do orçamento (tabela orcamento_status)'
              );
            });
        }
        this.setState({ showBotoes: false, showBotaoSalvarOrcamento: false });
      } else {
        toast.error('Valor da venda não bate com o do orçamento!');
        this.setState({ showBotaoSalvarOrcamento: false });
      }
    } else {
      toast.error(
        'Existem produtos que possuem preço ou quantidade menor ou igual a 0!'
      );
      this.setState({ showBotaoSalvarOrcamento: false });
    }
  }

  handleGerarProposta() {
    var doc = new jsPDF();
    var imgPropostaData = getPropostaImage();
    doc.setFontSize(11);
    let lineHeight = 7;
    let marginLeft = 10;
    let marginRight = 20;
    let pageWidth = doc.internal.pageSize.getWidth();
    let pageHeight = doc.internal.pageSize.getHeight();
    let textOptions = {
      maxWidth: pageWidth - (marginLeft + marginRight),
      align: 'justify',
    };
    doc.setFont('times');
    doc.addImage(imgPropostaData, 'JPEG', 0, 0, pageWidth, pageHeight);
    let line = 10;

    line += lineHeight * 4;
    doc.setFontType('bold');
    doc.text(marginLeft, line, `Proposta nº ${this.state.orcamento.id}`);
    doc.setFontType('normal');
    doc.text(
      marginLeft + 125,
      line,
      `${new Date().toLocaleString('pt-br', {
        weekday: 'long',
        month: 'long',
        year: 'numeric',
        day: 'numeric',
      })}`
    );
    doc.text(
      marginLeft,
      (line += lineHeight),
      `À comissão de formatura do curso: ${this.state.orcamento.cliente.curso}`
    );
    doc.text(
      marginLeft,
      (line += lineHeight),
      `Instituição: ${this.state.orcamento.cliente.Instituicao}`
    );
    line += lineHeight;
    doc.text(
      marginLeft,
      (line += lineHeight),
      `Conforme solicitação, apresentamos abaixo nossa estimativa de preços conforme especificações e condições gerais.`,
      textOptions
    );
    line += lineHeight;
    doc.text(
      marginLeft,
      (line += lineHeight),
      `Arte: Projeto designer alpha (company)`
    );
    doc.text(
      marginLeft + 5,
      (line += lineHeight),
      `* Resposta do Briefing no site da empresa, onde será obtido um conjunto de respostas que traduzem da melhor forma os anseios e intenções da turma.`,
      {
        maxWidth: pageWidth - (marginLeft + marginRight + 15),
        align: 'justify',
      }
    );
    doc.text(
      marginLeft + 5,
      (line += lineHeight * 2),
      `* Pesquisa de imagens que abrangem a tendência.`
    );
    doc.text(
      marginLeft + 5,
      (line += lineHeight),
      `* Criação, editoração e diagramação.`
    );
    doc.text(
      marginLeft + 5,
      (line += lineHeight),
      `* Duas provas online no site da Alpha convites, para revisão, alterações e validações pela comissão de formatura.`
    );

    line += lineHeight;
    doc.text(
      marginLeft,
      (line += lineHeight),
      `Itens orçados nesta proposta:`,
      textOptions
    );

    line += lineHeight;
    let tablebody = [];
    this.state.orcamento.itens.map((item) => {
      if (item.qtd > 0 && item.visivelProposta) {
        tablebody.push([
          item.nome +
            ' ' +
            item.detalhes +
            ` ${
              item.acabamentos.length > 0
                ? 'C/ ' +
                  item.acabamentos
                    .map((a) => {
                      return a.DescProoduto;
                    })
                    .join(', ')
                : ' '
            }` +
            '\n',
          item.qtd,
        ]);
      }
    });
    let head;
    head = ['ITENS', 'QTD'];
    doc.autoTable({
      head: [head],
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
      },
      body: tablebody,
      startY: line,
      styles: { overflow: 'linebreak' },
      columnStyles: {
        0: {
          cellPadding: { top: 2, right: 8, bottom: 2, left: 2 },
          cellWidth: 100,
        },
      },
      theme: 'grid',
    });
    line = doc.autoTable.previous.finalY + 10;

    let tablebodybrinde = [];
    this.state.kits.map((kit) => {
      if (kit.tipo === 'brinde') {
        if (!this.state.orcamento.propostaSemValor) {
          tablebodybrinde.push([`${kit.nome}`, kit.qtd]);
        } else {
          tablebodybrinde.push([`${kit.nome}`, kit.qtd]);
        }
      }
    });
    let headbrinde = ['BRINDES', 'QTD'];
    if (tablebodybrinde.length > 0) {
      doc.autoTable({
        head: [headbrinde],
        headStyles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
        },
        body: tablebodybrinde,
        startY: line,
        styles: { overflow: 'linebreak' },
        columnStyles: {
          0: {
            cellPadding: { top: 2, right: 8, bottom: 2, left: 2 },
            cellWidth: 100,
          },
        },
        theme: 'grid',
      });
      line = doc.autoTable.previous.finalY + 10;
    }

    if (pageHeight < line + lineHeight + 30) {
      doc.addPage();
      doc.addImage(imgPropostaData, 'JPEG', 0, 0, pageWidth, pageHeight);
      line = 25;
    }

    doc.text(
      marginLeft,
      (line += lineHeight),
      `Kits avulsos e combos gerados:`,
      textOptions
    );

    if (pageHeight < line + lineHeight + 30) {
      doc.addPage();
      doc.addImage(imgPropostaData, 'JPEG', 0, 0, pageWidth, pageHeight);
      line = 25;
    }
    line += lineHeight;
    tablebody = [];
    this.state.kits.map((kit) => {
      if (kit.tipo !== 'brinde') {
        if (!this.state.orcamento.propostaSemValor) {
          tablebody.push([
            `${kit.nome}`,
            kit.qtd,
            `R$:${this.formatReal(kit.preco, -1)}`,
            `R$:${this.formatReal(kit.preco * kit.qtd, -1)}`,
          ]);
        } else {
          tablebody.push([`${kit.nome}`, kit.qtd]);
        }
      }
    });
    head = !this.state.orcamento.propostaSemValor
      ? ['ITENS', 'QTD', 'UN', 'TOTAL']
      : ['ITENS', 'QTD'];
    doc.autoTable({
      head: [head],
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
      },
      body: tablebody,
      startY: line,
      styles: { overflow: 'linebreak' },
      columnStyles: {
        0: {
          cellPadding: { top: 2, right: 8, bottom: 2, left: 2 },
          cellWidth: 100,
        },
      },
      theme: 'grid',
    });
    line = doc.autoTable.previous.finalY + 10;

    line += lineHeight;

    if (pageHeight < line + lineHeight + 30) {
      doc.addPage();
      doc.addImage(imgPropostaData, 'JPEG', 0, 0, pageWidth, pageHeight);
      line = 25;
    }

    let temCombo = false;
    this.state.kits.forEach((kit) => {
      if (kit.tipo === 'kit') {
        if (!temCombo) {
          doc.text(
            marginLeft,
            (line += lineHeight),
            `Descrição dos combos:`,
            textOptions
          );
          temCombo = true;
        }
        doc.text(
          marginLeft + 5,
          (line += lineHeight),
          `${kit.nome} é composto por:`,
          {
            maxWidth: pageWidth - (marginLeft + marginRight + 15),
            align: 'justify',
          }
        );
        let itensDoCombo = '';
        kit.itens.forEach((item) => {
          itensDoCombo += `${item.qtd} ${item.nome}; `;
        });
        doc.text(marginLeft + 10, (line += lineHeight), itensDoCombo, {
          maxWidth: pageWidth - (marginLeft + marginRight + 15),
          align: 'justify',
        });
        line += lineHeight;
      }
    });

    if (this.state.possuiFrete) {
      doc.text(
        marginLeft,
        (line += lineHeight),
        'Frete terreste por conta do fornecedor'
      );
    }
    if (!this.state.orcamento.propostaSemValor) {
      doc.text(
        marginLeft + 150,
        (line += lineHeight * 2),
        `TOTAL: R$:${this.formatReal(this.precoTotalKits(), 2)}`
      );
      let valorTotal = this.formatReal(this.precoTotalKits(), 2);
      doc.text(
        `(${extenso(valorTotal.toLocaleString('pt-BR'))})`,
        marginLeft + 183.5,
        (line += lineHeight),
        {
          maxWidth: pageWidth - (marginLeft + marginRight + 15),
          align: 'right',
        }
      );
    }
    doc.text(
      marginLeft + 113,
      (line += lineHeight),
      `Condições e forma de pagamento a combinar`
    );
    doc.text(
      marginLeft + 141.5,
      (line += lineHeight),
      `Proposta válida por 30 dias`
    );
    line += lineHeight;
    let observacao = [];
    observacao =
      this.state.orcamento.cliente.Obs !== undefined &&
      this.state.orcamento.cliente.Obs !== ''
        ? this.state.orcamento.cliente.Obs.split('\n')
        : '';
    if (observacao.length > 0 && observacao[0] !== '') {
      doc.text(marginLeft, (line += lineHeight), `Observação:`);
      let linha = '';
      observacao.forEach((linha) => {
        if (pageHeight < line + lineHeight + 10) {
          doc.addPage();
          doc.addImage(imgPropostaData, 'JPEG', 0, 0, pageWidth, pageHeight);
          line = 25;
        }
        doc.text(marginLeft, (line += lineHeight), linha, textOptions);
        if (linha.length > 80) line += lineHeight;
      });
    }

    doc.text(
      marginLeft,
      pageHeight - 10,
      `Representante: ${this.state.orcamento.NomeVendedor} `
    );

    doc.save(`proposta-${this.state.orcamento.id}.pdf`);
  }

  async salvarKits() {
    await this.asyncSetState({ loading: true });
    let kits = this.state.kits;
    kits.forEach((kit) => {
      if (kit.qtd > 0 || kit.id[0] === 'B' || kit.tipo === 'itemFuturo') {
        if (kit.id[0] === 'K' || kit.id[0] === 'B') {
          this.adicionarKit(kit).then(async (kitAdd) => {
            kit = kitAdd;
          });
        } else {
          this.editarKit(kit).then(async (kitEditado) => {
            kit = kitEditado;
          });
        }
      }
    });
    let kitsDeletados = Object.assign([], this.state.kitsDeletados);
    kitsDeletados.forEach(async (kitDeletado) => {
      if (kitDeletado.id[0] !== 'K' && kitDeletado.id[0] !== 'B') {
        await axios.delete(`${REQUEST_URL}/orcamentos/kit/${kitsDeletados.id}`);
      }
    });
    kitsDeletados = [];
    await this.asyncSetState({
      kits,
      kitsDeletados,
      loading: false,
      brindes: [],
    });
    if (kits.length > 0) {
      kits.forEach((kit, i) => {
        if (document.querySelector('#cardColor' + i) !== null) {
          document.querySelector('#cardColor' + i).style.backgroundColor =
            '#16a085';
        }
      });
    }
    toast.success('Kit(s) salvo(s) com sucesso!');
  }

  adicionarKit(kit) {
    return new Promise((resolve, reject) => {
      let kitSemItens = Object.assign({}, kit);
      delete kitSemItens.itens;
      delete kitSemItens.id;
      kitSemItens.orcamento = this.state.orcamento.id;
      axios
        .post(`${REQUEST_URL}/orcamento/kit`, kitSemItens)
        .then(async (response) => {
          let idkitAdicionado = response.data.insertId;
          kit.id = idkitAdicionado;
          for (let i = 0; i < kit.itens.length; i++) {
            let item = kit.itens[i];
            delete item.idPlano;
            item.kit = idkitAdicionado;
            if (item.id[0] === 'I') {
              let response = await axios.post(
                `${REQUEST_URL}/orcamento/kit/item`,
                item
              );
              let idItemAdicionado = response.data.insertId;
              item.id = idItemAdicionado;
              kit.itens[i] = item;
            } else {
              await axios.put(
                `${REQUEST_URL}/orcamento/kit/item/${item.id}`,
                item
              );
            }
          }
          resolve(kit);
        });
    });
  }

  verificarSeOKitEhValido(nome, direcao) {
    let kits = this.state.kits,
      valida = false;
    for (let i = 0; i < kits.length; i++) {
      if (kits[i].nome === nome) {
        if (direcao === 'cima') {
          if (kits[i - 1].qtd !== 0) {
            valida = true;
          }
        } else {
          if (kits[i + 1].qtd !== 0) {
            valida = true;
          }
        }
      }
    }
    return valida;
  }

  moverItem(item, direcao) {
    let combos = this.state.combos;
    let aux;
    for (let i = 0; i < combos.length; i++) {
      if (combos[i].nome === item.nome) {
        if (direcao === 'cima') {
          if (i !== 0) {
            if (combos[i - 1].qtd - this.qtdNosKits(combos[i - 1]) > 0) {
              aux = combos[i - 1];
              combos[i - 1] = combos[i];
              combos[i] = aux;
            }
          }
        } else {
          if (i !== combos.length - 1) {
            if (combos[i].qtd - this.qtdNosKits(combos[i]) > 0) {
              aux = combos[i + 1];
              combos[i + 1] = combos[i];
              combos[i] = aux;
            }
          }
        }
        break;
      }
    }

    this.setState({ combos });
  }

  editarKit(kit) {
    return new Promise((resolve, reject) => {
      let kitSemItens = Object.assign({}, kit);
      delete kitSemItens.itens;
      delete kitSemItens.id;
      kitSemItens.orcamento = this.state.orcamento.id;
      axios
        .put(`${REQUEST_URL}/orcamento/kit/${kit.id}`, kitSemItens)
        .then(async (response) => {
          let idkitEditado = response.data;
          for (let i = 0; i < kit.itens.length; i++) {
            let item = kit.itens[i];
            item.kit = kit.id;
            if (item.id[0] === 'I') {
              let response = await axios.post(
                `${REQUEST_URL}/orcamento/kit/item`,
                item
              );
              let idItemAdicionado = response.data.insertId;
              item.id = idItemAdicionado;
              kit.itens[i] = item;
            } else {
              await axios.put(
                `${REQUEST_URL}/orcamento/kit/item/${item.id}`,
                item
              );
            }
          }

          resolve(kit);
        });
    });
  }

  asyncSetState(state) {
    return new Promise((resolve, reject) => {
      this.setState(state, () => {
        resolve();
      });
    });
  }

  onChangePrecoAvulso(item, qtd, ultimoItem, ultimaQtd, e) {
    if (
      this.state.combos.length === 1 ||
      qtd !== 0 ||
      ultimoItem !== null ||
      ultimaQtd !== 0
    ) {
      this.handleControleDeFocoInputKits(false);
      let preco = e.target.value,
        i = this.encontrarItemNoMeusKits(this.state.kits, item),
        kits = Object.assign([], this.state.kits),
        kitsDeletados = Object.assign([], this.state.kitsDeletados),
        existe = false;
      if (preco == '' && i.length !== 0) {
        preco = 0;
      }
      if (
        preco.length >= 2 &&
        preco[0] == 0 &&
        (preco[1] !== null && preco[1] !== '.')
      ) {
        preco = preco.substr(1, preco.length - 1);
      }
      for (let i = 0; i < kits.length; i++) {
        let kit = kits[i];
        if (kit && item.nome === kit.nome) {
          existe = true;
          if (preco > 0) {
            let kitEditar = kits[i];
            kitEditar.preco = preco;
            kits[i] = kitEditar;
          } else {
            if (kits[i].id[0] !== 'K') {
              kitsDeletados.push(kits[i]);
            }
            delete kits[i];
          }
        }
      }
      if (!existe) {
        kits.push({
          nome: item.nome,
          id: 'K_' + new Date().getTime(),
          itens: [
            {
              id: 'I_' + 0 + '_' + new Date().getTime(),
              nome: item.nome,
              qtd: 1,
              precoFinal: item.precoFinal,
              precoFinalUni: item.precoFinalUni,
              idPlano: item.id,
            },
          ],
          qtd: qtd,
          tipo: 'avulso',
          preco: preco,
        });
      }
      this.setState({ kits, kitsDeletados, showBotoes: true }, () => {
        this.atualizarValorDoPedidoExtra(item);
        if (ultimoItem) {
          let precoTotalDosKits = this.precoTotalKits();
          if (precoTotalDosKits == 0) {
            precoTotalDosKits = this.state.orcamento.precoTotal;
          }
          let kitDoUltimoItem = this.getKitDoAvulso(ultimoItem);
          if (kitDoUltimoItem) {
            precoTotalDosKits -= kitDoUltimoItem.preco * kitDoUltimoItem.qtd;
            let ultimaQtd = kitDoUltimoItem.qtd,
              precoRestante =
                this.state.orcamento.precoTotal - precoTotalDosKits,
              precoUni = parseFloat(precoRestante / ultimaQtd),
              indice = 0;
            kits = this.state.kits;
            kits.forEach((kit) => {
              if (kit.tipo == 'avulso') {
                indice++;
              }
            });
            if (precoTotalDosKits === 0 || indice <= 1) {
              precoUni = 0;
            }
            this.onChangePrecoAvulso(
              ultimoItem,
              ultimaQtd > 0 ? ultimaQtd : 0,
              null,
              0,
              { target: { value: precoUni } }
            );
          } else {
            let precoRestante =
                this.state.orcamento.precoTotal - precoTotalDosKits,
              precoUni = parseFloat(precoRestante / ultimaQtd);
            this.onChangePrecoAvulso(
              ultimoItem,
              ultimaQtd > 0 ? ultimaQtd : 0,
              null,
              0,
              { target: { value: precoUni } }
            );
          }
        }
      });
    }
  }

  formatReal(n, num = 2) {
    if (n !== undefined) {
      if (num === -2) {
        return Number(n);
      } else if (num === -1) {
        let numero = Number(n);
        return numero.toFixed(2);
      }
      return Number(n.toFixed(num).toLocaleString('pt-BR'));
    }
  }

  atualizarValorDoPedidoExtra(item) {
    let kits = this.state.kits,
      combos = this.state.combos;
    if (!this.existemKits()) {
      kits.forEach((kit) => {
        let combo = combos.find((c) => {
          return c.nome === item.nome;
        });
        if (kit.nome === item.nome) {
          kit.precoPedidoExtra = Number(kit.preco);
          combo.precoPedidoExtra = Number(kit.preco);
        }
        if (
          !kits.find((k) => {
            if (k) {
              return k.nome === item.nome;
            }
          })
        ) {
          combo.precoPedidoExtra = 0;
        }
      });
    }
    this.setState({ kits, combos });
  }

  handleBotoesMontarKit(conf, i = null) {
    let color = '#E9ECEF',
      plus = false,
      validar = false;
    if (conf === 'plus') {
      plus = true;
      validar = false;
    } else if (conf === 'validarKit') {
      plus = false;
      validar = true;
    }
    if (i !== null) {
      document.querySelector('#cardColor' + i).style.backgroundColor = color;
    }
    this.setState({ botaoPlus: plus, botaoValidarKit: validar });
  }

  criarLog(tipo, erro, mensagem) {
    new TelegramLog().novo(tipo, [
      JSON.stringify(erro),
      mensagem,
      `Orçamento nº: ${this.state.orcamento.id}`,
      `usuarioLogado: ${
        JSON.parse(sessionStorage.getItem('usuarioLogado'))[0].Login
      }`,
    ]);
  }

  async handleGerarAcesso() {
    this.setState({ carregando: true });
    let contato = this.state.contatosOrcamento.filter(
      (contato) =>
        contato.ContatoPrinc === true || contato.ContatoPrinc === 'SIM'
    );
    contato = contato !== undefined ? contato[0] : undefined;
    if (contato === undefined) {
      this.setState({
        validators: {
          temUmPrincipal: '',
          infoContatoPrincipal: 'none',
          datasPreenchidas: 'none',
        },
        carregando: false,
      });
    } else {
      if (
        this.state.contrato.dataFormatura === '' ||
        this.state.contrato.dataEntrega === ''
      ) {
        this.setState({
          validators: {
            temUmPrincipal: 'none',
            infoContatoPrincipal: 'none',
            datasPreenchidas: '',
          },
          carregando: false,
        });
      } else {
        this.setState({
          validators: {
            temUmPrincipal: 'none',
            infoContatoPrincipal: 'none',
            datasPreenchidas: 'none',
          },
        });
        await axios
          .post(URL_AGENCIA_API + '/usuarios/login', {
            login: 'mapavendas',
            senha: '92Ii+FNI?%eW',
          })
          .then(async (tokenAcesso) => {
            if (tokenAcesso.data.token.id) {
              let senha = Math.random()
                .toString(36)
                .slice(-8);
              // ---------- Cadastrar usuário
              await axios
                .post(
                  URL_AGENCIA_API + '/usuarios',
                  {
                    login: contato.email,
                    senha: senha,
                    email: contato.email,
                    nome: contato.Nome,
                    tipo: 'comissao',
                  },
                  { headers: { Authorization: tokenAcesso.data.token.id } }
                )
                .then(async (usuarioCadastrado) => {
                  // ---------- Cadastrar contrato
                  await this.alimentarContrato(
                    contato,
                    usuarioCadastrado.data.id
                  );
                  await axios
                    .post(URL_AGENCIA_API + '/contratos', this.state.contrato, {
                      headers: { Authorization: tokenAcesso.data.token.id },
                    })
                    .then(async (response) => {
                      // ---------- Cadastrar itens do orçamento
                      let contrato = this.state.contrato,
                        itensPreparados = [];
                      this.state.kits.forEach((item) => {
                        let novosIds = '',
                          novosNomes = '';
                        if (item.itens.length > 0) {
                          item.itens.forEach((itemItens) => {
                            novosIds += itemItens.id + ';';
                            novosNomes += `${itemItens.qtd} ${itemItens.nome};`;
                          });
                        }
                        itensPreparados.push({
                          ids: novosIds === '' ? `${item.id};` : novosIds,
                          itens:
                            novosNomes === ''
                              ? `${item.qtd} ${item.nome};`
                              : novosNomes,
                          quantidade: item.qtd,
                          tipo:
                            item.obs === undefined || item.obs === 'Sim'
                              ? 'F'
                              : 'P',
                          contrato: response.data.id,
                          produto: item.nome,
                          preco: Number(Number(item.preco).toFixed(2)),
                          observacao: item.tipo,
                          descricao: item.descricao,
                          valorAdendo: Number(
                            Number(item.precoPedidoExtra).toFixed(2)
                          ),
                        });
                      });
                      contrato.itens = itensPreparados;
                      this.setState({ contrato }, () => {
                        this.state.contrato.itens.forEach((item) => {
                          axios
                            .post(URL_AGENCIA_API + `/itens`, item, {
                              headers: {
                                Authorization: tokenAcesso.data.token.id,
                              },
                            })
                            .catch((erro) => {
                              toast.error(
                                'Não foi possível cadastrar o item do contrato!'
                              );
                              this.criarLog(
                                'ERROR',
                                erro,
                                'Não foi possível cadastrar o item do contrato'
                              );
                            });
                        });
                      });
                      // ---------- Alterar status do orçamento
                      await axios
                        .put(
                          REQUEST_URL +
                            `/orcamento/status/${this.state.orcamento.id}`,
                          { status: 'Fechado' }
                        )
                        .then(async (data) => {
                          let orcamento = Object.assign(
                            [],
                            this.state.orcamento
                          );
                          orcamento.status = 'Fechado';
                          await axios
                            .patch(
                              `${REQUEST_URL}/orcamento/desconto-comissao`,
                              {
                                idOrc: this.state.orcamento.id,
                                descontoComissao: this.state.descontoComissao,
                              }
                            )
                            .catch((erro) => {
                              this.criarLog(
                                'ERROR',
                                erro,
                                'Não foi possível atualizar o desconto da comissão'
                              );
                            });
                          await axios
                            .post(`${REQUEST_URL}/orcamento/status-orcamento`, {
                              orcamento: this.state.orcamento.id,
                              status: 'Acesso Gerado',
                            })
                            .then(() => {
                              this.setState({
                                orcamento,
                                statusOrcamento: 'Acesso Gerado',
                              });
                            })
                            .catch((erro) => {
                              toast.error(
                                'Não foi possível atualizar o status do orçamento'
                              );
                              this.criarLog(
                                'ERROR',
                                erro,
                                'Não foi possível atualizar o status do orçamento'
                              );
                            });
                        })
                        .catch((erro) => {
                          toast.error(
                            'Não foi possível atualizar o status do orçamento para Fechado!'
                          );
                          this.criarLog(
                            'ERROR',
                            erro,
                            'Não foi possível atualizar o status do orçamento para Fechado!'
                          );
                        });
                      // ---------- Alterar status do cliente
                      await axios
                        .put(
                          REQUEST_URL +
                            `/cliente/status/${this.getCodCliente()}`,
                          { status: 'Fechado' }
                        )
                        .then((data) => {
                          let orcamento = Object.assign(
                            [],
                            this.state.orcamento
                          );
                          orcamento.cliente.Status = 'Fechado';
                          this.setState({ orcamento });
                        })
                        .catch((erro) => {
                          toast.error(
                            'Não foi possível atualizar o status do cliente para Fechado!'
                          );
                          this.criarLog(
                            'ERROR',
                            erro,
                            'Não foi possível atualizar o status do cliente para Fechado!'
                          );
                        });
                      toast.success('Contrato cadastrado com sucesso!');
                      this.setState({
                        carregando: false,
                        showModalContrato: false,
                        showBotaoGerarAcesso: false,
                      });
                    })
                    .catch((erro) => {
                      toast.error(
                        'Não foi possível criar o contrato no sistema da agência!'
                      );
                      this.criarLog(
                        'ERROR',
                        erro,
                        'Não foi possível criar o contrato no sistema da agência'
                      );
                    });
                })
                .catch((erro) => {
                  toast.error(
                    'Não foi possível criar um usuário para essa comissão!'
                  );
                  this.criarLog(
                    'ERROR',
                    erro,
                    'Não foi possível criar um usuário para essa comissão!'
                  );
                });
            }
          })
          .catch((erro) => {
            toast.error(
              'Não foi possível criar o acesso do cliente porque o sistema da Agência está offline'
            );
            this.criarLog(
              'ERROR',
              erro,
              'Não foi possível se comunicar com o sistema da Agência'
            );
            this.setState({ carregando: false });
          });
      }
    }
  }

  async validarItensKit(kitValidar, index) {
    await this.removeKit(-1);
    let combos = this.state.combos,
      kits = this.state.kits,
      bloqueadosKits = 0,
      bloqueadosCombos = 0,
      color = '#DC3545',
      itensValidos = true;
    if (kitValidar.itens.length > 0) {
      for (let i = 0; i < kitValidar.itens.length; i++) {
        if (kitValidar.itens[i].qtd <= 0) {
          itensValidos = false;
        }
      }
    }
    if (kitValidar.preco <= 0) {
      toast.error(
        'Não é possível criar um kit com o valor inferior ou igual a 0!'
      );
    } else if (
      this.formatReal(kitValidar.preco * Number(kitValidar.qtd)) >
      this.formatReal(this.state.orcamento.precoTotal)
    ) {
      toast.error(
        'Não é possível criar um kit com o valor superior ao do orçamento!'
      );
    } else if (!itensValidos) {
      toast.error(
        'Não é possível cadastrar um kit com item(ns) com quantidade inferior ou igual a 0!'
      );
    } else if (kitValidar.itens.length === 0) {
      toast.error('Não é possível criar um kit sem itens!');
    } else if (kitValidar.qtd <= 0) {
      toast.error(
        'Não é possível criar um kit com o quantidade inferior ou igual a 0!'
      );
    } else {
      combos.forEach((combo) => {
        if (combo.qtd - this.qtdNosKits(combo) > 0) {
          bloqueadosCombos++;
        }
        kits.forEach((kit) => {
          if (combo.nome === kit.nome) {
            bloqueadosKits++;
          }
        });
      });
      if (
        this.formatReal(this.precoTotalKits()) !==
        this.formatReal(this.state.orcamento.precoTotal)
      ) {
        if (bloqueadosKits === 0 && bloqueadosCombos === 1) {
          this.setState({ showModalSugerirPreco: true });
        }
      }
      color = '#16a085';

      this.handleBotoesMontarKit('plus');
    }
    document.querySelector('#cardColor' + index).style.backgroundColor = color;
  }

  sugerirPrecoDoCombo() {
    let combos = this.state.combos;
    if (
      this.formatReal(this.precoTotalKits()) !==
      this.formatReal(this.state.orcamento.precoTotal)
    ) {
      combos.forEach((combo) => {
        if (combo.qtd - this.qtdNosKits(combo) > 0) {
          this.onChangePrecoAvulso(
            0,
            0,
            combo,
            combo.qtd - this.qtdNosKits(combo),
            { target: { value: 0 } }
          );
          this.setState({ showModalSugerirPreco: false });
        }
      });
    }
  }

  getKitDoAvulso(item) {
    let kits = [...this.state.kits];

    kits = kits.filter((kit) => kit !== undefined);
    kits = kits.filter((kit) => {
      return kit.nome === item.nome && kit.tipo !== 'brinde';
    });

    if (kits.length > 0) {
      return kits[0];
    } else {
      return null;
    }
  }

  alterarContato(e) {
    let campo = e.target.name,
      contato = this.state.contato,
      valor = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    contato[campo] = valor;
    this.setState({ contato });
  }

  calcularDataEntrega(dataFormatura) {
    let novaData = new Date(dataFormatura);
    novaData = new Date(novaData.getTime() + 24 * 60 * 60 * 1000 * -60);
    if (novaData.getDay() === 6) {
      novaData = new Date(novaData.getTime() + 24 * 60 * 60 * 1000 * -1);
    } else if (novaData.getDay() === 7) {
      novaData = new Date(novaData.getTime() + 24 * 60 * 60 * 1000 * -2);
    }
    return novaData
      .toLocaleDateString()
      .split('/')
      .reverse()
      .join('-');
  }

  async alimentarContrato(contato, id) {
    let contrato = Object.assign({}, this.state.contrato),
      temBrinde = this.state.kits.filter((k) => {
        return k.tipo === 'brinde';
      }),
      temKit = this.state.kits.filter((k) => {
        return k.tipo === 'kit';
      });

    contrato.emailComissao = contato.email;
    contrato.dono = 1288;
    contrato.numero = contrato.ref_cliente
      ? contrato.ref_cliente.split('/')[0]
      : String(contrato.numContrato).split('/')[0];
    contrato.contratante =
      contrato.ref_cliente &&
      (contrato.ref_cliente.indexOf('/N/') !== -1 ||
        contrato.ref_cliente.indexOf('/n/') !== -1)
        ? `Contrato ${contrato.ref_cliente}`
        : `Contrato ${contrato.numContrato}/${
            contrato.anoCorrente
              ? contrato.anoCorrente
              : String(new Date().getFullYear()).substring(2, 4)
          }`;
    contrato.usuario = id;
    contrato.dataInicio =
      new Date().toISOString().split('T')[0] +
      ' ' +
      new Date().toLocaleTimeString();
    contrato.nomeComissao = contato.Nome;
    contrato.idClienteMapa = contato.IdCliente;
    contrato.telefone = contato.Celular;
    contrato.numOrcamento = this.state.orcamento.id;
    contrato.statusCronograma = 'N';
    contrato.status = 'inserir';
    contrato.conviteOnline = this.state.orcamento.possuiConviteOnline ? 1 : 0;
    contrato.pagamentoIndividual = this.state.orcamento.pagamentoIndividual
      ? 'true'
      : 'false';
    contrato.dataFormatura = `${contrato.dataFormatura} 00:00:00`;
    contrato.dataEntrega = `${contrato.dataEntrega} 00:00:00`;
    contrato.dataChegada = contrato.dataChegada
      ? `${contrato.dataChegada} 00:00:00`
      : new Date().toISOString().split('T')[0] +
        ' ' +
        new Date().toLocaleTimeString();
    contrato.dataMaximaPagto = contrato.sistemaPagto
      ? contrato.dataMaximaPagto
        ? contrato.dataMaximaPagto
        : contrato.dataEntrega
      : null;
    contrato.avulsosLiberados = temKit.length > 0 ? 0 : 1;

    if (temBrinde.length > 0) {
      contrato.distribuiuBrindes = 0;
    }

    if (
      contrato.ref_cliente &&
      (contrato.ref_cliente.indexOf('/N/') !== -1 ||
        contrato.ref_cliente.indexOf('/n/') !== -1)
    ) {
      axios.patch(
        `${REQUEST_URL}/orcamento/${this.state.orcamento.id}/${
          contrato.ref_cliente.split('/')[0]
        }`
      );
    }

    this.setState({ contrato });
  }

  async deletarContato(contato) {
    await axios
      .delete(REQUEST_URL + `/cliente/contato/${contato.idContato}`)
      .then(() => {
        this.setState({ atualizarContatosOrcamento: true });
      })
      .catch(() => {
        toast.error('Não foi possível deletar o contato');
      });
  }

  async salvarContato(e) {
    e.preventDefault();
    let contato = this.state.contato;
    contato.IdCliente = this.state.orcamento.cliente.codCliente;
    if (contato.idContato === '') {
      await axios
        .post(REQUEST_URL + '/cliente/contato', contato)
        .then((data) => {
          contato = {
            idContato: '',
            Nome: '',
            email: '',
            Fixo: '',
            Celular: '',
            outroTelefone: '',
          };
          this.setState({ atualizarContatosOrcamento: true, contato: contato });
        })
        .catch((erro) => {
          toast.error('Erro ao cadastrar o contato');
          this.criarLog(
            'ERROR',
            erro,
            'Não foi possível cadastrar o contato no MAPA!'
          );
        });
    } else {
      await axios
        .put(REQUEST_URL + `/cliente/contato/${contato.idContato}`, contato)
        .then((data) => {
          contato = {
            idContato: '',
            Nome: '',
            email: '',
            Fixo: '',
            Celular: '',
            CPF: '',
            RG: '',
            CNPJ: '',
          };
          this.setState({ atualizarContatosOrcamento: true, contato: contato });
        })
        .catch((erro) => {
          toast.error('Erro ao atualizar o contato');
          this.criarLog(
            'ERROR',
            erro,
            'Não foi possível atualizar o contato no MAPA!'
          );
        });
    }
    this.setState({
      showModalContato: false,
      showBotaoGerarAcesso: false,
      validators: {
        temUmPrincipal: 'none',
        infoContatoPrincipal: 'none',
        datasPreenchidas: 'none',
      },
    });
  }

  calcularPrecoTotal(recalcular = true) {
    let orcamento = this.state.orcamento;
    var soma = 0;
    let precoTotalAcabamentos = 0;
    let precoTotalBrinde = 0;
    orcamento.itens.map((i, index) => {
      soma = soma + i.precoTotal;
      precoTotalAcabamentos += Number(i.precoTotalAcabamentos);
      precoTotalBrinde += i.precoTotalBrinde;
    });
    let precoDaArte = 0;
    let precoDoFrete = 0;
    if (recalcular) {
      precoDaArte = this.state.possuiArte
        ? this.state.orcamento.precoDaArteT
        : 0;
      precoDoFrete = this.state.possuiFrete
        ? this.state.orcamento.precoDoFreteT
        : 0;
    } else {
      precoDaArte = this.state.possuiArte ? this.state.orcamento.valorArte : 0;
      precoDoFrete = this.state.possuiArte
        ? this.state.orcamento.valorFrete
        : 0;
    }
    let totalOrcamento =
      soma +
      precoTotalAcabamentos +
      precoTotalBrinde +
      precoDaArte +
      precoDoFrete +
      (this.state.possuiPagamentoIndividual
        ? orcamento.valorBoleto + orcamento.valorCartao
        : 0);
    return totalOrcamento;
  }

  calcularPrecoTotalDoItem(item, recalcular = true) {
    let precoTotalDoItem = 0;
    if (item.servico === 'MIOLO') {
      let precoDaArte = 0;
      let precoDoFrete = 0;
      if (recalcular) {
        precoDaArte = this.state.possuiArte
          ? this.state.orcamento.precoDaArteU * (item.qtd + item.qtdBrinde)
          : 0;
        precoDoFrete = this.state.possuiFrete
          ? this.state.orcamento.precoDoFreteU * (item.qtd + item.qtdBrinde)
          : 0;
      } else {
        precoDaArte = this.state.possuiArte
          ? this.state.orcamento.valorArte
          : 0;
        precoDoFrete = this.state.possuiFrete
          ? this.state.orcamento.valorFrete
          : 0;
      }
      precoTotalDoItem =
        item.precoTotal +
        item.precoTotalAcabamentos +
        item.precoTotalBrinde +
        precoDaArte +
        precoDoFrete +
        (this.state.possuiPagamentoIndividual
          ? this.state.orcamento.valorBoleto + this.state.orcamento.valorCartao
          : 0);
    } else {
      precoTotalDoItem =
        item.precoTotal + item.precoTotalAcabamentos + item.precoTotalBrinde;
    }
    precoTotalDoItem += this.distribuirValorBrindePeloItem(
      precoTotalDoItem,
      recalcular
    );
    return precoTotalDoItem;
  }

  distribuirValorBrindePeloItem(precoDoItem, recalcular = true) {
    let totalBrinde = this.state.orcamento.valorTotalBrindes;
    let totalOrcamento = this.calcularPrecoTotal(recalcular) - totalBrinde;
    if (totalOrcamento > 0) {
      return (precoDoItem / totalOrcamento) * totalBrinde;
    }
    return 0;
  }

  async gerarAcesso() {
    this.setState({ showBotaoGerarAcesso: true });
    await axios
      .get(`${REQUEST_URL}/clientes/${this.getCodCliente()}`)
      .then(async (data) => {
        await axios
          .get(
            `${REQUEST_URL}/orcamentos/${this.state.orcamento.id}/numContrato`
          )
          .then(async (numContrato) => {
            if (!numContrato.data) {
              await axios
                .post(`${URL_CONTRATO_API}/num-contract`)
                .then((response) => {
                  numContrato = response.data.contract;
                  axios.patch(
                    `${REQUEST_URL}/orcamento/${this.state.orcamento.id}/${numContrato}`
                  );
                })
                .catch((erro) => {
                  this.criarLog(
                    'ERROR',
                    erro,
                    'Erro ao gerar o número do contrato deste orçamento pela API de contratos!'
                  );
                  toast.error(
                    'Erro ao gerar o número do contrato deste orçamento'
                  );
                });
            } else {
              numContrato = numContrato.data;
            }
            let dataFormatura = new Date(
                new Date(
                  data.data[0].Formatura.replace(/\//g, '-')
                ).setUTCHours(3)
              )
                .toLocaleDateString()
                .split('/')
                .reverse()
                .join('-'),
              dataEntrega = this.calcularDataEntrega(dataFormatura);
            this.setState({
              contrato: {
                ...this.state.contrato,
                dataFormatura,
                dataEntrega,
                numContrato: numContrato,
              },
              showModalContrato: true,
              atualizarContatosOrcamento: true,
            });
          })
          .catch((err) => {
            toast.error(
              'Erro ao consultar o número do contrato. Reabra o orçamento e tente novamente!'
            );
            this.setState({ showBotaoGerarAcesso: false });
          });
      })
      .catch((err) => {
        toast.error(
          'Erro ao consultar os clientes. Reabra o orçamento e tente novamente!'
        );
        this.setState({ showBotaoGerarAcesso: false });
      });
  }

  alterarPrecoPedidoExtra(nome, e) {
    let combos = this.state.combos,
      kits = this.state.kits;
    for (let i = 0; i < combos.length; i++) {
      let combo = combos[i];
      if (combo.nome === nome) {
        combo.precoPedidoExtra = Number(e.target.value);
      }
    }
    for (let i = 0; i < kits.length; i++) {
      let kit = kits[i];
      if (kit.nome === nome) {
        kit.precoPedidoExtra = Number(e.target.value);
      }
    }
    this.setState({ combos, kits });
  }

  existemKits() {
    let { kits } = this.state;
    kits = kits.filter((kit) => kit !== undefined);
    return kits.find(
      (kit) => (kit && kit.tipo === 'kit') || kit.tipo === 'itemFuturo'
    );
  }

  atualizarDataEntrega() {
    let { dataFormatura } = this.state.contrato,
      dataEntrega = this.calcularDataEntrega(dataFormatura);

    this.setState({ contrato: { ...this.state.contrato, dataEntrega } });
  }

  atualizarItens = (orcamentoModal) => {
    let { kits, combos, orcamento } = this.state,
      ultimoItem = orcamentoModal.itens[orcamentoModal.itens.length - 1],
      itemNosCombos = combos.filter((combo) => {
        return combo.nome === ultimoItem.nome;
      })[0];

    if (ultimoItem.ItemFuturo === 1 && !itemNosCombos) {
      let novoCombo = {
        descricao: ultimoItem.nome,
        id: '',
        nome: ultimoItem.nome,
        precoFinal: ultimoItem.precoTotalDoItem,
        precoFinalUni: ultimoItem.precoUnitarioDoItem,
        qtd: 0,
      };

      ultimoItem.indexProduto =
        orcamentoModal.itens.length + orcamento.itensCompletos.length;
      ultimoItem.idOrc = orcamentoModal.id;
      ultimoItem.qtd = 0;
      ultimoItem.qtdBrinde = 0;

      kits.push({
        nome: ultimoItem.nome,
        id: 'K_' + new Date().getTime(),
        descricao: ultimoItem.nome,
        itens: [],
        qtd: 0,
        tipo: 'itemFuturo',
        preco: 0,
      });

      this.setState({
        kits,
        combos: [...this.state.combos, novoCombo],
        orcamento: {
          ...this.state.orcamento,
          itensCompletos: [...this.state.orcamento.itensCompletos, ultimoItem],
        },
      });
    }
    this.setState({ showModalAddItem: false });
  };

  salvarItensNovos() {
    let { orcamento } = this.state;

    axios
      .post(`${REQUEST_URL}/orcamento/planos`, {
        itens: orcamento.itensCompletos,
        somaMargens: orcamento.somaMargens,
      })
      .catch((erro) => {
        toast.error('Não foi possível cadastrar os itens extras!');
        this.criarLog(
          'ERROR',
          erro,
          'Não foi possível cadastrar os itens extras!'
        );
      });
  }

  async deletarKit() {
    let { nomeKitSelecionado, orcamento, kits, combos } = this.state;

    await axios
      .delete(
        `${REQUEST_URL}/orcamentos/kit/nome/${nomeKitSelecionado}/${orcamento.id}`
      )
      .then(() => {
        combos = combos.filter((combo) => {
          return combo.nome !== nomeKitSelecionado;
        });
        kits = kits.filter((kit) => {
          return kit.nome !== nomeKitSelecionado;
        });
        toast.success('Item deletado com sucesso!');
      })
      .catch((err) => {
        toast.error('Não foi possível deletar o item. Tente novamente!');
        this.criarLog(
          'ERROR',
          JSON.stringify(err),
          'Falha ao deletar um item futuro'
        );
      });

    this.setState({
      kits,
      combos,
      showModalDeletarKit: false,
      nomeKitSelecionado: '',
    });
  }

  _renderTableItems() {
    return (
      <div className='table-responsive'>
        <table className='table table-sm table-bordered'>
          <thead>
            <tr>
              <th colSpan='10' style={{ textAlign: 'center' }}>
                TABELA DE PRODUTOS
              </th>
            </tr>
            <tr>
              <th
                colSpan='6'
                style={{ textAlign: 'center', backgroundColor: '#fbfbfb' }}
              >
                ITENS ORÇADOS
              </th>
              <th
                colSpan='3'
                style={{ textAlign: 'center', backgroundColor: '#fff' }}
              >
                ITENS P/ VENDAS AVULSAS
              </th>
              <th
                colSpan='1'
                rowSpan={2}
                style={{
                  verticalAlign: 'middle',
                  textAlign: 'center',
                  backgroundColor: '#fbfbfb',
                }}
              >
                VALOR P/ PEDIDOS EXTRAS
              </th>
            </tr>
            <tr>
              <th style={{ backgroundColor: '#fbfbfb' }}>#</th>
              <th style={{ backgroundColor: '#fbfbfb' }}>Nome</th>
              <th style={{ backgroundColor: '#fbfbfb' }}>Qtd</th>
              <th style={{ backgroundColor: '#fbfbfb' }}>Qtd disp</th>
              <th style={{ backgroundColor: '#fbfbfb' }}>Preço unitário</th>
              <th style={{ backgroundColor: '#fbfbfb' }}>Preço total</th>
              <th style={{ backgroundColor: '#fff' }}>Qtd</th>
              <th style={{ backgroundColor: '#fff' }}>Preço unitário</th>
              <th style={{ backgroundColor: '#fff' }}>Preço total</th>
            </tr>
          </thead>
          <tbody>
            {this.state.combos.map((item, i) => {
              let qtdRestante = item.qtd - this.qtdNosKits(item),
                ultimoItem = this.state.combos[this.state.combos.length - 1],
                ultimaQtdRestante = ultimoItem
                  ? ultimoItem.qtd - this.qtdNosKits(ultimoItem)
                  : null,
                kitDoAvulso = this.getKitDoAvulso(item),
                precoDoKitAvulso = 0,
                precoDoKitAvulsoTotal = 0,
                qtdKitAvulso = 0;
              if (kitDoAvulso) {
                if (item.preco < 0) {
                  qtdRestante = this.qtdNosKits(item);
                  qtdKitAvulso = 0;
                } else {
                  qtdKitAvulso = kitDoAvulso.qtd;
                }
                precoDoKitAvulso = kitDoAvulso.preco;
                precoDoKitAvulsoTotal = this.formatReal(
                  kitDoAvulso.preco * kitDoAvulso.qtd
                );
              }
              return (
                <tr key={'tI' + i}>
                  <td style={{ width: 10, backgroundColor: '#fbfbfb' }}>
                    <div style={{ width: 10, height: 20, position: 'initial' }}>
                      <i
                        style={{ position: 'relative', left: 0, top: -8 }}
                        onClick={() => {
                          this.moverItem(item, 'cima');
                        }}
                        className='fas fa-caret-up'
                      ></i>
                      <i
                        style={{ position: 'relative', left: 0, top: -15 }}
                        onClick={() => {
                          this.moverItem(item, 'baixa');
                        }}
                        className='fas fa-caret-down'
                      ></i>
                    </div>
                  </td>
                  <td style={{ backgroundColor: '#fbfbfb' }}>
                    {!item.id ? (
                      <span
                        onClick={() =>
                          this.setState({
                            showModalDeletarKit: true,
                            nomeKitSelecionado: item.nome,
                          })
                        }
                      >
                        <i
                          style={{ color: 'rgb(220, 53, 69)' }}
                          className='fa fa-trash'
                        ></i>
                      </span>
                    ) : null}{' '}
                    {item.nome}
                  </td>
                  <td style={{ backgroundColor: '#fbfbfb' }}>{item.qtd}</td>
                  <td style={{ backgroundColor: '#fbfbfb' }}>{qtdRestante}</td>
                  <td style={{ backgroundColor: '#fbfbfb' }}>
                    R$ {this.formatReal(item.precoFinalUni, -2)}
                  </td>
                  <td style={{ backgroundColor: '#fbfbfb' }}>
                    R$ {this.formatReal(item.precoFinal)}
                  </td>
                  <td style={{ backgroundColor: '#fff' }}>{qtdKitAvulso}</td>
                  <td style={{ backgroundColor: '#fff' }}>
                    <InputGroup size='sm'>
                      <InputGroupAddon addonType='prepend'>R$</InputGroupAddon>
                      {this.state.combos.length === 1 ? (
                        <Input
                          type='number'
                          onChange={this.onChangePrecoAvulso.bind(
                            this,
                            item,
                            qtdRestante < 0 ? 0 : qtdRestante,
                            null,
                            0
                          )}
                          value={precoDoKitAvulso}
                        />
                      ) : i === this.state.combos.length - 1 &&
                        this.state.combos.length > 1 ? (
                        <Input
                          type='number'
                          readOnly
                          value={precoDoKitAvulso}
                        />
                      ) : qtdRestante <= 0 && qtdKitAvulso <= 0 ? (
                        <Input
                          type='number'
                          readOnly
                          value={precoDoKitAvulso}
                        />
                      ) : (
                        <Input
                          type='number'
                          onChange={this.onChangePrecoAvulso.bind(
                            this,
                            item,
                            qtdRestante < 0 ? 0 : qtdRestante,
                            ultimoItem,
                            ultimaQtdRestante > 0 ? ultimaQtdRestante : 0
                          )}
                          value={precoDoKitAvulso}
                        />
                      )}
                    </InputGroup>
                  </td>
                  <td style={{ backgroundColor: '#fff' }}>
                    R$ {precoDoKitAvulsoTotal}
                  </td>
                  <td style={{ backgroundColor: '#fbfbfb' }}>
                    <InputGroup size='sm'>
                      <InputGroupAddon addonType='prepend'>R$</InputGroupAddon>
                      <Input
                        type='number'
                        disabled={!this.existemKits()}
                        value={item.precoPedidoExtra}
                        onChange={this.alterarPrecoPedidoExtra.bind(
                          this,
                          item.nome
                        )}
                      />
                    </InputGroup>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  _renderTableSelectItems() {
    return (
      <table className='table table-sm table-bordered'>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Quantidade</th>
          </tr>
        </thead>
        <tbody>
          {this.state.combos.map((item) => {
            if (
              !this.kitTemEsseItem(this.state.kitSelecionado, item) &&
              this.qtdNosKits(item) < item.qtd &&
              item.ids !== null
            ) {
              return (
                <tr
                  key={item.nome.split(' ')[0] + Math.random()}
                  onClick={this.selectItem.bind(this, item)}
                  style={
                    this.state.itensSelecionados.includes(item)
                      ? { backgroundColor: 'gray', color: '#fbfbfb' }
                      : {}
                  }
                >
                  <td>{item.nome}</td>
                  <td>{item.qtd - this.qtdNosKits(item)}</td>
                </tr>
              );
            }
          })}
        </tbody>
      </table>
    );
  }

  _renderCliente() {
    return (
      <Card style={{ borderRadius: 0 }}>
        <CardHeader style={{ borderBottom: 'none', backgroundColor: 'white' }}>
          <div className='table-responsive'>
            <table className='table table-sm'>
              <thead>
                <tr>
                  <th>Cliente</th>
                  <th>Faculdade</th>
                  <th>Curso</th>
                  <th>Cidade</th>
                  <th>Parceiro</th>
                  <th>Formatura</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ whiteSpace: 'nowrap' }}>
                    {this.state.orcamento.cliente.Descricao}
                  </td>
                  <td style={{ whiteSpace: 'nowrap' }}>
                    {this.state.orcamento.cliente.Instituicao}
                  </td>
                  <td style={{ whiteSpace: 'nowrap' }}>
                    {this.state.orcamento.cliente.curso}
                  </td>
                  <td style={{ whiteSpace: 'nowrap' }}>
                    {this.state.orcamento.cliente.Cidade}
                  </td>
                  <td style={{ whiteSpace: 'nowrap' }}>
                    {this.state.orcamento.cliente.NomeAgencia}
                  </td>
                  <td style={{ whiteSpace: 'nowrap' }}>
                    {this.state.orcamento.cliente.Formatura !== undefined
                      ? this.state.orcamento.cliente.Formatura
                      : '---'}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </CardHeader>
      </Card>
    );
  }

  _renderOrcamentoStatus() {
    return (
      <Card style={{ borderRadius: 0 }}>
        <CardHeader style={{ borderBottom: 'none', backgroundColor: 'white' }}>
          <div className='table-responsive'>
            <table className='table table-sm'>
              <thead>
                <tr>
                  <th style={{ whiteSpace: 'nowrap' }}>Orcamento</th>
                  <th style={{ whiteSpace: 'nowrap' }}>Status</th>
                  <th style={{ whiteSpace: 'nowrap' }}>Vendedor</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {this.state.orcamento.id
                      ? this.state.orcamento.id
                      : 'Não salvo'}
                  </td>
                  <td>
                    {this.state.orcamento.status}{' '}
                    {this.state.orcamento.duplicado
                      ? ', Duplicado do ' + this.state.orcamento.idDuplicado
                      : ''}
                  </td>
                  <td>{this.state.orcamento.NomeVendedor}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </CardHeader>
      </Card>
    );
  }

  render() {
    return (
      <div>
        <NavegationBlocker navigationBlocked={!this.state.orcamento.id} />
        <ToastContainer
          position='bottom-left'
          autoClose={5000}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange={false}
          draggable={false}
        />
        <NavBar
          className='no-print'
          nome={this.state.usuarioLogado.Nome}
          funcao={this.state.usuarioLogado.Funcao}
        />
        <Col id='principal' sm='12' md={{ size: 12 }} style={{ marginTop: 10 }}>
          <Alert color='warning' isOpen={true}>
            <h5>
              Atenção <i className='fa fa-exclamation'></i>
            </h5>
            1 - Caso sua venda possua kits, desça até a seção 'Montar kits' e
            adicione seus kits (clicando no botão +). Descreva o nome do kit, a
            quantidade, os itens que farão parte e o preço unitário. Observe que
            a quantidade informada será abatida da 'Qtd disponível', presente na
            tabela de produtos (terceira coluna).
            <br />
            2 - Os produtos que não foram selecionados para compor um kit serão
            considerados avulsos. Neste caso, você deverá informar o seu preço
            de venda no campo 'Unitário', presente dentro da tabela de produtos
            (sétima coluna). <br />3 - Caso você não crie nenhum kit em sua
            venda, você deverá informar os preços dos produtos que estão
            listados na tabela de produtos. <br />
            4 - O preço total dos kits e/ou itens da tabela deve ser igual ao
            preço do orçamento. Você pode verificar o preço do orçamento e o
            preço dos kits e/ou produtos no canto inferior direito.
            <br />
          </Alert>
          <Jumbotron
            id='jumbotron'
            style={{
              minHeight: 600,
              paddingTop: 5,
              marginTop: this.state.reabrindo ? -80 : 0,
              filter: `opacity(${this.state.reabrindo ? '50%' : '100%'})`,
              boxShadow: '0 2px 5px 0 rgba(0,0,0,.26)',
            }}
          >
            <Row style={{ marginTop: 10 }}>
              <Col sm='6' xs='12' md='8' lg='8' style={{ paddingLeft: 0 }}>
                {this._renderCliente()}
              </Col>
              <Col
                sm='6'
                xs='12'
                md='4'
                lg='4'
                style={{ paddingLeft: 0, paddingRight: 0 }}
              >
                {this._renderOrcamentoStatus()}
              </Col>
            </Row>
            {this.state.loading ? (
              <Row>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    paddingTop: '15px',
                  }}
                >
                  <i className='fa fa-spinner fa-pulse fa-2x'></i>
                </div>
              </Row>
            ) : (
              <div>
                <Row>
                  <Col sm='12' style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Card style={{ borderRadius: 0, marginTop: 10 }}>
                      <CardBody>
                        {this._renderTableItems()}
                        {/* <Button color="link" onClick={() => this.setState({ showModalAddItem: true })}><i className="fa fa-plus"/> Adicionar item extra</Button> */}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col sm='12' style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Card
                      style={{ borderRadius: 0, padding: '.75rem 1.25rem' }}
                    >
                      <h3>Montar Kits:</h3>
                      <Row>
                        {this.state.kits.map((kit, i) => {
                          if (kit.tipo === 'kit') {
                            return (
                              <Col
                                md='4'
                                key={'card' + i}
                                style={{ marginBottom: 10 }}
                              >
                                <Card id={'cardColor' + i}>
                                  <CardHeader
                                    style={{ display: 'flex', color: '#000' }}
                                  >
                                    <div
                                      style={{ flex: 1, padding: '0px 2px' }}
                                    >
                                      Nome{' '}
                                      <Input
                                        value={kit.nome}
                                        onChange={this.changeKitNome.bind(
                                          this,
                                          kit,
                                          i
                                        )}
                                      />
                                    </div>
                                    <div
                                      style={{ flex: 1, padding: '0px 2px' }}
                                    >
                                      Qtd alunos{' '}
                                      <Input
                                        type='number'
                                        value={kit.qtd}
                                        onChange={this.changeKitQtd.bind(
                                          this,
                                          kit,
                                          i
                                        )}
                                      />
                                    </div>
                                    <button
                                      style={{ flex: 0 }}
                                      className='delete-kit'
                                      onClick={this.removeKit.bind(this, i)}
                                    >
                                      <i className='fa fa-times'></i>
                                    </button>
                                  </CardHeader>
                                  <CardBody>
                                    <table className='table'>
                                      <tbody>
                                        {kit.itens.map((item, j) => {
                                          return (
                                            <tr
                                              key={'iJ' + j}
                                              style={{ cursor: 'default' }}
                                            >
                                              <td>{item.nome}</td>
                                              {this.state.darFocoAEsteCampo ===
                                                'i' + i + j &&
                                              this.state.digitouUmValor ? (
                                                <td>
                                                  <input
                                                    style={{ width: 40 }}
                                                    id={'i' + i + j}
                                                    autoFocus
                                                    value={item.qtd}
                                                    onChange={this.changeKitItemQtd.bind(
                                                      this,
                                                      kit,
                                                      item,
                                                      'i' + i + j,
                                                      true,
                                                      i
                                                    )}
                                                  />
                                                </td>
                                              ) : (
                                                <td>
                                                  <input
                                                    style={{ width: 40 }}
                                                    value={item.qtd}
                                                    id={'i' + i + j}
                                                    onChange={this.changeKitItemQtd.bind(
                                                      this,
                                                      kit,
                                                      item,
                                                      'i' + i + j,
                                                      true,
                                                      i
                                                    )}
                                                  />
                                                </td>
                                              )}
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <button
                                        className='btn btn-info'
                                        onClick={() =>
                                          this.setState(
                                            { kitSelecionado: kit },
                                            () => {
                                              this.setState({
                                                showModalItens: true,
                                              });
                                            }
                                          )
                                        }
                                      >
                                        <i className='fas fa-plus'></i>
                                      </button>
                                    </div>
                                  </CardBody>
                                  <CardFooter
                                    style={{ display: 'flex', color: '#000' }}
                                  >
                                    <div
                                      style={{ flex: 1, padding: '0px 2px' }}
                                    >
                                      UN sugerido
                                      <br />
                                      <InputGroup
                                        size='sm'
                                        style={{ width: '100%' }}
                                      >
                                        <InputGroupAddon addonType='prepend'>
                                          R$
                                        </InputGroupAddon>
                                        <Input
                                          disabled={true}
                                          value={this.formatReal(
                                            this.precoSugeridoKit(kit),
                                            -1
                                          )}
                                        />
                                      </InputGroup>
                                    </div>
                                    <div
                                      style={{
                                        flex: 1,
                                        padding: '0px 2px 5px',
                                      }}
                                    >
                                      UN
                                      <br />
                                      <InputGroup
                                        size='sm'
                                        style={{ width: '100%' }}
                                      >
                                        <InputGroupAddon addonType='prepend'>
                                          R$
                                        </InputGroupAddon>
                                        <Input
                                          type='number'
                                          value={kit.preco}
                                          onChange={this.changeKitPreco.bind(
                                            this,
                                            kit,
                                            i
                                          )}
                                        />
                                      </InputGroup>
                                    </div>
                                    <div
                                      style={{ flex: 1, padding: '0px 2px' }}
                                    >
                                      Total <br />
                                      <InputGroup
                                        size='sm'
                                        style={{ width: '100%' }}
                                      >
                                        <InputGroupAddon addonType='prepend'>
                                          R$
                                        </InputGroupAddon>
                                        <Input
                                          disabled={true}
                                          value={this.formatReal(
                                            kit.preco * kit.qtd
                                          )}
                                        />
                                      </InputGroup>
                                    </div>
                                    {this.state.botaoValidarKit ? (
                                      <div
                                        style={{
                                          width: '15%',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <button
                                          className='btn btn-success'
                                          onClick={this.validarItensKit.bind(
                                            this,
                                            kit,
                                            i
                                          )}
                                        >
                                          <i className='fas fa-check'></i>
                                        </button>
                                      </div>
                                    ) : null}
                                  </CardFooter>
                                </Card>
                              </Col>
                            );
                          }
                        })}
                        <Col className='col-add-kit' md='2'>
                          <Button
                            className='add-kit'
                            color='info'
                            style={{ borderRadius: 25 }}
                            onClick={this.addNewKit.bind(this)}
                          >
                            <i className='fas fa-plus'></i>
                          </Button>
                        </Col>
                      </Row>
                    </Card>
                    <Row>
                      <Col sm='3 offset-md-7' style={{ marginTop: 10 }}>
                        <Card style={{ borderRadius: 0 }}>
                          <CardBody>
                            <h4>
                              <small>Preço dos Kits e Combos</small>
                            </h4>
                            <h2>R$ {this.formatReal(this.precoTotalKits())}</h2>
                            <hr />
                            <h5 style={{ color: '#888' }}>
                              <small>Preço do orçamento</small>
                            </h5>
                            <h3 style={{ color: '#888' }}>
                              R${' '}
                              {this.formatReal(this.state.orcamento.precoTotal)}
                            </h3>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col sm='2' style={{ marginTop: 10 }}>
                        <div>
                          {this.state.statusOrcamento === 'Negociacao' ||
                          this.state.statusOrcamento === 'Kits Gerados' ? (
                            <div>
                              <Button
                                onClick={this.handleSaveItems.bind(this)}
                                disabled={this.state.showBotaoSalvarOrcamento}
                                color='success'
                                block
                              >
                                {this.state.showBotaoSalvarOrcamento ? (
                                  <span>
                                    <i className='fa fa-spinner fa-pulse'></i>{' '}
                                    Salvando
                                  </span>
                                ) : (
                                  <span>
                                    <i className='far fa-save'></i> Salvar
                                  </span>
                                )}
                              </Button>
                              {this.state.usuarioLogado.Funcao ===
                              'ORCAMENTISTA' ? (
                                <div style={{ marginTop: 8 }}>
                                  <Button
                                    onClick={this.handleGerarProposta.bind(
                                      this
                                    )}
                                    color='info'
                                    disabled={this.state.showBotoes}
                                    block
                                  >
                                    {' '}
                                    <i className='fas fa-file-powerpoint'></i>{' '}
                                    Gerar proposta{' '}
                                  </Button>
                                  {/* <Button onClick={() => {this.setState({showModalContratoFinal: true})}} color="danger" disabled={this.state.showBotoes} block> <i className="fas fa-file-pdf"></i> Gerar contrato </Button> */}
                                  <Button
                                    onClick={this.gerarAcesso.bind(this)}
                                    color='danger'
                                    disabled={this.state.showBotoes}
                                    block
                                  >
                                    {' '}
                                    {this.state.showBotaoGerarAcesso ? (
                                      <span>
                                        <i className='fa fa-spinner fa-pulse'></i>{' '}
                                        Gerando acesso
                                      </span>
                                    ) : (
                                      <span>
                                        <i className='fas fa-portrait'></i>{' '}
                                        Gerar acesso
                                      </span>
                                    )}
                                  </Button>
                                </div>
                              ) : null}
                            </div>
                          ) : // : this.state.statusOrcamento === "Contrato Gerado" ?
                          //   <div>
                          //     <div className="alert alert-success" style={{marginBottom:10}}>
                          //       O contrato já foi gerado!
                          //     </div>
                          //     <Button onClick={this.handleSaveItems.bind(this)} disabled={this.state.showBotaoSalvarOrcamento} color="success" block>{this.state.showBotaoSalvarOrcamento ? <span><i className="fa fa-spinner fa-pulse"></i> Salvando</span> : <span><i className="far fa-save"></i> Salvar</span>}</Button>
                          //     <Button onClick={this.gerarAcesso.bind(this)} color="danger" disabled={this.state.showBotaoGerarAcesso} block> {this.state.showBotaoGerarAcesso ? <span><i className="fa fa-spinner fa-pulse"></i> Gerando acesso</span> : <span><i className="fas fa-portrait"></i> Gerar acesso</span>}</Button>
                          //   </div>
                          this.state.statusOrcamento === 'Acesso Gerado' ? (
                            <div>
                              <div
                                className='alert alert-success'
                                style={{ marginBottom: 10 }}
                              >
                                O contrato e o acesso da comissão já foram
                                gerados!
                              </div>
                              <Button
                                onClick={this.handleSaveItems.bind(this)}
                                disabled={this.state.showBotaoSalvarOrcamento}
                                color='success'
                                block
                              >
                                {this.state.showBotaoSalvarOrcamento ? (
                                  <span>
                                    <i className='fa fa-spinner fa-pulse'></i>{' '}
                                    Salvando
                                  </span>
                                ) : (
                                  <span>
                                    <i className='far fa-save'></i> Salvar
                                  </span>
                                )}
                              </Button>
                              <Button
                                onClick={this.handleGerarProposta.bind(this)}
                                color='info'
                                disabled={this.state.showBotoes}
                                block
                              >
                                {' '}
                                <i className='fas fa-file-powerpoint'></i> Gerar
                                proposta{' '}
                              </Button>
                            </div>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            )}
          </Jumbotron>
        </Col>
        <Modal
          className='no-print'
          isOpen={this.state.showModalItens}
          size='md'
          id='modal-nomearquivo'
        >
          <ModalHeader toggle={() => this.setState({ showModalItens: false })}>
            Selecione os itens
          </ModalHeader>
          <ModalBody>{this._renderTableSelectItems()}</ModalBody>
          <ModalFooter>
            <Button color='success' onClick={this.inserirItemNoKit.bind(this)}>
              Inserir
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          className='no-print'
          isOpen={this.state.showModalContratoFinal}
          size='xl'
          id='modal-nomearquivo'
        >
          <ModalHeader
            toggle={() => this.setState({ showModalContratoFinal: false })}
          >
            Contrato nº {this.state.numContrato}
          </ModalHeader>
          <ModalBody>
            <Contrato
              orcamento={this.state.orcamento}
              kits={this.state.kits}
              atualizarNumeroContrato={(numero) => {
                this.setState({ numContrato: numero });
              }}
              updateState={(data) => {
                this.setState({
                  statusOrcamento: data.status,
                  contrato: {
                    ...this.state.contrato,
                    dataFormatura: data.dataFormatura,
                    numero: data.numero,
                  },
                });
              }}
              onCloseModal={(status) => {
                this.setState({ showModalContratoFinal: status });
              }}
            />
          </ModalBody>
        </Modal>
        <Modal
          className='no-print'
          isOpen={this.state.showModalSugerirPreco}
          size='md'
          id='modal-sugerirPreco'
        >
          <ModalHeader
            toggle={() => this.setState({ showModalSugerirPreco: false })}
          >
            Sugestão de preços
          </ModalHeader>
          <ModalBody>
            {this.state.combos.map((combo, i) => {
              if (combo.qtd - this.qtdNosKits(combo) > 0) {
                return (
                  <span key={'combo' + i}>
                    Ainda existem valores que não foram atribuidos ao produto{' '}
                    <strong>{combo.nome}</strong>. O sistema fará isso
                    automaticamente!
                  </span>
                );
              }
            })}
          </ModalBody>
          <ModalFooter>
            <Button
              color='success'
              onClick={this.sugerirPrecoDoCombo.bind(this)}
            >
              <i className='fas fa-check-circle'></i> Ok
            </Button>
            <Button
              color='secondary'
              onClick={() => {
                this.setState({ showModalSugerirPreco: false });
              }}
            >
              <i className='fas fa-times-circle'></i> Não, pretendo criar outro
              kit!
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          className='no-print'
          isOpen={this.state.showModalProposta}
          size='sm'
          id='modal-proposta'
        >
          <ModalHeader
            toggle={() => this.setState({ showModalProposta: false })}
          >
            Você gostaria de gerar a proposta?
          </ModalHeader>
          <ModalFooter>
            {this.state.carregando ? (
              <i className='fas fa-spinner fa-spin'></i>
            ) : null}
            <Button
              disabled={this.state.carregando}
              color='success'
              onClick={this.handleGerarProposta.bind(this)}
            >
              <i className='fas fa-check-circle'></i> Sim
            </Button>
            <Button
              disabled={this.state.carregando}
              color='danger'
              onClick={() => {
                this.setState({ showModalProposta: false });
              }}
            >
              <i className='fas fa-times-circle'></i> Não
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          className='no-print'
          isOpen={this.state.showModalContrato}
          onClosed={
            !this.state.showModalContrato
              ? () => this.setState({ showBotaoGerarAcesso: false })
              : null
          }
          size='xl'
          id='modal-clientes'
        >
          <ModalHeader
            toggle={() => this.setState({ showModalContrato: false })}
          >
            Criação do contrato {this.state.contrato.numContrato}
          </ModalHeader>
          <ModalBody>
            <Alert color='secondary'>
              <legend>Contatos</legend>
              <p>
                Segue abaixo todos os contatos que estão vinculados a este
                orçamento. Confirme todas as informações e selecione um contato
                principal!
                <br />
                <small>
                  <strong>
                    Atenção: o sistema irá gerar um login com as informações do{' '}
                    <u>contato principal</u>, por isso confirme se ele possui um
                    e-mail válido!
                  </strong>
                </small>
              </p>
            </Alert>
            <div className='table-responsive'>
              <Table className='table table-bordered'>
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>E-mail</th>
                    <th>Celular</th>
                    <th>Principal</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.contatosOrcamento.length > 0
                    ? this.state.contatosOrcamento.map((contato, i) => (
                        <tr key={'c' + i} style={{ cursor: 'default' }}>
                          <td>{contato.Nome}</td>
                          <td>{contato.email}</td>
                          <td>{contato.Celular}</td>
                          <td>
                            {contato.ContatoPrinc === false
                              ? 'NAO'
                              : contato.ContatoPrinc === true
                              ? 'SIM'
                              : contato.ContatoPrinc}
                          </td>
                          <td>
                            <ButtonGroup>
                              <Button
                                color='link'
                                onClick={() => {
                                  this.setState({
                                    contato,
                                    showModalContato: true,
                                    tipoPessoa: contato.CNPJ
                                      ? 'juridica'
                                      : 'fisica',
                                  });
                                }}
                              >
                                <i className='fas fa-pencil-alt'></i>
                              </Button>
                              <Button
                                color='link'
                                onClick={this.deletarContato.bind(
                                  this,
                                  contato
                                )}
                              >
                                <i className='fas fa-trash'></i>
                              </Button>
                            </ButtonGroup>
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </Table>
            </div>
            <output
              className={`form-group ${this.state.validators.temUmPrincipal}`}
              style={{ color: '#DD4B39' }}
            >
              <strong>É necessário ter um contato principal!</strong>
            </output>
            <output
              className={`form-group ${this.state.validators.infoContatoPrincipal}`}
              style={{ color: '#DD4B39' }}
            >
              <strong>Faltam informações do contato principal!</strong>
            </output>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                disabled={this.state.carregando}
                color='info'
                className='float-right'
                onClick={() => {
                  this.setState({
                    contato: {
                      idContato: '',
                      Nome: '',
                      email: '',
                      Fixo: '',
                      Celular: '',
                      CPF: '',
                      RG: '',
                      ContatoPrinc: false,
                    },
                    showModalContato: true,
                  });
                }}
              >
                <i className='fas fa-user-plus'></i> Novo contato
              </Button>
            </div>
            <legend>Outras informações</legend>
            <Row>
              <Col sm='4'>
                <Label>Data da formatura:</Label>
                <input
                  type='date'
                  className='form-control'
                  value={this.state.contrato.dataFormatura}
                  onChange={(e) =>
                    this.setState({
                      contrato: {
                        ...this.state.contrato,
                        dataFormatura: e.target.value,
                      },
                    })
                  }
                  onBlur={this.atualizarDataEntrega.bind(this)}
                />
              </Col>
              <Col sm='4'>
                <Label>Data de entrega:</Label>
                <input
                  type='date'
                  className='form-control'
                  value={this.state.contrato.dataEntrega}
                  onChange={(e) =>
                    this.setState({
                      contrato: {
                        ...this.state.contrato,
                        dataEntrega: e.target.value,
                      },
                    })
                  }
                />
              </Col>
              <Col sm='4'>
                <Label>Data de chegada:</Label>
                <input
                  type='date'
                  className='form-control'
                  value={this.state.contrato.dataChegada}
                  onChange={(e) =>
                    this.setState({
                      contrato: {
                        ...this.state.contrato,
                        dataChegada: e.target.value,
                      },
                    })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col sm='12' md='8'>
                <Label>Referência do cliente:</Label>
                <input
                  type='text'
                  className='form-control'
                  value={this.state.contrato.ref_cliente}
                  onChange={(e) => {
                    this.setState({
                      contrato: {
                        ...this.state.contrato,
                        ref_cliente: e.target.value,
                      },
                    });
                  }}
                />
              </Col>
              <Col sm='12' md='4'>
                <Label>Ano corrente:</Label>
                <input
                  type='text'
                  className='form-control form-group'
                  value={this.state.contrato.anoCorrente}
                  onChange={(e) => {
                    this.setState({
                      contrato: {
                        ...this.state.contrato,
                        anoCorrente: e.target.value,
                      },
                    });
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <div className='switch'>
                  <input
                    type='checkbox'
                    defaultChecked={this.state.contrato.sistemaPagto}
                    onClick={(e) =>
                      this.setState({
                        contrato: {
                          ...this.state.contrato,
                          sistemaPagto: e.target.checked,
                        },
                      })
                    }
                    id='switch2'
                    className='switch__input'
                  ></input>
                  <label
                    htmlFor='switch2'
                    className='switch__label'
                    style={{ fontWeight: 400 }}
                  >
                    {' '}
                    Este contrato utilizará o sistema de pagamento.
                  </label>
                </div>
              </Col>
            </Row>
            {this.state.contrato.sistemaPagto ? (
              <React.Fragment>
                <Row>
                  <Col sm='4'>
                    <FormGroup>
                      <Label>
                        Digite a porcentagem de desconto para os membros da
                        comissão (caso eles tenham):
                      </Label>
                      <input
                        type='number'
                        max='100'
                        className='form-control'
                        value={this.state.descontoComissao}
                        onChange={(e) =>
                          this.setState({ descontoComissao: e.target.value })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm='4'>
                    <Label>Data máxima para pagamento:</Label>
                    <input
                      type='date'
                      className='form-control'
                      value={this.state.contrato.dataMaximaPagto}
                      onChange={(e) =>
                        this.setState({
                          contrato: {
                            ...this.state.contrato,
                            dataMaximaPagto: e.target.value,
                          },
                        })
                      }
                    />
                  </Col>
                </Row>
              </React.Fragment>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={this.state.carregando}
              color='success'
              onClick={this.handleGerarAcesso.bind(this)}
            >
              {this.state.carregando ? (
                <span>
                  <i className='fas fa-spinner fa-spin'></i> Gerando acesso{' '}
                </span>
              ) : (
                <span>
                  <i className='fas fa-check-circle'></i> Gerar acesso
                </span>
              )}
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          className='no-print'
          isOpen={this.state.showModalContato}
          size='md'
          id='modal-contato'
        >
          <ModalHeader
            toggle={() =>
              this.setState({
                showModalContato: false,
                showBotaoGerarAcesso: false,
              })
            }
          >
            Contato
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={this.salvarContato.bind(this)}>
              <Row>
                <Col md='12'>
                  <FormGroup>
                    <Label for='Nome'>Nome</Label>
                    <Input
                      type='text'
                      id='Nome'
                      name='Nome'
                      onChange={this.alterarContato.bind(this)}
                      value={this.state.contato.Nome}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md='12'>
                  <FormGroup>
                    <Label for='email'>E-mail</Label>
                    <Input
                      type='email'
                      id='email'
                      name='email'
                      onChange={this.alterarContato.bind(this)}
                      value={this.state.contato.email}
                      required={
                        this.state.contato.ContatoPrinc === 'SIM'
                          ? true
                          : this.state.contato.ContatoPrinc === true
                          ? true
                          : false
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm='12' md='6'>
                  <FormGroup>
                    <Label for='Fixo'>Telefone</Label>
                    <InputMask
                      mask='(99) 9999-9999'
                      className='form-control'
                      id='Fixo'
                      name='Fixo'
                      onChange={this.alterarContato.bind(this)}
                      value={this.state.contato.Fixo}
                    />
                  </FormGroup>
                </Col>
                <Col sm='12' md='6'>
                  <FormGroup>
                    <Label for='Celular'>Celular</Label>
                    <InputMask
                      mask='(99) 9 9999-9999'
                      className='form-control'
                      id='Celular'
                      name='Celular'
                      onChange={this.alterarContato.bind(this)}
                      value={this.state.contato.Celular}
                      required={
                        this.state.contato.ContatoPrinc === 'SIM'
                          ? true
                          : this.state.contato.ContatoPrinc === true
                          ? true
                          : false
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm='12' md='6'>
                  <FormGroup>
                    <Label for='outroTelefone'>
                      Outro telefone: {this.state.mascaraOutroTelefone}
                    </Label>
                    {this.state.mascaraOutroTelefone === 'celular' ? (
                      <React.Fragment>
                        <InputMask
                          mask='(99) 9 9999-9999'
                          className='form-control'
                          id='outroTelefone'
                          name='outroTelefone'
                          onChange={this.alterarContato.bind(this)}
                          value={this.state.contato.outroTelefone}
                        />
                        <button
                          className='btn btn-link'
                          type='button'
                          onClick={() =>
                            this.setState({
                              mascaraOutroTelefone: 'telefone',
                              contato: {
                                ...this.state.contato,
                                outroTelefone: '',
                              },
                            })
                          }
                        >
                          Trocar para telefone
                        </button>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <InputMask
                          mask='(99) 9999-9999'
                          className='form-control'
                          id='outroTelefone'
                          name='outroTelefone'
                          onChange={this.alterarContato.bind(this)}
                          value={this.state.contato.outroTelefone}
                        />
                        <button
                          className='btn btn-link'
                          type='button'
                          onClick={() =>
                            this.setState({
                              mascaraOutroTelefone: 'celular',
                              contato: {
                                ...this.state.contato,
                                outroTelefone: '',
                              },
                            })
                          }
                        >
                          Trocar para celular
                        </button>
                      </React.Fragment>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md='12'>
                  <Label>
                    <input
                      type='checkbox'
                      onClick={(e) => {
                        let ContatoPrinc = this.state.contato.ContatoPrinc;
                        ContatoPrinc = !e.target.checked;
                        this.setState({ ContatoPrinc });
                      }}
                      name='ContatoPrinc'
                      onChange={this.alterarContato.bind(this)}
                      checked={
                        this.state.contato.ContatoPrinc === 'NAO' ||
                        this.state.contato.ContatoPrinc === false
                          ? false
                          : true
                      }
                    />{' '}
                    Contato principal
                  </Label>
                </Col>
              </Row>
              <hr />
              <Row
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingRight: 15,
                }}
              >
                {this.state.carregando ? (
                  <i className='fas fa-spinner fa-spin'></i>
                ) : null}
                <Button
                  disabled={this.state.carregando}
                  color='success'
                  type='submit'
                >
                  <i className='far fa-save'></i> Salvar
                </Button>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
        <Modal
          className='no-print'
          isOpen={this.state.showModalAddItem}
          size='lg'
          id='inserir_item'
        >
          <ModalAdicionarItem
            itemFuturo={1}
            orcamento={this.state.orcamento}
            criarLog={(data) => this.criarLog('ERROR', data.erro, data.msg)}
            close={(orcamento) => this.atualizarItens(orcamento)}
            possuiArte={this.props.possuiArte}
            possuiFrete={this.props.possuiFrete}
            possuiPagamentoIndividual={this.state.orcamento.pagamentoIndividual}
            listaPrecosArte={this.props.listaPrecosArte}
            listaPrecosFrete={this.props.listaPrecosFrete}
          />
        </Modal>
        <Modal
          className='no-print'
          isOpen={this.state.showModalDeletarKit}
          size='sm'
          id='confirmar_delete'
        >
          <ModalHeader
            toggle={() => this.setState({ showModalDeleteItem: false })}
          >
            Confirme sua ação
          </ModalHeader>
          <ModalBody>Você tem certeza que quer excluir este item?</ModalBody>
          <ModalFooter>
            <Button color='success' onClick={this.deletarKit.bind(this)}>
              <i className='fas fa-check-circle'></i> Confirmar
            </Button>
            <Button
              color='danger'
              onClick={() => this.setState({ showModalDeleteItem: false })}
            >
              <i className='fas fa-times-circle'></i> Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
