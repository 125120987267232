import React from 'react';
import {BrowserRouter as ReactRouter, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import PrivateRoute from '../components/PrivateRoute';

//Views
import Login from '../views/Login';
import Mapa from '../views/Mapa';
import NotFound from '../views/NotFound';
import Faculdades from '../views/Faculdades';
import Cadastro from '../views/Cadastro';
import Clientes from '../views/Clientes';
import EditarClientes from '../views/EditarClientes';
import Instituicoes from '../views/Instituicoes';
import Orcamento1 from '../views/Orcamento1';
import Orcamento1b from '../views/Orcamento1b';
import Orcamento2 from '../views/Orcamento2';
import Contrato from '../views/Contrato';
import Historico from '../views/Historico';
import Usuarios from '../views/Usuarios';
import KitsOrcamento from '../views/KitsOrcamento';
import MeusVouchers from '../views/MeusVouchers';
import Principal from '../views/PadraoLC/Principal';

class Router extends React.Component {
  render(){
    return (
      <ReactRouter>
        <div>
          <Switch>
            <Route exact path="/login" component={Login} />
            <PrivateRoute exact path="/" component={Mapa} title="Contrato" />
            <PrivateRoute exact path="/mapa" component={Mapa} title="Contrato" />
            <PrivateRoute exact path="/select_client" component={Orcamento1} title="Listar orçamentos pelo cliente" />
            <PrivateRoute exact path="/select_orcamento" component={Orcamento1b} title="Listar orçamentos" />
            <PrivateRoute exact path="/orcamento" component={Orcamento2} router={this.props.router} title="Orcamento" />
            <PrivateRoute exact path="/faculdades/:code" component={Faculdades} title="Faculdades" />
            <PrivateRoute exact path="/faculdades" component={Faculdades} title="Faculdades" />
            <PrivateRoute exact path="/cadastro" component={Cadastro} title="Cadastro" />
            <PrivateRoute exact path="/clientes" component={Clientes} title="Clientes" />
            <PrivateRoute exact path="/clientes/editar" component={EditarClientes} title="Editar clientes" />
            <PrivateRoute exact path="/instituicoes" component={Instituicoes} title="Gerenciar Instituições" />
            <PrivateRoute exact path="/contrato" component={Contrato} title="Contrato" />
            <PrivateRoute exact path="/historico" component={Historico} title="Historico" />
            <PrivateRoute exact path="/usuarios" component={Usuarios} title="Usuarios" />
            <PrivateRoute exact path="/kits-orcamento" component={KitsOrcamento} />
            <PrivateRoute exact path="/meus-vouchers" component={MeusVouchers} title="Meus Vouchers" />
            <PrivateRoute exact path="/relatorios/principal" component={Principal} title="Tabela de contratos" />
            <PrivateRoute component={NotFound} title="Página não encontrada" />
          </Switch>
        </div>
      </ReactRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
}

export default connect(mapStateToProps, {})(Router);