import React from 'react';
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { Input, Row, Col, Card, CardHeader, CardBody, CardTitle, CustomInput, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label } from 'reactstrap';
import NavBar from '../../components/NavBar';
import axios from 'axios';
import { REQUEST_URL } from '../../config/urls.js';
import { WEBHOOK_B24 } from '../../config/urls.js';
import './styles.css';

class Usuarios extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			usuarios: [],
			usuarioSelecionado: {},
			showModal: false,
			usuarioCadastro: {
				Email: "",
				Funcao: "",
				Login: "",
				Nivel: "",
				Nome: "",
				Senha: "",
				Telefone: "",
				cvFixo: "",
				idBitrix: "",
				senhaEmail: "",
				estado: "",
				carteiraTrabalho: "",
				cidade: "",
				endereco: "",
				margemCusto: "",
				liberarMargens: "",
				contadeparceiro: false,
			},
			nomeBitrixBusca: "",
			usuariosDaBusca: []
		};
	}
	componentDidMount() {
		axios.defaults.headers.common = {'Authorization': sessionStorage.getItem("token")};
		if (sessionStorage.getItem('usuarioLogado')) {
			this.setState({ usuarioLogado: JSON.parse(sessionStorage.getItem('usuarioLogado'))[0] }, () => {
				if (this.state.usuarioLogado.Funcao === "VENDEDOR" || this.state.usuarioLogado.Nivel === "RESTRITO") {
					this.props.history.push({
						pathname: "/mapa"
					});
				}
			});
		}
		this.buscarUsuarios();
	}
	buscarUsuarios() {
		axios.get(`${REQUEST_URL}/usuarios`).then((response) => {
			this.setState({ usuarios: response.data });
		});
	}
	onFormChange(e) {
		let usuarioCadastro = Object.assign({}, this.state.usuarioCadastro);
		if (e.target.type == "select") {
			usuarioCadastro[e.target.name] = e.options[e.selectedIndex].text;
		} else if(e.target.type === 'checkbox') {
			const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
			usuarioCadastro[e.target.name] = value ? "1" : "0";
		}	else {
			usuarioCadastro[e.target.name] = e.target.value;
		}
		this.setState({ usuarioCadastro });
	}
	confimarModal() {
		if (this.state.editar) {
			this.editar();
		} else {
			this.salvar();
		}	
	}
	salvar() {
		axios.post(`${REQUEST_URL}/usuarios`, this.state.usuarioCadastro).then((response) => {
			toast.success("Cadastrado com sucesso.");
			this.setState({
				usuarioCadastro: {
					Email: "",
					Funcao: "",
					Login: "",
					Nivel: "",
					Nome: "",
					Senha: "",
					Telefone: "",
					cvFixo: "",
					idBitrix: "",
					senhaEmail: "",
					estado: "",
					carteiraTrabalho: "",
					cidade: "",
					endereco: "",
					contadeparceiro: false
				}, showModal: false
			});
			this.buscarUsuarios();
		}).catch((erro) => {
			toast.error("Algo deu errado não foi possivel cadastrar");
		});
	}
	editar() {
		axios.put(`${REQUEST_URL}/usuarios/${this.state.usuarioCadastro.idUsuario}`, this.state.usuarioCadastro).then((response) => {
			toast.success("Editado com sucesso.");
			this.buscarUsuarios();
		}).catch((erro) => {
			toast.error("Algo deu errado não foi possivel editar");
		});
	}
	excluirUsuario() {
		let idUsuario = this.state.usuarioSelecionado.idUsuario;
		axios.delete(`${REQUEST_URL}/usuarios/${idUsuario}`).then((response) => {
			this.buscarUsuarios();
			toast.success("Deletado com sucesso");
			this.setState({ showModalExcluir: false });
		}).catch((erro) => {
			toast.error("Não é possivel excluir usuarios com clientes vinculados");
			this.setState({ showModalExcluir: false });
		})
	}
	buscarNomeNoBitrix(e) {
		e.preventDefault();
		delete axios.defaults.headers.common["Authorization"];
		axios.get(`${WEBHOOK_B24}/user.get?filter[NAME]=${this.state.nomeBitrixBusca}`).then((response) => {
			this.setState({ usuariosDaBusca: response.data.result });
			axios.defaults.headers.common = {'Authorization': sessionStorage.getItem("token")};
		});
	}
	render() {
		return (
			<div>
				<ToastContainer position="bottom-left" autoClose={10000} newestOnTop={false} closeOnClick rtl={false} pauseOnVisibilityChange={false} draggable={false}/>
				<NavBar nome={this.state.usuarioLogado && this.state.usuarioLogado.Nome} funcao={this.state.usuarioLogado && this.state.usuarioLogado.Funcao} />
				<Row className="conteudo" style={{ margin: "0px 0px 0px 0px" }}>
					<Col id="container-clientes" style={{ marginTop: 20 }}>
						<Card style={{ border: "none", borderRadius: 0, boxShadow: "0 2px 5px 0 rgba(0,0,0,.26)" }}>
							<CardHeader>
								<CardTitle>Usuarios Tabela/Prospecto</CardTitle>
							</CardHeader>
							<CardBody>
								<div className="table-reponsive" style={{ height: 500, overflowY: "auto" }}>
									<Table hover>
										<thead>
											<tr>
												<th>#ID</th>
												<th>Nome</th>
												<th>Login</th>
												<th>Senha</th>
												<th>Nivel</th>
												<th>Função</th>
												<th>Email</th>
												<th>Senha Email</th>
												<th>Telefone</th>
												<th>ID Bitrix</th>
												<th>Margem Fixa</th>
											</tr>
										</thead>
										<tbody>
											{
												this.state.usuarios.map((usuario) => (
													<tr key={usuario.idUsuario} className={this.state.usuarioSelecionado.idUsuario === usuario.idUsuario ? "table-info" : ""} onClick={() => { this.setState({ usuarioSelecionado: usuario }) }}>
														<td>{usuario.idUsuario}</td>
														<td>{usuario.Nome}</td>
														<td>{usuario.Login}</td>
														<td>{this.state.usuarioLogado.Nivel === "SENIOR" ? usuario.Senha : "-"}</td>
														<td>{usuario.Nivel}</td>
														<td>{usuario.Funcao}</td>
														<td>{usuario.Email}</td>
														<td>{this.state.usuarioLogado.Nivel === "SENIOR" ? usuario.senhaEmail : "-"}</td>
														<td>{usuario.Telefone}</td>
														<td>{usuario.idBitrix}</td>
														<td>{usuario.cvFixo}</td>
													</tr>
												))
											}
										</tbody>
									</Table>
								</div>
								<div className="opcoes">
									<Button onClick={() => this.setState({ showModal: true, editar: false, usuarioCadastro: {} })} color="success">Cadastrar Novo</Button>{' '}
									<Button disabled={!this.state.usuarioSelecionado.idUsuario} onClick={() => this.setState({ showModal: true, editar: true, usuarioCadastro: this.state.usuarioSelecionado })} color="info">Editar</Button>{' '}
									<Button disabled={!this.state.usuarioSelecionado.idUsuario} onClick={() => this.setState({ showModalExcluir: true, editar: false, usuarioCadastro: this.state.usuarioSelecionado })} color="danger">Excluir</Button>{' '}
									<Button color="primary" onClick={() => this.setState({ showModalBitrix: true })} >Buscar no Bitrix</Button>{' '}
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
				<Modal isOpen={this.state.showModal} size="lg" id="modal-cadastro">
					<ModalHeader toggle={() => this.setState({ showModal: false })}>{this.state.editar ? "Editando" : "Cadastrando"}</ModalHeader>
					<ModalBody>
						<Form onChange={this.onFormChange.bind(this)}>
							<Row form>
								<Col md={6}>
									<FormGroup>
										<Label for="nome">Nome</Label>
										<Input type="text" name="Nome" id="nome" placeholder="Nome" value={this.state.usuarioCadastro.Nome} />
									</FormGroup>
								</Col>
								<Col md={6}>
									<FormGroup>
										<Label for="login">Login</Label>
										<Input type="text" name="Login" id="nomelogin" placeholder="Login" value={this.state.usuarioCadastro.Login} />
									</FormGroup>
								</Col>
							</Row>
							<Row form>
								<Col md={6}>
									<FormGroup>
										<Label for="senha">Senha</Label>
										<Input type="password" name="Senha" id="senha" placeholder="Senha" value={this.state.usuarioCadastro.Senha} />
									</FormGroup>
								</Col>
							</Row>
							<hr></hr>
							<FormGroup>
								<Label for="nivel">Nivel de Acesso</Label>
								<Input type="select" name="Nivel" id="nivel" value={this.state.usuarioCadastro.Nivel}>
									<option></option>
									<option>RESTRITO</option>
									<option>SENIOR</option>
									<option>GERENTE</option>
									<option>REGULAR</option>
								</Input>
							</FormGroup>
							<FormGroup>
								<Label for="nivel">Função</Label>
								<Input type="select" name="Funcao" id="funcao" value={this.state.usuarioCadastro.Funcao}>
									<option></option>
									<option>ORCAMENTISTA</option>
									<option>VENDEDOR</option>
								</Input>
							</FormGroup>
							<FormGroup>
								<div style={{ padding: 10 }}>
									<label>
										<input name="contadeparceiro" type="checkbox" onClick={()=>{this.setState({contadeparceiro: !this.state.usuarioCadastro.contadeparceiro})}}/>
										É parceiro?
									</label>
								</div>
							</FormGroup>
							<hr></hr>
							<Row form>
								<Col md={8}>
									<FormGroup>
										<Label for="email">Email</Label>
										<Input type="email" name="Email" id="email" value={this.state.usuarioCadastro.Email} />
									</FormGroup>
								</Col>
								<Col md={4}>
									<FormGroup>
										<Label for="senhaEmail">Senha Email</Label>
										<Input type="password" name="senhaEmail" id="senhaEmail" value={this.state.usuarioCadastro.senhaEmail} />
									</FormGroup>
								</Col>
							</Row>
							<Row form>
								<Col md={4}>
									<FormGroup>
										<Label for="telefone">Telefone</Label>
										<Input type="text" name="Telefone" id="telefone" value={this.state.usuarioCadastro.Telefone} />
									</FormGroup>
								</Col>
								<Col md={4}>
									<FormGroup>
										<Label for="idBitrix">ID Bitrix</Label>
										<Input type="number" name="idBitrix" id="idBitrix" value={this.state.usuarioCadastro.idBitrix} />
									</FormGroup>
								</Col>
								<Col md={4}>
									<FormGroup>
										<Label for="cvFixo">Margem Custo</Label>
										<Input type="number" name="margemCusto" id="margemCusto" value={this.state.usuarioCadastro.margemCusto} />
									</FormGroup>
								</Col>
								<Col md={4}>
									<FormGroup>
										<Label for="cvFixo">Margem cv padrão</Label>
										<Input type="number" name="cvFixo" id="cvFixo" value={this.state.usuarioCadastro.cvFixo} />
									</FormGroup>
								</Col>
								<Col md={4}>
									<FormGroup>
										<Label for="cvFixo">Permitir edição cv</Label>
										<Input type="select" name="liberarMargens" id="liberarMargens" value={this.state.usuarioCadastro.liberarMargens}>
											<option></option>
											<option value="1">Sim</option>
											<option value="0">Bloquear</option>
										</Input>
									</FormGroup>
								</Col>
							</Row>
							<Row form>
								<Col md={8}>
									<FormGroup>
										<Label for="idBitrix">Carteira de trabalho</Label>
										<Input type="text" name="carteiraTrabalho" id="carteiraTrabalho" value={this.state.usuarioCadastro.carteiraTrabalho} />
									</FormGroup>
								</Col>
							</Row>
							<Row form>
								<Col md={4}>
									<FormGroup>
										<Label for="telefone">Cidade</Label>
										<Input type="text" name="cidade" id="cidade" value={this.state.usuarioCadastro.cidade} />
									</FormGroup>
								</Col>
								<Col md={3}>
									<FormGroup>
										<Label for="telefone">Estado</Label>
										<Input type="text" name="estado" id="estado" value={this.state.usuarioCadastro.estado} />
									</FormGroup>
								</Col>
								<Col md={5}>
									<FormGroup>
										<Label for="idBitrix">Endereco</Label>
										<Input type="text" name="endereco" id="endereco" value={this.state.usuarioCadastro.endereco} />
									</FormGroup>
								</Col>
							</Row>
							<Button onClick={this.confimarModal.bind(this)} color="success">{this.state.editar ? "EDITAR" : "CADASTRAR"}</Button>
						</Form>
					</ModalBody>
				</Modal>
				<Modal isOpen={this.state.showModalExcluir} size="sm" id="modal-excluir">
					<ModalHeader toggle={() => this.setState({ showModalExcluir: false })}>Exclusão de vendedor</ModalHeader>
					<ModalBody>
						Você tem certeza que quer excluir este vendedor?
					</ModalBody>
					<ModalFooter>
						<Button onClick={() => this.setState({ showModalExcluir: false, editar: false, usuarioCadastro: {} })} color="defaut">Cancelar</Button>{' '}
						<Button onClick={this.excluirUsuario.bind(this)} color="danger">Excluir</Button>
					</ModalFooter>
				</Modal>
				<Modal isOpen={this.state.showModalBitrix} size="lg" id="modal-busca-bitrix">
					<ModalHeader toggle={() => this.setState({ showModalBitrix: false })}>Busque por nome:</ModalHeader>
					<ModalBody>
						<form onSubmit={this.buscarNomeNoBitrix.bind(this)}>
							<Label>Nome do usuário</Label>
							<Input value={this.state.nomeBitrixBusca} onChange={(e) => this.setState({ nomeBitrixBusca: e.target.value })} />
						</form>
						<div>
							{this.state.usuariosDaBusca.length} resultados
							{
								this.state.usuariosDaBusca.map((u) => (
									<div style={{ margin: 5, padding: 5, border: "1px solid gray" }}>
										{
											Object.keys(u).map((key) => (
												<div>{key} : {u[key]}</div>
											))
										}
									</div>
								))
							}
						</div>
					</ModalBody>
				</Modal>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		usuario: state.usuario.autenticado,
		erro: state.usuario.erro,
		carregando: state.usuario.carregando
	};
}

export default connect(mapStateToProps, {})(Usuarios);

