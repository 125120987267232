import React, { useState, useEffect } from "react";
import { REQUEST_URL } from "../config/urls";
import { FaSpinner, FaTimes, FaPlus } from "react-icons/fa";
import { ModalHeader, ModalBody, Button, FormGroup, Label, Form, Badge, Input } from 'reactstrap';
import axios from "axios";
import Select from 'react-select';
import 'react-select/dist/react-select.css';

import TelegramLog from '../services/telegram';

export default function ModalCursos (props) {

  const [instituicaoSelecionada, setInstituicaoSelecionada] = useState({});
  const [cursosVinculados, setCursosVinculados] = useState([]);
  const [carregandoCursos, setCarregandoCursos] = useState(false);
  const [cursosDoBanco, setCursosDoBanco] = useState(null);
  const [cursosSelect, setCursosSelect] = useState(null);
  const [showDivAdicionarCurso, setShowDivAdicionarCurso] = useState(false);
  const [cursoSelecionado, setCursoSelecionado] = useState(null);
  const [cursoInserido, setCursoInserido] = useState("");
  const [carregandoAdicionarCurso, setCarregandoAdicionarCurso] = useState(false);
  const [showFormAdicionarCurso, setShowFormAdicionarCurso] = useState(true);
  const [carregandoCadastrarCurso, setCarregandoCadastrarCurso] = useState(false);

  useEffect (() => {
    const { instituicao, cursos } = props;
    setInstituicaoSelecionada(instituicao);
    setCursosVinculados(ordenarNomeCursoAlfabeticamente(removerCursosOcultos(cursos)));
  }, []);

  useEffect (() => {
    if (carregandoCursos) {
      axios.get(`${REQUEST_URL}/cursos`, {headers: {"Authorization": sessionStorage.getItem("token")}}).then((response) => {
        let aux = [];
        response.data.forEach((curso) => {
          aux.push({ value: curso.idcurso, label: curso.nomecurso });
        });
        setCursosDoBanco(aux);
        setCarregandoCursos(false);
        criarCursosSelect(aux);
      }).catch((erro) => {
        let aux = {value: 0, label: "Falha na consulta de cursos. Atualize a tela e tente novamente!"};
        setCursosDoBanco(aux);
        setCarregandoCursos(false);
        criarCursosSelect(aux);
        new TelegramLog().novo("ERRO", [JSON.stringify(erro), "Erro ao consultar os cursos", `usuarioLogado: ${JSON.parse(sessionStorage.getItem("usuarioLogado"))[0].Login}`]);
      });
    }
  }, [carregandoCursos]);

  useEffect (() => {
    if (carregandoAdicionarCurso) {
      axios.post(`${REQUEST_URL}/instituicoes/novo-curso`, { cod_ies: instituicaoSelecionada.value, cod_curso: cursoSelecionado.value, nome_curso: cursoSelecionado.label, cod_cidade: instituicaoSelecionada.idcidade }, { headers: {"Authorization": sessionStorage.getItem("token")} }).then((response) => {
        let auxCursosVinculados = cursosVinculados;
        auxCursosVinculados.push(response.data);
        setCursosVinculados(ordenarNomeCursoAlfabeticamente(auxCursosVinculados));
        setShowDivAdicionarCurso(false);
        setCarregandoAdicionarCurso(false);
        setCursoSelecionado(null);
      }).catch((erro) => {
        new TelegramLog().novo("ERRO", [JSON.stringify(erro), "Erro ao vincular um curso a universidade", `usuarioLogado: ${JSON.parse(sessionStorage.getItem("usuarioLogado"))[0].Login}`]);
        setShowDivAdicionarCurso(false);
        setCarregandoAdicionarCurso(false);
        setCursoSelecionado(null);
      });
    }
  }, [carregandoAdicionarCurso]);

  useEffect (() => {
    if (carregandoCadastrarCurso) {
      axios.post(`${REQUEST_URL}/cadastrar-curso`, { curso: cursoInserido }, { headers: {"Authorization": sessionStorage.getItem("token")} }).then((response) => {
        handleChangeCurso({ value: response.data.insertId, label: cursoInserido });
        setCarregandoAdicionarCurso(true);
        props.close({ tipo: "sucesso", mensagem: "Curso cadastrado com sucesso!" });
      }).catch((erro) => {
        new TelegramLog().novo("ERRO", [JSON.stringify(erro), "Erro ao cadastrar um curso", `usuarioLogado: ${JSON.parse(sessionStorage.getItem("usuarioLogado"))[0].Login}`]);
        setShowDivAdicionarCurso(false);
        setCarregandoCadastrarCurso(false);
        setCursoSelecionado(null);
      });
    }
  }, [carregandoCadastrarCurso]);

  function handleClickRemoverCurso (data) {
    axios.post(`${REQUEST_URL}/desativarCurso`, data, {headers: {"Authorization": sessionStorage.getItem("token")}}).then((response) => {
      setCursosVinculados(cursosVinculados.filter((curso) => { return curso.id_curso_inst !== data.id_curso_inst }));
    }).catch((erro) => {
      new TelegramLog().novo("ERRO", [JSON.stringify(erro), "Erro ao desativar um curso da instituição", `usuarioLogado: ${JSON.parse(sessionStorage.getItem("usuarioLogado"))[0].Login}`]);
    });
    console.log(data);
  }

  function handleChangeCurso (data) {
    setCursoSelecionado(data);
  }

  function handleClickVincularCurso () {
    if (!cursosDoBanco) {
      setCarregandoCursos(true);
    } else {
      criarCursosSelect(cursosDoBanco);
    }
  }

  function criarCursosSelect (cursos) {
    cursosVinculados.forEach((cursoVinculado) => {
      cursos = cursos.filter((curso) => { return curso.label !== cursoVinculado.nomecurso });
    });
    setCursosSelect(cursos);
    setShowFormAdicionarCurso(true);
    setShowDivAdicionarCurso(true);
  }

  function handleSubmitAdicionarCurso (event) {
    event.preventDefault();
    setCarregandoAdicionarCurso(true);
  }

  function handleSubmitCadastrarCurso (event) {
    event.preventDefault();
    setCarregandoCadastrarCurso(true);
  }

  function removerCursosOcultos (data) {
    return data.filter((curso) => { return curso.esconder !== 1 });
  }

  function ordenarNomeCursoAlfabeticamente (data) {
    return data.sort((a, b) => { return a.nomecurso > b.nomecurso ? 1 : -1 });
  }

  return (
    <React.Fragment>
      <ModalHeader toggle={props.close}>Gerenciar cursos</ModalHeader>
      <ModalBody>
        {
          showDivAdicionarCurso ?
            showFormAdicionarCurso ?
              <Form onSubmit={handleSubmitAdicionarCurso}>
                <FormGroup>
                  <Label for="curso">Selecione um curso</Label>
                  <Select
                    name="curso" id="curso" required
                    value={cursoSelecionado}
                    onChange={handleChangeCurso}
                    options={cursosSelect}
                    placeholder="Selecione"
                  />
                  <p className="pLink" onClick={() => setShowFormAdicionarCurso(false)}>Não achou o curso que procura? Clique aqui para cadastrar.</p>
                </FormGroup>
                <Button color="link" type="button" onClick={() => setShowDivAdicionarCurso(false)} disabled={carregandoAdicionarCurso}>Cancelar</Button>
                <Button color="primary" type="submit" disabled={carregandoAdicionarCurso}>
                  { 
                    carregandoAdicionarCurso ?
                      <span style={{ display: "flex", alignItems: "center" }}><FaSpinner style={{ marginRight: 5 }} className="rodarIcone"/> Carregando</span>
                    : "Adicionar"
                  }
                </Button>
              </Form>
            : <Form onSubmit={handleSubmitCadastrarCurso}>
              <FormGroup>
                <Label for="curso">Curso</Label>
                <Input name="curso" id="curso" value={cursoInserido} onChange={(e) => setCursoInserido(e.target.value)} required />
              </FormGroup>
              <Button color="link" type="button" onClick={() => setShowDivAdicionarCurso(false)} disabled={carregandoCadastrarCurso}>Cancelar</Button>
              <Button color="primary" type="submit" disabled={carregandoCadastrarCurso}>
                { 
                  carregandoCadastrarCurso ?
                    <span style={{ display: "flex", alignItems: "center" }}><FaSpinner style={{ marginRight: 5 }} className="rodarIcone"/> Carregando</span>
                  : "Adicionar"
                }
              </Button>
            </Form>
          : <div style={{display: "flex", flexWrap: "wrap"}}>
            {
              cursosVinculados.map((curso, i) => (
                <Badge key={i} color="secondary" className="badgePersonalizada">{curso.nomecurso} <FaTimes style={{cursor: "pointer", marginLeft: 5}} onClick={() => handleClickRemoverCurso(curso)}/></Badge>
              ))
            }
            <Badge color="success" style={{ cursor: "pointer" }} onClick={handleClickVincularCurso} className="badgePersonalizada"><FaPlus style={{ marginRight: 5 }}/> NOVO</Badge>
          </div>
        }
      </ModalBody>
    </React.Fragment>
  );
}