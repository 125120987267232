import React from 'react';
import { Link} from 'react-router-dom';
import axios from 'axios';
import {REQUEST_URL} from '../config/urls.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import {Modal, ModalBody, ModalHeader, ModalFooter, Button, Input,  Jumbotron,  Col,Row,  Card,CardBody,  Table,
} from 'reactstrap';
import  NavBar from '../components/NavBar';

import 'react-sliding-pane/dist/react-sliding-pane.css';

export default class Orcamento1 extends React.Component {
  constructor(props){
    super(props);
    document.title = "Orcamento";
    this.state = {
      usuarioLogado:{},
      contatos:[],
      inputId:"",
      inputNome:"",
      inputCurso:"",
      inputCidade:"",
      inputFaculdade:"",
      contatoSelecionado:{},
      step:1,
      ultimosOrcamentos:[],
      orcSelecionado:{},
      showModalMudarStatus:false,
      status:{}
    };
  }
  componentWillMount () {
    axios.defaults.headers.common = {'Authorization': sessionStorage.getItem("token")};
  }
  componentDidMount(){
    //console.log("logado=",sessionStorage.getItem('usuarioLogado'));
    if(sessionStorage.getItem('usuarioLogado')){
      this.setState({usuarioLogado:JSON.parse(sessionStorage.getItem('usuarioLogado'))[0]},()=>{
        axios.post(REQUEST_URL+"/clientes_vendas",{
            nome:"",
            vendedor:this.state.usuarioLogado.idUsuario,
            nivel:this.state.usuarioLogado.Nivel
        }).then((response)=>{
          this.setState({contatos:response.data});
        });
      });
    }
  }
  buscarUltimosOrcamentos(){
    axios.post(REQUEST_URL+"/orcamentos",{clienteId:this.state.contatoSelecionado.idCliente}).then((response)=>{
      this.setState({ultimosOrcamentos:response.data},()=>{
        this.gotoHistorico();
      });
    });
  }
  handleBuscaInput(event){
    let inputNome = event.target.value;
    this.setState({inputNome},()=>{
      this.procurarCliente();
    });
  }
  handleCidadeInput(event){
    let inputCidade = event.target.value;
    this.setState({inputCidade},()=>{
      this.procurarCliente();
    });
  }
  handleCursoInput(event){
    let inputCurso = event.target.value;
    this.setState({inputCurso},()=>{
      this.procurarCliente();
    });
  }
  handleFaculdadeInput(event){
    let inputFaculdade = event.target.value;
    this.setState({inputFaculdade},()=>{
      this.procurarCliente();
    });
  }
  handleIdInput(event){
    let inputId = event.target.value;
    this.setState({inputId},()=>{
      this.procurarCliente();
    });
  }
  procurarCliente(){
    axios.post(REQUEST_URL+"/clientes_vendas",{
        vendedor:this.state.usuarioLogado.idUsuario,
        id:this.state.inputId,
        nome:this.state.inputNome,
        cidade:this.state.inputCidade,
        curso:this.state.inputCurso,
        faculdade:this.state.inputFaculdade,
        nivel:this.state.usuarioLogado.Nivel
    }).then((response)=>{
      this.setState({contatos:response.data});
    });
  }
  gotoOrcamento(){
    this.props.history.push({
     pathname:"/orcamento",
     state:{
         cliente:this.state.contatoSelecionado,
      }
    });
  }
  gotoHistorico() {
    document.body.scrollTop = 800;
    document.documentElement.scrollTop = 800;
  }
  reabrirOrcamento(idOrc){
    this.props.history.push({
     pathname:"/orcamento",
     state:{
         cliente:this.state.contatoSelecionado,
         idOrc:idOrc
      }
    });
  }
  mudarStatusOrc(){
    if(this.state.orcSelecionado && this.state.status.value){
      axios.put(REQUEST_URL+"/orcamento/status/"+this.state.orcSelecionado.idOrc,{status:this.state.status.value}).then((response)=>{
        toast.success("Status Salvo");
      });
    }else{
      toast.success("Selecione um status e um orçamento");
    }
  }
  handleChangeStatus(status){
    this.setState({ status });
  }
  formatReal(n){
    return Number(n.toFixed(2)).toLocaleString('pt-BR');
  }
  render(){
    return (
      <div>
        <ToastContainer position="bottom-left" autoClose={10000} newestOnTop={false} closeOnClick rtl={false} pauseOnVisibilityChange={false} draggable={false}/>
        <NavBar nome={this.state.usuarioLogado.Nome} funcao={this.state.usuarioLogado.Funcao}/>
        <Row style={{margin:"0px 0px 0px 0px",}}>
          <Col style={{marginTop:20}} id="container-orc1">
            <Card style={{border:"none",borderRadius:0,margin:10, boxShadow:"0 2px 5px 0 rgba(0,0,0,.26)"}}>
              <CardBody >
                <Row>
                  <Col sm="12">
                    <Jumbotron style={{minHeight:600,padding:"2rem 1rem",marginBottom:0}} className="noselect">
                      <div id="header-orc1">
                        <Link to="/select_orcamento"><Button className="float-right">Selecionar por Orcamento?</Button></Link>
                        <Button color="success" className="float-right" style={{marginRight:5}} hidden={!this.state.contatoSelecionado.Descricao} onClick={()=>this.gotoOrcamento()}>Novo Orçamento</Button>
                        <h3>SELECIONE O CLIENTE </h3>
                      </div>
                      <div className="table-responsive" style={{border:"1px solid #6c757d",borderRadius:3}}>
                        Filtros:
                        <Row style={{margin:0,padding:5}}>
                          <Col lg="2" md="4" sm="6" xs="6">
                            <Input placeholder="ID"   onChange={this.handleIdInput.bind(this)} value={this.state.inputId}/>
                          </Col>
                          <Col lg="2" md="4" sm="6" xs="6">
                            <Input placeholder="Descrição"  onChange={this.handleBuscaInput.bind(this)} value={this.state.inputNome}/>
                          </Col>
                          <Col lg="2" md="4" sm="6" xs="6">
                            <Input placeholder="Cidade"  onChange={this.handleCidadeInput.bind(this)} value={this.state.inputCidade}/>
                          </Col>
                          <Col lg="2" md="4" sm="6" xs="6">
                            <Input placeholder="Curso"  onChange={this.handleCursoInput.bind(this)} value={this.state.inputCurso}/>
                          </Col>
                          <Col lg="2"  md="4" sm="6" xs="6">
                            <Input placeholder="Faculdade"  onChange={this.handleFaculdadeInput.bind(this)} value={this.state.inputFaculdade}/>
                          </Col>
                        </Row>
                      </div>
                      <div style={{height:450,overflowY:"auto",width:"100%"}} className="lista-orcamento">
                        <Table className="table-bordered table-sm">
                          <thead>
                            <tr style={{width:"100%"}}>
                              <th style={{width:"5%",whiteSpace: "nowrap",textOverflow: "ellipsis"}}> IdCliente </th>
                              <th style={{width:"25%",whiteSpace: "nowrap",textOverflow: "ellipsis"}}> Descrição </th>
                              <th style={{width:"25%",whiteSpace: "nowrap",textOverflow: "ellipsis"}}> Status </th>
                              <th style={{width:"20%",whiteSpace: "nowrap",textOverflow: "ellipsis"}}> Cidade </th>
                              <th style={{width:"25%",whiteSpace: "nowrap",textOverflow: "ellipsis"}}> Curso </th>
                              <th style={{width:"25%",whiteSpace: "nowrap",textOverflow: "ellipsis"}}> Instituição </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.contatos.map((c,i)=>(
                              <tr key={i} style={this.state.contatoSelecionado === c ?
                                  {cursor:"pointer",background:"#bbed21",width:"100%"}:
                                  {cursor:"pointer",width:"100%"}}
                                  onClick={this.state.contatoSelecionado === c ? ()=>this.setState({contatoSelecionado:{}}):()=>this.setState({contatoSelecionado:c}) }>
                                <td style={{width:"5%",whiteSpace: "nowrap",textOverflow: "ellipsis"}}> {c.idCliente} &nbsp;</td>
                                <td style={{width:"25%",whiteSpace: "nowrap",textOverflow: "ellipsis"}}> {c.Descricao} &nbsp;</td>
                                <td style={{width:"25%",whiteSpace: "nowrap",textOverflow: "ellipsis"}}> {c.Status} &nbsp;</td>
                                <td style={{width:"20%",whiteSpace: "nowrap",textOverflow: "ellipsis"}}>{c.Cidade} &nbsp;</td>
                                <td style={{width:"25%",whiteSpace: "nowrap",textOverflow: "ellipsis"}}>{c.curso} &nbsp;</td>
                                <td style={{width:"25%",whiteSpace: "nowrap",textOverflow: "ellipsis"}}>{c.SiglaInst} &nbsp;</td>
                              </tr>
                            ))
                          }
                          </tbody>
                        </Table>
                      </div>
                    </Jumbotron>
                    <div style={{textAlign:"center"}}>
                      {
                      this.state.contatoSelecionado.Descricao ?
                      <div>
                        <i onClick={()=>this.buscarUltimosOrcamentos()} style={{cursor:"pointer"}} className="fas fa-angle-down fa-4x" ></i>
                        <h5 style={{padding:0}}>Ultimos Orçamentos</h5>
                      </div>
                      :null
                      }
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            {
              this.state.contatoSelecionado.Descricao && this.state.ultimosOrcamentos.length>0  ?
              <Card style={{border:"none",borderRadius:0,margin:10,marginTop:25,height:1000, boxShadow:"0 2px 5px 0 rgba(0,0,0,.26)"}}>
                <CardBody className="table-responsive">
                  <Table>
                    <thead>
                      <tr>
                        <th>IdOrc</th>
                        <th>Inclusão</th>
                        <th>N° Itens</th>
                        <th>Status</th>
                        <th>Preço</th>
                        <th>Reabrir</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      this.state.ultimosOrcamentos.map((orc, i)=>(
                        <tr key={i}>
                          <td>{orc.idOrc}</td>
                          <td>{new Date(orc.DataInclusao).toLocaleString()}</td>
                          <td>{orc.nItens}</td>
                          <td><Button onClick={()=>{this.setState({orcSelecionado:orc,showModalMudarStatus:true})}}>{orc.Status}</Button></td>
                          <td>{orc.CustTotal ? (orc.CustTotal).toFixed(4) : "NULO"}</td>
                          <td><Button onClick={this.reabrirOrcamento.bind(this,orc.idOrc)}><i className="fas fa-arrow-right" ></i></Button></td>
                        </tr>
                      ))
                    }
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
              :null
            }
          </Col>
        </Row>
        <Modal isOpen={this.state.showModalMudarStatus} >
          <ModalHeader toggle={()=>this.setState({showModalMudarStatus:false})}>Mudar para?</ModalHeader>
          <ModalBody>
            <Select
              options={[
                {value:"Aguardando",label:"Aguardando"},
                {value:"Fechado",label:"Fechado"},
                {value:"Perdido",label:"Perdido"}
              ]}
              value={this.state.status}
              onChange={this.handleChangeStatus.bind(this)}
             />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={()=>this.setState({showModalMudarStatus:false})}>Cancelar</Button>
          <Button color="success" onClick={this.mudarStatusOrc.bind(this)}>Confirmar</Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}
