import React, { useState, useEffect } from 'react';
import { REQUEST_URL } from "../config/urls";
import { Collapse, Label, Input, FormGroup,  Button, Form } from 'reactstrap';
import { FaPlus, FaMinus, FaSpinner } from 'react-icons/fa';
import axios from 'axios';

import TelegramLog from '../services/telegram';
import { toast } from 'react-toastify';

const CollapseEditarInstituicao = (props) => {
  const [collapse, setCollapse] = useState(false);
  const [instituicaoSelecionada, setInstituicaoSelecionada] = useState({ value: "", label: "", nome: "", sigla: "" });
  const [alterandoInstituicao, setAlterandoInstituicao] = useState(false);

  const toggle = () => setCollapse(!collapse);

  useEffect (() => {
    const { instituicao } = props;
    setInstituicaoSelecionada(instituicao);
  }, [props]);

  useEffect (() => {
    if (alterandoInstituicao) {
      axios.put(`${REQUEST_URL}/instituicoes/${instituicaoSelecionada.value}`, instituicaoSelecionada, {headers: {"Authorization": sessionStorage.getItem("token")}}).then((response) => {
        toast.success("Instituição atualizada com sucesso!");
        setAlterandoInstituicao(false);
        toggle();
        props.atualizarInstituicaoSelecionadaSelect(instituicaoSelecionada);
      }).catch((erro) => {
        new TelegramLog().novo("ERRO", [JSON.stringify(erro), "Erro ao atualizar as informações da instituição", `usuarioLogado: ${JSON.parse(sessionStorage.getItem("usuarioLogado"))[0].Login}`]);
        toast.error("Erro ao atualizar as informações da instituição!");
        setAlterandoInstituicao(false);
      });
    }
  }, [alterandoInstituicao]);

  function handleChangeInstituicao (event) {
    const { name, value } = event.target;
    if (name === "nome") {
      setInstituicaoSelecionada({ ...instituicaoSelecionada, [name]: value, label: `${instituicaoSelecionada.sigla} - ${value}` }); 
    } else if (name === "sigla") {
      setInstituicaoSelecionada({ ...instituicaoSelecionada, [name]: value, label: `${value} - ${instituicaoSelecionada.nome}` }); 
    }
  }

  function handleSubmitInstituicao (event) {
    event.preventDefault();
    setAlterandoInstituicao(true);
  }

  return (
    <React.Fragment>
      <div style={{ border: "1px solid #ccc", borderRadius: 4, marginBottom: 15, padding: "3px 10px", backgroundColor: "white" }}>
        <h6 style={{ margin: "5px 0px" }}>
          <div onClick={toggle} style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
            { collapse ? <FaMinus style={{ marginRight: 5 }}/> : <FaPlus style={{ marginRight: 5 }}/> }
            Dados da universidade
          </div> 
        </h6>
        <Collapse isOpen={collapse} style={{padding: "15px 10px"}}>
          <Form onSubmit={handleSubmitInstituicao}>
            <FormGroup>
              <Label for="nome"><span className="perguntaObrigatoria">*</span>Instituição</Label>
              <Input type="text" id="nome" name="nome" value={instituicaoSelecionada.nome} onChange={handleChangeInstituicao} required/> 
            </FormGroup>
            <FormGroup>
              <Label for="sigla"><span className="perguntaObrigatoria">*</span>Sigla</Label>
              <Input type="text" id="sigla" name="sigla" value={instituicaoSelecionada.sigla} onChange={handleChangeInstituicao} required/> 
            </FormGroup>
            <Button type="submit" color="primary" disabled={alterandoInstituicao}>
              { 
                alterandoInstituicao ?
                  <span style={{ display: "flex", alignItems: "center" }}><FaSpinner style={{ marginRight: 5 }} className="rodarIcone"/> Carregando</span>
                : "Salvar"
              }
            </Button>
          </Form>
        </Collapse>
      </div>
    </React.Fragment>
  );
}

export default CollapseEditarInstituicao;