import React from "react";

import { Table, Input, Button, Space } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

export default class Tabela extends React.Component {

  state = {
    searchText: '',
    searchedColumn: '',
  };
  
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Pesquisar
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filtrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render () {
    const columns = [
      {
        title: 'SITUAÇÃO FINANCEIRO',
        width: 190,
        dataIndex: 'statusFinanceiro',
        key: 'statusFinanceiro',
        fixed: 'left',
        filters: [
          { text: 'Liberado', value: 'Liberado' },
          { text: 'Não liberado', value: 'Não liberado' },
          { text: 'Fechado financeiro', value: 'Fechado financeiro' },
          { text: 'Devolvido', value: 'Devolvido' },
          { text: 'Cancelado', value: 'Cancelado' }
        ],
        onFilter: (value, record) => record.statusFinanceiro.indexOf(value) === 0,
      },
      {
        title: 'STATUS',
        width: 140,
        dataIndex: 'statusCronograma',
        key: 'statusCronograma',
        fixed: 'left',
        ...this.getColumnSearchProps('statusCronograma')
      },
      {
        title: 'INDEX',
        key: '1',
        fixed: 'left',
        width: 80,
        render: (i) => <span>A</span>
      },
      {
        title: 'CONTRATO',
        dataIndex: 'numContrato',
        key: 'numContrato',
        fixed: 'left',
        width: 120,
        ...this.getColumnSearchProps('numContrato')
      },
      {
        title: 'INDIVIDUAL',
        dataIndex: 'individual',
        key: 'individual',
        filters: [
          { text: 'Sim', value: 'SIM' },
          { text: 'Não', value: 'NÃO' }
        ],
        onFilter: (value, record) => record.individual.indexOf(value) === 0,
      },
      {
        title: 'CÓDIGO BITRIX',
        dataIndex: 'codigoBitrix',
        key: 'codigoBitrix',
        ...this.getColumnSearchProps('codigoBitrix')
      },
      {
        title: 'CURSO',
        dataIndex: 'curso',
        key: 'curso',
        ...this.getColumnSearchProps('curso')
      },
      {
        title: 'INSTITUIÇÃO',
        dataIndex: 'instituicao',
        key: 'instituicao',
        width: 300,
        ...this.getColumnSearchProps('instituicao')
      },
      {
        title: 'CIDADE',
        dataIndex: 'cidade',
        key: 'cidade',
        ...this.getColumnSearchProps('cidade')
      },
      { title: 'UF', dataIndex: 'uf', key: 'uf' },
      {
        title: 'PARCEIRO',
        key: 'parceiro',
        ...this.getColumnSearchProps('parceiro'),
        render: record => <span style={{cursor: "pointer"}} onClick={() => this.props.handleChangeParceiro(record)}><i style={{color: "#337ab7"}} className="fas fa-pencil-alt fa-sm"></i> {record.parceiro}</span>
      },
      { title: 'ENTROU EM', dataIndex: 'dataChegada', key: 'dataChegada', ...this.getColumnSearchProps('dataChegada') },
      { title: 'FORMATURA', dataIndex: 'dataFormatura', key: 'dataFormatura', ...this.getColumnSearchProps('dataFormatura') },
      {
        title: 'REPRESENTANTE',
        key: 'vendedor',
        ...this.getColumnSearchProps('vendedor'),
        render: record => <span style={{cursor: "pointer"}} onClick={() => this.props.handleChangeRepresentante(record)}><i style={{color: "#337ab7"}} className="fas fa-pencil-alt fa-sm"></i> {record.vendedor}</span>
      },
      { title: 'VALOR FECHADO', dataIndex: 'valorFechado', key: 'valorFechado', ...this.getColumnSearchProps('valorFechado') },
      { title: 'VALOR FINAL', dataIndex: 'valorFinal', key: 'valorFinal', ...this.getColumnSearchProps('valorFinal') },
      { title: 'FECHAMENTO FINANCEIRO', dataIndex: 'dataFechamentoFinanceiro', key: 'dataFechamentoFinanceiro', ...this.getColumnSearchProps('dataFechamentoFinanceiro') },
      { title: 'ASSINATURA CONTRATO', dataIndex: 'dataAssinaturaContrato', key: 'dataAssinaturaContrato', ...this.getColumnSearchProps('dataAssinaturaContrato') },
      { title: 'COMISSÃO VENDEDOR', dataIndex: 'comissaoVendedor', key: 'comissaoVendedor', ...this.getColumnSearchProps('comissaoVendedor') },
      { title: 'LIBERAÇÃO STAND BY', dataIndex: 'dataLiberacaoStandBy', key: 'dataLiberacaoStandBy', ...this.getColumnSearchProps('dataLiberacaoStandBy') }
    ];
    return <Table columns={columns} dataSource={this.props.data} scroll={{ x: 3000, y: 500 }} pagination={false} size="small" bordered rowKey={record => record.id} />;
  }
}