import React from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from 'reactstrap';
import {logout} from '../actions/LoginActions.js';
import {connect} from 'react-redux';
import {Redirect} from 'react-router';
class NavBar extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isOpen:false,
      logout:false,
      showItem: true
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount () {
    if(sessionStorage.getItem('usuarioLogado')){
      let usuarioLogado = JSON.parse(sessionStorage.getItem('usuarioLogado'))[0];
      if (usuarioLogado.contadeparceiro === 1) {
        this.setState({showItem: false});
      } else {
        this.setState({showItem: true});
      }
    }
  }

  logout = () => {
    this.props.logout();
    this.setState({logout: true});
  }

  toggle () {
    this.setState({isOpen:!this.state.isOpen});
  }

  render () {
    if (this.state.logout) {
      return <Redirect to="login"/>
    } 
    return (
      <Navbar style={{boxShadow:"0 2px 5px 0 rgba(0,0,0,.26)", backgroundColor:"#fff"}} light expand="md">
        {
          this.state.showItem ?
            <NavbarBrand href="/mapa"> <i className="fas fa-map-marker-alt"></i> Mapa de vendas</NavbarBrand>
          : null
        }
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem><NavLink href="/select_client">Orçamento</NavLink></NavItem>
            {
              this.state.showItem ?
                <NavItem><NavLink href="/clientes">Clientes</NavLink></NavItem>
              : null
            }
            <NavItem><NavLink href="/cadastro">Cadastrar lead</NavLink></NavItem>
            
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>{this.props.nome}</DropdownToggle>
              <DropdownMenu right>
                <DropdownItem disabled>Meus dados</DropdownItem>
                <DropdownItem disabled href="/meus-vouchers">Vouchers Alpha-e</DropdownItem>
                {
                  this.props.funcao !== "VENDEDOR" ?
                    <React.Fragment>
                      <DropdownItem divider />
                      <DropdownItem header>Administrativo</DropdownItem>
                      <DropdownItem href="/clientes/editar">Clientes</DropdownItem>
                      <DropdownItem href="/instituicoes">Instituições</DropdownItem>
                      <DropdownItem href="/usuarios">Usuários</DropdownItem>
                    </React.Fragment>
                  : null
                }
                <DropdownItem divider />
                <DropdownItem onClick={this.logout}>Sair</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    usuario: state.usuario.autenticado,
  };
}

export default connect(mapStateToProps, {logout})(NavBar);
