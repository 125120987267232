import React from "react";

import "./index.css";

export default class FloatingMenuItem extends React.Component {

	handleClick () {
    if (this.props.action && !this.props.disabled) {
		  this.props.action();
      this.props.toggleMenu();
    }
	}
	
	render () {
		return ( 
      <div onClick={this.handleClick.bind(this)} className="floating-menu-item">
        {this.props.label ? <label>{this.props.label}</label> : null}
        <div className="floating-menu-icon" style={{ backgroundColor: this.props.disabled ? "#61BCCB" : "#17a2b8"}}><i className={this.props.icon}/></div>
      </div>
    );
	}
}