import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {unregister} from './registerServiceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import clientApollo from "./config/apollo";
import {ApolloProvider} from "react-apollo";

ReactDOM.render(
  <ApolloProvider client={clientApollo}>
    <App/>
  </ApolloProvider>,
  document.getElementById('root')
);
unregister();
