import React from 'react';
import {Provider} from 'react-redux';
import {makeStore} from './config/store';
import Router from './config/Router';
var supportsES6 = function() {
  try {
    new Function("(a = 0) => a");
    return true;
  }
  catch (err) {
    return false;
  }
}();
export default class App extends React.Component {
  componentDidMount(){
    if(!supportsES6){
      alert("Seu navegador não possui recursos para execução dessa aplicação, se quiser uma experiencia completa por favor atualize ou utilize outro navegador");
    }
  }
  render() {
    return (
      <Provider store={makeStore((error, store) => {})}>
        <Router />
      </Provider>
    );
  }
}
