import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { toast } from 'react-toastify';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { REQUEST_URL } from '../config/urls';
import TelegramLog from '../services/telegram';
import CollapseContato from './CollapseContato';
import ModalCursos from './ModalCursos';

export default function ModalEditarClientes(props) {
  const [clienteSelecionado, setClienteSelecionado] = useState(props.cliente);
  const estados = [
    { value: 'AC', label: 'Acre' },
    { value: 'AL', label: 'Alagoas' },
    { value: 'AP', label: 'Amapá' },
    { value: 'AM', label: 'Amazonas' },
    { value: 'BA', label: 'Bahia' },
    { value: 'CE', label: 'Ceará' },
    { value: 'DF', label: 'Distrito Federal' },
    { value: 'ES', label: 'Espírito Santo' },
    { value: 'GO', label: 'Goiás' },
    { value: 'MA', label: 'Maranhão' },
    { value: 'MT', label: 'Mato Grosso' },
    { value: 'MS', label: 'Mato Grosso do Sul' },
    { value: 'MG', label: 'Minas Gerais' },
    { value: 'PA', label: 'Pará' },
    { value: 'PB', label: 'Paraíba' },
    { value: 'PR', label: 'Paraná' },
    { value: 'PE', label: 'Pernambuco' },
    { value: 'PI', label: 'Piauí' },
    { value: 'RJ', label: 'Rio de Janeiro' },
    { value: 'RN', label: 'Rio Grande do Norte' },
    { value: 'RS', label: 'Rio Grande do Sul' },
    { value: 'RO', label: 'Rondônia' },
    { value: 'RR', label: 'Roraima' },
    { value: 'SC', label: 'Santa Catarina' },
    { value: 'SP', label: 'São Paulo' },
    { value: 'SE', label: 'Sergipe' },
    { value: 'TO', label: 'Tocantins' },
  ];
  const [estadoSelecionado, setEstadoSelecionado] = useState({});
  const [cidades, setCidades] = useState([]);
  const [cidadeSelecionada, setCidadeSelecionada] = useState({});
  const [instituicoes, setInstituicoes] = useState([]);
  const [instituicaoSelecionada, setInstituicaoSelecionada] = useState({});
  const [cursos, setCursos] = useState([]);
  const [cursosResponse, setCursosResponse] = useState([]);
  const [cursoSelecionado, setCursoSelecionado] = useState({});
  const [vendedorSelecionado, setVendedorSelecionado] = useState(null);
  const [parceiroSelecionado, setParceiroSelecionado] = useState(null);
  const [contatos, setContatos] = useState([]);
  const [alterandoCliente, setAlterandoCliente] = useState(false);
  const [carregarContatos, setCarregarContatos] = useState(false);
  const [anos, setAnos] = useState([]);
  const [anoSelecionado, setAnoSelecionado] = useState({});
  const semestres = [
    { value: '1', label: 'Primeiro semestre' },
    { value: '1', label: 'Segundo semestre' },
  ];
  const [semestreSelecionado, setSemestreSelecionado] = useState({});
  const turnos = [
    { value: 'Matutino', label: 'Matutino' },
    { value: 'Vespertino', label: 'Vespertino' },
    { value: 'Noturno', label: 'Noturno' },
    { value: 'Integral', label: 'Integral' },
  ];
  const [turnoSelecionado, setTurnoSelecionado] = useState({});
  const [showModalAdicionarCurso, setShowModalAdicionarCurso] = useState(false);

  useEffect(() => {
    const { cliente, parceiros, vendedores } = props;

    let anos = [],
      anoAtual = new Date().getFullYear(),
      dataFormatura = cliente.Formatura.split('/');

    for (let i = -2; i < 10; i++) {
      let ano = anoAtual + i;
      anos.push({ value: ano, label: ano });
    }

    setClienteSelecionado(cliente);
    alimentarEstadoCidadeFaculdadeCurso(cliente.id_curso_inst);
    setCarregarContatos(true);
    setAnos(anos);
    setAnoSelecionado(
      anos.find((ano) => {
        return ano.value === Number(dataFormatura[0]);
      })
    );
    // Para definir o semestre é necessário verificar se a data está trabalhando com o mês ou com semestre.
    // Se for semestre, é só exibir. Senão é necessário pegar o mês e calcular.
    setSemestreSelecionado(
      (dataFormatura.length === 3 && Number(dataFormatura[1]) >= 7) ||
        (dataFormatura.length === 2 && Number(dataFormatura[1] === 2))
        ? { value: '2', label: 'Segundo semestre' }
        : { value: '1', label: 'Primeiro semestre' }
    );
    setTurnoSelecionado(
      turnos.find((turno) => {
        return turno.value === cliente.turno;
      })
    );
    setVendedorSelecionado(
      vendedores.filter((vendedor) => {
        return cliente.Vendedor === vendedor.value;
      })[0]
    );

    if (cliente.codAgencia > 0) {
      setParceiroSelecionado(
        parceiros.filter((p) => {
          return p.value === cliente.codAgencia;
        })[0]
      );
    }
  }, []);

  useEffect(() => {
    if (estadoSelecionado) {
      axios
        .post(
          `${REQUEST_URL}/cidades`,
          { estado_cod: estadoSelecionado.value },
          { headers: { Authorization: sessionStorage.getItem('token') } }
        )
        .then((response) => {
          let aux = [];
          response.data.map((c) => {
            aux.push({ value: c.codigo, label: c.cidade });
          });
          setCidades(aux);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [estadoSelecionado]);

  useEffect(() => {
    if (cidadeSelecionada) {
      axios
        .post(
          `${REQUEST_URL}/instituicoes`,
          { cidade_cod: cidadeSelecionada.value },
          { headers: { Authorization: sessionStorage.getItem('token') } }
        )
        .then((response) => {
          let aux = [];
          response.data.map((i) => {
            aux.push({
              value: i.cod_ies,
              label: `${i.sigla} - ${i.nomeintituicao}`,
              sigla: i.sigla,
            });
          });
          setInstituicoes(aux);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [cidadeSelecionada]);

  useEffect(() => {
    if (instituicaoSelecionada) {
      axios
        .post(
          `${REQUEST_URL}/cursos`,
          {
            cidade_cod: cidadeSelecionada.value,
            inst_cod: instituicaoSelecionada.value,
          },
          { headers: { Authorization: sessionStorage.getItem('token') } }
        )
        .then((response) => {
          let aux = [];
          response.data.map((c) => {
            aux.push({
              value: c.idcurso,
              label: c.nomecurso,
              id_curso_inst: c.id_curso_inst,
            });
          });
          setCursos(aux);
          setCursosResponse(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [instituicaoSelecionada]);

  useEffect(() => {
    if (alterandoCliente) {
      axios
        .put(`${REQUEST_URL}/clientes`, {
          Cidade: cidadeSelecionada.label,
          Descricao: clienteSelecionado.Descricao,
          Formatura: `${anoSelecionado.value}/${semestreSelecionado.value}`,
          turno: turnoSelecionado.value,
          Vendedor: vendedorSelecionado.value,
          idCursoInst: cursoSelecionado.id_curso_inst,
          codAgencia: parceiroSelecionado ? parceiroSelecionado.value : 0,
          idCliente: clienteSelecionado.idCliente,
        })
        .then(() => {
          props.close('sucesso');
        })
        .catch((erro) => {
          new TelegramLog().novo('ERRO', [
            JSON.stringify(erro),
            'Erro ao atualizar as informações do cliente',
            `usuarioLogado: ${
              JSON.parse(sessionStorage.getItem('usuarioLogado'))[0].Login
            }`,
          ]);
          props.close('erro');
        });
    }
  }, [alterandoCliente]);

  useEffect(() => {
    if (carregarContatos && clienteSelecionado.idCliente) {
      axios
        .get(`${REQUEST_URL}/cliente/${clienteSelecionado.idCliente}/all`, {
          headers: { Authorization: sessionStorage.getItem('token') },
        })
        .then((response) => {
          setContatos(response.data);
          setClienteSelecionado({
            ...clienteSelecionado,
            ['QtdContatos']: response.data.length,
          });
          setCarregarContatos(false);
        })
        .catch((erro) => {
          new TelegramLog().novo('ERRO', [
            JSON.stringify(erro),
            'Erro ao consultar os contatos',
            `usuarioLogado: ${
              JSON.parse(sessionStorage.getItem('usuarioLogado'))[0].Login
            }`,
          ]);
          setCarregarContatos(false);
        });
    }
  }, [carregarContatos]);

  function handleChangeCliente(event) {
    const { name, value } = event.target;
    setClienteSelecionado({ ...clienteSelecionado, [name]: value });
  }

  function handleChangeEstado(data) {
    setEstadoSelecionado(data);
    setCidadeSelecionada({});
    setInstituicaoSelecionada({});
    setCursoSelecionado({});
  }

  function handleChangeCidade(data) {
    setCidadeSelecionada(data);
    setInstituicaoSelecionada({});
    setCursoSelecionado({});
  }

  function handleChangeInstituicao(data) {
    setInstituicaoSelecionada(data);
    setCursoSelecionado({});
  }

  function handleChangeCurso(data) {
    setCursoSelecionado(data);
  }

  function handleChangeAno(data) {
    setAnoSelecionado(data);
  }

  function handleChangeSemestre(data) {
    setSemestreSelecionado(data);
  }

  function handleChangeTurno(data) {
    setTurnoSelecionado(data);
  }

  function handleChangeVendedor(data) {
    setVendedorSelecionado(data);
  }

  function handleChangeParceiro(data) {
    setParceiroSelecionado(data);
  }

  function handleCloseModalCurso(resposta) {
    if (resposta.tipo === 'sucesso') {
      toast.success(resposta.mensagem);
    }
    setShowModalAdicionarCurso(false);
    handleChangeInstituicao({
      ...instituicaoSelecionada,
      idcidade: cidadeSelecionada.value,
    });
  }

  function handleSubmitCliente(event) {
    event.preventDefault();
    setAlterandoCliente(true);
  }

  function alimentarEstadoCidadeFaculdadeCurso(idCursoInst) {
    axios
      .get(`${REQUEST_URL}/descobrir-cidade-faculdade-curso/${idCursoInst}`)
      .then((response) => {
        let data = response.data[0];
        setEstadoSelecionado(
          estados.filter((e) => {
            return e.value === data.siglaestado;
          })[0]
        );
        setCidadeSelecionada({ value: data.idcidade, label: data.nomecidade });
        setInstituicaoSelecionada({
          value: data.cod_ies,
          label: data.nomeintituicao,
          sigla: data.sigla,
        });
        setCursoSelecionado({
          value: data.idcurso,
          label: data.nomecurso,
          id_curso_inst: idCursoInst,
        });
      })
      .catch((erro) => {
        console.log(erro);
        toast.error(
          'Não foi possível encontrar a o estado, cidade, faculdade e curso do lead'
        );
      });
  }

  return (
    <React.Fragment>
      <Modal className='no-print' isOpen={true} size='lg'>
        <ModalHeader toggle={props.close}>Alteração de cliente</ModalHeader>
        <Form onSubmit={handleSubmitCliente}>
          <ModalBody>
            <Row>
              <Col>
                <FormGroup>
                  <Label for='descricao'>
                    <span className='perguntaObrigatoria'>*</span>Descrição
                  </Label>
                  <Input
                    type='text'
                    id='descricao'
                    name='Descricao'
                    value={clienteSelecionado.Descricao}
                    required
                    onChange={handleChangeCliente}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md='4'>
                <FormGroup>
                  <Label for='estado'>
                    <span className='perguntaObrigatoria'>*</span>Estado
                  </Label>
                  <Select
                    name='estado'
                    id='estado'
                    required
                    value={estadoSelecionado}
                    onChange={handleChangeEstado}
                    options={estados}
                    placeholder='Selecione'
                  />
                </FormGroup>
              </Col>
              <Col md='8'>
                <FormGroup>
                  <Label for='cidade'>
                    <span className='perguntaObrigatoria'>*</span>Cidade
                  </Label>
                  <Select
                    name='cidade'
                    id='cidade'
                    required
                    noOptionsMessage='Nenhuma cidade encontrada'
                    value={cidadeSelecionada}
                    onChange={handleChangeCidade}
                    options={cidades}
                    placeholder='Selecione'
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md='8'>
                <FormGroup>
                  <Label for='instituicao'>
                    <span className='perguntaObrigatoria'>*</span>Instituição
                  </Label>
                  <Select
                    name='instituicao'
                    id='instituicao'
                    required
                    noOptionsMessage='Nenhuma instituição encontrada'
                    value={instituicaoSelecionada}
                    onChange={handleChangeInstituicao}
                    options={instituicoes}
                    placeholder='Selecione'
                  />
                </FormGroup>
              </Col>
              <Col md='4'>
                <FormGroup>
                  <Label for='curso'>
                    <span className='perguntaObrigatoria'>*</span>Curso
                  </Label>
                  <Select
                    name='curso'
                    id='curso'
                    required
                    noOptionsMessage='Nenhuma curso encontrado'
                    value={cursoSelecionado}
                    onChange={handleChangeCurso}
                    options={cursos}
                    placeholder='Selecione'
                  />
                  {instituicaoSelecionada ? (
                    <p
                      className='pLink'
                      onClick={() => setShowModalAdicionarCurso(true)}
                    >
                      Não achou o curso? Clique aqui e cadastre.
                    </p>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md='4'>
                <FormGroup>
                  <Label for='ano'>
                    <span className='perguntaObrigatoria'>*</span>Ano
                  </Label>
                  <Select
                    name='ano'
                    id='ano'
                    required
                    value={anoSelecionado}
                    onChange={handleChangeAno}
                    options={anos}
                    placeholder='Selecione'
                  />
                </FormGroup>
              </Col>
              <Col md='4'>
                <FormGroup>
                  <Label for='semestre'>
                    <span className='perguntaObrigatoria'>*</span>Semestre
                  </Label>
                  <Select
                    name='semestre'
                    id='semestre'
                    required
                    value={semestreSelecionado}
                    onChange={handleChangeSemestre}
                    options={semestres}
                    placeholder='Selecione'
                  />
                </FormGroup>
              </Col>
              <Col md='4'>
                <FormGroup>
                  <Label for='turno'>
                    <span className='perguntaObrigatoria'>*</span>Turno
                  </Label>
                  <Select
                    name='turno'
                    id='turno'
                    required
                    value={turnoSelecionado}
                    onChange={handleChangeTurno}
                    options={turnos}
                    placeholder='Selecione'
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <FormGroup>
                  <Label for='vendedor'>Vendedor</Label>
                  <Select
                    name='vendedor'
                    id='vendedor'
                    value={vendedorSelecionado}
                    onChange={handleChangeVendedor}
                    options={props.vendedores}
                    placeholder='Selecione'
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <FormGroup>
                  <Label for='parceiro'>Parceiro</Label>
                  <Select
                    name='parceiro'
                    id='parceiro'
                    value={parceiroSelecionado}
                    onChange={handleChangeParceiro}
                    options={props.parceiros}
                    placeholder='Selecione'
                  />
                </FormGroup>
              </Col>
            </Row>
            <Label>Contatos:</Label>
            {carregarContatos ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <FaSpinner className='fa-spin fa-2x' />
              </div>
            ) : (
              <div>
                {contatos.map((contato, i) => (
                  <CollapseContato
                    key={i}
                    contato={contato}
                    index={`-${i}`}
                    atualizarContatos={() => setCarregarContatos(true)}
                    style={{ cursor: 'pointer' }}
                  />
                ))}
                <CollapseContato
                  contato={{
                    Nome: '',
                    email: '',
                    CPF: '',
                    RG: '',
                    CNPJ: '',
                    Celular: '',
                    Fixo: '',
                    idContato: '',
                    IdCliente: clienteSelecionado.idCliente,
                    ContatoPrinc: false,
                  }}
                  index={'-999'}
                  atualizarContatos={() => setCarregarContatos(true)}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button type='submit' color='success' disabled={alterandoCliente}>
              {alterandoCliente ? (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <FaSpinner
                    style={{ marginRight: 5 }}
                    className='rodarIcone'
                  />{' '}
                  Carregando
                </span>
              ) : (
                'Confirmar'
              )}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Modal className='no-print' isOpen={showModalAdicionarCurso} size='lg'>
        <ModalCursos
          instituicao={{
            ...instituicaoSelecionada,
            idcidade: cidadeSelecionada.value,
          }}
          cursos={cursosResponse}
          close={(resposta) => handleCloseModalCurso(resposta)}
        />
      </Modal>
    </React.Fragment>
  );
}
