import React from "react";

import { Row, Col, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Button } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import axios from "axios";

import NavBar from '../../components/NavBar';
import TelegramLog from "../../services/telegram";
import { REQUEST_URL } from '../../config/urls.js';
import Tabela from '../../components/PadraoLC/Principal/Tabela';

class Principal extends React.Component {

  state = {
    iniciar: true,
    carregando: false,
    orcamento: {},
    usuarioLogado: {},
    contratos: [],
    contratoSelecionado: {},
    representantes: [],
    representanteSelecionado: {},
    showModalEditarParceiro: false,
    parceiros: [],
    parceiroSelecionado: {},
    showModalEditarRepresentante: false
  }

  componentDidMount () {
    let usuarioLogado = JSON.parse(sessionStorage.getItem("usuarioLogado"))[0];

    if (usuarioLogado && usuarioLogado.Funcao === "ORCAMENTISTA") {
      this.carregarTabelaPrincipal();
      this.carregarParceiros();
      this.carregarRepresentantes();
      this.setState({ usuarioLogado });
    } else {
      this.props.history.push("/mapa");
    }
  }

  carregarParceiros () {
    axios.get(`${REQUEST_URL}/agencias`, {headers: {"Authorization": sessionStorage.getItem("token")}}).then((response) => {
      let aux = [{ value: null, label: "Nenhum" }];
      response.data.map(a => { aux.push({ value: a.IdAgencia, label: a.NomeAgencia })});
      this.setState({parceiros: aux});
    }).catch(erro => {
      this.criarLog("ERROR", erro, "Não foi possível carregar a lista de parceiros");
      toast.error("Não foi possível carregar a lista de parceiros");
    });
  }

  carregarRepresentantes () {
    axios.get(`${REQUEST_URL}/vendedores`, {headers: {"Authorization": sessionStorage.getItem("token")}}).then((response) => {
      let aux = [];
      response.data.map(v => { aux.push({ value: v.idUsuario, label: v.Nome })});
      this.setState({representantes: aux});
    }).catch(erro => {
      this.criarLog("ERROR", erro, "Não foi possível carregar a lista de representantes");
      toast.error("Não foi possível carregar a lista de representantes");
    });
  }

  criarLog (tipo, erro, mensagem) {
    new TelegramLog().novo(tipo, [
      JSON.stringify(erro), 
      mensagem,
      "Padrão LC - Tabela Principal",
      `usuarioLogado: ${this.state.usuarioLogado.Login}`
    ]);
  }

  carregarTabelaPrincipal () {
    axios.get(`${REQUEST_URL}/padrao-lc/tabela-principal`, {headers: {"Authorization": sessionStorage.getItem("token")}}).then(response => {
      this.setState({ contratos: response.data, iniciar: false });
    }).catch(erro => {
      this.criarLog("ERROR", erro, "Não foi possível carregar a tabela principal");
      toast.error("Não foi possível carregar a tabela principal");
      this.setState({ iniciar: false });
    });
  }

  handleChangeParceiro (parceiroSelecionado) {
    this.setState({parceiroSelecionado});
  }

  handleChangeRepresentante (representanteSelecionado) {
    this.setState({representanteSelecionado});
  }

  handleUpdateParceiro () {
    const { contratoSelecionado, parceiroSelecionado } = this.state;

    this.setState({carregando: true});

    axios.patch(`${REQUEST_URL}/padrao-lc/tabela-principal/atualizar-parceiro`, { id: contratoSelecionado.id, parceiro: parceiroSelecionado.value }, {headers: {"Authorization": sessionStorage.getItem("token")}}).then(() => {
      toast.success("Parceiro atualizado com sucesso!");
      this.carregarTabelaPrincipal();
    }).catch(erro => {
      this.criarLog("ERROR", erro, "Não foi possível atualizar o parceiro. Tente novamente!");
      toast.error("Não foi possível atualizar o parceiro. Tente novamente!");
    }).finally(() => {
      this.setState({carregando: false, showModalEditarParceiro: false});
    })
  }

  handleUpdateRepresentante () {
    const { contratoSelecionado, representanteSelecionado } = this.state;

    this.setState({carregando: true});

    axios.patch(`${REQUEST_URL}/padrao-lc/tabela-principal/atualizar-representante`, { id: contratoSelecionado.id, representante: representanteSelecionado.value }, {headers: {"Authorization": sessionStorage.getItem("token")}}).then(() => {
      toast.success("Representante atualizado com sucesso!");
      this.carregarTabelaPrincipal();
    }).catch(erro => {
      this.criarLog("ERROR", erro, "Não foi possível atualizar o representante. Tente novamente!");
      toast.error("Não foi possível atualizar o representante. Tente novamente!");
    }).finally(() => {
      this.setState({carregando: false, showModalEditarRepresentante: false});
    })
  }

  _renderTabelaPrincipal () {
    const { contratos } = this.state;

    return (
      <Card style={{boxShadow: "0.5rem 0.5rem 2rem 0 rgb(8 15 41 / 8%), 0 0 1px 0 rgb(8 15 41 / 8%)", borderRadius: ".375rem"}}>
        <CardBody>
          <Tabela
            data={contratos}
            handleChangeParceiro={(data) => this.setState({contratoSelecionado: data, showModalEditarParceiro: true})}
            handleChangeRepresentante={(data) => this.setState({contratoSelecionado: data, showModalEditarRepresentante: true})}
          />
        </CardBody>
      </Card>
    );
  }

  render () {
    return (
      <div style={{backgroundColor: "#f5f4f9", paddingBottom: 30, position: "relative", minHeight: "100vh"}}>
        {
          this.state.iniciar ?
            <div style={{position: "absolute", zIndex: 9999, width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,.4)", display: "flex", justifyContent: "center", alignItems: "center"}}>
              <img src="svgs/loading.svg" style={{height: 80}}/>
            </div>
          : null
        }
        <ToastContainer position="bottom-left" autoClose={5000} newestOnTop={false} closeOnClick  rtl={false} pauseOnVisibilityChange={false} draggable={false}/>
        <NavBar className="no-print" nome={this.state.usuarioLogado.Nome} funcao={this.state.usuarioLogado.Funcao}/>
        <div className="container-fluid">
          <Row style={{marginTop: 30}}>
            <Col sm="12">
              {this._renderTabelaPrincipal()}
            </Col>
          </Row>
        </div>
        <Modal className="no-print" isOpen={this.state.showModalEditarParceiro} size="sm">
          <ModalHeader toggle={() => this.setState({showModalEditarParceiro: false})}>Alteração de parceiro</ModalHeader>
          <ModalBody>
            <Label for="parceiro">Parceiro</Label>
            <Select
              required
              placeholder="Selecione o parceiro"
              name="parceiro"
              value={this.state.parceiroSelecionado}
              onChange={this.handleChangeParceiro.bind(this)}
              options={this.state.parceiros}
            />
          </ModalBody>
          <ModalFooter>
            { this.state.carregando ? <i className="fas fa-spinner fa-spin"></i> : null }
            <Button disabled={this.state.carregando} color="success" onClick={() => this.handleUpdateParceiro()}><i className="fas fa-check-circle"></i> Confirmar</Button>
            <Button disabled={this.state.carregando} color="danger" onClick={() => this.setState({showModalEditarParceiro:false, contratoSelecionado: {}, parceiroSelecionado: {}})}> <i className="fas fa-times-circle"></i> Cancelar</Button>
          </ModalFooter>
        </Modal>
        <Modal className="no-print" isOpen={this.state.showModalEditarRepresentante} size="sm">
          <ModalHeader toggle={() => this.setState({showModalEditarRepresentante: false})}>Alteração de representante</ModalHeader>
          <ModalBody>
            <Label for="representante">Representante</Label>
            <Select
              required
              placeholder="Selecione o representante"
              name="representante"
              value={this.state.representanteSelecionado}
              onChange={this.handleChangeRepresentante.bind(this)}
              options={this.state.representantes}
            />
          </ModalBody>
          <ModalFooter>
            { this.state.carregando ? <i className="fas fa-spinner fa-spin"></i> : null }
            <Button disabled={this.state.carregando} color="success" onClick={() => this.handleUpdateRepresentante()}><i className="fas fa-check-circle"></i> Confirmar</Button>
            <Button disabled={this.state.carregando} color="danger" onClick={() => this.setState({showModalEditarRepresentante:false, contratoSelecionado: {}, representanteSelecionado: {}})}> <i className="fas fa-times-circle"></i> Cancelar</Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

export default Principal;