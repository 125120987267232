import React from 'react';

class NotFound extends React.Component {
  constructor(props){
    super(props);
    document.title = "NotFound";
    this.state = {
    };
  }
  render(){
    return(
      <div>NotFound</div>
    );
  }
}
export default NotFound;
