import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Button, Jumbotron, Table, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form, FormGroup, FormText} from "reactstrap";
import TelegramLog from "../services/telegram";
import axios from "axios";
import { URL_ALPHAE_API } from "../config/urls";
import { toast } from "react-toastify";
import Select from 'react-select';

const GET_ALL_VOUCHERS = gql`
  query vouchersMapaVendas {
    vouchersMapaVendas {
      id
      code
      dateRegistered
      sellerCode
      usersLimit
      expirationDate
      percentage
      clients {
        id
        name
        email
        cellPhone
      }
    } 
  }`,
  GET_VOUCHERS_BY_ID = gql`
    query vouchersVendedor ($id: Int!) {
      vouchersVendedor (id: $id) {
        id
        code
        dateRegistered
        sellerCode
        usersLimit
        expirationDate
        percentage
        clients {
          id
          name
          email
          cellPhone
        }
      } 
    }
  `;

function criarLog (tipo, erro, mensagem) {
  new TelegramLog().novo(tipo, [
    JSON.stringify(erro), 
    mensagem, 
    `usuarioLogado: ${JSON.parse(sessionStorage.getItem("usuarioLogado"))[0].Login}`
  ]);
}

function gerarOptionVendedores (vendedores) {
  let options = [];
  vendedores.forEach((vendedor) => {
    options.push({label: vendedor.Nome, value: vendedor.idUsuario});
  });
  return options;
}

function TabelaVoucherAlphaE (props) {
  const { data, loading } = props.vendedor === 0 ? useQuery(GET_ALL_VOUCHERS) : useQuery(GET_VOUCHERS_BY_ID, {variables: {id: props.vendedor}}), 
    cadastrarNovoVoucher = e => {
      e.preventDefault();
      setBotaoAdicionarVoucher(true); 
      setExibirOutput(false);
      voucher.sellerCode = props.ehOrcamentista ? vendedorSelecionado.value : props.vendedor;
      if (voucher.expirationDate > new Date().toISOString().split("T")[0]) {
        axios.post(`${URL_ALPHAE_API}/voucher`, voucher, {headers: {"Content-Type": "application/json", "X-Token": sessionStorage.getItem("tokenAlphaE")}}).then((response) => {
          setModalAdicionarVoucher(false);
          setBotaoAdicionarVoucher(false); 
          setModalCodigoVoucher(response.data.code);
          response.data.dateRegistered = response.data.dateRegistered.replace(/-/g, "/"); 
          response.data.expirationDate = response.data.expirationDate.replace(/-/g, "/"); 
          props.ehOrcamentista ? data.vouchersMapaVendas.push(response.data) : data.vouchersVendedor.push(response.data);
          setVendedorSelecionado(null);
        }).catch((erro) => { 
          criarLog("ERROR", erro, `Erro ao criar o voucher | ${JSON.stringify(voucher)}`);
          toast.error("Não foi possível cadastrar o voucher. Tente novamente!"); 
        });
      } else {
        setBotaoAdicionarVoucher(false);
        setExibirOutput("Datas retroativas não são permitidas!");
      }
    },
    [voucherSelecionado, setVoucherSelecionado] = useState(null),
    [exibirOutput, setExibirOutput] = useState(false),
    [modalClientes, setModalClientes] = useState(false),
    [modalAdicionarVoucher, setModalAdicionarVoucher] = useState(false),
    [modalCodigoVoucher, setModalCodigoVoucher] = useState(false),
    [voucher, setVoucher] = useState({sellerCode: "", percentage: 0, expirationDate: "", usersLimit: 0}),
    [botaoAdicionarVoucher, setBotaoAdicionarVoucher] = useState(false),
    [vendedorSelecionado, setVendedorSelecionado] = useState(null);

  if (loading) {
    return (
      <div>
        <div style={{textAlign: 'center'}}>
          <i className="fas fa-spinner fa-spin fa-2x"/>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Jumbotron style={{ minHeight: 600, padding: "2rem 1rem", marginBottom: 0 }} className="noselect">
        <div id="header-orc1">
          { props.vendedor === 0 ? <Button color="success" className="float-right" style={{ marginRight: 5 }} onClick={() => setModalAdicionarVoucher(true)}>Novo Voucher</Button> : null }
          {
            voucherSelecionado ?
              <Button color="info" className="float-right" style={{ marginRight: 5 }} onClick={() => setModalClientes(true)}>Ver clientes</Button>
            : null
          }
          <h3><strong>ALPHA-E</strong> | Meus vouchers</h3>
        </div>
        <div style={{ height: 450, overflowY: "auto", width: "100%" }} className="lista-orcamento">
          <Table className="table table-bordered table-sm">
            <thead>
              <tr style={{ width: "100%" }}>
                <th>Voucher</th>
                <th>Desconto</th>
                <th>Data Registro</th>
                <th>Data Expiração</th>
                <th>Clientes</th>
                { props.ehOrcamentista ? <th>Vendedor</th> : null }
              </tr>
            </thead>
            <tbody>
              {
                props.ehOrcamentista ?
                  data && data.vouchersMapaVendas.length > 0 ?
                    data.vouchersMapaVendas.map((item, i) => (
                      <tr key={i} style={voucherSelecionado === item ? {backgroundColor: "#73dbff73"} : {}} onClick={() => setVoucherSelecionado(item)}>
                        <td>{item.code}</td>
                        <td>{item.percentage}%</td>
                        <td>{item.dateRegistered.split("T")[0].split("-").reverse().join("/")}</td>
                        <td>{item.expirationDate.split("T")[0].split("-").reverse().join("/")}</td>
                        <td>{item.clients === null ? "Este voucher ainda não foi utilizado!" : `${item.clients.length} clientes`}</td>
                        <td>{props.vendedores.filter((vendedor) => {return vendedor.idUsuario === item.sellerCode})[0].Nome}</td>
                      </tr>
                    ))
                  : <tr><td colSpan={6} style={{textAlign: "center"}}>Não há nenhum voucher cadastrado</td></tr>
                : data.vouchersVendedor && data.vouchersVendedor.length > 0 ?
                    data.vouchersVendedor.map((item, i) => (
                      <tr key={i} style={voucherSelecionado === item ? {backgroundColor: "#73dbff73"} : {}} onClick={() => setVoucherSelecionado(item)}>
                        <td>{item.code}</td>
                        <td>{item.percentage}%</td>
                        <td>{item.dateRegistered.split("T")[0].split("-").reverse().join("/")}</td>
                        <td>{item.expirationDate.split("T")[0].split("-").reverse().join("/")}</td>
                        <td>{item.clients === null ? "Este voucher ainda não foi utilizado!" : `${item.clients.length} clientes`}</td>
                      </tr>
                    ))
                  : <tr><td colSpan={5} style={{textAlign: "center"}}>Não há nenhum voucher cadastrado</td></tr>
              }
            </tbody>
          </Table>
        </div>
      </Jumbotron>
      <Modal className="no-print" isOpen={modalClientes === true} size="lg" id="modal-clientes">
        <ModalHeader toggle={() => setModalClientes(false)}>Clientes do voucher {voucherSelecionado ? voucherSelecionado.code : ""}</ModalHeader>
        <ModalBody>
          <Table className="table-bordered table-sm">
            <thead>
              <tr style={{ width: "100%" }}>
                <th>Nome</th>
                <th>E-mail</th>
                <th>Telefone</th>
              </tr>
            </thead>
            <tbody>
              {
                voucherSelecionado && voucherSelecionado.clients && voucherSelecionado.clients.length > 0 ?
                  voucherSelecionado.clients.map((client, i) => (
                    <tr key={i}>
                      <td>{client.name ? client.name : ""}</td>
                      <td>{client.email ? client.email : ""}</td>
                      <td>{client.cellPhone ? client.cellPhone : ""}</td>
                    </tr>
                  ))
                :
                <tr><td colSpan={3} style={{ textAlign: "center" }}>Esse voucher ainda não foi utilizado!</td></tr>
              }
            </tbody>
          </Table>
        </ModalBody>
      </Modal>
      <Modal className="no-print" isOpen={modalAdicionarVoucher} size="md" id="modal-novo-voucher">
        <ModalHeader toggle={() => setModalAdicionarVoucher(false)}>Alpha-E | Novo voucher</ModalHeader>
        <Form onSubmit={cadastrarNovoVoucher}>      
          <ModalBody>
            <FormGroup>
              <Label for="porcentagem">Desconto (%)</Label>
              <Input type="number" id="porcentagem" className="form-control" name="percentage" step="1" max={props.ehOrcamentista ? 100 : 30} onChange={e => setVoucher({...voucher, percentage: e.target.value})} />
            </FormGroup>
            <FormGroup>
              <Label for="dataExpiracao">Data de expiração</Label>
              <Input type="date" id="dataExpiracao" className="form-control" name="expirationDate" onChange={e => setVoucher({...voucher, expirationDate: e.target.value})} />
            </FormGroup>
            <FormGroup>
              <Label for="usuarios">Quantidade de vouchers</Label>
              <Input type="number" id="porcentagem" className="form-control" name="usersLimit" onChange={e => setVoucher({...voucher, usersLimit: e.target.value})}/>
              <FormText color="muted">
                Preencha este campo se quiser que o voucher seja limitado!
              </FormText>
            </FormGroup>
            {
              props.ehOrcamentista ?
                <FormGroup>
                  <Label for="vendedor">Vendedor</Label>
                  <Select options={gerarOptionVendedores(props.vendedores)} value={vendedorSelecionado} onChange={(e) => setVendedorSelecionado(e)} placeholder="Selecione..." required/>
                </FormGroup>
              : null
            }
            {exibirOutput ? <output style={{color: "#c7254e", fontWeight: 600}}>{exibirOutput}</output> : null}
          </ModalBody>
          <ModalFooter>
            <Button disabled={botaoAdicionarVoucher} color="success" type="submit">{botaoAdicionarVoucher ? <i className="fas fa-spinner fa-spin"></i> : null} Cadastrar</Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Modal className="no-print" isOpen={modalCodigoVoucher !== false} size="md" id="modal-codigo-voucher">
        <ModalHeader toggle={() => setModalCodigoVoucher(false)}>Alpha-E | Código do voucher</ModalHeader>
        <ModalBody>
          <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
            <img src="./imgs/success.png" alt="Voucher criado com sucesso!" height="75"/>
            <h4>Voucher criado com sucesso!</h4>
            <h6>Segue abaixo o código deste voucher:</h6>
            <h2 style={{border: "3px solid #555", borderRadius: 15, padding: "5px 15px"}}>
              <strong>{modalCodigoVoucher}</strong>
            </h2>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setModalCodigoVoucher(false)}>Fechar</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export { TabelaVoucherAlphaE };
