let URL_EMAIL = 'https://mail.alphaultrapress.com.br',
  WEBHOOK_B24 = 'https://alphae.bitrix24.com.br/rest/4/6xndulvpk2kt7pkp/',
  URL_TELEGRAMLOG_API = 'https://log.alphaultrapress.com.br/log',
  URL_ALPHAE_API = 'https://api.alphae.com.br',
  URL_CONTRATO_API = 'https://gateway.alphaeditora.com.br/api/numcontrato',
  REQUEST_URL = 'https://gateway.alphaeditora.com.br/api/mapavendas',
  URL_AGENCIA_API = 'https://gateway.alphaeditora.com.br/api/agencia',
  URL_ALPHAE_GRAPHQL = 'https://gateway.alphaeditora.com.br/graphql';
// URL_ALPHAE_API = "https://centauriapi.alphaultrapress.com.br",
// URL_CONTRATO_API = "https://centauricontract.alphaultrapress.com.br/api",
// REQUEST_URL = "https://mapavendasteste.alphaeditora.com.br:5122/api/mapavendas",
// URL_AGENCIA_API = "https://agenciateste.alphaeditora.com.br:25000";

if (process.env.NODE_ENV === 'development') {
  // REQUEST_URL = "http://localhost:5124/api/mapavendas";
  // URL_AGENCIA_API = "http://localhost:3000/api/agencia";
  // URL_ALPHAE_API = "https://centauriapi.alphaultrapress.com.br";
  // URL_CONTRATO_API = "https://centauricontract.alphaultrapress.com.br/api";
}

export {
  REQUEST_URL,
  WEBHOOK_B24,
  URL_AGENCIA_API,
  URL_EMAIL,
  URL_ALPHAE_API,
  URL_CONTRATO_API,
  URL_TELEGRAMLOG_API,
  URL_ALPHAE_GRAPHQL,
};
