import React, { useEffect, useState } from "react";
import { Jumbotron, Row, Col, Card, CardBody, Table, FormGroup, Modal } from "reactstrap";
import axios from "axios";
import { REQUEST_URL } from "../../config/urls";
import ModalCursos from "../../components/ModalCursos";
import ModalInstituicao from "../../components/ModalInstituicao";
import ModalCidade from "../../components/ModalCidade";
import CollapseEditarInstituicao from "../../components/CollapseEditarInstituicao";
import Select from 'react-select';
import NavBar from '../../components/NavBar';
import 'react-select/dist/react-select.css';
import './main.css';
import { ToastContainer, toast } from "react-toastify";
import { FaSpinner } from "react-icons/fa";

function Instituicoes (props) {
  const [usuarioLogado, setUsuarioLogado] = useState("");
  const [instituicoesSelect, setInstituicoesSelect] = useState([]);
  const [carregandoInstituicoesSelect, setCarregandoInstituicoesSelect] = useState(false);
  const [instituicaoSelecionadaSelect, setInstituicaoSelecionadaSelect] = useState(null);
  const [instituicoesTabela, setInstituicoesTabela] = useState([]);
  const [instituicaoSelecionadaTabela, setInstituicaoSelecionadaTabela] = useState(null);
  const [carregandoInstituicoesTabela, setCarregandoInstituicoesTabela] = useState(false);
  const [cursos, setCursos] = useState(null);
  const [showModalCurso, setShowModalCurso] = useState(false);
  const [showModalCidade, setShowModalCidade] = useState(false);
  const [showModalInstituicao, setShowModalInstituicao] = useState(false);

  useEffect (() => {
    let usuario = JSON.parse(sessionStorage.getItem("usuarioLogado"))[0];
    if (usuario.Funcao === "VENDEDOR" || usuario.Nivel === "RESTRITO") {
      props.history.push({
        pathname: "/mapa"
      });
		} else {
      setUsuarioLogado(usuario);
    }
    setCarregandoInstituicoesSelect(true);
  }, []);
  
  useEffect (() => {
    if (carregandoInstituicoesSelect) {
      axios.get(`${REQUEST_URL}/instituicoes`, {headers: {"Authorization": sessionStorage.getItem("token")}}).then((response) => {
        let aux = [];
        response.data.forEach((instituicao) => {
          aux.push({ value: instituicao.cod_ies, label: `${instituicao.sigla} - ${instituicao.nomeintituicao}`, nome: instituicao.nomeintituicao, sigla: instituicao.sigla });
        });
        setInstituicoesSelect(aux);
        setCarregandoInstituicoesSelect(false);
      });
    }
  }, [carregandoInstituicoesSelect]);

  useEffect (() => {
    if (carregandoInstituicoesTabela && instituicaoSelecionadaSelect) {
      axios.get(`${REQUEST_URL}/pegar-instituicoes-por-nome?instituicao=${instituicaoSelecionadaSelect.nome}`, {headers: {"Authorization": sessionStorage.getItem("token")}}).then((response) => {
        let aux = [];
        response.data.forEach((instituicao) => {
          aux.push({ value: instituicao.cod_ies, label: instituicao.nomeintituicao, sigla: instituicao.sigla, nomecidade: instituicao.nomecidade, siglaestado: instituicao.siglaestado, idcidade: instituicao.idcidade });
        });
        setInstituicoesTabela(aux);
        setCarregandoInstituicoesTabela(false);
      }).catch((err) => {
        setCarregandoInstituicoesTabela(false);
      });
    }
  }, [carregandoInstituicoesTabela]);

  useEffect (() => {
    if (instituicaoSelecionadaTabela && instituicaoSelecionadaTabela.value) {
      axios.post(`${REQUEST_URL}/cursos`, { cidade_cod: instituicaoSelecionadaTabela.idcidade, inst_cod: instituicaoSelecionadaTabela.value }, {headers: {"Authorization": sessionStorage.getItem("token")}}).then((response) => {
        setCursos(response.data);
        setShowModalCurso(true);
      }).catch((err)=>{
        console.log(err);
      });
    }
  }, [instituicaoSelecionadaTabela]);

  function handleChangeInstituicaoSelect (data) {
    setInstituicaoSelecionadaSelect(data);
    if (data) {
      setCarregandoInstituicoesTabela(true);
    }
  }

  function handleClickInstituicao (data) {
    setInstituicaoSelecionadaTabela(data);
  }

  function handleCloseModalCurso (resposta) {
    setShowModalCurso(false);
    if (resposta.tipo === "sucesso") {
      toast.success(resposta.mensagem);
      handleChangeInstituicaoSelect({...instituicaoSelecionadaSelect});
      setInstituicaoSelecionadaTabela(null)
    } else if (resposta.tipo === "erro") {
      toast.error(resposta.mensagem);
      setInstituicaoSelecionadaTabela(null);
    } else {
      setInstituicaoSelecionadaTabela(null);
    }
  }
  
  function handleCloseModalCidade (resposta) {
    setShowModalCidade(false);
    if (resposta.tipo === "sucesso") {
      toast.success(resposta.mensagem);
      handleChangeInstituicaoSelect({...instituicaoSelecionadaSelect});
    } else if (resposta.tipo === "erro") {
      toast.error(resposta.mensagem);
    }
  }
  
  function handleCloseModalInstituicao (resposta) {
    setShowModalInstituicao(false);
    if (resposta.tipo === "sucesso") {
      toast.success(resposta.mensagem);
      setCarregandoInstituicoesSelect(true);
    } else if (resposta.tipo === "erro") {
      toast.error(resposta.mensagem);
    }
  }

  function handleUpdateInstituicaoSelecionadaSelect (data) {
    let auxInstituicoes = [];
    auxInstituicoes = instituicoesSelect.map((instituicao) => { return instituicao.value === data.value ? data : instituicao });
    setInstituicaoSelecionadaSelect(data);
    setInstituicoesSelect(auxInstituicoes);
  }

  return (
    <React.Fragment>
      <NavBar className="no-print" nome={usuarioLogado.Nome} funcao={usuarioLogado.Funcao} />
      <ToastContainer position="top-right" autoClose={5000} newestOnTop={false} closeOnClick rtl={false} pauseOnVisibilityChange={false} draggable={false}/>
      <Row style={{ margin: 0 }}>
        <Col style={{ marginTop: 20 }}>
          <Card style={{ border: "none", borderRadius: 0, margin: 10, boxShadow: "0 2px 5px 0 rgba(0,0,0,.26)" }}>
            <CardBody>
              <Jumbotron style={{ minHeight: 600, padding: "2rem 1rem", marginBottom: 0 }} className="noselect">
                <div id="header-orc1"><h3>GERENCIAR INSTITUIÇÕES E CURSOS</h3></div>
                <FormGroup>
                  <Select 
                    name="instituicao" id="instituicao" required
                    value={instituicaoSelecionadaSelect} 
                    onChange={handleChangeInstituicaoSelect} 
                    options={instituicoesSelect} 
                    placeholder="Selecione a instituição"
                    disabled={instituicoesSelect.length === 0}
                  />
                  <p className="pLink" onClick={() => setShowModalInstituicao(true)}>Não achou a IES que procura? Clique aqui para cadastrar.</p>
                </FormGroup>
                <React.Fragment>
                  {
                    instituicaoSelecionadaSelect ?
                      <React.Fragment>
                        {
                          carregandoInstituicoesTabela ?
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center"}}><FaSpinner className="fa-spin fa-2x"/></div>
                          : <div>
                            <CollapseEditarInstituicao instituicao={instituicaoSelecionadaSelect} atualizarInstituicaoSelecionadaSelect={(data) => handleUpdateInstituicaoSelecionadaSelect(data)}/>
                            <p>A {instituicaoSelecionadaSelect.sigla && instituicaoSelecionadaSelect.sigla !== "" ? instituicaoSelecionadaSelect.sigla : instituicaoSelecionadaSelect.nome} está cadastrada nas seguintes cidades:</p>
                            <Table className="table-bordered table-sm">
                              <thead>
                                <tr>
                                  <th>Cidade</th>
                                  <th>Estado</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  instituicoesTabela.length > 0 ?
                                    instituicoesTabela.map((instituicao, i) => (
                                      <tr key={i} onClick={() => handleClickInstituicao(instituicao)} style={instituicaoSelecionadaTabela === instituicao ? {cursor: "pointer", backgroundColor: "rgba(115, 219, 255, .45)", width: "100%"} : {cursor: "pointer", width: "100%"}}>
                                        <td>{instituicao.nomecidade}</td>
                                        <td>{instituicao.siglaestado}</td>     
                                      </tr>
                                    ))
                                  : <tr><td colSpan="4" style={{ textAlign: "center" }}>Nenhuma cidade encontrada!</td></tr>
                                }
                              </tbody>
                            </Table>
                            <p className="pLink" onClick={() => setShowModalCidade(true)}>Clique aqui para vincular a {instituicaoSelecionadaSelect.sigla && instituicaoSelecionadaSelect.sigla !== "" ? instituicaoSelecionadaSelect.sigla : instituicaoSelecionadaSelect.nome} a uma outra cidade.</p>
                          </div>
                        }
                      </React.Fragment>
                    : <p>Selecione uma universidade para gerenciá-la!</p>
                  }
                </React.Fragment>
              </Jumbotron>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal className="no-print" isOpen={showModalCurso} size="lg">
        <ModalCursos instituicao={instituicaoSelecionadaTabela} cursos={cursos} close={(resposta) => handleCloseModalCurso(resposta)}/>
      </Modal>
      <Modal className="no-print" isOpen={showModalCidade} size="lg">
        <ModalCidade instituicao={instituicaoSelecionadaSelect} cidades={instituicoesTabela} close={(resposta) => handleCloseModalCidade(resposta)}/>
      </Modal>
      <Modal className="no-print" isOpen={showModalInstituicao} size="lg">
        <ModalInstituicao close={(resposta) => handleCloseModalInstituicao(resposta)}/>
      </Modal>
    </React.Fragment>
  )
};

export default Instituicoes; 