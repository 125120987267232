import React from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import {REQUEST_URL} from '../config/urls.js';
import 'react-toastify/dist/ReactToastify.css';
import 'react-select/dist/react-select.css';
import { Button, Input, Jumbotron, Col,Row,  Card,CardBody, Table
} from 'reactstrap';
import  NavBar from '../components/NavBar';
import 'react-sliding-pane/dist/react-sliding-pane.css';

export default class Orcamento1b extends React.Component {
  constructor(props){
    super(props);
    document.title = "Orcamento";
    this.state = {
      usuarioLogado:{},
      contatos:[],
      inputId:"",
      vendedor:"",
      cliente:"",
      contatoSelecionado:{},
      step:1,
      ultimosOrcamentos:[],
      orcSelecionado:{},
      showModalMudarStatus:false,
      status:{}
    };
  }
  componentWillMount () {
    axios.defaults.headers.common = {'Authorization': sessionStorage.getItem("token")};
  }
  componentDidMount(){
    if(sessionStorage.getItem('usuarioLogado')){
      this.setState({usuarioLogado:JSON.parse(sessionStorage.getItem('usuarioLogado'))[0]},()=>{       
        let post = {
          codVendedor:this.state.usuarioLogado.idUsuario
        }
        if(this.state.usuarioLogado.Nivel !== "RESTRITO"){
          post.codVendedor = null        
        }
        axios.post(REQUEST_URL+"/orcamentos",post).then((response)=>{
          this.setState({contatos:response.data});
        });
      });
    }
  }
  handleVendedorInput(event){
    let vendedor = event.target.value;
    this.setState({vendedor},()=>{
      this.procurarOrcamento();
    });
  }
  handleClienteInput(event){
    let cliente = event.target.value;
    this.setState({cliente},()=>{
      this.procurarOrcamento();
    });

  }
  handleIdInput(event){
    let inputId = event.target.value;
    this.setState({inputId},()=>{
      this.procurarOrcamento();
    });
  }

  procurarOrcamento(){
    let post ={
      codVendedor:this.state.usuarioLogado.idUsuario,
      cliente:this.state.cliente,
      vendedor:this.state.vendedor,
      idOrc:this.state.inputId
    }
    if(this.state.usuarioLogado.Nivel !== "RESTRITO"){
      post.codVendedor = null        
    }
    axios.post(REQUEST_URL+"/orcamentos",post).then((response)=>{
      this.setState({contatos:response.data});
    });
  }
  reabrirOrcamento(idOrc){
    this.props.history.push({
      pathname:"/orcamento",
      state:{
        cliente:this.state.contatoSelecionado,
        idOrc:this.state.contatoSelecionado.idOrc
      }
    });
  }
  formatReal(n){
    return n ? Number(n.toFixed(2)).toLocaleString('pt-BR') : "NULO";
  }
  render(){
    return (
      <div>       
        <NavBar nome={this.state.usuarioLogado.Nome} funcao={this.state.usuarioLogado.Funcao}/>

        <Row style={{margin:"0px 0px 0px 0px",}}>
          <Col  id="container-orc1" style={{margin:"20px 0px 0px 0px",}}>
            <Card style={{border:"none",borderRadius:0,margin:10, boxShadow:"0 2px 5px 0 rgba(0,0,0,.26)"}}>
              <CardBody >
                <Row>
                  <Col sm="12">
                    <Jumbotron style={{minHeight:600}} className="noselect">
                      <div id="header-orc1">
                        <Link to="/select_client"><Button className="float-right">Selecionar por Cliente?</Button></Link>
                        <Button color="success" className="float-right" style={{marginRight:5}} hidden={!this.state.contatoSelecionado.idOrc} onClick={()=>this.reabrirOrcamento()}>Abrir</Button>
                        <h3>SELECIONE O ORÇAMENTO </h3>
                      </div>
                      <div className="table-responsive" style={{border:"1px solid #6c757d",borderRadius:3}}>
                        Filtros:
                        <Row style={{margin:0,padding:5}}>
                          <Col lg="2" md="4" sm="6" xs="6">
                            <Input placeholder="ID" style={{width:"100%"}}  onChange={this.handleIdInput.bind(this)} value={this.state.inputId}/>
                          </Col>
                          <Col lg="2" md="4" sm="6" xs="6">
                            <Input placeholder="Descrição" style={{width:"100%"}}  onChange={this.handleClienteInput.bind(this)} value={this.state.cliente}/>
                          </Col>
                          <Col lg="2" md="4" sm="6" xs="6">
                            <Input placeholder="Vendedor" style={{width:"100%"}} onChange={this.handleVendedorInput.bind(this)} value={this.state.vendedor}/>
                          </Col>
                        </Row>
                      </div>
                      <div style={{height:450,overflowY:"auto",width:"100%"}} className="lista-orcamento">
                        <Table className="table-bordered table-sm">
                          <thead>
                            <tr>
                              <th style={{width:"16%"}}> idOrc </th>
                              <th style={{width:"16%"}}> Cliente </th>
                              <th style={{width:"16%"}}> Vendedor </th>
                              <th style={{width:"16%"}}> Status </th>
                              <th style={{width:"16%"}}> Data Inclusão </th>
                              <th style={{width:"16%"}}> Custo Total </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.contatos.map((c,i)=>(
                              <tr key={i} style={this.state.contatoSelecionado === c ?
                                   {cursor:"pointer",background:"#bbed21",width:"100%"}:
                                   {cursor:"pointer",width:"100%"}}
                                   onClick={this.state.contatoSelecionado === c ? ()=>this.setState({contatoSelecionado:{}}):()=>this.setState({contatoSelecionado:c}) }>
                                <td style={{width:"16%",whiteSpace: "nowrap",textOverflow: "ellipsis"}}> {c.idOrc} &nbsp;</td>
                                <td style={{width:"16%",whiteSpace: "nowrap",textOverflow: "ellipsis"}}>{c.Cliente} &nbsp;</td>
                                <td style={{width:"16%",whiteSpace: "nowrap",textOverflow: "ellipsis"}}>{c.Vendedor} &nbsp;</td>
                                <td style={{width:"16%",whiteSpace: "nowrap",textOverflow: "ellipsis"}}>{c.Status} &nbsp;</td>
                                <td style={{width:"16%",whiteSpace: "nowrap",textOverflow: "ellipsis"}}>{new Date(c.DataInclusao).toLocaleString()} &nbsp;</td>
                              <td style={{width:"16%",whiteSpace: "nowrap",textOverflow: "ellipsis"}}>R$: {this.formatReal(c.CustTotal)} &nbsp;</td>
                              </tr>
                            ))
                          }
                          </tbody>
                        </Table>
                      </div>
                    </Jumbotron>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}
