import React from 'react';
import {Redirect} from 'react-router';
import {connect} from 'react-redux';

import { ToastContainer} from 'react-toastify';

import { login } from '../actions/LoginActions.js';
import TelegramLog from "../services/telegram";

class Login extends React.Component {
  constructor(props){
    super(props);
    document.title = "Login";
    this.state = {
      login: "",
      senha: "",
      mensagem: { tipo: "erro", mensagem: "" },
      mostrarMensagem: false,
      mostrarSenha: false,
      carregando: false
    };
  }

  async login (e) {
    e.preventDefault();
    this.setState({ carregando: true });
    let campos = {
      login: this.state.login,
      senha: this.state.senha
    };
    this.props.login(campos);
  }
  
  criarLog (tipo, erro, mensagem) {
    new TelegramLog().novo(tipo, [
      JSON.stringify(erro), 
      mensagem, 
      `usuarioLogado: ${this.state.login}`
    ]);
  }

  render () { 
    if (this.props.usuario) {
      return <Redirect to="/" />;
    }

    return (
      <div>
        <ToastContainer position="bottom-left" autoClose={5000} newestOnTop={false} closeOnClick rtl={false} pauseOnVisibilityChange={false} draggable={false}/>
        <div className="card card-container" style={{maxWidth: 350, backgroundColor: "#fff", boxShadow: "0 0 0 1px rgb(0 0 0 / 2%), 0 0 0.5rem 0 rgb(0 0 0 / 1%), 0.25rem 0.5rem 1rem 0 rgb(0 48 111 / 8%)", margin: "0 auto 10px", marginTop: "10%", border: "none", borderRadius: 0}}>
          <div className="card-body" style={{padding:"40px 40px"}}>
            <img alt="avatar" className="profile-img-card" src="//ssl.gstatic.com/accounts/ui/avatar_2x.png" style={{width: "96px",height: "96px",margin: "0 auto 10px",display: "block",MozBorderRadius:"50%",WebkitBorderRadius: "50%",borderRadius: "50%"}} />
            <p className="profile-name-card"></p>
            <form className="form-signin" onSubmit={(e)=>this.login(e)}>
              <span id="reauth-email" className="reauth-email"></span>
              <input style={{marginBottom:10}} value={this.state.login} onChange={(e)=>{this.setState({login:e.target.value})}} type="text" className="form-control" placeholder="Login" required autoFocus/>
              <div style={{position:"relative"}}>
                <input style={{marginBottom:10}} value={this.state.senha} onChange={(e)=>{this.setState({senha:e.target.value})}} type={!this.state.mostrarSenha ?"password":"text"} id="inputPassword" className="form-control" placeholder="Senha" required/>
                <div style={{position:"absolute",right:10,top:7}} onClick={()=>{this.setState({mostrarSenha:!this.state.mostrarSenha})}}>{!this.state.mostrarSenha ?<i className="fas fa-eye-slash"></i>: <i className="fas fa-eye"></i>}</div>
              </div>
              <button style={{backgroundColor:"#51a6c1",border:"none",fontWeight: 700}} className="btn btn-lg btn-primary btn-block btn-signin" type="submit">
                {
                  this.props.carregando ?
                    <span><i className="fas fa-spinner fa-spin"></i> Aguarde</span> 
                  : "Entrar"
                }
              </button>
            </form>
          </div>
        </div>
        {
          this.state.mostrarMensagem ?
            <div className="card card-container" style={{maxWidth:350,padding:"10px 20px 10px 20px",  backgroundColor: "#F7F7F7",boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.3)",margin: "0 auto 25px",  marginTop: 0,border:"none",borderLeft:"10px solid #d33724",borderRadius:0}}>
              {this.state.mensagem.mensagem}
            </div>
          : null
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    usuario: state.usuario.autenticado,
    erro: state.usuario.erro,
    carregando: state.usuario.carregando
  };
}

export default connect(mapStateToProps, {login})(Login);
